import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { NotificationService } from 'src/app/services/notification.service';
import { Lead } from '../../leads/models/lead.model';
import { Observable, finalize, map, zip } from 'rxjs';
import { LeadsService } from "../../leads/services/leads.service";
import { Utils } from "src/app/core/services/utils";
import { User } from 'src/app/models/user/user.model';
import { ReferralSource } from 'src/app/models/referral-source.model';
import { AgentService } from 'src/app/services/agent.service';
import { Tag } from '../../leads/models/lead-list.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { Table } from 'primeng/table';
import { ApplicationService } from '../services/applications.service';
import { LinkLoanToLeadRequest } from '../models/link-as-converted-from-lead.model';
import { LeadFilters } from '../../leads/models/lead-filters.model';
import { LeadFirstLastNameSearchDialogComponent } from './lead-first-last-name-search-dialog/lead-first-last-name-search-dialog.component';
import { Constants } from 'src/app/services/constants';

@Component({
  selector: 'link-to-lead-dialog',
  templateUrl: 'link-to-lead-dialog.component.html',
  styleUrls: ['link-to-lead-dialog.component.scss']
})

export class LinkToLeadDialogComponent extends ApplicationContextBoundComponent implements OnInit {

  @ViewChild('dt1')
  leadTable: Table;

  @ViewChild('searchInput')
  searchInput: HTMLInputElement;

  filteredLeads: Lead[] = [];
  globalFilterFields: string[] = [];
  globalSearchString: string = null;
  selectedColumns: any[] = [];
  columns: any[] = [];
  selectedLead: {
    name: string,
    leadId: number,
  }

  request = new LinkLoanToLeadRequest();

  private _tagLists: Tag[] = [];

  private _referralSources: ReferralSource[];

  private _allLeads: Lead[] = [];

  private firstName;
  private lastName;

  constructor(injector: Injector,
    public activeModal: NgbActiveModal,
    private readonly _modalService: NgbModal,
    private readonly _leadService: LeadsService,
    private readonly _agentService: AgentService,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _applicationService: ApplicationService,
    private _notifyService: NotificationService) {
    super(injector);
  }

  ngOnInit() {
    this._spinnerService.show();
    this.getAllLeadLists();

    this.globalFilterFields = ["displayName", "firstName", "leadStatusName", "leadCampaignName", "loanPurposeName", "leadContactName", "referralSourceName"];

    this.columns = [
      { field: 'lastName', header: 'Name', order: 1, visible: true },
      { field: 'mobilePhone', header: 'Contact Info', order: 2, visible: true },
      { field: 'leadStatusId', header: 'Status / Note', order: 3, visible: true },
      { field: 'leadContactUserId', header: 'Assigned To', order: 4, visible: true },
      { field: 'leadListIds', header: 'Referral Source / Tags', order: 5, visible: true },
      { field: 'dateInserted', header: 'Created On', order: 6, visible: true },
    ]

    this.columns.forEach(column => {
      this.globalFilterFields.push(column.field);
      if (column.visible) {
        this.selectedColumns.push(column);
      }
    });

    const observer = {
      next: (results => {
        this._allLeads = results[0];
        this._referralSources = results[1];
        this.populateOtherLeadPropertiesToDisplayOnTableV2(this._allLeads);
      }),
      error: ((error: any) => {
        this._notifyService.showError(error.error?.message || "Error encountered while getting leads", "Error!")
      })
    }

    const primaryBorrower = this.applicationContext.borrowers.find(b => b.borrowerId === this.applicationContext.application.primaryBorrowerId);

    if (this.applicationContext.borrowers.length > 1) {
      if (primaryBorrower) {
        this.firstName = primaryBorrower.firstName;
        this.lastName = primaryBorrower.lastName;
      }
    }
    else {
      this.firstName = this.applicationContext.borrowers[0].firstName;
      this.lastName = this.applicationContext.borrowers[0].lastName;
    }

    zip(this.onLeadSearch(), this._agentService.getAllReferralSources())
      .pipe(finalize(() => this._spinnerService.hide()))
      .subscribe(observer);
  }

  onSearchAgain = () => {
    let modalRef = this._modalService.open(LeadFirstLastNameSearchDialogComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.firstName = this.firstName;
    modalRef.componentInstance.lastName = this.lastName;

    modalRef.result.then((result: { firstName: string, lastName: string }) => {
      this.firstName = result.firstName;
      this.lastName = result.lastName;

      this._spinnerService.show();
      this.onLeadSearch().subscribe({
        next: (leads) => {
          this._allLeads = leads;
          this.populateOtherLeadPropertiesToDisplayOnTableV2(this._allLeads);
        },
        error: (err) => {
          this._notifyService.showError(err.error?.message || "Error encountered while getting leads", "Error!")
        }
      })
        .add(() => this._spinnerService.hide());
    }, () => {
    });
  }

  onGlobalSearchStringChanged = (e: any) => {
    const globalSearchCache: any = {};
    globalSearchCache.globalSearchString = this.globalSearchString;
    this.filteredLeads = Utils.filter(this.globalFilterFields, this.globalSearchString, this._allLeads);
    this.leadTable.first = 0;
  };

  getLeadListNameById = (tagId: number): string => {
    if (!this._tagLists) {
      return "";
    }
    let matched = this._tagLists.filter(tagList => tagList.tagId == tagId)[0];
    return matched ? matched.name : "";
  }

  onSaveClicked = () => {
    this._spinnerService.show();
    this.request.loanId = this.applicationContext.application.applicationId;
    this._applicationService.linkToLead(this.request)
      .pipe(finalize(() => this._spinnerService.hide()))
      .subscribe({
        next: (result) => {
          this.activeModal.close();
        },
        error: (err) => {
          this._notifyService.showError(err.error?.message || "Error encountered while saving process", "Error!");
        }
      })
  }

  onCancelClicked = () => {
    this.activeModal.dismiss();
  }

  onSelectLead = (data: Lead) => {
    if (this.selectedLead?.leadId === data?.leadId) {
      this.selectedLead = null;
      this.request.leadId = null;
      return;
    }

    this.selectedLead = {
      name: Utils.getPersonsDisplayName(data),
      leadId: data.leadId,
    };

    this.request.leadId = this.selectedLead.leadId;
  }

  getSortedColumns = () => {
    return this.selectedColumns.sort((a, b) => a.order - b.order);
  }

  private onLeadSearch = (): Observable<Lead[]> => {
    const filters = new LeadFilters();
    filters.showArchived = false;

    if (this.firstName) {
      filters.firstName = this.firstName;
    }
    if (this.lastName) {
      filters.lastName = this.lastName;
    }

    return this._leadService.getLeads(filters).pipe(map((leads) => {
      this.filteredLeads = Utils.filter(this.globalFilterFields, this.globalSearchString, [...leads]);
      return leads
    }));
  }

  private populateOtherLeadPropertiesToDisplayOnTableV2 = (leads: Lead[]) => {
    leads.map(lead => {
      lead['displayName'] = Utils.getPersonsDisplayName(lead);
      // populate matched lead status

      const matchedLeadStatus = this.applicationContext.globalConfig.leadStatus.find(status => status.loanStatusId == lead.leadStatusId);
      if (matchedLeadStatus) {
        lead["leadStatusName"] = matchedLeadStatus.loanStatusName;
      }

      // populate matched lead source
      const matchedLeadCampaign = this.applicationContext.globalConfig.leadCampaigns.find(campaign => campaign.leadCampaignId == lead.leadCampaignId);
      if (matchedLeadCampaign) {
        lead["leadCampaignName"] = matchedLeadCampaign.name;
      }

      // populate matched loan purpose
      const matchedLoanPurpose = this.applicationContext.globalConfig.loanPurpose.find(loanPurpose => loanPurpose.loanPurposeId == lead.loanPurposeId);
      if (matchedLoanPurpose) {
        lead["loanPurposeName"] = matchedLoanPurpose.loanPurposeName;
      }

      // populate matched loan type
      const matchedLoanType = this.applicationContext.globalConfig.loanType.find(loanType => loanType.loanTypeId == lead.loanTypeId);
      if (matchedLoanType) {
        lead["loanTypeName"] = matchedLoanType.loanTypeName;
      }

      // populate matched lead contact
      this.populateLeadContactName(lead, lead.leadContactUserId, this.applicationContext.globalConfig.users);

      // populate matched referral source
      var referralSourceItem = this._referralSources.find(agent => agent.agentId == lead.referralSource);
      lead["referralSourceName"] = referralSourceItem ? referralSourceItem.lastName + " " + referralSourceItem.firstName : "";

      return lead;
    }
    )
  }

  private populateLeadContactName = (lead: Lead, leadContactUserId: string, users: User[]) => {
    const matchedLeadContact = users.find(user => user.userCompanyGuid == leadContactUserId);
    if (matchedLeadContact) {
      lead["leadContactName"] = Utils.getPersonsDisplayName(matchedLeadContact);
    }
  }

  private getAllLeadLists = () => {
    this._leadService.getLeadLists().subscribe(lists => {
      this._tagLists = lists.filter(l => l.name != null && l.name.length > 0).sort((a, b) => a.name.localeCompare(b.name)); // name asc
    });
  }
}
