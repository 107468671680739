import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CustomDataItemsModalComponent } from '../custom-data-items-modal/custom-data-items-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EnumItem } from "../../../../../models/enum-item.model";
import { CustomDataConfig } from '../../../../../models';
import Swal, { SweetAlertResult } from "sweetalert2";
import { SystemLevelService } from "../../../../../services/system-level.service";
import { finalize } from "rxjs/operators";
import { NotificationService } from "../../../../../services/notification.service";
import { CustomDataUsagesModal } from "../custom-data-usages-modal/custom-data-usages-modal.component";
import { Constants } from "../../../../../services/constants";
import { NgxSpinnerService } from "ngx-spinner";
import { Lookup } from 'src/app/modules/app-details/models/lookup.model';
import { CustomDataEditorType } from 'src/app/modules/app-details/models/custom-data-config.model';
import { CustomDataConfigService } from 'src/app/services/custom-data-config.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'custom-data-setting',
  templateUrl: './custom-data-setting.component.html'
})
export class CustomDataSettingComponent implements OnInit {
  @ViewChild('customDataSettingForm') customDataSettingForm: NgForm | undefined;
  @Input() label: string;
  @Input() customDataConfigModel: CustomDataConfig;
  @Input() number: number;
  @Input() fieldTypeOptions: EnumItem[];
  @Input()
  set editorTypeOptions(value: EnumItem[]) {
    this._editorTypeOptions = value;
    this.initializeDataTypeToEditorMapping();
    this.setEditorTypeOptions();
  }
  get editorTypeOptions() {
    return this._editorTypeOptions || [];
  }

  @Input() listTypes: EnumItem[];

  @Output() 
  customDataConfigRemoved: EventEmitter<number> = new EventEmitter<number>();

  @Output() 
  customDataConfigSaved: EventEmitter<CustomDataConfig> = new EventEmitter<CustomDataConfig>();

  dropdownSettings: IDropdownSettings = {};
  removing: boolean = false;
  saving: boolean = false;
  dataTypeToEditorMapping: Map<string, EnumItem[]> = new Map<string, EnumItem[]>();
  filteredEditorTypeOptions: EnumItem[];
  private _editorTypeOptions: EnumItem[];

  constructor(
    private readonly _modalService: NgbModal,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _notificationService: NotificationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _customDataConfigService: CustomDataConfigService
  ) { }

  ngOnInit(): void {
    this.dropdownSettings = {
      idField: 'value',
      textField: 'name',
      itemsShowLimit: 2
    };
  }

  initializeDataTypeToEditorMapping(): void {
    this.dataTypeToEditorMapping.set('Integer', this.editorTypeOptions.filter(t => ['Text', 'Currency', 'Percent'].includes(t.name)));
    this.dataTypeToEditorMapping.set('Double', this.editorTypeOptions.filter(t => ['Text', 'Currency', 'Percent'].includes(t.name)));
    this.dataTypeToEditorMapping.set('Decimal', this.editorTypeOptions.filter(t => ['Text', 'Currency', 'Percent'].includes(t.name)));
    this.dataTypeToEditorMapping.set('String', this.editorTypeOptions.filter(t => !['Date', 'Currency', 'Percent'].includes(t.name)));
    this.dataTypeToEditorMapping.set('DateTime', this.editorTypeOptions.filter(t => t.name === 'Date'));
  }

  openCustomDataItemsModal(): void {
    const modalRef = this._modalService.open(CustomDataItemsModalComponent);

    modalRef.componentInstance.customDataConfigModel = this.customDataConfigModel;
    modalRef.componentInstance.multiLookupType = this.customDataConfigModel.multiLookupType;
    modalRef.componentInstance.number = this.number;
    modalRef.componentInstance.listTypes = this.listTypes;

    modalRef.result.then(
      (customLookupItems: Lookup[]) => {
        this.customDataConfigModel.customDropDownOptions = JSON.stringify(customLookupItems);
      },
      () => { }
    );
  }

  removeCustomData(): void {
    Swal.fire({
      title: 'Remove Custom Data',
      text: 'Are you sure you want to remove this custom data. This will delete any data related to this custom field in Applications, Leads, or Expressions. This action cannot be undone!',
      icon: 'question',
      showCancelButton: true
    }).then((result: SweetAlertResult) => {
      if (!result.value) {
        return;
      }
      this.removeCustomDataConfig()
    });
  }

  removeCustomDataConfig(): void {
    this.removing = true;
    this._spinner.show();
    this._customDataConfigService.removeCustomDataConfig(this.customDataConfigModel.customDataFieldConfigId).pipe(finalize(() => {
      this.removing = false;
      this._spinner.hide();
    })).subscribe({
      next: res => {
        this.customDataConfigRemoved.emit(this.customDataConfigModel.customDataFieldConfigId);
        this._notificationService.showSuccess(res.message, 'System Level');
        // if (res.success) {
        //   this.customDataConfigModel.labelText = null;
        //   this.customDataConfigModel.fieldType = '';
        //   this.customDataConfigModel.editorType = CustomDataEditorType.Unspecified;
        // } else {
        //   const modalRef = this._modalService.open(CustomDataUsagesModal, Constants.modalOptions.large);
        //   modalRef.componentInstance.expressionGroupUsages = res.expressionGroupUsage;
        //   modalRef.componentInstance.number = this.number;
        //   modalRef.result.then(
        //     (result) => { },
        //     () => { }
          // );
        // }
      },
      error: error => {
        this._notificationService.showError(`${error ? error.message : 'Unable to remove custom data.'}`, 'System Level');
      }
    });
  }

  saveCustomData = () => {
    if (this.customDataSettingForm) {
      this.customDataSettingForm.form.markAllAsTouched();
      if (!this.customDataSettingForm.form.valid) {
        return;
      }
    }

    this.saving = true;
    this._spinner.show();
    this._customDataConfigService.updateCustomDataConfig(-1, this.customDataConfigModel).pipe(finalize(() => {
      this.saving = false;
      this._spinner.hide();
    })).subscribe({
      next: res => {
        this.customDataConfigModel = res;
        this.customDataConfigSaved.emit(res);
      },
      error: error => {
        this._notificationService.showError(`${error ? error.message : 'Unable to save custom data.'}`, 'System Level');
      }
    });
  }

  onDataTypeChanged(): void {
    this.customDataConfigModel.editorType = CustomDataEditorType.Unspecified;
    this.setEditorTypeOptions();
  }

  private setEditorTypeOptions(): void {
    this.filteredEditorTypeOptions = this.dataTypeToEditorMapping.get(this.customDataConfigModel.fieldType);
  }
}
