import { formatDate } from "@angular/common";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgForm } from "@angular/forms";
import { BaseKeyDate } from "src/app/models";
import { LoanService } from "src/app/services/loan";
import { NotificationService } from "src/app/services/notification.service";
import { FeeWizardTypes } from "../loan-fees.component";

@Component({
  templateUrl: "pre-check-fee-wizard-dialog.component.html",
})
export class PreCheckFeeWizardDialogComponent implements OnInit {

  @Input() estimatedClosingKeyDate: BaseKeyDate;
  @Input() isPurchase: boolean;
  @Input() applicationId: number;
  @Input() applicationReceivedKeyDate: BaseKeyDate;
  @Input() feeWizardTypeToUse: FeeWizardTypes;

  @ViewChild("preFeeWizardForm") preFeeWizardForm: NgForm;

  estimatedClosingDate: string
  disbursement: string;
  firstPayment: string;
  applicationDate: string;

  isSaving: boolean;
  showApplicationDate: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private _loanService: LoanService,
    private _notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.estimatedClosingDate = this.estimatedClosingKeyDate?.eventDate || null;
    this.applicationDate = this.applicationReceivedKeyDate?.eventDate || null;
    if (this.estimatedClosingDate && this.applicationDate) {
      this.showApplicationDate = new Date(this.estimatedClosingDate).getTime() < new Date(this.applicationDate).getTime()
    }
  }

  onEstimatedClosingDateChanged() {
    if (this.estimatedClosingDate && this.applicationDate) {
      this.showApplicationDate = new Date(this.estimatedClosingDate).getTime() < new Date(this.applicationDate).getTime()
    } else {
      this.showApplicationDate = false;
    }
    this.calculateKeyDates();
  }

  onDismiss() {
    this.activeModal.dismiss();
  }

  onSave() {
    this.preFeeWizardForm.form.markAllAsTouched();
    if (!this.preFeeWizardForm.valid) {
      return;
    }

    let request = {
      "EstimatedClosing": this.isValidDate(this.estimatedClosingDate) ? new Date(this.estimatedClosingDate).toISOString() : null,
    }

    if (this.feeWizardTypeToUse !== FeeWizardTypes.MortgageCTO) {
      request["Disbursement"] = this.isValidDate(this.disbursement) ? new Date(this.disbursement).toISOString() : null,
      request["FirstPayment"] = this.isValidDate(this.firstPayment) ? new Date(this.firstPayment).toISOString() : null
    }

    this.isSaving = true;
    this._loanService.saveKeyDatesByType(request, this.applicationId).subscribe({
      next: () => {
        this.activeModal.close()
      },
      error: (error) => {
        this._notificationService.showError(error?.message || "Unable to save key dates", "Error");
      }
    }).add(() => this.isSaving = false)
  }

  private calculateKeyDates() {
    this.calculateDisbursement();
    this.calculatedFirstPaymentDate();
  }

  private calculateDisbursement() {
    if (!this.estimatedClosingDate) {
      this.disbursement = null;
      return;
    }

    if (this.isPurchase) {
      this.disbursement = this.estimatedClosingDate;
    } else {
      this.setDisbursementDate(this.estimatedClosingDate);
    }
  }

  private calculatedFirstPaymentDate() {
    if (!this.disbursement) {
      this.firstPayment = null;
      return;
    }

    this.firstPayment = this.disbursement;
    this.setFirstPaymentDate(this.firstPayment);
  }

  private setDisbursementDate(date: string) {
    if (!date) return;

    const daysToAdd = 3;
    const parsedDate = new Date(date);
    const dayOfAWeek = parsedDate.getDay();
    const now = new Date();

    let newDate: Date;
    if (dayOfAWeek === 3 || dayOfAWeek === 4 || dayOfAWeek === 5) { // Wednesday, Thursday, Friday
      newDate = new Date(parsedDate.getFullYear(), parsedDate.getMonth(), parsedDate.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());
      newDate.setDate(newDate.getDate() + daysToAdd + 2);
    } else if (dayOfAWeek === 6) { // Saturday
      newDate = new Date(parsedDate.getFullYear(), parsedDate.getMonth(), parsedDate.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());
      newDate.setDate(newDate.getDate() + daysToAdd + 1);
    } else {
      newDate = new Date(parsedDate.getFullYear(), parsedDate.getMonth(), parsedDate.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());
      newDate.setDate(newDate.getDate() + daysToAdd);
    }

    this.disbursement = formatDate(newDate, 'MM/dd/yyyy', 'en-US');
  }

  private setFirstPaymentDate(date: string) {
    const monthsToAdd = 2;
    const parsedDate = new Date(date);
    const now = new Date();

    const newDate = new Date(parsedDate.getFullYear(), parsedDate.getMonth(), 1, now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());
    newDate.setMonth(newDate.getMonth() + monthsToAdd);

    this.firstPayment = formatDate(newDate, 'MM/dd/yyyy', 'en-US');
  }

  private isValidDate(dateString: string) {
    if (!dateString) return false;
    const date = Date.parse(dateString);
    return !isNaN(date);
  }
}
