<div class="modal-header">
  <h5 class="modal-title">{{modalTitle}}</h5>
  <button type="button" class="btn-close" (click)="activeModal.close('cancel')" aria-label="Close"></button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="form-group col-md-12">
      <label class="form-label">{{notesLabel}}</label>
      <input type="text" class="form-control" [(ngModel)]="taskNote.note"/>
    </div>
  </div>
  <div class="row" *ngIf="borrowerFacingApplicable">
    <div class="form-group col-md-12">
      <label class="form-label">{{borowerNotesLabel}}</label>
      <input type="text" class="form-control" [(ngModel)]="taskNote.borrowerNote"/>
    </div>
  </div>
</div>
<div class="modal-footer form-footer">
  <button type="button" class="btn btn-secondary right" (click)="activeModal.close('cancel')">
    Cancel
  </button>
  <button type="button" class="btn btn-danger right" (click)="activeModal.close(taskNote)">
    Continue
  </button>
</div>
