import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { Key, ThirdPartyCredential, ThirdPartyKeyValue } from '../../../../../../models';
import { ApplicationContextBoundComponent } from '../../../../../../shared/components';
import { NotificationService } from 'src/app/services/notification.service';
import { ScopeType } from '../../integrations.component';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { SystemLevelService } from 'src/app/services/system-level.service';
import { EnumerationService } from 'src/app/services/enumeration-service';
import * as _ from 'lodash';
import { CreditTechnicalAffiliate } from 'src/app/models/credit/credit-technical-affiliate.model';
import { LoanServicesService } from 'src/app/services/loan';
import { Observer } from 'rxjs';

@Component({
  selector: 'credit-modal',
  templateUrl: './credit-modal.component.html'
})
export class CreditModalComponent extends ApplicationContextBoundComponent implements OnInit {

  @ViewChild('credentialForm') 
  credentialForm: NgForm | undefined;

  @Input()
  set credential(credential: ThirdPartyCredential) {
    this._credential = credential;
    if (credential && credential.vendorName) {
      this.decideIfAffiliatesApplicable();
    }
  }

  get credential(): ThirdPartyCredential {
    return this._credential;
  }

  scope: ScopeType;
  technicalAffiliates: CreditTechnicalAffiliate[] = [];
  creditProviders: EnumerationItem[] = [];

  keys: Key[] = [];

  bureaus: EnumerationItem[] = [
    new EnumerationItem("Experian", "experianDefault"),
    new EnumerationItem("Equifax", "equifaxDefault"),
    new EnumerationItem("TransUnion", "transunionDefault"),
  ];

  selectedDefaultBureaus: string[] = [];

  keyValuePairs = {
    technicalAffiliate: null,
    interfaceIdentifiers: null,
    experianDefault: null,
    equifaxDefault: null,
    transunionDefault: null,
    defaultForOnlineApp: null,
    apiKey: null,
    creditVendorId: null,
    creditClientId: null,
    secretAccessToken: null,
    surrogateLoginUsername: null,
    softPullLoanPurposeString: null
  }

  userCompanyGuid: string;

  branchId?: number;

  isSaving: boolean;
  isUrlDisabled: boolean = true;
  isDefaultForOnlineApp: boolean = false;
  disabledChannel: boolean = false;

  protected affiliatesApplicable: boolean = true;

  protected isLoadingAffiliates: boolean = false;

  private _credential: ThirdPartyCredential;

  constructor(
    private readonly injector: Injector,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _notifyService: NotificationService,
    private readonly _enumerationService: EnumerationService,
    public activeModal: NgbActiveModal,
    private readonly _loanServicesService: LoanServicesService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.keys = [{ key: 'InterfaceIdentifier', displayText: 'Interface Identifier' }];

    this.keyValuePairs.technicalAffiliate = this._credential.thirdPartyKeyValuePairs.find(el => el.key === 'TechnicalAffiliate') || new ThirdPartyKeyValue("TechnicalAffiliate", "");
    this.keyValuePairs.technicalAffiliate.value = this.keyValuePairs.technicalAffiliate.value ? this.keyValuePairs.technicalAffiliate.value : "";

    this.keyValuePairs.surrogateLoginUsername = this._credential.thirdPartyKeyValuePairs.find(el => el.key === 'SurrogateLoginUsername') || new ThirdPartyKeyValue("SurrogateLoginUsername", "");
    this.keyValuePairs.surrogateLoginUsername.value = this.keyValuePairs.surrogateLoginUsername.value ? this.keyValuePairs.surrogateLoginUsername.value : "";

    this.keyValuePairs.softPullLoanPurposeString = this._credential.thirdPartyKeyValuePairs.find(el => el.key === 'SoftPullLoanPurposeString') || new ThirdPartyKeyValue("SoftPullLoanPurposeString", "");
    this.keyValuePairs.softPullLoanPurposeString.value = this.keyValuePairs.softPullLoanPurposeString.value ? this.keyValuePairs.softPullLoanPurposeString.value : "";

    this.keyValuePairs.apiKey = this._credential.thirdPartyKeyValuePairs.find(el => el.key === 'ApiKey') || new ThirdPartyKeyValue("ApiKey", "");
    this.keyValuePairs.apiKey.value = this.keyValuePairs.apiKey.value ? this.keyValuePairs.apiKey.value : ""

    this.keyValuePairs.interfaceIdentifiers = this._credential.thirdPartyKeyValuePairs.filter(el => el.key === 'InterfaceIdentifier') || [];

    this.keyValuePairs.creditVendorId = this._credential.thirdPartyKeyValuePairs.find(el => el.key === 'CreditVendorId') || new ThirdPartyKeyValue("CreditVendorId", "");
    this.keyValuePairs.creditVendorId.value = this.keyValuePairs.creditVendorId.value ? this.keyValuePairs.creditVendorId.value : ""

    this.keyValuePairs.creditClientId = this._credential.thirdPartyKeyValuePairs.find(el => el.key === 'CreditClientId') || new ThirdPartyKeyValue("CreditClientId", "");
    this.keyValuePairs.creditClientId.value = this.keyValuePairs.creditClientId.value ? this.keyValuePairs.creditClientId.value : ""

    this.keyValuePairs.secretAccessToken = this._credential.thirdPartyKeyValuePairs.find(el => el.key === 'SecretAccessToken') || new ThirdPartyKeyValue("SecretAccessToken", "");
    this.keyValuePairs.secretAccessToken.value = this.keyValuePairs.secretAccessToken.value ? this.keyValuePairs.secretAccessToken.value : ""

    this.keyValuePairs.experianDefault = this._credential.thirdPartyKeyValuePairs.find(el => el.key === 'ExperianDefault') || new ThirdPartyKeyValue("ExperianDefault", "false");
    this.keyValuePairs.equifaxDefault = this._credential.thirdPartyKeyValuePairs.find(el => el.key === 'EquifaxDefault') || new ThirdPartyKeyValue("EquifaxDefault", "false");
    this.keyValuePairs.transunionDefault = this._credential.thirdPartyKeyValuePairs.find(el => el.key === 'TransunionDefault') || new ThirdPartyKeyValue("TransunionDefault", "false");
    this.keyValuePairs.defaultForOnlineApp = this._credential.thirdPartyKeyValuePairs.find(el => el.key === 'DefaultForOnlineApp') || new ThirdPartyKeyValue("DefaultForOnlineApp", "false");

    this.creditProviders = _.orderBy(this._enumerationService.creditProviders, ["name"], ["asc"]);
    
    this.convertUiValuesFromKpv();

    if (this.affiliatesApplicable && this._credential.vendorName || this._credential.vendorName === 'EncompassCredit') {
      this.loadtechnicalAffiliates();
    }
  }

  saveCredential(): void {
    if (!this.credentialForm) {
      return;
    }

    this.credentialForm.form.markAllAsTouched();

    if (!this.credentialForm.form.valid) {
      return;
    }

    if (this.scope === 'User') {
      this._credential.userId = this.userCompanyGuid || this.applicationContext.currentlyLoggedInUser.userCompanyGuid;
    }
    if (this.scope === 'Branch') {
      this._credential.branchId = String(this.branchId);
    }

    this.mapUiValuesToKvp();

    this._credential.thirdPartyKeyValuePairs = [
      this.keyValuePairs.technicalAffiliate,
      this.keyValuePairs.experianDefault,
      this.keyValuePairs.equifaxDefault,
      this.keyValuePairs.transunionDefault,
      this.keyValuePairs.defaultForOnlineApp,
      this.keyValuePairs.apiKey,
      this.keyValuePairs.creditVendorId,
      this.keyValuePairs.creditClientId,
      this.keyValuePairs.secretAccessToken
    ];

    if (this._credential.vendorName.includes('MeridianLink') && this.keyValuePairs.technicalAffiliate.value && this.keyValuePairs.technicalAffiliate.value == '5') {
      this._credential.thirdPartyKeyValuePairs.push(this.keyValuePairs.surrogateLoginUsername);
      this._credential.thirdPartyKeyValuePairs.push(this.keyValuePairs.softPullLoanPurposeString);
    }

    this._credential.thirdPartyKeyValuePairs = [
      ...this._credential.thirdPartyKeyValuePairs,
      ...this.keyValuePairs.interfaceIdentifiers
    ];

    this._credential.companyId = this.applicationContext.userPermissions.companyId;

    if (this._credential.vendorName === 'EncompassCredit' && !this._credential.url) {
      this._credential.url = 'xxx';
    }

    this.isSaving = true;
    this._systemLevelService.saveCredential(this._credential)
      .pipe(finalize(() => this.isSaving = false))
      .subscribe({
        next: (res: ThirdPartyCredential) => {
          this._notifyService.showSuccess("Credit details upserted successfully", "Success");
          this.activeModal.close(res);
        },
        error: (error) => {
          this._notifyService.showError(error?.message || "Couldn't upsert credit details", "Error");
        }
      });
  }

  addConfig(): void {
    this.keyValuePairs.interfaceIdentifiers.push({
      key: '',
      value: '',
      alias: '',
      userName: '',
      password: ''
    })
  }

  onTechnicalAffiliateChanged(creditTechnicalAffiliateId: number) {
    this.isUrlDisabled = true;

    if (!creditTechnicalAffiliateId) {
      this._credential.url = ""
    } else {
      const url = this.technicalAffiliates.find(ta => ta.creditTechnicalAffiliateId == creditTechnicalAffiliateId)?.url;
      this._credential.url = url ? url.trim() : "";
    }
  }

  onSelectAllDefaultBureaus() {
    this.selectedDefaultBureaus = this.bureaus.map(b => b.value);
  }

  onChangeVendor(e:{ vendorName: string, companyCredentialId?: number }) {
    if (this._credential.vendorName === "Xactus") {
      this._credential.url = "https://www.ultraamps.com/uaweb/mismo3";
    } else if (this._credential.vendorName === "ISoftPull") {
      this._credential.url = "https://app.isoftpull.com/api/v2/reports";
    } else {
      this._credential.url = null;
    }
    this.decideIfAffiliatesApplicable();
    if (e.vendorName && this.affiliatesApplicable || e.vendorName === 'EncompassCredit') {
      this.loadtechnicalAffiliates();
    }
  }

  private loadtechnicalAffiliates() {
    const observer: Observer<CreditTechnicalAffiliate[]> = {
      next: (affiliates: CreditTechnicalAffiliate[]) => {
        this.technicalAffiliates = affiliates.filter(el => el.mlcid && el.code);
        if (this._credential.vendorName === 'EncompassCredit') {
          this.creditProviders = affiliates.map(el => new EnumerationItem(el.name, el.code));
        }
      },
      error: (err) => {
        this._notifyService.showError(err?.message || 'Unable to load technical affiliates.', 'Credit Credentials Error');
      },
      complete: () => {
      }
    };
    this.isLoadingAffiliates = true;
    this._loanServicesService.getTechnicalAffiliates(this._credential.vendorName).subscribe(observer)
      .add(() => this.isLoadingAffiliates = false);
  }

  private decideIfAffiliatesApplicable() {
    this.affiliatesApplicable = this._credential.vendorName !== 'Xactus' && 
    this._credential.vendorName !== 'CredCoSoftPull' && 
    this._credential.vendorName !== 'CredCoHardPull' &&
    this._credential.vendorName !== 'FactualDataSoftPull' && 
    this._credential.vendorName !== 'FactualDataHardPull' && 
    this._credential.vendorName !== 'EncompassCredit' && 
    this._credential.vendorName !== 'ISoftPull';
  }

  private convertUiValuesFromKpv() {
    this.bureaus.forEach(b => {
      // only bureaus
      if (!this.keyValuePairs[b.value]) return;

      const isSelected = this.keyValuePairs[b.value].value === true ||
        this.keyValuePairs[b.value].value.toString().toLowerCase() === "true";
      if (isSelected) {
        this.selectedDefaultBureaus.push(b.value);
      }
    })

    this.isDefaultForOnlineApp = this.keyValuePairs.defaultForOnlineApp.value === true ||
      this.keyValuePairs.defaultForOnlineApp.value.toString().toLowerCase() === "true";
  }

  private mapUiValuesToKvp() {
    this.bureaus.forEach(b => {
      // only bureaus
      if (!this.keyValuePairs[b.value]) return;

      this.keyValuePairs[b.value].value = (this.selectedDefaultBureaus.indexOf(b.value) > -1).toString().toLowerCase();
    })

    this.keyValuePairs.defaultForOnlineApp.value = this.isDefaultForOnlineApp.toString().toLowerCase();
  }
}
