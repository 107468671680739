import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { SystemLevelService } from '../../../../services/system-level.service';
import { NotificationService } from '../../../../services/notification.service';
import { BorrowerPortalContent, SystemLevel } from 'src/app/models';
import { CustomCssControlsConfig } from '../../../admin/config-shared/components/custom-css-controls/custom-css-controls.component';

@Component({
    selector: 'borrower-portal-settings',
    templateUrl: './borrower-portal-settings.component.html'
})
export class BorrowerPortalSettingsComponent implements OnInit {

    borrowerPortalContent: BorrowerPortalContent = new BorrowerPortalContent();
    loadingSystemData: boolean;
    isSaving: boolean;

    protected readonly customCssControlsConfig = CustomCssControlsConfig.BorrowerPortal();

    constructor(
        private readonly _systemLevelService: SystemLevelService,
        private readonly _notificationService: NotificationService
    ) { }

    ngOnInit(): void {
        this.loadingSystemData = true;
        this._systemLevelService.getBorrowerPortalContent().subscribe((data: BorrowerPortalContent) => {
            if (data) {
                this.borrowerPortalContent = data;
            }
        }).add(() => this.loadingSystemData = false);
    }

    save(): void {
        this.isSaving = true;
        this._systemLevelService.saveBorrowerPortalContent(this.borrowerPortalContent).pipe(finalize(() => {
            this.isSaving = false;
        })).subscribe({
            next: res => {
                this._notificationService.showSuccess(`Settings saved successfully.`, 'System Level');
            },
            error: error => {
                this._notificationService.showError(`${error ? error.message : 'Unable to save.'}`, 'System Level');
            }
        });
    }
}


