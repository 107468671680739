import { Injectable } from '@angular/core';
import { DataService } from '../core/services/data.service';
import { CustomDataConfig } from '../models';

@Injectable({
  providedIn: 'root',
})
export class CustomDataConfigService {
  constructor(private readonly _dataService: DataService) {}

  getCustomDataConfig = (key?: string) => {
    let url = 'api/custom-data-config';
    if (key) {
      url += '?key=' + key;
    }
    return this._dataService.get(url);
  };

  updateCustomDataConfig = (
    customDataFieldConfigId: number,
    customDataConfig: CustomDataConfig
  ) => {
    let url = 'api/custom-data-config/' + customDataFieldConfigId;
    return this._dataService.post(url, customDataConfig);
  };

  removeCustomDataConfig = (customDataFieldConfigId: number) => {
    let url = 'api/custom-data-config/' + customDataFieldConfigId;
    return this._dataService.delete(url);
  };
}
