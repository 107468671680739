import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { ApplicationContext, UserType } from 'src/app/models';
import { AuthData } from 'src/app/models/auth/auth-data.model';
import { AuthenticationUserCompany } from 'src/app/models/auth/authentication-response.model';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { AuthService } from 'src/app/services/auth.service';
import { Constants } from 'src/app/services/constants';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'company-switch',
  templateUrl: 'company-switch.component.html',
  styleUrls: ['./company-switch.component.scss'],
})
export class CompanySwitchComponent implements OnInit, OnDestroy {

  company: AuthenticationUserCompany;

  private readonly _activatedRouteSubscription: Subscription;
  private _applicationContextSubscription: Subscription;
  private _applicationContextSubscription2: Subscription;

  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _navigationService: NavigationService,
    private readonly _authService: AuthService,
    private readonly _localStorageService: LocalStorageService,
    private readonly _applicationContextService: ApplicationContextService
  ) {
    this._activatedRouteSubscription = this._activatedRoute.queryParams.subscribe((params) => {
      const companyGuid = params[Constants.authorization.companyGuid];
      const userType = params[Constants.authorization.userType];
      const userCompanyGuid = params[Constants.authorization.userCompanyGuid];

      this._applicationContextSubscription2 = this._applicationContextService.context.subscribe(context => {
        if (context.userPermissions.superAdmin) {
          const companyForAdmin = context.currentlyLoggedInUser.allUserCompanyData.find(c => c.companyGuid === companyGuid);
          if (!companyForAdmin) {
            throw new Error("Company not found");
          }

          this.company = {
            companyGuid: companyForAdmin.companyGuid,
            companyId: companyForAdmin.companyId,
            companyName: companyForAdmin.companyName,
            companyUrl: companyForAdmin.companyUrl,
            roleId: null,
            roleName: null,
            userType: UserType.Admin
          };
        } else {
          this.company = context.currentlyLoggedInUser.allUserCompanyData.find(c => c.companyGuid === companyGuid && c.userType === userType);
        }
        this.switchToCompany(this.company.companyGuid, this.company.userType, userCompanyGuid || '');
      });
    });
  }

  ngOnInit() { }

  ngOnDestroy(): void {
    this._activatedRouteSubscription?.unsubscribe();
    this._applicationContextSubscription?.unsubscribe();
    this._applicationContextSubscription2?.unsubscribe();
  }

  private switchToCompany = (companyGuid: string, userType: UserType, userCompanyGuid: string) => {
    const authData: AuthData = this._localStorageService.authorizationData;
    this._authService.exchangeToken(companyGuid, userType, userCompanyGuid).subscribe(response => {
      authData.token = response.jwtToken;
      authData.refreshToken = response.refreshToken;
      authData.companyGuid = companyGuid;
      this._localStorageService.authorizationData = authData;
      this._applicationContextService.reset();
      this._applicationContextSubscription = this._applicationContextService.context.subscribe(context => {
        this.onAfterLogin(context);
      });
    });
  }

  private onAfterLogin = (context: ApplicationContext) => {
    if (context.currentlyLoggedInUser.userType === UserType.Tpo) {
      this._navigationService.navigateToPath('tpo');
    } else {
      this._navigationService.navigateToPath('admin');
    }
  };
}
