import { ExpressionDataType, ExpressionFieldType } from "src/app/models/expressions/expression.model";

export class ContactList {
  contactListId: number
  contactListType: ContactListType = null
  isCustom: boolean;
  name: string;
  accessMode: ContactAccessMode = null
  userId: string;
  userAccessList: string;
  tableDefinitionId: number;
  columns: ContactListColumnDefinition[] = [];
  readonly companyId: number
  readonly insertedBy: string;
  readonly dateInserted: string;
  readonly updatedBy: string;
  readonly dateUpdated: string;
  lastUpdated: string;

  constructor(contactListType?: ContactListType, columns?: ContactListColumnDefinition[]) {
    this.contactListType = contactListType || null;
    this.columns = columns || [];
  }
}

export class ContactListColumnDefinition {
  contactListColumnDefinitionId: number;
  contactListId: number;
  columnDefinitionId: number;
  isEnum: boolean;
  contactListEnumListId: number;
  mappedPropertyPath: string;
  isTriggerField: boolean
  isContactMethodField: boolean;
  isMergeField: boolean;
  isHidden: boolean;
  columnDefinition: ColumnDefinition = new ColumnDefinition();
  enumList: ContactListEnumList = new ContactListEnumList();
  readonly companyId: number;
  readonly insertedBy: string;
  readonly dateInserted: string;
  readonly updatedBy: string;
  readonly dateUpdated: string;

  uniqueIdentifier: number; // uique key in case contactListColumnDefinitionId is 0
  nameToShow?: string; // ui
}

export class ColumnDefinition {
  readonly columnDefinitionId: number;
  readonly tableDefinitionId: number;
  name: string;
  useForDeDuplication: boolean;
  dataType: ExpressionDataType;
  fieldType: ExpressionFieldType;
  dataLength: number;
  decimalPlaces: number;
  isSearchable: boolean;
  includeInSearchResult: boolean;
  readonly companyId: number;
  readonly insertedBy: string;
  readonly dateInserted: string;
  readonly updatedBy: string;
  readonly dateUpdated: string;
}

export enum ContactListType {
  Unspecified = 'Unspecified',
  Application = 'Application',
  Agent = 'Agent',
  Borrower = 'Borrower',
  Lead = 'Lead',
  CreditMonitoring = 'CreditMonitoring',
  ExternalBrokerage = "ExternalBrokerage",
  ExternalBroker = "ExternalBroker",
  Conversation = "Conversation",
  Tagged = "Tagged",
  System = "System", // used as a system contact columns list provider
}

export enum ContactAccessMode {
  Public = 'Public',
  Private = 'Private',
  AccessList = 'AccessList'
}

export class ContactListEnumList {
  contactListEnumListId: number;
  name: string;
  values: ContactListEnumItem[] = [];
  readonly companyId: number;
  readonly insertedBy: string;
  readonly updatedBy: string;
  readonly dateUpdated: string;
}

export class ContactListEnumItem {
  readonly contactListEnumItemId: number;
  contactListEnumListId: number;
  value: string;
  readonly companyId: number;
  readonly insertedBy: string;
  readonly dateInserted: Date;
  readonly updatedBy: string;
  readonly dateUpdated: string;
}
