import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import { CacheService } from "src/app/core/services/cache-service";
import {DataService} from "src/app/core/services/data.service";
import { HttpRequestCache } from "src/app/core/services/http-request-cache.decorator";
import { Utils } from "src/app/core/services/utils";
import { RecordType } from "../../dialer/models/dial-list-record-basic.model";
import {Event} from "../models/event.model";
import { OutlookCalendar } from "../models/outlook-calendar.model";

@Injectable()
export class EventsService {
  constructor(
    private readonly _dataService: DataService,
    private readonly _cache: CacheService
  ) {}

  private readonly _refreshSubject = new Subject();

  getEventsByDate = (
    filters?: EventFilters,
    includeLoanEvents?: boolean
  ): Observable<Event[]> => {
    let queryParams = this.requestFilterQueryString(filters);
    if (includeLoanEvents != null) {
      queryParams += `&includeLoanEvents=${includeLoanEvents}`;
    }
    const url = `api/Events/AllEvents?${queryParams}`;
    return this._dataService.get(url);
  };

  getEventsByStartAndEndDate = (
    startDate: string,
    endDate: string,
    includeLoanEvents?: boolean
  ): Observable<Event[]> => {
    let queryParams = `startDate=${startDate}&endDate=${endDate}`;
    if (includeLoanEvents != null) {
      queryParams += `&includeLoanEvents=${includeLoanEvents}`;
    }
    const url = `api/Events/AllEvents?${queryParams}`;
    return this._dataService.get(url);
  };

  @HttpRequestCache<EventsService>(function () {
    return {
      storage: this._cache,
      refreshSubject: this._refreshSubject,
    };
  })
  getEventsByDateCached(filters?: EventFilters): Observable<Event[]> {
    const queryParams = this.requestFilterQueryString(filters);

    const url = `api/Events/AllEvents?${queryParams}`;
    return this._dataService.get(url);
  }

  getEventsByType = (type: RecordType, id: number): Observable<Event[]> => {
    let url = `api/Events/AllEvents`;
    if (type && id) {
      let fieldName = Utils.getRecordIdFieldName(type);
      if (fieldName) {
        url += `?${fieldName}=${id}`;
      }
    }
    return this._dataService.get(url);
  };

  createEvent = (event: Event): Observable<Event> => {
    const url = 'api/Events/NewEvent';
    return this._dataService.post(url, event);
  };

  updateEvent = (event: Event): Observable<Event> => {
    const url = 'api/Events/UpdateEvent';
    return this._dataService.post(url, event);
  };

  deleteEvent = (
    eventId: number,
    thirdPartyEventId: string
  ): Observable<Event> => {
    var url = 'api/Events/DeleteEvent/' + eventId;
    if (thirdPartyEventId) url += `?thirdPartyEventId=${thirdPartyEventId}`;
    return this._dataService.delete(url);
  };

  dropEvent = (
    eventId: number,
    drag: number,
    thirdPartyEventId: string
  ): Observable<Event> => {
    var url = `api/Events/dropEvent/${eventId}/${drag}/true`;
    if (thirdPartyEventId) url += `?thirdPartyEventId=${thirdPartyEventId}`;
    return this._dataService.post(url, {});
  };

  getOutlookCalendars = (userId: string): Observable<OutlookCalendar[]> => {
    var url = `api/events/outlook-calendars`;
    if (userId) url += `?userId=${userId}`;

    return this._dataService.get(url);
  };

  private requestFilterQueryString(filters: EventFilters): string {
    if (!filters) return '';

    return Object.keys(filters)
      .filter((key) => filters[key] != null && filters[key] !== undefined)
      .map((key: keyof EventFilters) => {
        return `${key}=${filters[key]}`;
      })
      .join('&');
  }
}

type EventFilters = {
  filterDate?: string;
  startDate?: string;
  endDate?: string;
}
