import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from '../core/services/data.service';
import { AuditDataValues, McrAuditData, McrAuditDataLookup, McrFullAuditData, McrQuarterYear } from '../modules/reports/mortgage-call/models/mcr-audit-data.model';
import { McrBulkAuditData } from '../modules/reports/mortgage-call/models/mcr-bulk-audit-data.model';
import { McrSearchRequest } from '../modules/reports/mortgage-call/models/mcr-search-request.model';
import { MortgageCall } from '../modules/reports/mortgage-call/models/mortgage-call.model';
import { QuarterEnum } from '../modules/reports/mortgage-call/models/quarter-enum.model';
import { McrSearchResult } from '../modules/reports/mortgage-call/models/mcr-search-result.model';
import { HttpParams } from '@angular/common/http';
import { filterOutNil } from '../core/services/utils';
import { MCRLineNumber } from '../modules/reports/mortgage-call/models/mcr-line-number.model';

@Injectable()
export class MortgageCallReportService {
  constructor(
    private readonly _dataService: DataService
  ) { }

  generateReport = (state: string, year: number, quarter: QuarterEnum, version: string, includeInvestments: boolean, branchId?: number): Observable<MortgageCall> => {
    const fromObject = filterOutNil({
      state,
      year,
      quarter,
      version,
      branchId,
      includeInvestments,
    });
    const queryStr = new HttpParams({ fromObject }).toString();

    const url = `api/mortgage-call-reports/generate/report?${queryStr}`;
    return this._dataService.post(url, {});
  }

  generateXML = (state: string, year: number, quarter: QuarterEnum, version: string, includeInvestments: boolean): Observable<string> => {
    const url = `api/mortgage-call-reports/generate/state-xml?state=${state}&year=${year}&quarter=${quarter}&version=${version}&includeInvestments=${includeInvestments}`;
    return this._dataService.downloadBinaryFileWithPost(url, {});
  }

  generateXMLForStates = (states: string[], year: number, quarter: QuarterEnum, version: string, includeInvestments: boolean): Observable<string> => {
    const url = `api/mortgage-call-reports/generate/nationwide-xml?year=${year}&quarter=${quarter}&version=${version}&includeInvestments=${includeInvestments}`;
    return this._dataService.downloadBinaryFileWithPost(url, states);
  }

  emailXML = (state: string, year: number, quarter: QuarterEnum, version: string, includeInvestments: boolean): Observable<string> => {
    const url = `api/mortgage-call-reports/email/state-xml?state=${state}&year=${year}&quarter=${quarter}&version=${version}&includeInvestments=${includeInvestments}`;
    return this._dataService.downloadBinaryFileWithPost(url, {});
  }

  emailXMLForStates = (states: string[], year: number, quarter: QuarterEnum, version: string, includeInvestments: boolean): Observable<string> => {
    const url = `api/mortgage-call-reports/email/nationwide-xml?year=${year}&quarter=${quarter}&version=${version}&includeInvestments=${includeInvestments}`;
    return this._dataService.downloadBinaryFileWithPost(url, states);
  }

  generateExcel = (state: string, year: number, quarter: QuarterEnum, version: string, includeInvestments: boolean): Observable<string> => {
    const url = `api/mortgage-call-reports/generate/excel?state=${state}&year=${year}&quarter=${quarter}&version=${version}&includeInvestments=${includeInvestments}`;
    return this._dataService.downloadBinaryFileWithPost(url, {});
  }

  getAuditDataForApplication = (applicationId: number): Observable<McrAuditDataLookup[]> => {
    let url = `api/mortgage-call-reports/audit-data/menu/${applicationId}`;
    return this._dataService.get(url);
  }

  getFullAuditData = (appId: number, year?: number, quarter?: QuarterEnum): Observable<McrFullAuditData> => {
    let url = `api/mortgage-call-reports/audit-data/${appId}?year=${year}&quarter=${quarter}`;
    return this._dataService.get(url);
  }

  insertFullAuditData = (appId: number, year?: number, quarter?: QuarterEnum): Observable<McrFullAuditData> => {
    let url = `api/mortgage-call-reports/audit-data/${appId}?year=${year}&quarter=${quarter}`;
    return this._dataService.post(url,{});
  }

  saveAuditData = (appId: number, year: number, quarter: QuarterEnum, data: AuditDataValues): Observable<McrAuditData> => {
    let url = `api/mortgage-call-reports/audit-data/${appId}?year=${year}&quarter=${quarter}`;
    return this._dataService.put(url, data);
  }

  requestSearch(lineNumber: MCRLineNumber, year: number, quarter: QuarterEnum, state: string): Observable<McrSearchRequest> {
    const fromObject = filterOutNil({
      lineNumber,
      year,
      quarter,
      state,
    });
    const queryStr = new HttpParams({ fromObject }).toString();

    const url = `api/mortgage-call-reports/manager/request?${queryStr}`;
    return this._dataService.get(url);
  }

  searchAuditData = (req: McrSearchRequest): Observable<McrSearchResult> => {
    let url = `api/mortgage-call-reports/manager/search`;
    return this._dataService.post(url, req);
  }

  getCurrentYearAndQuarter = () : Observable<McrQuarterYear> =>{
    let url = `api/mortgage-call-reports/get-current-year-quarter`;
    return this._dataService.get(url);
  }

  saveBulkAuditData = (data: McrBulkAuditData): Observable<any> => {
    let url = `api/mortgage-call-reports/manager/patch`;
    return this._dataService.post(url, data);
  }

  getAuditData = (appId: number, year: number, quarter: QuarterEnum): Observable<McrAuditData> => {
    let url = `api/mortgage-call-reports/audit-data/${appId}?year=${year}&quarter=${quarter}`;
    return this._dataService.get(url);
  }

  deleteAuditData = (appId: number, year: number, quarter: QuarterEnum): Observable<McrAuditData> => {
    let url = `api/mortgage-call-reports/audit-data/${appId}?year=${year}&quarter=${quarter}`;
    return this._dataService.delete(url);
  }

  // missing endpoints in new API
  getApplicationsMissingAuditData = (): Observable<number[]> => {
    let url = `api/MortgageCallReports/ApplicationsMissingAuditData`;
    return this._dataService.get(url);
  }

  getStatesWithActivity = (year: number, quarter: QuarterEnum): Observable<string[]> => {
    let url = `api/mortgage-call-reports/get-states-with-activity?quarter=${quarter}&year=${year}`;
    return this._dataService.get(url);
  }
}
