export class HeaderMenuItem {
    children?: HeaderMenuItem[];
    icon: string;
    text: string;
    link: string;
    requireAdminPermission?: boolean;
    requireSuperAdminPermission?: boolean;
    restrictedWhileImpersonating?: boolean;
    selected?: boolean;
    event?: string;
    hide?: boolean;
    data?: any;

    externalLink: boolean = false;

    constructor(children: HeaderMenuItem[], icon: string, text: string, link: string, requireAdminPermission?: boolean, event?: string, data?: any) {
        this.children = children;
        this.icon = icon;
        this.text = text;
        this.link = link;
        this.requireAdminPermission = requireAdminPermission;
        this.event = event;
        this.data = data;
        this.externalLink = false;
    }
}
