import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {LdeInfoComponent} from './lde-info.component';
import {LdeLoanCreateComponent} from './lde-loan-create/lde-loan-create.component';
import {LdeLoanLinkComponent} from './lde-loan-link/lde-loan-link.component';
import {LdeLoanReferenceComponent} from './lde-loan-reference/lde-loan-reference.component';
import {LdeTransactionHistoryComponent} from './lde-transaction-history/lde-transaction-history.component';
import {DatePipe} from '@angular/common';
import {AppDetailsService} from '../app-details/services/app-details.service';
import {LoanDocsService} from '../loan-docs/services/loan-docs.service';
import {LdeSyncDirectionComponent} from './lde-sync-direction/lde-sync-direction.component';
import {LdeSyncDocumentComponent} from './lde-sync-document/lde-sync-document.component';
import {TableModule} from 'primeng/table';
import { LdeService } from 'src/app/services/lde.service';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        SharedModule,
        TableModule,
        NgbTooltipModule
    ],
    declarations: [
        LdeInfoComponent,
        LdeLoanCreateComponent,
        LdeLoanLinkComponent,
        LdeLoanReferenceComponent,
        LdeTransactionHistoryComponent,
        LdeSyncDirectionComponent,
        LdeSyncDocumentComponent
    ],
    exports: [
        LdeInfoComponent,
        LdeLoanCreateComponent,
        LdeLoanLinkComponent,
        LdeLoanReferenceComponent,
        LdeTransactionHistoryComponent,
        LdeSyncDirectionComponent,
        LdeSyncDocumentComponent
    ],
    providers: [
        LdeService,
        AppDetailsService,
        LoanDocsService,
        DatePipe
    ]
})
export class LdeInfoModule {
  constructor() {

  }
}
