import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataService } from "src/app/core/services/data.service";
import { Tag } from "src/app/modules/leads/models/lead-list.model";


@Injectable()
export class TagManagementService {
  constructor(private readonly _dataService: DataService) { }

  getTagList = (): Observable<Tag[]> => {
    const url = "api/tag/list";
    return this._dataService.get(url);
  }

  getTagById = (tagId: number): Observable<Tag> => {
    const url = `api/tag/${tagId}`; 
    return this._dataService.get(url);
  }

  updateTag = (tag: Tag): Observable<Tag> => {
    const url = `api/tag/${tag.tagId}`;
    return this._dataService.post(url, tag);
  }

  deleteTag = (tagId: number): Observable<any> => {
    const url = `api/tag/${tagId}`;
    return this._dataService.delete(url);
  }

  postTag = (tag: Tag): Observable<Tag> => {
    const url = `api/tag/`;
    return this._dataService.post(url, tag);
  }

}