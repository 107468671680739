import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ThirdPartyCredential } from 'src/app/models/third-party/third-party-creds.model';
import { ThirdPartyKeyValue } from 'src/app/models/third-party/third-party-key-value.model';
import { NotificationService } from 'src/app/services/notification.service';
import { SystemLevelService } from 'src/app/services/system-level.service';

@Component({
  selector: 'pmi-rate-pro-vendor-editor-dialog',
  templateUrl: './pmi-rate-pro-vendor-editor-dialog.component.html',
  styleUrls: ['./pmi-rate-pro-vendor-editor-dialog.component.scss']
})
export class PmiRateProVendorEditorDialogComponent implements OnInit {

  @ViewChild("miQuoteForm")
  miQuoteForm: NgForm;

  @Input()
  vendor: ThirdPartyCredential;

  @Input()
  inEditMode: boolean = true;

  urls: string[] = [
    "https://integrator.api.pmiratepro.com",
    "https://api.pmiratepro.com",
    "https://prod.api.pmiratepro.com"
  ];

  constructor(public activeModal: NgbActiveModal,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    if(!this.vendor.thirdPartyKeyValuePairs.length){
      this.vendor.thirdPartyKeyValuePairs = [new ThirdPartyKeyValue("OrganizationId",null)];
    }
  }

  saveCredential = () => {

    this.miQuoteForm.form.markAllAsTouched();
    if (!this.miQuoteForm.form.valid) {
      return;
    }

    const observer = {
      next: (result => {
        this.activeModal.close(result);
        this._spinner.hide();
      }),
      error: (error => {
        this._spinner.hide();
        this._notificationService.showError(error?.message || "An error has been encountered when saving a credential", "Error!")
      })
    }
    this._spinner.show();
    this._systemLevelService.saveCredential(this.vendor).subscribe(observer);
  }

}
