import { PricingScenarioFieldChange } from "./models/pricing/pricing-scenario-field-change.model";
import { ProductSearchRequest } from "./models/pricing/product-search-request-info.model"

export class PricingUtils {

  private static _currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  public static findMortgageModelChanges = (productSearchRequest: ProductSearchRequest, originalProductSearchRequest: ProductSearchRequest): PricingScenarioFieldChange[] => {
    const changes: any[] = [];

    if (
      productSearchRequest.loanInformation.loanPurpose !=
      originalProductSearchRequest?.loanInformation?.loanPurpose
    ) {
      let change = {
        fieldName: 'Loan Purpose',
        oldvalue:
          originalProductSearchRequest?.loanInformation?.loanPurpose,
        newvalue: productSearchRequest.loanInformation.loanPurpose,
      };
      changes.push(change);
    }
    if (productSearchRequest.loanInformation.refinancePurpose) {
      if (
        productSearchRequest.loanInformation.refinancePurpose !=
        originalProductSearchRequest?.loanInformation?.refinancePurpose
      ) {
        let change = {
          fieldName: 'Refinance Purpose',
          oldvalue:
            originalProductSearchRequest?.loanInformation?.refinancePurpose,
          newvalue: productSearchRequest.loanInformation.refinancePurpose,
        };
        changes.push(change);
      }
    }
    if (productSearchRequest.loanInformation.documentationType) {
      if (
        productSearchRequest.loanInformation.documentationType !=
        originalProductSearchRequest?.loanInformation?.documentationType
      ) {
        let change = {
          fieldName: 'Documentation Type',
          oldvalue:
            originalProductSearchRequest?.loanInformation?.documentationType,
          newvalue: productSearchRequest.loanInformation.documentationType,
        };
        changes.push(change);
      }
    }
    if (
      productSearchRequest.loanInformation.baseLoanAmount !=
      originalProductSearchRequest?.loanInformation?.baseLoanAmount
    ) {
      let change = {
        fieldName: 'Loan Amount',
        oldvalue: originalProductSearchRequest?.loanInformation?.baseLoanAmount ? this._currencyFormatter.format(
          originalProductSearchRequest?.loanInformation?.baseLoanAmount
        ) : "",
        newvalue: this._currencyFormatter.format(productSearchRequest.loanInformation.baseLoanAmount),
      };
      changes.push(change);
    }

    if (
      productSearchRequest.loanInformation.secondLienAmount !=
      originalProductSearchRequest?.loanInformation?.secondLienAmount
    ) {
      const secondLienAmountOldValue = originalProductSearchRequest?.loanInformation?.secondLienAmount ?
        this._currencyFormatter.format(
          originalProductSearchRequest?.loanInformation?.secondLienAmount
        ) : "";
      let change = {
        fieldName: 'Second Lien Amount',
        oldvalue: secondLienAmountOldValue,
        newvalue: this._currencyFormatter.format(productSearchRequest.loanInformation.secondLienAmount),
      };
      changes.push(change);
    }

    if (
      productSearchRequest.propertyInformation.appraisedValue !=
      originalProductSearchRequest?.propertyInformation?.appraisedValue
    ) {
      let change = {
        fieldName: 'Appraised Value',
        oldvalue: originalProductSearchRequest?.propertyInformation?.appraisedValue ? this._currencyFormatter.format(
          originalProductSearchRequest?.propertyInformation?.appraisedValue
        ) : "",
        newvalue: this._currencyFormatter.format(productSearchRequest.propertyInformation.appraisedValue),
      };
      changes.push(change);
    }

    if (
      productSearchRequest.propertyInformation.salesPrice !=
      originalProductSearchRequest?.propertyInformation?.salesPrice
    ) {
      let change = {
        fieldName: 'Purchase Price',
        oldvalue: originalProductSearchRequest?.propertyInformation?.salesPrice ? this._currencyFormatter.format(
          originalProductSearchRequest?.propertyInformation?.salesPrice
        ): "",
        newvalue: this._currencyFormatter.format(productSearchRequest.propertyInformation.salesPrice),
      };
      changes.push(change);
    }

    if (
      productSearchRequest.representativeCreditScore !=
      originalProductSearchRequest?.representativeCreditScore
    ) {
      let change = {
        fieldName: 'FICO',
        oldvalue: originalProductSearchRequest?.representativeCreditScore,
        newvalue: productSearchRequest.representativeCreditScore,
      };
      changes.push(change);
    }

    const originalWaiveEscrows = !originalProductSearchRequest?.loanInformation?.waiveEscrows ? false : true;
    const newWaiveEscrows = !productSearchRequest.loanInformation.waiveEscrows ? false : true;
    if (
      originalWaiveEscrows !=
      newWaiveEscrows
    ) {
      let change = {
        fieldName: 'Waive Escrows',
        oldvalue: PricingUtils.getValueOfRelatedField(originalProductSearchRequest?.loanInformation?.waiveEscrows),
        newvalue: PricingUtils.getValueOfRelatedField(productSearchRequest.loanInformation.waiveEscrows),
      };
      changes.push(change);
    }

    const originalSelfEmployed = !originalProductSearchRequest?.borrowerInformation?.selfEmployed ? false : true;
    const newSelfEmployed = !productSearchRequest.borrowerInformation.selfEmployed ? false : true;
    if (
      newSelfEmployed !=
      originalSelfEmployed
    ) {
      let change = {
        fieldName: 'Self Employed',
        oldvalue: PricingUtils.getValueOfRelatedField(originalProductSearchRequest?.borrowerInformation?.selfEmployed),
        newvalue: PricingUtils.getValueOfRelatedField(productSearchRequest.borrowerInformation.selfEmployed),
      };
      changes.push(change);
    }

    const originalFirstTimeHomeBuyer = !originalProductSearchRequest?.borrowerInformation?.firstTimeHomeBuyer ? false : true;
    const newFirstTimeHomeBuyer = !productSearchRequest.borrowerInformation.firstTimeHomeBuyer ? false : true;
    if (
      newFirstTimeHomeBuyer !=
      originalFirstTimeHomeBuyer
    ) {
      let change = {
        fieldName: 'First Time Buyer',
        oldvalue: PricingUtils.getValueOfRelatedField(originalProductSearchRequest?.borrowerInformation?.firstTimeHomeBuyer),
        newvalue: PricingUtils.getValueOfRelatedField(productSearchRequest.borrowerInformation.firstTimeHomeBuyer),
      };
      changes.push(change);
    }

    if (
      productSearchRequest.borrowerInformation.citizenship !=
      originalProductSearchRequest?.borrowerInformation?.citizenship
    ) {
      let change = {
        fieldName: 'Citizenship',
        oldvalue:
          originalProductSearchRequest?.borrowerInformation?.citizenship,
        newvalue: productSearchRequest.borrowerInformation.citizenship,
      };
      changes.push(change);
    }

    if (
      productSearchRequest.propertyInformation.state !=
      originalProductSearchRequest?.propertyInformation?.state
    ) {
      let change = {
        fieldName: 'State',
        oldvalue: originalProductSearchRequest?.propertyInformation?.state,
        newvalue: productSearchRequest.propertyInformation.state,
      };
      changes.push(change);
    }

    if (
      productSearchRequest.propertyInformation.county !=
      originalProductSearchRequest?.propertyInformation?.county
    ) {
      let change = {
        fieldName: 'County',
        oldvalue: originalProductSearchRequest?.propertyInformation?.county,
        newvalue: productSearchRequest.propertyInformation.county,
      };
      changes.push(change);
    }

    if (
      productSearchRequest.propertyInformation.numberOfUnits !=
      originalProductSearchRequest?.propertyInformation?.numberOfUnits
    ) {
      let change = {
        fieldName: 'No of Units',
        oldvalue:
          originalProductSearchRequest?.propertyInformation?.numberOfUnits,
        newvalue: productSearchRequest.propertyInformation.numberOfUnits,
      };
      changes.push(change);
    }

    if (
      productSearchRequest.propertyInformation.occupancy !=
      originalProductSearchRequest?.propertyInformation?.occupancy
    ) {
      let change = {
        fieldName: 'Occupancy',
        oldvalue:
          originalProductSearchRequest?.propertyInformation?.occupancy,
        newvalue: productSearchRequest.propertyInformation.occupancy,
      };
      changes.push(change);
    }

    if (
      productSearchRequest.propertyInformation.zipCode !=
      originalProductSearchRequest?.propertyInformation?.zipCode
    ) {
      let change = {
        fieldName: 'Zip Code',
        oldvalue:
          originalProductSearchRequest?.propertyInformation?.zipCode,
        newvalue: productSearchRequest.propertyInformation.zipCode,
      };
      changes.push(change);
    }

    if (
      productSearchRequest.propertyInformation.attachmentType !=
      originalProductSearchRequest?.propertyInformation?.attachmentType
    ) {
      let change = {
        fieldName: 'Attachment Type',
        oldvalue:
          originalProductSearchRequest?.propertyInformation?.attachmentType,
        newvalue: productSearchRequest.propertyInformation.attachmentType,
      };
      changes.push(change);
    }
    if (
      productSearchRequest.propertyInformation.manufacturedHomeWidthType !=
      originalProductSearchRequest?.propertyInformation?.manufacturedHomeWidthType
    ) {
      let change = {
        fieldName: 'Home Width Type',
        oldvalue:
          originalProductSearchRequest?.propertyInformation?.manufacturedHomeWidthType,
        newvalue: productSearchRequest.propertyInformation.manufacturedHomeWidthType,
      };
      changes.push(change);
    }
    if (
      productSearchRequest.propertyInformation.projectDesignType !=
      originalProductSearchRequest?.propertyInformation?.projectDesignType
    ) {
      let change = {
        fieldName: 'Project Design Type',
        oldvalue:
          originalProductSearchRequest?.propertyInformation?.projectDesignType,
        newvalue: productSearchRequest.propertyInformation.projectDesignType,
      };
      changes.push(change);
    }
    if (
      productSearchRequest.propertyInformation.projectType !=
      originalProductSearchRequest?.propertyInformation?.projectType
    ) {
      let change = {
        fieldName: 'Project Type',
        oldvalue:
          originalProductSearchRequest?.propertyInformation?.projectType,
        newvalue: productSearchRequest.propertyInformation.projectType,
      };
      changes.push(change);
    }
    if (
      productSearchRequest.propertyInformation.isPlannedUnitDevelopment !=
      originalProductSearchRequest?.propertyInformation?.isPlannedUnitDevelopment
    ) {
      let change = {
        fieldName: 'Planned Unit Development (PUD)',
        oldvalue:
          originalProductSearchRequest?.propertyInformation?.isPlannedUnitDevelopment,
        newvalue: productSearchRequest.propertyInformation.isPlannedUnitDevelopment,
      };
      changes.push(change);
    }
    if (
      productSearchRequest.propertyInformation.constructionMethod !=
      originalProductSearchRequest?.propertyInformation?.constructionMethod
    ) {
      let change = {
        fieldName: 'Construction Method',
        oldvalue:
          originalProductSearchRequest?.propertyInformation?.constructionMethod,
        newvalue: productSearchRequest.propertyInformation.constructionMethod,
      };
      changes.push(change);
    }
    if (
      productSearchRequest.buydown !=
      originalProductSearchRequest?.buydown
    ) {
      let change = {
        fieldName: 'BuyDown',
        oldvalue: originalProductSearchRequest?.buydown,
        newvalue: productSearchRequest.buydown,
      };
      changes.push(change);
    }
    // productSearchRequest.loanInformation.baseLoanAmount
    // originalProductSearchRequest?.loanInformation?.baseLoanAmount
    // fieldName: string;
    // oldvalue: string;
    // newValue: string;
    //attachmentType, constructionMethod, manufacturedHomeWithType, projectDesignType, isPlannedUnitDevelopment
    return changes;
  };

  private static getValueOfRelatedField = (value: string | boolean) => {
    if (value === false || value === 'false') {
      return 'No';
    }
    else if (value === true || value === 'true') {
      return 'Yes';
    }
    else {
      return 'No Selected';
    }
  }

}
