import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { CustomData } from 'src/app/models';
import { Lead } from 'src/app/modules/leads/models/lead.model';
import { LeadsService } from 'src/app/modules/leads/services/leads.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'lead-custom-data',
  templateUrl: './lead-custom-data.component.html',
  styleUrls: ['./lead-custom-data.component.scss']
})
export class LeadCustomDataComponent implements OnInit {

  @Input() lead: Lead;

  loading: boolean = false;
  isSaving: boolean = false;
  customData: CustomData[] = [];

  constructor(
    private _leadsService: LeadsService,
    private _notifyService: NotificationService
  ) {

  }

  ngOnInit(): void {
    if (!this.lead.leadId) {
      return;
    }

    this.loading = true
    this._leadsService.getCustomLeadData(this.lead.leadId).subscribe({
      next: (customData) => {
        this.customData = customData;
        if (!this.customData) {
          this.customData = [];
        }
      },
      error: (err) => {
        this._notifyService.showError("Error encountered while loading custom data of the lead", "Error!");
      }
    }).add(() => this.loading = false);
  }

  public isDirty = (): boolean => {

   return false;
  }
}
