import { Injectable } from "@angular/core";
import { EnvironmentService } from "../core/services/environment/environment.service";
import { LocalStorageService } from "../core/services/local-storage.service";
import { ApplicationContext, GenericSignalRMessage, UserType } from '../models';
import { AuthData } from "../models/auth/auth-data.model";
import * as signalR from "@microsoft/signalr";
import { ApplicationContextService } from "./application-context.service";
import { NewLeadReceivedDialogComponent } from "../layout/admin/lead-route-leads/dialogs/new-lead-received-dialog/new-lead-received-dialog.component";
import { EventEditorDialogComponent } from "../modules/events/components/event-editor-dialog/event-editor-dialog.component";
import { EditTaskDialogComponent } from "../modules/tasks/components/edit-task-dialog/edit-task-dialog.component";
import { DialerEventType } from "../modules/dialer/models/dialer-event.model";
import { AdminEventType, AdminService } from "./admin.service";
import { NotificationService } from "./notification.service";
import { LogService } from "./log.service";
import { DialerService } from "../modules/dialer/services/dialer.service";
import { LeadRouteService } from "./lead-route.service";
import { LeadRouteEventType } from "../modules/leads/models/lead-event.model";
import { AlertsCreatedEvent, AlertsService } from "./alerts.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConversationService } from "../modules/conversations/services/conversation.service";
import { FirebaseServicesService } from "./firebase";
import { TaskService } from "./task.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";

@Injectable()
export class SignalRService {

  get connection(): signalR.HubConnection {
    return this._connection;
  }

  private _connection: signalR.HubConnection;

  private _contextSubscription: Subscription;

  private _context: ApplicationContext;

  constructor(
    private readonly _modalService: NgbModal,
    private readonly _localStorageService: LocalStorageService,
    private readonly _conversationService: ConversationService,
    private readonly _applicationContextService: ApplicationContextService,
    private readonly _environment: EnvironmentService,
    private readonly _adminService: AdminService,
    private readonly _notificationService: NotificationService,
    private readonly _logService: LogService,
    private readonly _dialerService: DialerService,
    private readonly _alertsService: AlertsService,
    private readonly _leadRouteService: LeadRouteService,
    private readonly _firebaseServicesService: FirebaseServicesService,
    private readonly _taskService: TaskService,
    private readonly _router: Router,
    private readonly _route: ActivatedRoute
  ) {
    this._contextSubscription = this._applicationContextService.context.subscribe(context => {
      this._context = context;
    });
  }

  run = (context: ApplicationContext) => {
    let connection = this.createConnection(context);
    if (connection == null) {
      setTimeout(() => this.run(context), 1000);
      return;
    }
    const self = this;

    connection.on('InitializeAlertData', function (alertList) {
      // alertFactory.SetAlertData(alertList);
    });

    connection.on('SendToUser', function (model) {
      console.debug('message received: ' + model);
      switch (model.msgType) {
        case 'NewLead':
          const modal = self._modalService.open(NewLeadReceivedDialogComponent)
          modal.componentInstance.notifyOnly = false;
          modal.componentInstance.leadModel = model;
          modal.componentInstance.isFreeForAllAccept = false;
          modal.componentInstance.closeDialog.subscribe(() => {
            modal.close();
          })

          break;
      }
    });

    // Recent Lists

    connection.on('UpdateRecentAgentList', function (payload: GenericSignalRMessage) {
      console.debug('UpdateRecentAgentList called', payload);
      self.onRecentAgentChanged(payload.data);
    });

    connection.on('UpdateRecentAppList', function (payload: GenericSignalRMessage) {
      console.debug('UpdateRecentAppList called', payload);
      self.onRecentApplicationChanged(payload.data);
    });

    connection.on('UpdateRecentLeadList', function (payload: GenericSignalRMessage) {
      console.debug('UpdateRecentLeadList called', payload);
      self.onRecentLeadChanged(payload.data);
    });


    //Messaging / Chat

    connection.on('NewInternalMessagePosted', function (payload: GenericSignalRMessage) {
      console.debug('NewInternalMessagePosted called', payload);
      //TODO: (Piotr)
    });

    connection.on('NewBorrowerMessagePosted', function (payload: GenericSignalRMessage) {
      console.debug('NewBorrowerMessagePosted called', payload);
      if (payload.data.senderId !== context.userPermissions.userId) {
        self._conversationService.addBorrowerChat(
          payload.data.applicationId,
          payload.data.borrowerId,
          payload.data,
          false,
          true
        )
      }
    });

    connection.on('NewSmsHistoryPosted', function (payload: GenericSignalRMessage) {
      console.debug('NewSmsHistoryPosted called', payload);
      if (payload.data.direction == 'Inbound') {
        self._conversationService.openSmsChat({
          userName: payload.data.senderName || payload.data.from,
          userPhone: payload.data.from,
          smsMessage: payload.data,
          external: true
        })
      }
    });

    connection.on('NewGroupMmsHistoryPosted', function (payload: GenericSignalRMessage) {
      console.debug('NewGroupMmsHistoryPosted called', payload);
      if (payload.data.direction == 'Inbound') {
        self._conversationService.openSmsChat({
          userName: payload.data.senderName || payload.data.from,
          userPhone: payload.data.from,
          smsMessage: payload.data,
          conversationId: payload.data.conversationId,
          external: true
        })
      }
    });

    connection.on('ConversationMessageReceived', function (payload: GenericSignalRMessage) {
      console.debug('ConversationMessageReceived called', payload);
      //TODO: (Piotr)
    });

    connection.on('NewAssignedConversationPosted', function (payload: GenericSignalRMessage) {
      console.debug('NewAssignedConversationPosted called', payload);
      //TODO: (Piotr)
    });

    //Generic Events

    connection.on('AppointmentComingUp', function (payload: GenericSignalRMessage) {
      console.debug('appointmentComingUp called', payload);
      const modal = self._modalService.open(EventEditorDialogComponent);
      modal.componentInstance.event = payload.data;
    });

    connection.on('AddAlertData', function (payload: GenericSignalRMessage) {
      console.debug('addAlertData called', payload);
      const alertsCreatedEvent = new AlertsCreatedEvent();
      alertsCreatedEvent.alerts = payload.data;
      self._alertsService.publish(alertsCreatedEvent);
    });

    connection.on('RemoveAlertData', function (payload: GenericSignalRMessage) {
      console.debug('removeAlertData called', payload);
      //TODO: (Piotr) payload.data.alertId
    });

    connection.on('ApplicationUpdated', function (payload: GenericSignalRMessage) {
      console.debug('ApplicationUpdated called', payload);
      //TODO: (Piotr)
    });

    connection.on('CreditReportResponseReceived', function (payload: GenericSignalRMessage) {
      console.debug('creditReportResponseReceived called', payload);
      //payload.data.integrationHistoryId
      self._firebaseServicesService.getReportContent(payload.data).subscribe(result => {
        const newWindow = window.open('', '_blank');
        newWindow.document.write(result);
      })
    });

    if (context.userPermissions.ocrEnabled) {
      connection.on('DocFileSplittingComplete', function (payload: GenericSignalRMessage) {
        console.debug('Doc file event triggered', payload);

        const { borrowerId, applicationId, fileName, applicationName } = payload.data;

        const url = `app-details/${borrowerId}/${applicationId}`;
        const index = window.location.href.indexOf(url);
        const className = `notification-toaster-${Math.random().toString(26).slice(2)}`;

        let message = `File ${fileName} in ${applicationName} loan has been processed.`;
        if (index > -1) {
          message += `Please refresh the page. &nbsp <b><i class="${className} la la-refresh"></i></b>`;
        }

        self._notificationService.showInfo(
          message,
          "Info",
          {
            timer: 10000,
            timerProgressBar: true,
            showCloseButton: true,
          }
        );

        if (index === -1) return;

        setTimeout(() => {
          const refreshIcon = document.querySelector(`.${className}`) as HTMLElement;
          if (!refreshIcon) return;

          refreshIcon.onclick = location.reload;
        }, 100)
      });
    }

    connection.on('LoanDocTasksAdded', function (payload: GenericSignalRMessage) {
      console.debug('LoanDocTasksAdded called', payload);
      self._logService.logSignalRAck('LoanDocTasksAdded', payload.traceId);

      //trigger task reload on the application if the application id matches current loan
      self._applicationContextService.updateLoanTasks();
    });

    connection.on('LoanDocTaskChangedStatus', function (payload: GenericSignalRMessage) {
      console.debug('loanDocTaskChangedStatus called', payload);

      const className = `notification-toaster-${Math.random().toString(26).slice(2)}`
      const message = `<i class="${className}">Click Here</i> to view now`;
      self._notificationService.showInfo(
        message,
        'Task Status Changed!',
        {
          timer: 15000,
          timerProgressBar: true,
          showCloseButton: true,
        }
      );

      setTimeout(() => {
        const clickBtn = document.querySelector(`.${className}}`) as HTMLElement;
        if (!clickBtn) return;

        clickBtn.onclick = () => {
          self._taskService.getTaskDashboardViewById(payload.data.loanDocTaskId).subscribe(task => {
            const modal = self._modalService.open(EditTaskDialogComponent);
            modal.componentInstance.task = task;
          })
        };
      }, 100)
    });

    connection.on('NewDocumentReadyForReview', function (payload: GenericSignalRMessage) {
      console.debug('NewDocumentReadyForReview called', payload);
      const { primaryBorrowerId, appId } = payload.data;
      if (!primaryBorrowerId || !appId) return;

      const message = `<i><a href='admin/app-details/${appId}'>Click Here</a></i> to review now`;
      self._notificationService.showInfo(
        message,
        'New Document Ready for Review!',
        {
          timer: 15000,
          timerProgressBar: true,
          showCloseButton: true,
        }
      );
    });

    connection.on('NotifyUser', function (payload: GenericSignalRMessage) {
      console.debug('notifyUser called', payload);
      const message = `<i>${payload.data.body}</i>`;
      self._notificationService.showInfo(
        message,
        payload.data.head,
        {
          timer: 10000,
          timerProgressBar: true,
          showCloseButton: true,
        }
      );
    });


    //Dialer Events

    const isTPOUser = context.userPermissions.userType == UserType.Tpo;
    if (!isTPOUser) {

      connection.on(LeadRouteEventType.NewAssignedLeadPosted, (payload: GenericSignalRMessage) => {
        console.log('NewAssignedLeadPosted called', payload);

        self._leadRouteService.publish({
          eventType: LeadRouteEventType.NewAssignedLeadPosted,
          data: payload.data,
        })
      });

      connection.on('NewUnassignedLeadPosted', function (payload: GenericSignalRMessage) {
        console.debug('NewUnassignedLeadPosted called', payload);
        const message = `<i><a href='admin/leads?filter=unassigned'>Click Here</i> to go to lead list`;
        self._notificationService.showInfo(
          message,
          "New Lead Available!",
          {
            timer: 60000,
            timerProgressBar: true,
            showCloseButton: true,
          }
        );
      });

      connection.on('NewUnassignedLeadTaken', function (payload: GenericSignalRMessage) {
        console.debug('NewUnassignedLeadTaken called', payload);
        //TODO: (Piotr)
      });

      if (context.userPermissions.dialerEnabled) {
        connection.on(AdminEventType.LeadsOnToggle, (payload: GenericSignalRMessage) => {
          console.log("LeadsOnToggle called", payload);

          self._adminService.publish({
            eventType: AdminEventType.LeadsOnToggle,
            data: payload.data
          })
        });

        connection.on(AdminEventType.CallsOnToggle, (payload: GenericSignalRMessage) => {
          console.log("CallsOnToggle called", payload);
          self._logService.logSignalRAck('CallsOnToggle', payload.traceId);
          self._adminService.publish({
            eventType: AdminEventType.CallsOnToggle,
            data: payload.data,
          })
        });

        connection.on(DialerEventType.CallStatusChanged, (payload: GenericSignalRMessage) => {
          console.log('callStatusChanged called', payload);
          self._logService.logSignalRAck(DialerEventType.CallStatusChanged, payload.traceId);

          self._dialerService.publish({
            eventType: DialerEventType.CallStatusChanged,
            data: payload.data
          })
        });

        connection.on(DialerEventType.DialerDashboardEvent, (payload: GenericSignalRMessage) => {
          console.log('DialerDashboardEvent called', payload);
          self._logService.logSignalRAck(DialerEventType.DialerDashboardEvent, payload.traceId);

          self._dialerService.publish({
            eventType: DialerEventType.DialerDashboardEvent,
            data: payload.data
          })
        });

        connection.on(DialerEventType.ConferenceEnded, (payload: GenericSignalRMessage) => {
          console.log('conferenceEnded called', payload);
          self._logService.logSignalRAck(DialerEventType.ConferenceEnded, payload.traceId);

          self._dialerService.publish({
            eventType: DialerEventType.ConferenceEnded,
            data: {
              conferenceId: payload.data
            }
          })
        });

        connection.on(DialerEventType.DialListRecordRemoved, function (payload: GenericSignalRMessage) {
          console.log('dialListRecordsRemoved called', payload);
          self._logService.logSignalRAck(DialerEventType.DialListRecordRemoved, payload.traceId);

          self._dialerService.publish({
            eventType: DialerEventType.DialListRecordRemoved,
            data: payload.data
          })
        });

        connection.on(DialerEventType.ExternalCallTransferred, function (payload: GenericSignalRMessage) {
          console.debug('ExternalCallTransferred called', payload);
          //TODO: (Piotr)
        });

        connection.on(LeadRouteEventType.FreeForAllLeadAvailable, (payload: GenericSignalRMessage) => {
          console.log('freeForAllLeadAvailable called', payload);
          self._logService.logSignalRAck(LeadRouteEventType.FreeForAllLeadAvailable, payload.traceId);
          self._leadRouteService.publish({
            eventType: LeadRouteEventType.FreeForAllLeadAvailable,
            data: payload.data,
          })
        });

        connection.on(LeadRouteEventType.FreeForAllLeadTaken, (payload: GenericSignalRMessage) => {
          console.log('freeForAllLeadTaken called', payload);
          self._logService.logSignalRAck(LeadRouteEventType.FreeForAllLeadTaken, payload.traceId);
          self._leadRouteService.publish({
            eventType: LeadRouteEventType.FreeForAllLeadTaken,
            data: payload.data,
          })
        });

        connection.on(DialerEventType.ScreenPopRecord, (payload: GenericSignalRMessage) => {
          console.log('ScreenPopRecord called', payload);
          self._logService.logSignalRAck(DialerEventType.ScreenPopRecord, payload.traceId);
          //self._voiceService.loadConferenceParticipants(model.data);

          self._dialerService.publish({
            eventType: DialerEventType.ScreenPopRecord,
            data: payload.data
          })
        });

        connection.on(LeadRouteEventType.WarmTransferLeadAvailable, (payload: GenericSignalRMessage) => {
          console.log('warmTransferLeadAvailable called', payload);
          self._logService.logSignalRAck(LeadRouteEventType.WarmTransferLeadAvailable, payload.traceId);
          self._leadRouteService.publish({
            eventType: LeadRouteEventType.WarmTransferLeadAvailable,
            data: payload.data,
          })
        });

        connection.on(DialerEventType.WarmTransferConnected, (payload: GenericSignalRMessage) => {
          console.log('warmTransferConnected called', payload);
          self._logService.logSignalRAck(DialerEventType.WarmTransferConnected, payload.traceId);

          self._dialerService.publish({
            eventType: DialerEventType.WarmTransferConnected,
            data: payload.data
          })
        });

        connection.on(DialerEventType.WarmTransferCompleted, (payload: GenericSignalRMessage) => {
          console.log('warmTransferCompleted called', payload);
          self._logService.logSignalRAck(DialerEventType.WarmTransferCompleted, payload.traceId);
          //self._voiceService.loadConferenceParticipants(model.data);

          self._dialerService.publish({
            eventType: DialerEventType.WarmTransferCompleted,
            data: payload.data
          })
        });

        connection.on(DialerEventType.WarmTransferCanceled, (payload: GenericSignalRMessage) => {
          console.log('WarmTransferCanceled called', payload);
          self._logService.logSignalRAck(DialerEventType.WarmTransferCanceled, payload.traceId);
          //self._voiceService.loadConferenceParticipants(model.data);

          self._dialerService.publish({
            eventType: DialerEventType.WarmTransferCanceled,
            data: payload.data
          })
        });

        connection.on(DialerEventType.WarmTransferReceived, (payload: GenericSignalRMessage) => {
          console.log('WarmTransferReceived called', payload);
          self._logService.logSignalRAck(DialerEventType.WarmTransferReceived, payload.traceId);
          //self._voiceService.loadConferenceParticipants(model.data);

          self._dialerService.publish({
            eventType: DialerEventType.WarmTransferReceived,
            data: payload.data
          })
        });
      }
    } else {
      connection.on('EncompassDisclosuresUpdate', function (payload: GenericSignalRMessage) {
        console.debug('EncompassDisclosuresUpdate called', payload);
        self._logService.logSignalRAck('EncompassDisclosuresUpdate', payload.traceId);
        if (payload.data.isCompleted) {
          //unregister
          connection.invoke("RemoveRegistrationForLoanEvents", payload.data.applicationId);

          const message = `${payload.data.updateMessage}<br/><br><i><a href='tpo/app-details/${payload.data.applicationId}'>Click Here</i> to go to the loan`;
          self._notificationService.showInfo(
            message,
            "Disclosures Completed",
            {
              timer: 30000,
              timerProgressBar: true,
              showCloseButton: true,
            }
          );

          const queryParams = { ...self._route.snapshot.queryParams };
          queryParams['disclosuresPending'] = false;

          const baseUrl = self._router.url.split('?')[0];

          self._router.navigate([baseUrl], {
            queryParams: queryParams,
          });

        } else {
          self._notificationService.showInfo(
            payload.data.updateMessage,
            "Disclosure Progress Update",
            {
              timer: 7000,
              timerProgressBar: true,
              showCloseButton: true,
            }
          );
        }
      });
    }
  }

  ngDestroy = () => {
    this._contextSubscription.unsubscribe();
    if (this._connection) {
      this._connection.stop().then(() => {
        this._connection = null;
      }).catch(err => {
        return console.error(err);
      });
    }
  }

  createConnection = (context: ApplicationContext): signalR.HubConnection => {
    let authData = this._localStorageService.authorizationData as AuthData;

    if (authData) {

      let userType = context.userPermissions.userType;
      if (!userType) {
        return;
      }

      if (this._connection) {
        return this._connection;
      }

      let connection = new signalR.HubConnectionBuilder()
        .configureLogging(signalR.LogLevel.Trace)
        .withUrl(this._environment.apiInfo.apiBaseUrl + "usernotificationhub", { accessTokenFactory: () => authData.token })
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: (retryContext) => {
            if (retryContext.previousRetryCount < 10) {
              // Retry attempts 0-9 every 5 seconds
              return 5000;
            } else {
              // Retry attempts 10+ every 30 seconds
              return 30000;
            }
          }
        })
        .build();

      connection.onclose(error => {
        console.assert(connection.state === signalR.HubConnectionState.Disconnected);
        this._localStorageService.setItem("HubStatus", connection.state)
        console.log('Live Sync connection closed.');
      });

      connection.onreconnecting(error => {
        console.assert(connection.state === signalR.HubConnectionState.Reconnecting);
        this._localStorageService.setItem("HubStatus", connection.state)
        console.log('We are currently experiencing difficulties with the connection. Reconnecting...');
      });

      connection.onreconnected(connectionId => {
        console.assert(connection.state === signalR.HubConnectionState.Connected);
        this._localStorageService.setItem("HubStatus", connection.state)
        console.log('Connection restored.');
      });

      connection.start().then(() => {
        this._localStorageService.setItem("HubStatus", connection.state);
      }).catch(err => {
        console.error(err);
        return null;
      });

      this._connection = connection;
      return connection;
    }
  }

  private onRecentApplicationChanged = (recentList) => {
    this._applicationContextService.updateRecentAppsList(recentList);
  }

  private onRecentLeadChanged = (recentList) => {
    this._applicationContextService.updateRecentLeadsList(recentList);
  }

  private onRecentAgentChanged = (recentList) => {
    this._applicationContextService.updateRecentAgentsList(recentList);
  }
}
