<ng-container *ngIf="pricedScenarios && pricedScenarios.length > 0">
  <div *ngFor="let pricedScenario of pricedScenarios">
    <p-table #dt2 [value]="pricedScenario.pinnedProducts" *ngIf="pricedScenario.pinnedProducts.length > 0"
      rowGroupMode="rowspan" sortField="productName" sortMode="single" responsiveLayout="scroll"
      styleClass="p-datatable-gridlines p-datatable-sm">
      <ng-template pTemplate="header">
        <tr>
          <th width="830px">
            <span>
              Pinned Prices: {{'Loan Amount: ' + (pricedScenario.pricingScenario.loanInformation.baseLoanAmount |
              currency)
              + ', LTV: ' + (pricedScenario.pricingScenario.loanInformation.ltv | percent : '1.1-2') + ', Loan Purpose:
              '
              + pricedScenario.pricingScenario.loanInformation.loanPurpose}}
            </span>
            <button *ngIf="!transactionType" type="button" class="btn btn-primary btn-sm ms-2" id="addToScenarios"
              (click)="onAddScenarioClicked(pricedScenario)">
              <i class="fas fa-plus me-1"></i> Add to Scenarios
            </button>
          </th>
          <th [ngStyle]="{'width': canPriceApplyToLoan ? '117px' : '69px'}"> Rate </th>
          <th [ngStyle]="{'width': canPriceApplyToLoan ? '152px' : '76px'}"> Price </th>
          <th [ngStyle]="{'width': canPriceApplyToLoan ? '105px' : '136px'}"> Lock Period </th>
          <th style="width: 98px;">P&amp;I</th>
          <th style="width: 98px;" *ngIf="showMI">MI</th>
          <th style="width: 98px;">PI(TI){{showMI ? "/MI" : ""}}</th>
          <th style="width: 150px;">Discount/Rebate</th>
          <th style="width: 132px;" class="text-center" [ngClass]="{'hidden': !canPriceApplyToLoan && !forPriceSelectionOnly}">{{transactionType ? (transactionType == 'reprice' ? 'Reprice' : 'Program Change') : (canPriceApplyToLoan ? 'Apply to Loan' : 'Select')}}</th>
          <th style="width: 113px;" class="text-center" [ngClass]="{'hidden': canPriceApplyToLoan || hideQmColumn}">QM</th>
          <th style="width: 150px;" *ngIf="requestLockEnabled" class="text-center">Forward Lock</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product let-rowIndex="rowIndex">
        <tr>
          <td>
            <div class="d-flex justify-content-between">
              <div class="d-flex" style="align-items: center;">
                <span class="font-bold ml-2 ms-1">{{product.product.productName}}</span>
              </div>
              <div>
                <div *ngFor="let pinnedPrice of product.pinnedPrices" class="ps-2">
                  <a (click)="onShowLoanQuoteClicked(product.product, pinnedPrice, pricedScenario)"
                    title="Show the Loan Quote"> <i class="fas fa-eye text-primary"></i> </a>
                  <a class="ms-1" *ngIf="!transactionType" (click)="onCreateScenarioClicked(product, pinnedPrice, pricedScenario)"
                    title="Add to Scenarios"> <i class="fas fa-plus text-primary"></i> </a>
                </div>
              </div>
            </div>
          </td>
          <td style="padding: 0 !important;">
            <div *ngFor="let pinnedPrice of product.pinnedPrices" class="ps-2">
              {{(pinnedPrice.rate / 100) | percent:'1.3-3'}}
              <a (click)="onUnpinClicked(price, product)"></a>
            </div>
          </td>
          <td>
            <div *ngFor="let pinnedPrice of product.pinnedPrices" class="d-flex justify-content-between">
              {{pinnedPrice.price | number: '1.3-3'}}
              <span class="ms-1">
                <a (click)="onPinClickedFromPinnedScenarios(product.product, pinnedPrice, pricedScenario)">
                  <i class="dripicons-pin text-success"></i>
                </a>
              </span>
            </div>
          </td>
          <td>
            <div *ngFor="let pinnedPrice of product.pinnedPrices">
              {{pinnedPrice.lockPeriod}}
            </div>
          </td>
          <td>
            <div *ngFor="let pinnedPrice of product.pinnedPrices">
              {{pinnedPrice.principalAndInterest | currency}}
            </div>
          </td>

          <td *ngIf="showMI">
            <div *ngFor="let pinnedPrice of product.pinnedPrices" class="d-inline-flex">
              <a *ngIf="!pinnedPrice.selectedMiQuote && !pinnedPrice.monthlyMi && product.product?.product.miRequiredOver80Ltv == 'true'" class="text-warning apply" (click)="onGetMiClicked(product, pinnedPrice)"> Get
                MI </a>
              <span *ngIf="pinnedPrice.selectedMiQuote || pinnedPrice.monthlyMi">{{pinnedPrice.monthlyMi | currency}}</span>
              <a class="ms-2" *ngIf="pinnedPrice.selectedMiQuote" (click)="onSelectMiQuoteClicked(product, pinnedPrice)"><i
                  class="fa fa-calculator"></i></a>
            </div>
          </td>

          <td>
            <div *ngFor="let pinnedPrice of product.pinnedPrices">
              {{pinnedPrice.principalAndInterestTaxesInsurance | currency}}
            </div>
          </td>
          <td style="padding: 0 !important;">
            <div *ngFor="let price of product.pinnedPrices" class="ps-2">
              <span *ngIf="price.price < 100" class="price-discount">
                ({{price.discountPercent | number:'0.3-3'}}) / ({{price.discountDollars | currency}})
              </span>
              <span *ngIf="price.price >= 100" class="price-rebate">
                {{price.rebatePercent | number:'0.3-3'}} / {{price.rebateDollars | currency}}
              </span>
            </div>
          </td>
          <td class="text-center" [ngClass]="{'hidden': !canPriceApplyToLoan && !forPriceSelectionOnly }">
            <div *ngFor="let pinnedPrice of product.pinnedPrices">
              <ng-container *ngIf="!transactionType">
                <ng-container *ngIf="canPriceApplyToLoan">
                  <a class="text-success apply" *ngIf="product?.product?.priceStatus === 'Available'"
                  (click)="onSelectProductPriceForLoan(product.product, pinnedPrice, pricedScenario)">
                    Apply
                  </a>
                  <span class="text-danger apply"  *ngIf="product?.product?.priceStatus !== 'Available'" (click)="onSelectProductPriceForLoan(product.product, pinnedPrice, pricedScenario)" title="Pricing Unavailable">
                    Apply*
                  </span>
                </ng-container>
                <ng-container *ngIf="forPriceSelectionOnly">
                  <span  class="text-primary apply" (click)="onSelectProductPrice(product, price)" title="Select">
                    Select
                  </span>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="isReprice">
                <a class="text-success apply" *ngIf="product?.product?.priceStatus === 'Available'"
                (click)="onRepriceProductPrice(product.product, pinnedPrice, pricedScenario)">
                  Reprice
                </a>
              </ng-container>

              <ng-container *ngIf="isProgramChange">
                <a class="text-success apply" *ngIf="product?.product?.priceStatus === 'Available'"
                (click)="onProgramChangeProductPrice(product.product, pinnedPrice, pricedScenario)">
                  Program Change
                </a>
              </ng-container>
            </div>
          </td>
          <td class="text-center" [ngClass]="{'hidden': canPriceApplyToLoan || hideQmColumn }">
            <div *ngFor="let pinnedPrice of product.pinnedPrices" class="ps-2">
              <a class="text-warning apply"
                (click)="onQualifiedMortgageClicked(product.product, pinnedPrice, pricedScenario)">
                Show </a>
            </div>
          </td>
          <td class="text-center" [ngClass]="{'hidden': !requestLockEnabled }">
            <div *ngFor="let pinnedPrice of product.pinnedPrices" class="ps-2">
              <a class="text-warning apply"
                (click)="onRequestLockClicked(product.product, pinnedPrice, pricedScenario)">
                Request Lock </a>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</ng-container>

<p-table id="scenarios" #dt1 [value]="filteredProducts" [paginator]="true" [rows]="25"
  [ngClass]="{'min-height-class': pinnedProductTableNotEmpty}"
  *ngIf="pricedProducts && filteredProducts" [rowsPerPageOptions]="[25,50,100]"
  styleClass="p-datatable-gridlines p-datatable-sm" [showCurrentPageReport]="true"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" responsiveLayout="scroll"
  [autoLayout]="true" [scrollable]="true" [globalFilterFields]="['productName','price','principalAndInterest']">
  <ng-template pTemplate="caption">
    <div class="p-d-flex align-items-center">
      <div>
        <select class="form-select" name="eligibility" [(ngModel)]="filter" (change)="onFilterChanged()">
          <option value="all">All</option>
          <option value="eligible">Eligible Only</option>
          <option value="ineligible">Ineligible Only</option>
        </select>
      </div>
      <div class="ms-2">
        <span class="text-muted"><small><i> Search Id: {{searchId}} </i></small></span>
      </div>
      <span class="p-input-icon-right p-ml-auto">
        <i class="pi pi-search"></i>
        <input class="form-control" style="padding: 0.375rem 0.75rem" pInputText type="text"
          (input)="dt1.filterGlobal($event.target.value, 'contains')" (ngModelChange)="dt1.first=0"
          placeholder="Search keyword" />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th width="830px">Product Name</th>
      <th [ngStyle]="{'width': canPriceApplyToLoan ? '117px' : '69px'}"> Rate </th>
      <th [ngStyle]="{'width': canPriceApplyToLoan ? '152px' : '76px'}"> Price </th>
      <th [ngStyle]="{'width': canPriceApplyToLoan ? '105px' : '136px'}"> Lock Period </th>
      <th style="width: 98px;">P&amp;I</th>
      <th style="width: 98px;" *ngIf="showMI">MI</th>
      <th style="width: 98px;">PI(TI){{showMI ? "/MI" : ""}}</th>
      <th style="width: 150px;">Discount/Rebate</th>
      <th style="width: 132px;" class="text-center" [ngClass]="{'hidden': !canPriceApplyToLoan && !forPriceSelectionOnly}">{{transactionType ? (transactionType == 'reprice' ? 'Reprice' : 'Program Change') : (canPriceApplyToLoan ? 'Apply to Loan' : 'Select')}}</th>
      <th style="width: 113px;" class="text-center" [ngClass]="{'hidden': canPriceApplyToLoan || hideQmColumn}">QM</th>
      <th style="width: 150px;" *ngIf="requestLockEnabled" class="text-center">Forward Lock</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-product>
    <tr>
      <td>
        <a class="productName ms-1" [ngClass]="{'ineligible-product': !product.isEligible}"
          [disabled]="product.priceStatus !== 'Available'"
          (click)="product.isEligible ? onProductDetailsClicked(product.productId) : onIneligibleProductDetailsClicked(product.productId)">
          {{product.productName}}
          <span class="text-danger" *ngIf="product.priceStatus !== 'Available'"> - Unavailable</span>
        </a>
      </td>
      <td style="padding: 0 !important;"
        [ngClass]="{'pinned': product.prices.length == 1 && product.prices[0].isPinned, 'align-bottom': !canPriceApplyToLoan && product.prices.length > 1 && !transactionType}">
        <div *ngFor="let price of product.prices" class="px-2"
          [ngClass]="{'pinned': price.isPinned, 'height-29 pt-1': product.prices.length > 1}">
          {{(price.rate / 100) | percent:'1.3-3'}}
        </div>
      </td>
      <td style="padding: 0 !important;"
        [ngClass]="{'pinned': product.prices.length == 1 && product.prices[0].isPinned, 'align-bottom': !canPriceApplyToLoan && product.prices.length > 1 && !transactionType}">
        <div *ngFor="let price of product.prices" class="d-flex justify-content-between px-2"
          [ngClass]="{'pinned': price.isPinned, 'height-29 pt-1': product.prices.length > 1}">
          {{price.price | number: '1.3-3'}}
          <span class="ms-1" *ngIf="canPin">
            <a (click)="onPinClicked(product, price)">
              <i
                [ngClass]="{'dripicons-pin text-success': price.isPinned, 'pt-1': product.prices.length > 1, 'fas fa-thumbtack text-danger' : !price.isPinned}">
              </i>
            </a>
          </span>
        </div>
      </td>
      <td style="padding: 0 !important;"
        [ngClass]="{'pinned': product.prices.length == 1 && product.prices[0].isPinned, 'align-bottom': !canPriceApplyToLoan && product.prices.length > 1 && !transactionType}">
        <div *ngFor="let price of product.prices" class="ps-2"
          [ngClass]="{'pinned': price.isPinned, 'height-29 pt-1': product.prices.length > 1}">
          {{price.lockPeriod}}
        </div>
      </td>
      <td style="padding: 0 !important;"
        [ngClass]="{'pinned': product.prices.length == 1 && product.prices[0].isPinned, 'align-bottom': !canPriceApplyToLoan && product.prices.length > 1 && !transactionType}">
        <div *ngFor="let price of product.prices" class="px-2"
          [ngClass]="{'pinned': price.isPinned, 'height-29 pt-1': product.prices.length > 1}">
          {{price.principalAndInterest | currency}}
        </div>
      </td>
      <td style="padding: 0 !important;" *ngIf="showMI"
        [ngClass]="{'pinned': product.prices.length == 1 && product.prices[0].isPinned, 'align-bottom': !canPriceApplyToLoan && product.prices.length > 1 && !transactionType}">
        <div *ngFor="let price of product.prices" class="px-2 d-inline-flex gap-2"
          [ngClass]="{'pinned': price.isPinned, 'height-29 pt-1': product.prices.length > 1}">
          <a *ngIf="!price.selectedMiQuote && !price.monthlyMi && product.product.miRequiredOver80Ltv == 'true'" class="text-warning apply" (click)="onGetMiClicked(product, price)"> Get MI
          </a>
          <span *ngIf="price.selectedMiQuote || price.monthlyMi">{{price.monthlyMi | currency}}</span>

          <div class="dropdown" *ngIf="price.selectedMiQuote || price.monthlyMi">
            <a class="dropdown-toggle" href="#" id="dropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="fa fa-calculator"></i>
            </a>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <a *ngIf="price.selectedMiQuote" class="dropdown-item" (click)="onSelectMiQuoteClicked(product, price)">See Existing Results</a>
              <a class="dropdown-item" (click)="onGetMiClicked(product, price)">Re-Run Search</a>
            </div>
          </div>
        </div>
      </td>
      <td style="padding: 0 !important;"
        [ngClass]="{'pinned': product.prices.length == 1 && product.prices[0].isPinned, 'align-bottom': !canPriceApplyToLoan && product.prices.length > 1 && !transactionType}">
        <div *ngFor="let price of product.prices" class="px-2"
          [ngClass]="{'pinned': price.isPinned, 'height-29 pt-1': product.prices.length > 1}">
          {{price.principalAndInterestTaxesInsurance | currency}}
        </div>
      </td>
      <td style="padding: 0 !important;"
        [ngClass]="{'pinned': product.prices.length == 1 && product.prices[0].isPinned, 'align-bottom': !canPriceApplyToLoan && product.prices.length > 1 && !transactionType}">
        <div *ngFor="let price of product.prices" class="ps-2"
          [ngClass]="{'pinned': price.isPinned, 'height-29 pt-1': product.prices.length > 1}">
          <span *ngIf="price.price < 100" class="price-discount">
            ({{price.discountPercent | number:'0.3-3'}}) / ({{price.discountDollars | currency}})
          </span>
          <span *ngIf="price.price >= 100" class="price-rebate">
            {{price.rebatePercent | number:'0.3-3'}} / {{price.rebateDollars | currency}}
          </span>
        </div>
      </td>
      <td class="text-center" [ngClass]="{'hidden': !canPriceApplyToLoan && !forPriceSelectionOnly}">
        <div *ngFor="let price of product.prices" [ngClass]="{'height-29 pt-1': product.prices.length > 1}">
          <ng-container *ngIf="!transactionType">
            <ng-container  *ngIf="canPriceApplyToLoan">
              <a *ngIf="product.isEligible && product?.product?.priceStatus === 'Available'" class="text-success apply" (click)="onSelectProductPriceForLoan(product, price)">
                Apply
              </a>
              <span *ngIf="!product.isEligible || product?.product?.priceStatus !== 'Available'" class="text-danger apply" (click)="onSelectProductPriceForLoan(product, price)" title="Pricing Unavailable">
                Apply*
              </span>
            </ng-container>
            <ng-container *ngIf="forPriceSelectionOnly">
              <span  class="text-primary apply" (click)="onSelectProductPrice(product, price)" title="Select">
                Select
              </span>
            </ng-container>

          </ng-container>
          <ng-container *ngIf="isReprice">
            <a *ngIf="product.isEligible && product?.product?.priceStatus === 'Available'" class="text-success apply" (click)="onRepriceProductPrice(product, price)">
              Reprice
            </a>
          </ng-container>
          <ng-container *ngIf="isProgramChange">
            <a *ngIf="product.isEligible && product?.product?.priceStatus === 'Available'" class="text-success apply" (click)="onProgramChangeProductPrice(product, price)">
              Program Change
            </a>
          </ng-container>
        </div>
      </td>
      <td class="text-center" style="padding: 0 !important;"
        [ngClass]="{'pinned': product.prices.length == 1 && product.prices[0].isPinned, 'align-bottom': !canPriceApplyToLoan && product.prices.length > 1 && !transactionType, 'hidden': canPriceApplyToLoan || hideQmColumn}">
        <div *ngFor="let price of product.prices" class="ps-2"
          [ngClass]="{'pinned': price.isPinned, 'height-29 pt-1': product.prices.length > 1}">
          <a *ngIf="product.isEligible" class="text-warning apply" (click)="onQualifiedMortgageClicked(product, price)">
            Show </a>
        </div>
      </td>
      <td class="text-center" style="padding: 0 !important;"
        [ngClass]="{'pinned': product.prices.length == 1 && product.prices[0].isPinned, 'align-bottom': !canPriceApplyToLoan && product.prices.length > 1 && !transactionType, 'hidden': !requestLockEnabled}">
        <div *ngFor="let price of product.prices" class="ps-2"
          [ngClass]="{'pinned': price.isPinned, 'height-29 pt-1': product.prices.length > 1}">
          <a class="text-warning apply" (click)="onRequestLockClicked(product, price)"> Request Lock </a>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
