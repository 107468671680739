import { MiQuoteDetail } from "src/app/models/mi-quote-search-result.model";
import { BaseProduct } from "./product-search-result.model";

export class PricedProductRow {
  product: BaseProduct;
  productId: string;
  productName: string;
  priceStatus: string;
  prices: Price[];
  isEligible: boolean;
  principalAndInterest: number;
  totalLoanAmount: number;
  pinnedPrice?: Price;
  loanType: string;

  constructor(product: BaseProduct, prices: Price[], isEligible: boolean, 
      totalLoanAmount: number, priceStatus: string, loanType?: string) {
    this.product = product;
    this.productId = product.productId;
    this.productName = product.productName;
    this.isEligible = isEligible;
    this.prices = prices;
    this.totalLoanAmount = totalLoanAmount;
    this.priceStatus = priceStatus;
    this.loanType = loanType;
  }
}

export class Price {
  rate: number;
  price: number;
  lockPeriod: number;
  principalAndInterest: number;
  monthlyMi: number;
  taxesAndInsurance: number;

  get principalAndInterestTaxesInsurance(): number {
    return (this.principalAndInterest || 0) + (this.taxesAndInsurance || 0) + (this.monthlyMi || 0);
  }

  discountPercent: number;
  discountDollars: number;
  rebatePercent: number;
  rebateDollars: number;
  isPinned?: boolean;
  miQuotes: MiQuoteDetail[] = [];
  selectedMiQuote: MiQuoteDetail = null;

  constructor(rate: number, price: number, lockPeriod: number, principalAndInterest: number, monthlyMi: number, taxesInsuranceMonthly: number,
    discountPercent: number, discountDollars: number, rebatePercent: number, rebateDollars: number, isPinned?: boolean) {
    this.rate = rate;
    this.price = price;
    this.lockPeriod = lockPeriod;
    this.principalAndInterest = principalAndInterest;
    this.taxesAndInsurance = taxesInsuranceMonthly;
    this.monthlyMi = monthlyMi;
    this.discountPercent = discountPercent;
    this.discountDollars = discountDollars;
    this.rebatePercent = rebatePercent;
    this.rebateDollars = rebateDollars;
    this.isPinned = isPinned;
  }

  
}
