import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataService } from "src/app/core/services/data.service";
import { Lender } from "src/app/models/config/lender.model";
import { CacheService } from "src/app/services/cache.service";


@Injectable()
export class LenderConfigService {
  constructor(private readonly _dataService: DataService,
    private readonly _cacheService: CacheService
  ) {}
  CACHEKEY = `api/Admin/AllLender`;

  getAllLenders = (): Observable<Lender[]> => {
    const url = `api/Admin/AllLender`;
    return this._cacheService.wrap<Lender[]>(this.CACHEKEY, this._dataService.get(url), 60, true);
  };

  insertLender = (lender: Lender): Observable<Lender> => {
    const url = `api/Admin/InsertLender`;
    this._cacheService.clearByKey(this.CACHEKEY);
    return this._dataService.post(url, lender);
  };

  updateLender = (lender: Lender): Observable<any> => {
    const url = `api/Admin/UpdateLender`;
    this._cacheService.clearByKey(this.CACHEKEY);
    return this._dataService.post(url, lender);
  };

  deleteLender = (lenderId: number, replaceWithLenderId?: number): Observable<any> => {
    const url = `api/Admin/DeleteLender/${lenderId}${replaceWithLenderId ? '?replaceWithLenderId=' + replaceWithLenderId : ''}`;
    this._cacheService.clearByKey(this.CACHEKEY);
    return this._dataService.delete(url);
  };

  reOrderLenders = (lenderIds: number[]): Observable<any> => {
    const url = `api/Admin/ReorderLenders`;
    this._cacheService.clearByKey(this.CACHEKEY);
    return this._dataService.post(url, lenderIds);
  };

  getTitleLenders = (): Observable<any[]> => {
    const url = `api/title/lenders`;
    return this._dataService.get(url);
  };
}
