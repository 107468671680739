import { CdkDrag, CdkDragDrop, CdkDropList, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin } from 'rxjs';
import { CustomDataConfig, UserType } from 'src/app/models';
import { CustomDataConfigService } from 'src/app/services/custom-data-config.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
    selector: 'custom-data-fields-layout',
    templateUrl: './custom-data-fields-layout.component.html',
    styleUrls: ['./custom-data-fields-layout.component.scss']
})
export class CustomDataFieldsLayoutComponent implements OnInit {

    @Input()
    set customDataFieldConfigModels(value: CustomDataConfig[]) {
        this._customDataFieldConfigModels = value.filter(c => c.customDataFieldConfigId);
    }
    
    get customDataFieldConfigModels(): CustomDataConfig[] {
        return this._customDataFieldConfigModels;
    } 

    roleType: UserType = UserType.Admin;

    customDataFieldConfigsLayout: Map<number, CustomDataConfig[]> = new Map<number, CustomDataConfig[]>();

    filteredCustomDataFieldConfigModels: CustomDataConfig[] = [];

    isSaving: boolean = false;

    private _customDataFieldConfigModels: CustomDataConfig[];

    constructor(
        private readonly _notificationService: NotificationService,
        private readonly _spinner: NgxSpinnerService,
        private readonly _customDataConfigService: CustomDataConfigService
    ) { }

    ngOnInit(): void {
        this.initialize();
    }

    initialize = () => {
        this.filteredCustomDataFieldConfigModels = [];
        for (let i=1; i <= this.customDataFieldConfigModels.length; i++) {
            this.customDataFieldConfigsLayout.set(i, []);
        }
        this.customDataFieldConfigModels.forEach(customDataConfig => {
            if (customDataConfig.layouts) {
                const layout = customDataConfig.layouts.find(l => l.roleType == this.roleType)
                if (layout && layout.uiRow && layout.uiColumn) {
                    const layoutIndex = (layout.uiRow - 1) * 2 + layout.uiColumn;
                    this.customDataFieldConfigsLayout.set(layoutIndex, [customDataConfig]);
                } else {
                    this.filteredCustomDataFieldConfigModels.push(customDataConfig);
                }
            } else {
                this.filteredCustomDataFieldConfigModels.push(customDataConfig);
            }
        });
    }

    roleTypeChanged = () => {
        this.initialize();
    }

    drop(event: CdkDragDrop<any[]>) {
        // this.customDataFieldConfigsLayout.set(key, [event.item.data]);

        transferArrayItem(event.previousContainer.data,
            event.container.data,
            event.previousIndex,
            event.currentIndex);
    }

    dropListEnterPredicate(customDataFieldConfig) {
        return function(drag: CdkDrag, drop: CdkDropList) {
            return !customDataFieldConfig.length;
        };
    }

    noReturnPredicate() {
        return function(drag: CdkDrag, drop: CdkDropList) {
            return false;
        };
    }

    save = () => {
        let observables = [];
        this.customDataFieldConfigModels.forEach(customDataFieldConfig => {
            const index  = this.filteredCustomDataFieldConfigModels.findIndex(f => f.customDataFieldConfigId === customDataFieldConfig.customDataFieldConfigId);
            if (index > -1) {
                const layoutIndex = customDataFieldConfig.layouts.findIndex(l => l.roleType === this.roleType);
                if (layoutIndex > -1) {
                    customDataFieldConfig.layouts[layoutIndex].uiColumn = null;
                    customDataFieldConfig.layouts[layoutIndex].uiRow = null;
                }
            } else {
                this.customDataFieldConfigsLayout.forEach((value, key) => {
                    if (value.findIndex(c => c.customDataFieldConfigId === customDataFieldConfig.customDataFieldConfigId) > -1) {
                        if (customDataFieldConfig.layouts && customDataFieldConfig.layouts.length) {
                            const layoutIndex = customDataFieldConfig.layouts.findIndex(l => l.roleType === this.roleType);
                            if (layoutIndex > -1) {
                                customDataFieldConfig.layouts[layoutIndex].uiColumn = key % 2 === 1 ? 1 : 2;
                                customDataFieldConfig.layouts[layoutIndex].uiRow = Math.ceil(key/2);
                            } else {
                                customDataFieldConfig.layouts.push({
                                    userType: this.roleType,
                                    roleType: this.roleType,
                                    uiColumn: key % 2 === 1 ? 1 : 2,
                                    uiRow: Math.ceil(key/2)
                                });
                            }
                        } else {
                            customDataFieldConfig.layouts = [];
                            customDataFieldConfig.layouts.push({
                                userType: this.roleType,
                                roleType: this.roleType,
                                uiColumn: key % 2 === 1 ? 1 : 2,
                                uiRow: Math.ceil(key/2)
                            });
                        }
                    }
                });
            }

            observables.push(this._customDataConfigService.updateCustomDataConfig(customDataFieldConfig.customDataFieldConfigId,customDataFieldConfig));
        });

        this._spinner.show;
        forkJoin(observables).subscribe({
            next: (results) => {
                this.customDataFieldConfigModels = results;
            },
            error: (err) => {
              this._notificationService.showError(
                err ? (err.data ? err.data.message : '') : '',
                'Error'
              );
            },
        })
        .add(() => this._spinner.hide());
    }
}