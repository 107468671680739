import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GlobalConfig } from "../models/config/global-config.model";
import { DataService } from "../core/services/data.service";
import { map } from "rxjs/operators";
import { TimeZone } from "../models/timezone.model";
import { CacheService } from "./cache.service";

@Injectable()
export class GlobalConfigService {

    constructor(private readonly _dataService: DataService,
        private readonly _cacheService: CacheService) {
    }

    getConfig = (companyId: number): Observable<GlobalConfig> => {
        const url = `api/Admin/PageResource/ALL?companyId=${companyId}`;
        return this._cacheService.wrap<GlobalConfig>(url, this._dataService.get(url), 60, false, true);
    }

    getMenu = () => {
        const url = `./assets/app-data.json`;
        return this._dataService.getRelativePath(url);
    }

    getTimeZones(): Observable<TimeZone[]> {
        return this._dataService.get('api/Admin/PageResource/TimeZone').pipe(
            map(response => {
                return response.timezone;
            })
        );
    }
}
