<form #sendEmailForm="ngForm" ngbAutofocus>
  <ngx-spinner bdColor="rgba(51,51,51,0.3)" size="large" color="#fff" type="ball-scale-multiple" [fullScreen]="false"
    [name]="sendEmailSpinner">
    <p style="font-size: 20px; color: white">Please wait...</p>
  </ngx-spinner>
  <div class="mb-3" *ngIf="showBorrowerPicker">
    <label class="control-label">Borrowers</label>
    <ng-select2 *ngIf="!forSendingVideoEmailFromTemplate" class="select2 mb-3 select2-multiple" name="borrowersToSendTo"
      [(ngModel)]="selectedBorrowerIds" [options]="optionsMultipleSelect" [data]="borrowerOptions"
      (ngModelChange)="onSelectedBorrowersChanged()">
    </ng-select2>
    <select *ngIf="forSendingVideoEmailFromTemplate" class="form-select" name="borrowersToSendTo"
      [(ngModel)]="selectedBorrowerIds" (ngModelChange)="onSelectedBorrowersChanged()">
      <option *ngFor="let borrower of borrowerOptions" [value]="borrower.id">
        {{ borrower.text }}
      </option>
    </select>
  </div>
  <div class="mb-3" *ngIf="showSendFrom">
    <label class="control-label" for="sendFrom">Send From</label>
    <select class="form-select" name="sendFrom" [(ngModel)]="impersonationFromUserId"
      (ngModelChange)="onImpersonationFromChanged()">
      <option [ngValue]="null">-- Select One --</option>
      <ng-container *ngFor="let extendedInternalContact of extendedInternalContacts | groupBy: 'roleName'">
        <optgroup label="{{extendedInternalContact.value[0].roleName}}">
          <option *ngFor="let item of extendedInternalContact.value" value="{{item.userId}}">
            {{item.formattedName}}
          </option>
        </optgroup>
      </ng-container>
    </select>
  </div>
  <div class="mb-3">
    <label class="control-label" for="recipientEmailAddresses">To</label>
    <div class="d-flex">
      <div class="flex-grow-1">
        <input class="form-control" type="email" trim
          [ngClass]="{ 'is-invalid' : recipients && recipients.touched && recipients.invalid }"
          name="recipientEmailAddresses" [(ngModel)]="commaDelimitedRecipientEmailAddresses" #recipients="ngModel"
          required />
        <div class="invalid-feedback">To is required.</div>
      </div>
      <button class="btn btn-primary float-end mx-2" type="button" [disabled]="isLoading"
        (click)="onAddContactsToEmailClicked()">
        <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        {{ isLoading ? 'Please wait...' : 'Add Contacts to Email'}}
      </button>
    </div>
  </div>
  <div class="mb-3">
    <a class="nav-link" [hidden]="isLoading" style="padding: 0;" (click)="willAddCcOrBcc = true;">CC or BCC</a>
  </div>
  <ng-container *ngIf="willAddCcOrBcc">
    <div class="mb-3">
      <label class="control-label">CC</label>
      <div class="d-flex">
        <div class="flex-grow-1">
          <input class="form-control" trim name="ccEmailAddresses" [(ngModel)]="commaDelimitedCcEmailAddresses"
            #recipients="ngModel" />
        </div>
        <button class="btn btn-primary float-end mx-2" type="button" [disabled]="isLoading"
          (click)="onAddContactsAsCcToEmailClicked()">
          <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          {{ isLoading ? 'Please wait...' : 'Add Contacts to Email'}}
        </button>
      </div>
    </div>
    <div class="mb-3">
      <label class="control-label">BCC</label>
      <div class="d-flex">
        <div class="flex-grow-1">
          <input class="form-control" trim name="bccEmailAddresses" [(ngModel)]="commaDelimitedBccEmailAddresses"
            #recipients="ngModel" />
        </div>
        <button class="btn btn-primary float-end mx-2" type="button" [disabled]="isLoading"
          (click)="onAddContactsAsBccToEmailClicked()">
          <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          {{ isLoading ? 'Please wait...' : 'Add Contacts to Email'}}
        </button>
      </div>
    </div>
  </ng-container>
  <div *ngIf="!forSendingVideoEmailFromTemplate" class="mb-3">
    <a class="nav-link" [hidden]="isLoading" style="padding: 0;" (click)="useTemplateEmail = true;">Use Template
      Email</a>
  </div>
  <ng-container *ngIf="useTemplateEmail">
    <div class="mb-3">
      <label class="control-label" for="emailTemplate">Email Template</label>
      <select class="form-select" name="emailTemplate" id="emailTemplate" [(ngModel)]="selectedEmailTemplateId"
        (ngModelChange)="onSelectedEmailTemplateChanged()" placeholder="Select a Template">
        <option value="">--Select a Template--</option>
        <option [value]="emailTemplate.emailTemplateId" *ngFor="let emailTemplate of emailTemplates"
          [hidden]="!emailTemplate.emailTemplateName">
          {{emailTemplate.emailTemplateName}}</option>
      </select>
    </div>
  </ng-container>
  <div class="mb-3">
    <label class="control-label" for="subject">Subject</label>
    <div class="d-flex">
      <div class="flex-grow-1">
        <input class="form-control"
          [ngClass]="{ 'is-invalid' : emailSubject && emailSubject.touched && emailSubject.invalid }" name="subject"
          [(ngModel)]="subject" #emailSubject="ngModel" required />
        <div class="invalid-feedback">Subject is required.</div>
      </div>
    </div>
  </div>
  <div class="mb-3">
    <label class="control-label" for="body">Body</label>
    <rich-text-editor [name]="'emailBody'" [(ngModel)]="body">
    </rich-text-editor>
    <div *ngIf="!body && validationDone" class="invalid">Body is required.</div>
  </div>
  <div class="custom-control custom-switch switch-success">
    <input type="checkbox" class="custom-control-input" id="appendEmailSignature" name="appendEmailSignature"
      [(ngModel)]="appendEmailSignature">
    <label style="padding-left: 4px;" class="form-label" for="appendEmailSignature">Append Email Signature</label>
  </div>
  <button *ngIf="!forSendingVideoEmailFromTemplate" class="btn btn-primary" type="button"
    [disabled]="isLoading || (attachments && attachments.length > 0)" name="addAttachmentButton"
    (click)="onAddAttachmentsClicked()">
    <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    {{ isLoading ? 'Please wait...' : 'Add Attachments'}}
  </button>
  <button *ngIf="!forSendingVideoEmailFromTemplate" type="button" class="btn" (click)="recordVideo()">
    <i class="fa fa-video"></i> Record Video
  </button>
  <label class="mx-2" *ngIf="numberOfFilesAttached">{{numberOfFilesAttached}} files attached</label>
</form>