import {Injectable} from '@angular/core';
import {formatCurrency, formatDate, formatPercent} from '@angular/common';
import {GlobalConfig} from 'src/app/models/config/global-config.model';
import {Constants} from 'src/app/services/constants';
import {User} from 'src/app/models/user/user.model';
import * as _ from 'lodash';
import {ChannelService} from 'src/app/services/channel.service';
import {CustomDataEditorType} from '../../app-details/models/custom-data-config.model';
import * as moment from 'moment';
import {isNullOrUndefined} from 'src/utils';
import {EnumerationService} from 'src/app/services/enumeration-service';
import {map, Observable, of, Subscription} from 'rxjs';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';

@Injectable()
export class CustomDataService {
  
  timeZones: Array<string> = [];

  private _companyStatuses: EnumerationItem[] = [];

  private _enumsSubscription: Subscription;

  constructor(
    private readonly _channelService: ChannelService,
    private readonly _enumerationService: EnumerationService
  ) {
    this.timeZones = Constants.timeZones;
    this._enumsSubscription = this._enumerationService.getCommonEnumerations().subscribe(enums => {
      this._companyStatuses = enums[Constants.commonEnumerations.companyStatus];
    })
  }

  getSelectData(lookupData: GlobalConfig, fieldType: string): Observable<any> {
    const getData = (): any[] | Observable<any[]> => {
      switch (fieldType) {
        case 'ExternalCompany':
          return _.orderBy(
            lookupData.externalCompanies,
            ['name'],
            ['asc']
          );
        case 'LoanType':
          return lookupData.loanType;
        case 'LoanPurpose':
          return lookupData.loanPurpose;
        case 'LoanStatus':
        case 'AgentLoanStatus':
          return lookupData.loanStatus;
        case 'LeadStatus':
          return lookupData.leadStatus;
        case 'User':
          return _.orderBy(
            lookupData.usersAll,
            ['active', 'firstName', 'lastName'],
            ['desc', 'asc', 'asc']
          );
        case 'Product':
          return lookupData.product;
        case 'Lender':
          return lookupData.lender;
        case 'LookupList':
          return lookupData.lookup;
        case 'LeadCampaign':
          return lookupData.leadCampaigns;
        case 'Branch':
          return _.orderBy(lookupData.branches, ['branchName']);
        case 'LeadRoute':
          return lookupData.lookupLeadRoutes;
        case 'LeadRouteGroup':
          return lookupData.lookupLeadRouteGroups;
        case 'RedistributionRoute':
          return lookupData.lookupRedistributionRoutes;
        case 'RedistributionRouteGroup':
          return lookupData.lookupRedistributionRouteGroups;
        case 'TimeZone':
          return this.timeZones;
        case 'SubStatus':
          return lookupData.subStatus;
        case 'Channel':
          return this._channelService.getChannelsFromCommaDelimitedString(
            lookupData.enabledChannels.map(c => c.name).join(),
            true
          );
        case 'ChannelFlags':
          return this._channelService.getChannelsFromCommaDelimitedString(
            lookupData.enabledChannels.map(c => c.name).join()
          );
        case 'CompanyStatus':
          return this._companyStatuses;
        case 'AgentTags':
          return lookupData.agentList;
        case 'ContactTrackingTag':
          return lookupData.tags;
        case 'CompensationType':
          return lookupData.compensationTypes;
        case 'DisclosurePath':
          return lookupData.disclosurePaths;
        case 'IncomeVerificationMethod':
          return this._enumerationService.getIncomeVerificationMethods();
        case 'IncomeVerificationType':
          return this._enumerationService.getIncomeVerificationTypes();
        case 'DocumentationType':
          return this._enumerationService.getDocumentationTypes();
        case 'MortgagePartyType':
          return this._enumerationService.getMortgagePartyType(); 
        case 'MortgageEntityType':
          return this._enumerationService.getMortgageEntityType(); 
        default:
          console.warn(`Unknown field type ${fieldType}`);
          return [];
      }
    };

    const result = getData();
    if (result instanceof Observable) {
      return result;
    } else {
      return of(result);
    }
  }

  getSelectOptionName(fieldType: string, item: any): string {
    switch (fieldType) {
      case 'ExternalCompany':
        return item.name;
      case 'LoanType':
        return item.loanTypeName;
      case 'LoanPurpose':
        return item.loanPurposeName;
      case 'LoanStatus':
      case 'AgentLoanStatus':
        return item.loanStatusName;
      case 'LeadStatus':
        return item.loanStatusName;
      case 'User':
        const user: User = item;

        let name = `${user.firstName} ${user.lastName}`;
        if (!user.active) {
          name += ' (inactive)';
        }

        return name;
      case 'Product':
        return item.productName;
      case 'Lender':
        return item.name;
      case 'LookupList':
        return item.lookupDescription;
      case 'LeadCampaign':
        return item.name;
      case 'Branch':
        return item.branchName;
      case 'LeadRoute':
        return item.name;
      case 'LeadRouteGroup':
        return item.name;
      case 'RedistributionRoute':
        return item.name;
      case 'RedistributionRouteGroup':
        return item.name;
      case 'TimeZone':
        return item;
      case 'SubStatus':
        return item.subStatusName;
      case 'Channel':
      case 'ChannelFlags':
        return item.name;
      case 'CompanyStatus':
        return item.name;
      case 'AgentTags':
        return item.name;
      case 'ContactTrackingTag':
        return item.name;
      case 'CompensationType':
        return item.name;
      case 'DisclosurePath':
        return item.name;
      case 'IncomeVerificationMethod':
        return item.name;
      case 'IncomeVerificationType':
        return item.name;
      case 'DocumentationType':
        return item.name;
      case 'MortgagePartyType':
        return item.name;
      case 'MortgageEntityType':
        return item.name;
      default:
        console.warn(`Unknown field type ${fieldType}`);
        if (item.name) {
          return item.name;
        }

        console.warn(`item.name is not set for item of unknown field type ${fieldType}`, item);
        return 'UNKNOWN OPTION';
    }
  }

  getSelectOptionValue(fieldType: string, item: any): any {
    switch (fieldType) {
      case 'ExternalCompany':
        return item.externalCompanyId.toString();
      case 'LoanType':
        return item.loanTypeId.toString();
      case 'LoanPurpose':
        return item.loanPurposeId.toString();
      case 'LoanStatus':
      case 'AgentLoanStatus':
        return item.loanStatusId.toString();
      case 'LeadStatus':
        return item.loanStatusId.toString();
      case 'User':
        return item.userCompanyGuid.toString();
      case 'Product':
        return item.productId.toString();
      case 'Lender':
        return item.lenderId.toString();
      case 'LookupList':
        return item.id.toString();
      case 'LeadCampaign':
        return item.leadCampaignId.toString();
      case 'Branch':
        return item.branchId.toString();
      case 'LeadRoute':
        return item.value.toString();
      case 'LeadRouteGroup':
        return item.value.toString();
      case 'RedistributionRoute':
        return item.value.toString();
      case 'RedistributionRouteGroup':
        return item.value.toString();
      case 'TimeZone':
        return item;
      case 'SubStatus':
        return item.subStatusId.toString();
      case 'Channel':
        return item.value;
      case 'ChannelFlags':
        return item.name;
      case 'CompanyStatus':
        return item.value.toString();
      case 'AgentTags':
        return item.agentListId.toString();
      case 'ContactTrackingTag':
        return item.tagId.toString();
      case 'CompensationType':
        return item.value;
      case 'DisclosurePath':
        return item.value;
      case 'IncomeVerificationMethod':
        return item.value;
      case 'IncomeVerificationType':
        return item.value;
      case 'DocumentationType':
        return item.value;
      case 'MortgagePartyType':
        return item.value;
      case 'MortgageEntityType':
        return item.value;
      default:
        console.warn(`Unknown field type ${fieldType}`);
        if (Object.prototype.hasOwnProperty.call(item, 'value')) {
          return item.value;
        }

        console.warn(`item.value is not set for item of unknown field type ${fieldType}`, item);
        return undefined;
    }
  }

  mapCustomDataToString(editorType: CustomDataEditorType, value: any): string {
    if (isNullOrUndefined(value)) return '';

    switch (editorType) {
      case CustomDataEditorType.CheckBox:
      case CustomDataEditorType.RadioButton:
        return Boolean(value) ? 'true' : 'false';
      case CustomDataEditorType.Currency:
        return !isNaN(value) ? formatCurrency(value, 'en-US', '$', undefined, '1.0-3') : '';
      case CustomDataEditorType.Date:
        return moment(value).isValid() ? formatDate(value, 'M/d/yyyy', 'en-US') : '';
      case CustomDataEditorType.Percent:
        return !isNaN(value) ? formatPercent(value, 'en-US', '1.3-3') : '';
      case CustomDataEditorType.Multiselect:
        return Array.isArray(value) ? value.map(el => el.value).join(',') : '';
      default:
        return value;
    }
  }

  ngOnDestroy(): void {
    this._enumsSubscription?.unsubscribe();
  }
}
