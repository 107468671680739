import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomData, CustomDataConfig, UserType } from 'src/app/models';
import { CustomDataEditConfig } from './custom-field-editor/custom-data-edit-config.model';
import { AppDetailsService } from '../../app-details/services/app-details.service';
import _ from 'lodash';

export enum CustomInfoTemplateType {
  Label_LEFT = "Label_LEFT",
  Label_BOTTOM = "Label_BOTTOM"
}
@Component({
  selector: 'custom-data-info',
  templateUrl: './custom-data-info.component.html',
  styleUrls: ['./custom-data-info.component.scss']
})
export class CustomDataInfoComponent implements OnInit {

  @Output()
  customDataInfoInitialized: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  set customData(customData: CustomData[]) {
    if (!customData) {
      return;
    }
    this._customData = customData;
    this.ngOnInit();
  }

  get customData(): CustomData[] {
    return this._customData;
  }

  @Input()
  isInClassicEditMode: boolean = true;

  @Input()
  userType: UserType;

  @Input()
  leadId?: number;

  @Input()
  applicationId?: number;

  @Input()
  templateType: CustomInfoTemplateType = CustomInfoTemplateType.Label_LEFT;

  @Input()
  labelAlignment: 'left' | 'right' = 'right';

  customDataConfig: CustomDataConfig[] = [];
  customDataFiltered: {
    row: number;
    col1: CustomDataEditConfig;
    col2: CustomDataEditConfig;
    customData1: CustomData;
    customData2: CustomData;
  }[] = [];

  isLoading: boolean = false;

  private _customData: CustomData[];

  constructor(
    private readonly _appService: AppDetailsService,
  ) {
    this._customData = [];
  }

  ngOnInit() {

    this.isLoading = true;

    this._appService.getCustomDataConfig().subscribe(response => {
      this.customDataConfig = (response || []).filter(x => !!x.layouts?.find(l => l.userType == this.userType && l.uiRow));

      this.customDataConfig.forEach(item => {
        var cd = this.customDataFiltered.find(x => x.row == item.layouts?.filter(x => x.userType == this.userType)[0].uiRow);
        var layout = item.layouts.filter(x => x.userType == this.userType)[0];
        if (!cd) {
          cd = { row: layout.uiRow, col1: null, col2: null, customData1: null, customData2: null };
          this.customDataFiltered.push(cd);
        }
        if (layout.uiColumn == 1) {
          cd.col1 = new CustomDataEditConfig(item.labelText, item.editorType, item.fieldType, item.customDropDownOptions, item.multiLookupType);
          cd.customData1 = this._customData?.find(x => x.customDataFieldConfigId == item.customDataFieldConfigId);
          if (!cd.customData1) {
            cd.customData1 = new CustomData();
            cd.customData1.customDataFieldConfigId = item.customDataFieldConfigId;
            cd.customData1.leadId = this.leadId;
            cd.customData1.applicationId = this.applicationId;
            this._customData.push(cd.customData1);
          }
        }
        if (layout.uiColumn == 2) {
          cd.col2 = new CustomDataEditConfig(item.labelText, item.editorType, item.fieldType, item.customDropDownOptions, item.multiLookupType);
          cd.customData2 = this._customData?.find(x => x.customDataFieldConfigId == item.customDataFieldConfigId);
          if (!cd.customData2) {
            cd.customData2 = new CustomData();
            cd.customData2.customDataFieldConfigId = item.customDataFieldConfigId;
            cd.customData2.leadId = this.leadId;
            cd.customData2.applicationId = this.applicationId;
            this._customData.push(cd.customData2);
          }
        }
      });

      const customDataConfigWithoutLayout = (response || []).filter(x => !(!!x.layouts?.find(l => l.userType == this.userType && l.uiRow)));

      this.customDataFiltered = _.orderBy(this.customDataFiltered, x=>x.row);

      let index = 0;
      this.customDataFiltered.forEach(customData => {
        if (!customData.col1) {
          if (customDataConfigWithoutLayout[index]) {
            customData.col1 = new CustomDataEditConfig(customDataConfigWithoutLayout[index].labelText,
              customDataConfigWithoutLayout[index].editorType, customDataConfigWithoutLayout[index].fieldType,
              customDataConfigWithoutLayout[index].customDropDownOptions, customDataConfigWithoutLayout[index].multiLookupType);
            customData.customData1 = this.customData.find(x => x.customDataFieldConfigId == customDataConfigWithoutLayout[index].customDataFieldConfigId);
            if (!customData.customData1) {
                customData.customData1 = new CustomData();
                customData.customData1.customDataFieldConfigId = customDataConfigWithoutLayout[index].customDataFieldConfigId;
                customData.customData1.leadId = this.leadId;
                customData.customData1.applicationId = this.applicationId;
                this._customData.push(customData.customData1);
            }
            index++;
          }
        }
        if (!customData.col2) {
          if (customDataConfigWithoutLayout[index]) {
            customData.col2 = new CustomDataEditConfig(customDataConfigWithoutLayout[index].labelText,
              customDataConfigWithoutLayout[index].editorType, customDataConfigWithoutLayout[index].fieldType,
              customDataConfigWithoutLayout[index].customDropDownOptions, customDataConfigWithoutLayout[index].multiLookupType);
            customData.customData2 = this.customData.find(x => x.customDataFieldConfigId == customDataConfigWithoutLayout[index].customDataFieldConfigId);
            if (!customData.customData2) {
                customData.customData2 = new CustomData();
                customData.customData2.customDataFieldConfigId = customDataConfigWithoutLayout[index].customDataFieldConfigId;
                customData.customData2.leadId = this.leadId;
                customData.customData2.applicationId = this.applicationId;
                this._customData.push(customData.customData2);
            }
            index++;
          }
        }
      });

      while (index < customDataConfigWithoutLayout.length) {
        let cd = { row: this.customDataFiltered.length + 1, col1: null, col2: null, customData1: null, customData2: null };
        this.customDataFiltered.push(cd);
        if (customDataConfigWithoutLayout[index]) {
          this.customDataFiltered[this.customDataFiltered.length - 1].col1 = new CustomDataEditConfig(customDataConfigWithoutLayout[index].labelText,
            customDataConfigWithoutLayout[index].editorType, customDataConfigWithoutLayout[index].fieldType,
            customDataConfigWithoutLayout[index].customDropDownOptions, customDataConfigWithoutLayout[index].multiLookupType);
            this.customDataFiltered[this.customDataFiltered.length - 1].customData1 = this.customData.find(x => x.customDataFieldConfigId == customDataConfigWithoutLayout[index].customDataFieldConfigId);
          if (!this.customDataFiltered[this.customDataFiltered.length - 1].customData1) {
              this.customDataFiltered[this.customDataFiltered.length - 1].customData1 = new CustomData();
              this.customDataFiltered[this.customDataFiltered.length - 1].customData1.customDataFieldConfigId = customDataConfigWithoutLayout[index].customDataFieldConfigId;
              this.customDataFiltered[this.customDataFiltered.length - 1].customData1.leadId = this.leadId;
              this.customDataFiltered[this.customDataFiltered.length - 1].customData1.applicationId = this.applicationId;
              this._customData.push(this.customDataFiltered[this.customDataFiltered.length - 1].customData1);
          }
          index++;
        }
        if (customDataConfigWithoutLayout[index]) {
          this.customDataFiltered[this.customDataFiltered.length - 1].col2 = new CustomDataEditConfig(customDataConfigWithoutLayout[index].labelText,
            customDataConfigWithoutLayout[index].editorType, customDataConfigWithoutLayout[index].fieldType,
            customDataConfigWithoutLayout[index].customDropDownOptions, customDataConfigWithoutLayout[index].multiLookupType);
            this.customDataFiltered[this.customDataFiltered.length - 1].customData2 = this.customData.find(x => x.customDataFieldConfigId == customDataConfigWithoutLayout[index].customDataFieldConfigId);
          if (!this.customDataFiltered[this.customDataFiltered.length - 1].customData2) {
              this.customDataFiltered[this.customDataFiltered.length - 1].customData2 = new CustomData();
              this.customDataFiltered[this.customDataFiltered.length - 1].customData2.customDataFieldConfigId = customDataConfigWithoutLayout[index].customDataFieldConfigId;
              this.customDataFiltered[this.customDataFiltered.length - 1].customData2.leadId = this.leadId;
              this.customDataFiltered[this.customDataFiltered.length - 1].customData2.applicationId = this.applicationId;
              this._customData.push(this.customDataFiltered[this.customDataFiltered.length - 1].customData2);
          }
          index++;
        }
      }
      this.isLoading = false;
      this.customDataInfoInitialized.emit();
    }, () => {
      this.isLoading = false;
    });
  }

  protected reRender = () => {
    this.isLoading = true;
    setTimeout(() => { this.isLoading = false; }, 100);
  }
}
