<div class="ineligible-reasons">
  <div class="modal-header">
    <h5 class="modal-title">Notes and Advisories </h5>
    <button type="button" class="btn-close" (click)="activeModal.close()" aria-label="Close"></button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <table class="table table-striped table-bordered">
          <tbody>
            <tr *ngFor="let noteAndAdvisory of notesAndAdvisories">
              <td>
                {{noteAndAdvisory}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card row mt-2" *ngIf="parameters.length">
        <div class="card-header">
          <h4>Pricing Parameters</h4>
        </div>
        <div class="card-body">
          <p-table #dt [value]="parameters" [paginator]="true" [rows]="10" responsiveLayout="scroll" name="parameters"
            [rowsPerPageOptions]="[10, 25, 50, 100]" [globalFilterFields]="['name','value']"
            [autoLayout]="true" [scrollable]="true" [showCurrentPageReport]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            styleClass="p-datatable-gridlines p-datatable-sm">
            <ng-template pTemplate="caption">
              <div class="d-flex justify-content-end">
                <span class="p-input-icon-right ms-2">
                  <i class="pi pi-search"></i>
                  <input pInputText #searchText type="text" class="form-control h-100" [(ngModel)]="globalSearchString"
                    (ngModelChange)="dt.filterGlobal($event, 'contains');"
                    placeholder="Search keyword" />
                </span>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th>Name</th>
                <th>Value</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr>
                <td>{{rowData.name}}</td>
                <td>
                  <ng-container *ngIf="rowData.type == 'dollar'">{{rowData.value | currency }}</ng-container>
                  <ng-container *ngIf="rowData.type == 'percent'">{{rowData.value | percent }}</ng-container>
                  <ng-container *ngIf="rowData.type == 'plain'">{{rowData.value}}</ng-container>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>

      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="activeModal.close()" data-bs-dismiss="modal"> Close
    </button>
  </div>
</div>
