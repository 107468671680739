import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ExpressionConfig } from 'src/app/models/expressions/expression-config.model';
import { Operator } from 'src/app/models/expressions/operator.model';
import { FunctionalExpressionService } from 'src/app/modules/expression-builder/services/functional-expression.service';
import { ExpressionEditorComponent } from '../expression-editor/expression-editor.component';

@Component({
  selector: 'expression-editor-dialog',
  templateUrl: 'expression-editor-dialog.component.html',
  styleUrls: ['expression-editor-dialog.component.scss']
})

export class ExpressionEditorDialogComponent implements OnInit, AfterViewInit {

  @ViewChild(ExpressionEditorComponent) expressionEditor: ExpressionEditorComponent | undefined;

  config: ExpressionConfig;

  constructor(public activeModal: NgbActiveModal,
    private readonly _expressionService: FunctionalExpressionService) { }

  selectedOperator: Operator;

  private _expression: string = "";

  set expression(expression: string) {
    this._expression = expression;
  }

  ngOnInit() { }

  ngAfterViewInit(): void {
    if (this.expressionEditor) {
      setTimeout(() => {
        this.expressionEditor.expression = this._expression;
      });
    }
  }

  onOkClicked = () => {
    if (this.expressionEditor) {
      this.expressionEditor.alert = null;
      const expression = this.expressionEditor.expression.trim();
      if (expression.length == 0) {
        this.activeModal.close(this.expressionEditor.expression);
        return;
      }
      // this._expressionService.parse(expression).subscribe(result => {
      //   this.expressionEditor.validationResult = result;
      //   if (this.expressionEditor.validationResult.isValid) {
          this.activeModal.close(expression);
      //   } else {
      //     this.expressionEditor.alert = new CustomAlert("Syntax Error!", "Your expression is not valid. Error: " + this.expressionEditor.validationResult.error + ", index: " + this.expressionEditor.validationResult.index, AlertType.Error);
      //   }
      // });
    }
  }

  onOperatorClicked = (operator: Operator) => {
    this.selectedOperator = operator;
    let text = this.selectedOperator.helpText.replace(/(\r\n|\n|\r)/g,"<br />");
    this.selectedOperator.helpText = text;
  }

  divStyle: string = "1";

  handleDragStart(ev :any) {
    ev.dataTransfer.setData("operator", ev.target.id);
    this.divStyle = '0.4';
  }

  handleDragEnd(ev :any) {
    ev.dataTransfer.setData("text", ev.target.id);
    this.divStyle = '1';
  }
}
