<ul class="list-unstyled topbar-nav float-end mb-0">
  <!-- <li class="creat-btn">
    <div class="nav-link">
      <a class=" btn btn-sm btn-soft-primary" href="#" role="button"><i class="fas fa-plus me-2"></i><span class="hide-on-md">New Task</span></a>
    </div>
  </li> -->
  <li *ngIf="companyId == 2 || companyId == 155" class="notification-list hide-on-sm">
    <a class="nav-link arrow-none waves-light waves-effect show" title="Ring Central" (click)="showNiceInContact('ring-central')"
      role="button">
      <!-- <i class="fa fa-nice-in-contact headphones-button"></i> -->
      <img src="assets/images/ringcentral-logo.png" class="in-contact-headphones-button"/>
      <!-- <span class="badge bg-danger rounded-pill noti-icon-badge" *ngIf="alertsAndNotifications.totalAlertCount">{{alertsAndNotifications.totalAlertCount > 99 ? '+99' : alertsAndNotifications.totalAlertCount}}</span>-->
    </a>
  </li>
  <li *ngIf="companyId == 2 || companyId == 155" class="notification-list hide-on-sm">
    <a class="nav-link arrow-none waves-light waves-effect show" title="Nice In Contact" (click)="showNiceInContact('nice-in-contact')"
      role="button">
      <!-- <i class="fa fa-nice-in-contact headphones-button"></i> -->
      <img src="assets/images/incontact-logo.png" class="in-contact-headphones-button"/>
      <!-- <span class="badge bg-danger rounded-pill noti-icon-badge" *ngIf="alertsAndNotifications.totalAlertCount">{{alertsAndNotifications.totalAlertCount > 99 ? '+99' : alertsAndNotifications.totalAlertCount}}</span>-->
    </a>
  </li>
  <!-- <li class="notification-list hide-on-sm">
    <a class="nav-link arrow-none waves-light waves-effect show" title="Dial a Number" (click)="showNiceInContact('nice-in-contact')"
      role="button">
      <svg  xmlns="http://www.w3.org/2000/svg" width="20"
                  height="20" viewBox="0 0 64 64" stroke-width="3" stroke="currentColor" fill="none">
                  <path
                    d="M11.11,8.4a2.62,2.62,0,0,0-2.53,2.78c.35,6,2,20.64,9.9,29.77,9.46,11,21.78,14.79,34.42,14.23a2.68,2.68,0,0,0,2.52-2.65V42.92a4,4,0,0,0-3.09-3.86L46,37.66a4,4,0,0,0-4.16,1.69l-1.4,2.12a1,1,0,0,1-1.22.37C36,40.45,23.17,34.45,21.76,24.33a1,1,0,0,1,.48-1l2.54-1.55a4,4,0,0,0,1.81-4.21L25.2,11.13a4,4,0,0,0-4-3.12Z" />
                  <polyline points="41.7 10.21 53.34 10.53 53.02 22.17" />
                  <line x1="53.34" y1="10.53" x2="37.11" y2="25.89" />
                </svg>
    </a>
  </li> -->

  <li class="hide-on-md" *ngIf="!isSuperAdmin && !isCompanyPHM">
    <calls-on-leads-on></calls-on-leads-on>
  </li>

  <li class="notification-list hide-on-xsd">
    <a class="nav-link arrow-none waves-light waves-effect show" title="Support" (click)="onSupportLinkClicked()"
      role="button">
      <i class="ti-headphone headphones-button"></i>
      <!-- <span class="badge bg-danger rounded-pill noti-icon-badge" *ngIf="alertsAndNotifications.totalAlertCount">{{alertsAndNotifications.totalAlertCount > 99 ? '+99' : alertsAndNotifications.totalAlertCount}}</span>-->
    </a>
  </li>
  <li class="dropdown" *ngIf="applicationMode === 1">
    <a class="nav-link dropdown-toggle arrow-none waves-light waves-effect" id="search-bar-animation"
      data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false"
      (click)="onSearchIconClicked()">
      <i class="fa fa-search" style="font-size: 18px; font-weight: 600; line-height: inherit;"></i>
    </a>

    <div class="dropdown-menu dropdown-search-menu dropdown-menu-end dropdown-lg p-0 scale-up-hor-center">
      <!-- Top Search Bar -->
      <div class="app-search-topbar">
        <form name="searchForm" id="searchForm" action="#" method="get">
          <div class="d-flex">
            <input 
              type="search"
              [pattern]="searchMode == 'SSN' ? ssnPattern : null"
              [mask]="searchMode == 'SSN' ? '000-00-0000' : null"
              [showMaskTyped]="true"
              [dropSpecialCharacters]="false"
              name="searchInput" 
              id="searchInput" 
              [(ngModel)]="searchText"
              class="from-control top-search mb-0" 
              placeholder="Type text...">
            <select id="search-mode" name="searchMode" class="form-select search-input-button-style"
              [(ngModel)]="searchMode" style="width: auto;" (ngModelChange)="onSearchIconClicked()">
              <option value="Contains" [selected]="searchMode == 'Contains'">Global</option>
              <option value="Name" [selected]="searchMode == 'Name'">Name</option>
              <option value="Address" [selected]="searchMode == 'Address'">Address</option>
              <option value="Phone" [selected]="searchMode == 'Phone'">Phone</option>
              <option value="Email" [selected]="searchMode == 'Email'">Email</option>
              <option value="SSN" [selected]="searchMode == 'SSN'">SSN</option>
            </select>
            <button class="" type="submit" (click)="search()"><i class="ti-search"></i></button>
          </div>
        </form>
      </div>
    </div>
  </li>

  <li class="notification-list hide-on-xsd">
    <a class="nav-link arrow-none waves-light waves-effect show" (click)="showDialpad()" role="button">
      <i class="fas fa-phone-volume" style="font-size: 20px; font-weight: 600; line-height: inherit;"></i>
    </a>
  </li>

  <li class="notification-list hide-on-xsd">
    <a class="nav-link arrow-none waves-light waves-effect show" (click)="showNotifications()" role="button">
      <i class="far fa-bell badge-notify"></i>
      <span class="badge bg-danger rounded-pill noti-icon-badge"
        *ngIf="alertsAndNotifications.totalAlertCount">{{alertsAndNotifications.totalAlertCount > 99 ? '+99' :
        alertsAndNotifications.totalAlertCount}}</span>
    </a>
  </li>

  <li class="notification-list hide-on-xsd">
    <a class="nav-link arrow-none waves-light waves-effect show" (click)="showMentions()" role="button">
      <i class="mdi mdi-at"></i>
      <span *ngIf="unreadMentionCount > 0" class="badge bg-danger rounded-pill noti-icon-badge">
        {{unreadMentionCount >= 200 ? '+200' : unreadMentionCount}}
      </span>
    </a>
  </li>

  <li class="notification-list hide-on-xsd" style="border-right: 1px solid #e3ebf6;">
    <a class="nav-link arrow-none waves-light waves-effect show" (click)="showConversations()" role="button">
      <i class="far fa-comment" style="line-height: unset;"></i>
      <span class="badge bg-danger rounded-pill noti-icon-badge chatList-count" *ngIf="!!unreadConversations">
        ...
      </span>
    </a>
  </li>

  <li class="dropdown" [style]="isDisconnected ? 'background-color:#ff0036' : ''">
    <a class="nav-link dropdown-toggle waves-effect waves-light nav-user" data-bs-toggle="dropdown" href="#"
      role="button" aria-haspopup="false" aria-expanded="false">
      <app-user-avatar [user]="user"></app-user-avatar>
    </a>
    <div class="dropdown-menu nextgen-profile-menu dropdown-profile border-radius-10">
      <a class="dropdown-item">
        <img [src]="user?.userImage || 'assets/images/users/user-5.jpg'" alt="profile-user"
          class="rounded-circle thumb-sm">
        <span class="nav-user-name  fw-semibold px-1">{{user?.firstName || 'John Doe' }} {{user?.lastName}}</span>
      </a>

      <div class="dropdown-divider mb-1 mt-1"></div>

      <ng-container *ngIf="companyId === 2 || isSuperAdmin">
        <a class="dropdown-item classic-dropdown-item-padding font-14" (click)="onGotoNextGenClicked()">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
            class="feather feather-zap-off icon-dual">
            <polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon>
          </svg> {{applicationMode === 1 ? "Experience NextGen" : "Switch to Classic Mode" }}
        </a>
        <div class="dropdown-divider mb-1 mt-1"></div>
      </ng-container>

      <ng-container *ngIf="currentUserIsPrimaryRoleUser && wizardUrls.length === 1">
        <a class="dropdown-item classic-dropdown-item-padding font-14" (click)="copyLink(wizardUrls[0])">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
            class="feather feather-activity align-self-center icon-xs icon-dual me-1">
            <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
            <polyline points="15 3 21 3 21 9"></polyline>
            <line x1="10" y1="14" x2="21" y2="3"></line>
          </svg> Online App Link
        </a>
        <div class="dropdown-divider mb-1 mt-1"></div>
      </ng-container>

      <a class="dropdown-item classic-dropdown-item-padding font-14"
        [routerLink]="restrictFromEditingProfile ? null : applicationMode === 1 ? ['/admin/profile'] : ['/loda-nextgen/profile']"><svg
          xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
          class="feather feather-user align-self-center icon-xs icon-dual me-1">
          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
          <circle cx="12" cy="7" r="4"></circle>
        </svg> Profile
      </a>
      <a class="dropdown-item classic-dropdown-item-padding font-14"
        [routerLink]="applicationMode === 1 ? ['/admin/settings'] : ['/loda-nextgen/settings']"><svg
          xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
          class="feather feather-settings align-self-center icon-xs icon-dual me-1">
          <circle cx="12" cy="12" r="3"></circle>
          <path
            d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z">
          </path>
        </svg> Settings
      </a>
      <a class="dropdown-item classic-dropdown-item-padding font-14"
        [routerLink]="applicationMode === 1 ? ['/admin/change-password'] : ['/loda-nextgen/change-password']">
        <svg style="margin-right: 6px;margin-left: -1px;" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
          viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
          stroke-linejoin="round" class="feather feather-lock icon-dual">
          <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
          <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
        </svg>Change Password</a>
      <div class="dropdown-divider mb-1 mt-1"></div>

      <ng-container *ngIf="companies && companies.length > 1">
        <a class="dropdown-item classic-dropdown-item-padding font-14" (click)="onSwitchCompanyClicked()">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
            class="feather feather-zap icon-dual">
            <polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon>
          </svg> Switch Companies
        </a>
        <div class="dropdown-divider mt-1"></div>
      </ng-container>

      <ng-container>
        <a class="dropdown-item classic-dropdown-item-padding font-14" (click)="onClearCacheClicked()">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
            class="feather feather-trash icon-dual">
            <polyline points="3 6 5 6 21 6"></polyline>
            <path d="M19 6l-2 14H7L5 6h14z"></path>
            <line x1="10" y1="11" x2="10" y2="17"></line>
            <line x1="14" y1="11" x2="14" y2="17"></line>
          </svg> Clear Cache
        </a>
        <div class="dropdown-divider mt-1"></div>
      </ng-container>

      <a class="dropdown-item classic-dropdown-item-padding font-14" (click)="onLogoutClicked()">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
          class="feather feather-power align-self-center icon-xs icon-dual me-1">
          <path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path>
          <line x1="12" y1="2" x2="12" y2="12"></line>
        </svg> Logout
      </a>
      <div class="dropdown-divider mt-1"></div>
      <a class="dropdown-item dropdown-last-login font-12">Last Login: {{user.lastTimeLogin}} </a>
    </div>
  </li>

  <!--end menu item-->
</ul>
