import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Borrower, LoanDoc } from 'src/app/models';
import { LoanActivityService } from 'src/app/modules/loan-activity/services/loan-activity.service';
import { ConditionsTask } from 'src/app/models/task/conditons-task.model';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from 'src/app/services/constants';
import { FileService } from 'src/app/services/file.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/services/notification.service';
import { TaskNote, TaskService, TpoTaskFilterEnum } from 'src/app/services/task.service';
import { LoanDocService } from 'src/app/services/loan-doc.service';
import { partition } from 'lodash';
import { EditTaskDialogComponent } from 'src/app/modules/tasks/components/edit-task-dialog/edit-task-dialog.component';
import { DocFile } from 'src/app/modules/loan-docs/models/doc-file.model';
import { LoanDocsService } from 'src/app/modules/loan-docs/services/loan-docs.service';
import { DocFilesEditDialogComponent } from 'src/app/modules/loan-docs/components/doc-files-edit-dialog/doc-files-edit-dialog.component';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { FileDto } from 'src/app/models/borrower/file-dto.model';
import { handleNonErrorDismissals, Utils } from 'src/app/core/services/utils';
import { User } from 'src/app/models/user/user.model';
import { ContextMenuComponent } from 'ngx-contextmenu';
import { Table } from 'primeng/table';
import { MergeDocFilesRequest } from 'src/app/modules/loan-docs/models/merge-files/merge-doc-files-request.model';
import { TaskEmailSenderModalComponent } from '../task-email-sender-modal/task-email-sender-modal.component';
import { catchError, EMPTY, firstValueFrom, from, Subscription } from 'rxjs';
import { LoanService } from 'src/app/services/loan';
import { AddRejectionNotesDialogComponent } from 'src/app/modules/tasks/components/task-table/add-rejection-notes-dialog/add-rejection-notes-dialog.component';

@Component({
  selector: 'tpo-condition-table',
  templateUrl: 'tpo-condition-table.component.html',
  styleUrls: ['./tpo-condition-table.component.scss']
})

export class TpoConditionTableComponent extends ApplicationContextBoundComponent implements OnInit, OnDestroy {

  @ViewChild(ContextMenuComponent)
  basicMenu: ContextMenuComponent;

  @ViewChild('eSignConditionTable') eSignConditionTable: Table;

  @Input()
  otherConditions: ConditionsTask[] = [];

  @Input()
  otherOptionalConditions: ConditionsTask[] = [];

  @Input()
  borrowers: Borrower[];

  // @Input()
  // applicationState: TpoTaskFilterEnum;

  @Input()
  hideFilter: boolean;

  @Input()
  forSubmission: boolean = false;

  @Output()
  onTaskUpdated: EventEmitter<void> = new EventEmitter<void>();

  loading: boolean = true;
  allowMarkNA: boolean = false;

  columns: any[];
  filteredTableColumns: any[];
  filteredList: any = {};  // Key is the bucket name

  deleteFileIndex: string = "";
  userId: string = "";
  conditionBuckets: string[];
  conditionCount: any = {};
  applicationState: any = {};
  conditionalApprovalDate: Date;

  element;

  protected esignConditions: ConditionsTask[] = [];
  protected exportOnlyColumnsMapping: Map<string, string[]> = new Map<string, string[]>();

  private _modalOptions: NgbModalOptions;
  private _usersAll: User[] = [];
  private _loanDocs: LoanDoc[] = [];
  private _taskEmailSenderModalSubscription?: Subscription;
  private _globalState: TpoTaskFilterEnum;

  constructor(
    injector: Injector,
    private readonly _loanActivityService: LoanActivityService,
    private readonly _modalService: NgbModal,
    private readonly _loanDocsService: LoanDocsService,
    private readonly _fileService: FileService,
    private readonly _taskService: TaskService,
    private readonly _loanService: LoanService,
    private readonly _loanDocService: LoanDocService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifyService: NotificationService
  ) {
    super(injector);
    this._modalOptions = {
      windowClass: 'modal-full-width',
      backdrop: 'static',
      centered: true,
    };
    this.userId = this.applicationContext.userPermissions.userId;
    this._usersAll = this.applicationContext.globalConfig.usersAll;
    this.allowMarkNA = this.applicationContext.isCompanyPRMG;

    this.applicationState['Generic'] = TpoTaskFilterEnum.Outstanding;
    this.applicationState['Approval'] = TpoTaskFilterEnum.Outstanding;
    this.applicationState['Docs'] = TpoTaskFilterEnum.Outstanding;
    this.applicationState['Closing'] = TpoTaskFilterEnum.Outstanding;
    this.applicationState['Funding'] = TpoTaskFilterEnum.Outstanding;
    this.applicationState['Purchase'] = TpoTaskFilterEnum.Outstanding;
    this.applicationState['PerformAction'] = TpoTaskFilterEnum.Outstanding;
  }

  async ngOnInit() {
    this.columns = [
      { field: 'detail', header: 'Actions', sortable: false, style: 'width:170px;' },
      { field: 'description', header: 'Description', sortable: true },
      { field: 'dueDate', header: 'Due Date', sortable: true, style: 'width:165px;' },
      // { field: 'viewFile', header: 'View File', sortable: false },
      { field: 'taskStatus', header: 'Status', sortable: true, style: 'width:150px;' },
    ];

    this.filteredTableColumns = [
      { field: 'detail', header: 'Actions', sortable: false, style: 'width:170px;' },
      { field: 'description', header: 'Description', sortable: true },
      { field: 'dueDate', header: 'Due Date', sortable: true, style: 'width:165px;' },
      // { field: 'viewFile', header: 'View File', sortable: false },
      { field: 'taskStatus', header: 'Status', sortable: true, style: 'width:150px;' },
    ];

    this.exportOnlyColumnsMapping.set("dueDate", ["requestDate", "dueDate"]);
    this.exportOnlyColumnsMapping.set("description", ["borrowerName", "description"]);

    await this.getAllLoanDocs();

    if (this.otherOptionalConditions.length > 0) {
      this.otherOptionalConditions.filter(c => c.taskStatus === 'Pending' || c.taskStatus === 'Outstanding');
    }

    if (this.otherConditions && this.otherConditions.length > 0) {
      this.initialize(this.otherConditions, true);
    } else if (this.applicationContext.application.applicationId) {
      this._loanService.getKeyDatesByType(this.applicationContext.application.applicationId).subscribe(x => {
        if (x.approvedWithConditions?.eventDate)
          this.conditionalApprovalDate = new Date(x.approvedWithConditions.eventDate);

        this._taskService.getAllTpoTask(this.applicationContext.application.applicationId, TpoTaskFilterEnum.All).subscribe((response) => {
          this.initialize(response, true);
        }, (err) => {
        });
      }, (err) => {
      }).add(() => { this.loading = false; });
    } else {
      this.loading = false;
    }
  }

  ngOnDestroy() {
    this._taskEmailSenderModalSubscription?.unsubscribe();

    super.ngOnDestroy();
  }

  markTaskComplete = (conditionsTask: ConditionsTask) => {
    this._taskService.setTaskStatus(conditionsTask.loanDocTaskId, conditionsTask.requiresReview ? "ReviewReady" : "TpoSubmitted").subscribe((response) => {
      if (!this.hideFilter) {
        const state = this.applicationState[conditionsTask.taskType] || TpoTaskFilterEnum.Outstanding;
        this.initialFilterApplicationForAll(state);
      }
      this.onTaskUpdated.emit();
    });
  }

  markTaskNA = (conditionsTask: ConditionsTask) => {
    const modalRef = this._modalService.open(AddRejectionNotesDialogComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.borrowerFacingApplicable = false;
    modalRef.componentInstance.notesLabel = "Please explain 'Why' or just click Continue:";
    modalRef.result.then((result: TaskNote | 'cancel') => {
      var note = result == 'cancel' ? null : result.note;

      this._taskService.setTaskStatus(conditionsTask.loanDocTaskId, conditionsTask.requiresReview ? "ReviewReady" : "TpoSubmitted", note).subscribe((response) => {
        if (!this.hideFilter) {
          const state = this.applicationState[conditionsTask.taskType] || TpoTaskFilterEnum.Outstanding;
          this.initialFilterApplicationForAll(state);
        }
        this.onTaskUpdated.emit();
      });
    });
  }

  editTaskModal = (conditionsTask: ConditionsTask, files?) => {
    this._taskService.getTaskDashboardViewById(conditionsTask.loanDocTaskId).subscribe((response) => {
      if (!response.docFiles) {
        response.docFiles = [];
      }

      if (files) {
        files.forEach((targetFile) => {
          const file = targetFile;
          let docFile = new FileDto();
          docFile.fileName = file.name;
          docFile.loanDocId = response.loanDocId;
          docFile.note = '';
          docFile.borrowerNote = '';
          docFile.guid = null;
          docFile.active = true;
          docFile.file = file;
          response.docFiles.push(docFile);
        });
      }

      let modalRef = this._modalService.open(EditTaskDialogComponent, Constants.modalOptions.xlarge);
      modalRef.componentInstance.task = response;

      modalRef.result.then(async (result) => {
        if (result) {
          if (!this.hideFilter) {
            await this.getAllLoanDocs();
            this.initialFilterApplicationForAll(this._globalState);
          }
          this.onTaskUpdated.emit();
        }
      }, err => { });
    })
  }

  esignInProgress: boolean = false;

  esignTaskModal = (conditionsTask: ConditionsTask) => {
    this.esignInProgress = true;
    this._taskService.getTaskDashboardViewById(conditionsTask.loanDocTaskId).subscribe((response) => {
      if (!response.docFiles) {
        response.docFiles = [];
      }

      let modalRef = this._modalService.open(EditTaskDialogComponent, Constants.modalOptions.xlarge);
      modalRef.componentInstance.task = response;
      modalRef.componentInstance.startESign = true;
      modalRef.result.then((result) => {
        this.esignInProgress = false;
        if (result) {
          if (!this.hideFilter) {
            this.initialFilterApplicationForEsign();
          }
          this.onTaskUpdated.emit();
        }
      }, err => { this.esignInProgress = false; });
    }, err => { this.esignInProgress = false; })
  }

  docGenTaskModal = (conditionsTask: ConditionsTask) => {
    this._taskService.getTaskDashboardViewById(conditionsTask.loanDocTaskId).subscribe((response) => {
      if (!response.docFiles) {
        response.docFiles = [];
      }

      let modalRef = this._modalService.open(EditTaskDialogComponent, Constants.modalOptions.xlarge);
      modalRef.componentInstance.task = response;
      modalRef.componentInstance.startDocumentGeneration = true;
      modalRef.result.then((result) => {
        if (result) {
          if (!this.hideFilter) {
            const state = this.applicationState[conditionsTask.taskType] || TpoTaskFilterEnum.Outstanding;
            this.initialFilterApplicationForAll(state);
          }
          this.onTaskUpdated.emit();
        }
      }, err => { });
    })
  }

  downloadDocument = (loanDocId: number) => {
    this._spinner.show();
    this._loanDocService.getLoanDoc(loanDocId).subscribe(result => {
      if (result && result.docFiles.length) {
        let fileGuid = result.docFiles[0].guid;
        let mimeType = result.docFiles[0].mimeType;
        this._loanDocService.downloadFile(fileGuid, mimeType).subscribe(data => {
          this._spinner.hide();
          const blob = new Blob([data], { type: 'application/pdf', });
          let downloadLink = document.createElement('a');
          downloadLink.href = URL.createObjectURL(blob);
          let fileName = result.docFiles[0].fileName;
          downloadLink.setAttribute('download', fileName);
          document.body.appendChild(downloadLink);
          downloadLink.click();
        });
      } else {
        this._spinner.hide();
      }
    }, error => {
      this._notifyService.showError(error.message, 'Error');
      this._spinner.hide();
    })
  }

  onShowMergeFilesClicked = (file: DocFile) => {
    const splitFileName = file.fileName.split(".");
    const fileExtension = splitFileName[1];
    this._fileService.getDocFile(file.guid).subscribe((docFile) => {
      this._loanDocsService.getLoanDocs(this.applicationContext.application.applicationId).subscribe((loanDocs) => {
        const modalRef = this._modalService.open(DocFilesEditDialogComponent, this._modalOptions);
        modalRef.componentInstance.title = 'Document File Editor';
        modalRef.componentInstance.file = docFile;
        modalRef.componentInstance.fileExtension = fileExtension;
        modalRef.componentInstance.appId = this.applicationContext.application.applicationId;
        modalRef.componentInstance.loanDocs = loanDocs;
        modalRef.componentInstance.globalConfig = this.applicationContext.globalConfig;
        modalRef.result.then((result) => {

        }, (res) => {
        });
      }, (err) => {
        this._notifyService.showError("Error retrieving loan docs for app " + this.applicationContext.application.applicationId + '. ' + err ? err.message || err : '', 'Error');
      });
    }, (err) => {
      this._notifyService.showError(err ? (err.data ? err.data.message : "Error retrieving doc file") : "Error retrieving doc file", 'Error');
    });
  }

  comfirmDelete = (guid: string) => {
    this._spinner.show();
    this._fileService.removeFile(guid).subscribe(async (response) => {
      this._notifyService.showSuccess("Moved file to trash", "Success");
      await this.getAllLoanDocs();
      this._spinner.hide();
    }, (err) => {
      this._notifyService.showError(err ? err.message : 'Unable to move file to trash', "Error");
      this._spinner.hide();
    })
  }

  cancelDelete = (guid: string) => {
    this.deleteFileIndex = "";
  }

  deleteFile = (guid: string) => {
    this.deleteFileIndex = guid;
  }

  protected filterApplications = (key: string, applicationState: TpoTaskFilterEnum) => {
    this.applicationState[key] = applicationState;
    this.filteredList[key] = [];

    this._globalState = applicationState;

    switch (applicationState) {
      case "All":
        this.filteredList[key] = this.otherConditions?.filter(x => (x.conditionType ?? 'Generic') == key);
        break;
      case "Outstanding":
        this.otherConditions?.filter(x => (x.conditionType || 'Generic') == key).forEach((item) => {
          if (item.taskStatus === 'Outstanding' || item.taskStatus === 'Pending' || item.taskStatus === 'Rejected')
            this.filteredList[key].push(item);
        });
        break;
      case "PendingApproval":
        this.otherConditions?.filter(x => (x.conditionType ?? 'Generic') == key).forEach((item) => {
          if (item.taskStatus === 'PendingApproval' || item.taskStatus === 'TpoSubmitted')
            this.filteredList[key].push(item);
        });
        break;
      case "Completed":
        this.otherConditions?.filter(x => (x.conditionType ?? 'Generic') == key).forEach((item) => {
          if (item.taskStatus === 'Completed')
            this.filteredList[key].push(item);
        });
        break;
    }
  }

  private initialize = (conditions: ConditionsTask[], isFromOnInit = false) => {

    if (this.conditionalApprovalDate && (this.applicationContext.isCompanyPRMG || this.applicationContext.isCompanyArcstone))
      conditions = conditions.filter(x => !!x.losConditionRefId || x.printExternally);

    const [esignConditions, otherConditions] = partition(conditions, (r: any) => r.taskType === 'EsignDocument' || r.taskType === 'LosEsign');
    this.esignConditions = esignConditions.filter(c => c.taskStatus === 'Pending' || c.taskStatus === 'Outstanding');
    this.otherConditions = otherConditions;
    this.otherConditions.forEach(condition => {
      const docType = this.applicationContext.globalConfig.documentType.find(dt => dt.documentTypeId === condition.documentTypeId);
      condition['docTypeName'] = docType?.documentTypeName || "";
    })

    this.seperateOtherConditionsIntoBucketsAndPrepareDataForTableDisplay();
    if (isFromOnInit) {
      Object.keys(this.conditionCount).forEach(k => this.filterApplications(k, TpoTaskFilterEnum.Outstanding))
      this.filterApplications('Generic', TpoTaskFilterEnum.Outstanding);
      this.initialFilterApplicationForEsign();
    }
  }

  private initialFilterApplicationForEsign = () => {
    this._spinner.show();
    this._taskService.getAllTpoTask(this.applicationContext.application.applicationId, TpoTaskFilterEnum.Outstanding).subscribe({
      next: (response: any) => {
        const esignConditions = response.filter((r: any) => r.taskType === 'EsignDocument' || r.taskType === 'LosEsign');
        this.esignConditions = [...esignConditions];
      },
      error: (err) => {
        this._notifyService.showError(err ? err.message : 'Tasks could not be loaded.', "Error");
      }
    }).add(() => this._spinner.hide())
  }

  private initialFilterApplicationForAll = (state: TpoTaskFilterEnum) => {
    this._spinner.show();
    const stateToFilterWith = state || TpoTaskFilterEnum.Outstanding;
    this._taskService.getAllTpoTask(this.applicationContext.application.applicationId, stateToFilterWith).subscribe({
      next: (response: any) => {
        this.initialize(response);
      },
      error: (err) => {
        this._notifyService.showError(err ? err.message : 'Tasks could not be loaded.', "Error");
      }
    }).add(() => this._spinner.hide())
  }

  resendEsignNotification = (condition: ConditionsTask) => {
    const borrower = this.borrowers.find(borrower => borrower.borrowerId == condition.borrowerId);
    if (!borrower) {
      console.error('Borrower not found for condition', condition);
      this._notifyService.showError('Borrower not found for condition', 'Error');
      return;
    }

    const modalRef = this._modalService.open(TaskEmailSenderModalComponent, Constants.modalOptions.medium);
    const instance = modalRef.componentInstance as TaskEmailSenderModalComponent;
    instance.borrower = borrower;
    instance.task = condition;
    instance.forceEmailUpdate = true;

    this._taskEmailSenderModalSubscription?.unsubscribe();
    this._taskEmailSenderModalSubscription = from(modalRef.result).pipe(
      catchError((error) => {
        handleNonErrorDismissals(error);
        return EMPTY;
      }),
    ).subscribe(_ => {
      this.initialFilterApplicationForEsign();
      this.onTaskUpdated.emit();
    });
  }

  onFileRenameClicked = (file: DocFile) => {
    file['shouldEdit'] = true;
  }

  onFileRenameCancelled = (file: DocFile) => {
    const { fileName, extension } = Utils.getFileNameAndExtension(file.fileName);
    file['fileNameWithoutExtension'] = fileName;
    file['shouldEdit'] = false;
  }

  onFileRenameConfirmed = (file: DocFile) => {
    this._spinner.show();
    const { fileName, extension } = Utils.getFileNameAndExtension(file['originalFileName']);
    file.fileName = file['fileNameWithoutExtension'] + "." + extension;
    file['shouldEdit'] = false;

    this.cleanupUiSpecificFieldsOnFileBeforeSave(file);
    let request = new MergeDocFilesRequest(null, null, file.guid, file.fileName, null, false);
    this._loanDocsService.mergeDocFiles(request, file.loanDocId).subscribe({
      next: async () => {
        await this.getAllLoanDocs();
        this._notifyService.showSuccess('Successfully saved document!', 'Success');
      },
      error: (err) => {
        this._notifyService.showError(err.message || err.error || 'Error while saving document!', 'Failure');
      }
    }).add(() => this._spinner.hide());
  }

  docFilesFilter = (task: ConditionsTask) => {
    return task.docFiles.filter(t => !this.shouldFilter(task) || t.docFileType !== 'Normal')
  }

  shouldFilter = (task: ConditionsTask) => {
    return task.taskStatus !== 'Pending' && (task.taskType === 'EsignDocument' || task.taskType === 'LosEsign');
  }

  onViewFileClicked = (url: string) => {
    window.open(url);
  }

  onFileDeleted = (file: DocFile, task: ConditionsTask) => {
    task.docFiles = task.docFiles.filter(t => t.guid != file.guid);
    this.getAllLoanDocs();
    this._notifyService.showSuccess('Successfully deleted document!', 'Success');
  }

  onFileRenamed = (file: DocFile, task: ConditionsTask) => {
    file['fileNameWithoutExtension'] = file.fileName;
    const { fileName, extension } = Utils.getFileNameAndExtension(file['originalFileName']);
    file['originalFileName'] = file.fileName + "." + extension;
    const index = task.docFiles?.findIndex(t => t.guid == file.guid);
    if (index > -1) {
      task.docFiles[index] = { ...file, fileName: file['originalFileName'] };
    }
  }

  private getAllLoanDocs = async () => {
    try {
      this._spinner.show();
      this._loanDocs = await firstValueFrom(this._loanActivityService.getAllLoanDocs(this.applicationContext.application.applicationId));
      this.setInitialFileNameForEdit();
    } catch (err) {
      this._notifyService.showError(err.message || err.error || 'Error while loading loan documents!', 'Failure');
    } finally {
      this._spinner.hide();
    }
  }

  private seperateOtherConditionsIntoBucketsAndPrepareDataForTableDisplay = () => {
    if (this.otherConditions.length == 0) {
      this.filteredList['Generic'] = [];
      this.conditionCount['Generic'] = 0;
      return;
    }

    let conditionsByType = this.otherConditions.map(x => x.conditionType || 'Generic').reduce((a, b) => {
      if (!a.includes(b)) {
        a.push(b);
      }
      return a;
    }, []);

    this.conditionBuckets = [];
    this.conditionCount = {};
    if (conditionsByType.indexOf('Generic') > -1) {
      this.conditionBuckets.push('Generic');
      this.conditionCount['Generic'] = this.getConditionCountWithoutType();
    }
    if (conditionsByType.indexOf('Approval') > -1) {
      this.conditionBuckets.push('Approval');
      this.conditionCount['Approval'] = this.getConditionCountByType('Approval');
    }
    if (conditionsByType.indexOf('Docs') > -1) {
      this.conditionBuckets.push('Docs');
      this.conditionCount['Docs'] = this.getConditionCountByType('Docs');
    }
    if (conditionsByType.indexOf('Closing') > -1) {
      this.conditionBuckets.push('Closing');
      this.conditionCount['Closing'] = this.getConditionCountByType('Closing');
    }
    if (conditionsByType.indexOf('Funding') > -1) {
      this.conditionBuckets.push('Funding');
      this.conditionCount['Funding'] = this.getConditionCountByType('Funding');
    }
    if (conditionsByType.indexOf('Purchase') > -1) {
      this.conditionBuckets.push('Purchase');
      this.conditionCount['Purchase'] = this.getConditionCountByType('Purchase');
    }

    this.otherConditions.forEach(condition => {
      (condition as any).lastRequest = Utils.timeSince(condition.requestDate);
      let borrower: Borrower = null;
      if (Array.isArray(this.borrowers)) {
        borrower = this.borrowers.find(borrower => borrower.borrowerId == condition.borrowerId);
      }
      if (borrower) {
        condition.borrowerName = Utils.getPersonsDisplayName(borrower);
      }
      if (condition.loanDocModel && condition.loanDocModel.docFiles) {
        condition.docFiles = condition.loanDocModel.docFiles;
      } else {
        condition.docFiles = this._loanDocs.find(loanDoc => loanDoc.loanDocId == condition.loanDocId)?.docFiles;
      }
      if (condition.docFiles?.length > 0) {
        condition.docFiles.forEach(docFile => {
          (docFile as any).createdBy = this.getInsertedBy(docFile.insertedBy);
        })
      }
    });

    this.conditionBuckets.forEach(key => {
      this.filteredList[key] = [];
      this.otherConditions?.filter(x => (x.conditionType || 'Generic') == key).forEach((item) => {
        this.filteredList[key].push(item);
      });
    })

    this.esignConditions.forEach(condition => {
      (condition as any).lastRequest = Utils.timeSince(condition.requestDate);
      let borrower: Borrower = null;
      if (Array.isArray(this.borrowers)) {
        borrower = this.borrowers.find(borrower => borrower.borrowerId == condition.borrowerId);
      }
      if (borrower) {
        condition.borrowerName = Utils.getPersonsDisplayName(borrower);
      }
      if (condition.loanDocModel && condition.loanDocModel.docFiles) {
        condition.docFiles = condition.loanDocModel.docFiles;
        condition.docFiles?.forEach((docFile) => {
          (docFile as any).linked = this.getLoanDocLinked(condition);
        });
      } else {
        condition.docFiles = this._loanDocs.find(loanDoc => loanDoc.loanDocId == condition.loanDocId)?.docFiles;
        condition.docFiles?.forEach((docFile) => {
          (docFile as any).linked = this.getLoanDocLinked(condition);
        });
      }
      if (condition.docFiles?.length > 0) {
        condition.docFiles.forEach(docFile => {
          (docFile as any).createdBy = this.getInsertedBy(docFile.insertedBy);
        })
      }
    });
  }

  private getLoanDocLinked = (condition: ConditionsTask) => {
    if (condition.loanDocId && condition.loanDocModel?.losLoanDocId) {
      return 'Loda/LOS';
    } else if (condition.loanDocId) {
      return 'Loda';
    } else if (condition.loanDocModel.losLoanDocId) {
      return 'LOS';
    } else {
      return '';
    }
  }

  private setInitialFileNameForEdit = () => {
    const docFiles = this._loanDocs?.map(loanDoc => loanDoc.docFiles).flat();
    docFiles.forEach(file => {
      if (file.fileName) {
        file['shouldEdit'] = false;
        const { fileName, extension } = Utils.getFileNameAndExtension(file.fileName);
        file['originalFileName'] = file.fileName;
        file['fileNameWithoutExtension'] = fileName;
      } else {
        file['originalFileName'] = '';
      }
    })
  }

  private getInsertedBy = (id: string) => {
    const findInserter = this._usersAll.find(u => u.userCompanyGuid == id);
    return Utils.getBorrowerFullName(findInserter);
  }

  private cleanupUiSpecificFieldsOnFileBeforeSave = (file: DocFile) => {
    delete file['shouldEdit'];
    delete file['originalFileName'];
    delete file['fileNameWithoutExtension'];
    delete file['createdBy']
  }

  private getConditionCountWithoutType = () => {
    return this.otherConditions?.filter(x => !x.conditionType).length ?? 0;
  }

  private getConditionCountByType = (type: string) => {
    return this.otherConditions?.filter(x => x.conditionType == type).length ?? 0;
  }
}
