<div [attr.id]="'customFieldEditor' + editConfig.fieldName" class="row py-1 {{ !isInClassicEditMode ? 'inlineEditRow' : '' }}" style="min-height:41.5px" *ngIf="templateType == 'Label_LEFT'">
  <div class="col-6" *ngIf="labelVisible">
    <label class="custom-field-label" [ngClass]="{ 'col-form-label': labelAlignment == 'right' }"
      for="{{ editConfig.label }}">
      {{ editConfig.label }}
    </label>
  </div>

  <div class="col-6" *ngIf="
      ['Text', 'String', 'Unspecified'].indexOf(editConfig.editorType) > -1
    ">
    <input id="{{ editConfig.fieldName }}" name="{{ editConfig.fieldName }}" type="text" class="form-control" *ngIf="isInClassicEditMode || isEditActive"
      [(ngModel)]="model.value" [readonly]="readonly"/>
    <ng-container *ngTemplateOutlet="applyCancelButtons"></ng-container>
    <span class="inline-editor-container {{readonly ? 'readonly' : ''}}" [ngClass]="{
        'empty-field':
          model.value == null ||
          model.value == undefined
      }" title="{{ !readonly ? 'Click to Edit': ''}}" *ngIf="!isInClassicEditMode && !isEditActive" (click)="editClicked()">{{
      model.value || (!readonly ? '--' : '') }}</span>
  </div>

  <div class="col-6" *ngIf="['TimeZone', 'Date'].indexOf(editConfig.editorType) > -1">
    <date-input *ngIf="isInClassicEditMode || isEditActive" [name]="editConfig.fieldName" [id]="editConfig.fieldName"
      [(ngModel)]="model.value" [readonly]="readonly">
    </date-input>
    <ng-container *ngTemplateOutlet="applyCancelButtons"></ng-container>

    <span class="inline-editor-container {{readonly ? 'readonly' : ''}}" [ngClass]="{
        'empty-field':
          model.value == null ||
          model.value == undefined ||  model.value == ''
      }" title="{{ !readonly ? 'Click to Edit': ''}}" *ngIf="!isInClassicEditMode && !isEditActive" (click)="editClicked()">{{
      (model.value | date : 'M/d/yyyy') || (!readonly ? '--' : '') }}</span>
  </div>

  <div class="col-6" *ngIf="editConfig.editorType == 'Email'">
    <input *ngIf="isInClassicEditMode || isEditActive" name="{{ editConfig.fieldName }}" type="email" class="form-control" trim
      [(ngModel)]="model.value" [readonly]="readonly" />
    <ng-container *ngTemplateOutlet="applyCancelButtons"></ng-container>

    <span class="inline-editor-container {{readonly ? 'readonly' : ''}}" [ngClass]="{
        'empty-field':
          model.value == null ||
          model.value == undefined
      }" title="{{ !readonly ? 'Click to Edit': ''}}" *ngIf="!isInClassicEditMode && !isEditActive" (click)="editClicked()">{{
      model.value || (!readonly ? '--' : '') }}</span>
  </div>

  <div class="col-6" *ngIf="
      ['MobilePhone', 'LandLinePhone', 'FaxPhone', 'Phone'].indexOf(
        editConfig.editorType
      ) > -1
    ">
    <input *ngIf="isInClassicEditMode || isEditActive" name="{{ editConfig.fieldName }}" type="text" class="form-control"
      mask="(000) 000-0000" placeHolderCharacter="#" [showMaskTyped]="true" [(ngModel)]="model.value" [readonly]="readonly" />
    <ng-container *ngTemplateOutlet="applyCancelButtons"></ng-container>

    <span class="inline-editor-container {{readonly ? 'readonly' : ''}}" [ngClass]="{
        'empty-field':
          model.value == null ||
          model.value == undefined
      }" title="{{ !readonly ? 'Click to Edit': ''}}" 
      *ngIf="!isInClassicEditMode && !isEditActive" (click)="editClicked()">{{(model.value | phone) || (!readonly ? '--' : '') }}</span>
  </div>

  <div class="col-6" *ngIf="editConfig.editorType == 'Currency'">
    <currency-input *ngIf="isInClassicEditMode || isEditActive" name="{{ editConfig.fieldName }}"
      [(ngModel)]="model.value" [readonly]="readonly">
    </currency-input>
    <ng-container *ngTemplateOutlet="applyCancelButtons"></ng-container>

    <span class="inline-editor-container {{readonly ? 'readonly' : ''}}" [ngClass]="{
        'empty-field':
          model.value == null ||
          model.value == undefined
      }" title="{{ !readonly ? 'Click to Edit': ''}}" *ngIf="!isInClassicEditMode && !isEditActive" (click)="editClicked()">{{
      (model.value | currency) || (!readonly ? '--' : '') }}</span>
  </div>

  <div class="col-6" *ngIf="['Percent', 'Rate'].indexOf(editConfig.editorType) > -1">
    <percent-input *ngIf="isInClassicEditMode || isEditActive" name="{{ editConfig.fieldName }}"
      [rateIsTrueToTextValue]="true"
      [digitsInfo]="'1.3-3'"
      [(ngModel)]="model.value" [readonly]="readonly">
    </percent-input>
    <ng-container *ngTemplateOutlet="applyCancelButtons"></ng-container>

    <span class="inline-editor-container {{readonly ? 'readonly' : ''}}" [ngClass]="{
        'empty-field':
          model.value == null ||
          model.value == undefined
      }" title="{{ !readonly ? 'Click to Edit': ''}}" *ngIf="!isInClassicEditMode && !isEditActive" (click)="editClicked()">{{
      ((model.value / 100) | percent : '1.3-3') || (!readonly ? '--' : '') }}</span>
  </div>

  <div class="col-6" *ngIf="editConfig.editorType == 'Multiline'">
    <textarea *ngIf="isInClassicEditMode" class="form-control" cols="3" rows="7" [(ngModel)]="model.value"
      style="resize: none" [readonly]="readonly"></textarea>
    <span *ngIf="!isInClassicEditMode && readonly" class="inline-editor-container readonly pe-2" [ngClass]="{
        'empty-field':
          model.value == null ||
          model.value == undefined
      }">
      {{ multiLineFinalValue ? multiLineFinalValue : (!readonly ? '--' : '') }}
    </span>
    <span *ngIf="!isInClassicEditMode && !readonly" class="inline-editor-container text-line pe-2" [ngClass]="{
        'empty-field':
          model.value == null ||
          model.value == undefined
      }" #multiLinePopover="bs-popover" title="{{ !readonly ? 'Click to Edit': ''}}" data-bs-container="body" data-bs-toggle="popover"
      placement="right" [popover]="templateMultiline" data-trigger="click" [outsideClick]="false"
      [containerClass]="'templateMultiline'" (click)="editClicked()">
      {{ multiLineFinalValue ? multiLineFinalValue : '--' }}
    </span>
    <ng-template #templateMultiline>
      <textarea class="form-control" cols="3" rows="7" [(ngModel)]="model.value"
        style="resize: none" [readonly]="readonly"></textarea>
      <div class="mt-1 ms-5">
        <ng-container *ngTemplateOutlet="applyCancelButtons"></ng-container>
      </div>
    </ng-template>
  </div>

  <div class="col-6 d-flex" *ngIf="editConfig.editorType == 'Multiselect'">
    <div class="flex-grow-1" *ngIf="isInClassicEditMode">
      <ng-select2 style="width: 100%" class="select2 select2-multiple" name="{{ editConfig.fieldName }}"
        [(ngModel)]="multiselectSelectedValues" [options]="optionsMultipleSelect"
        (ngModelChange)="onMultiSelectValueChanged()" [data]="multiSelectPossibleValues" [readonly]="readonly">
      </ng-select2>
    </div>
    <span *ngIf="!isInClassicEditMode && readonly" class="inline-editor-container readonly" [ngClass]="{
      'empty-field':
      !multiselectSelectedValues.length
    }">
    <abbr *ngIf="multiselectSelectedValues.length > 2" title="{{this.model[this.editConfig.fieldName] || '--'}} ({{ !readonly ? 'Click to Edit': ''}})">
      {{ trimmedMultiSelectValue }}
    </abbr>
    <span *ngIf="multiselectSelectedValues.length <= 2" title="{{ !readonly ? 'Click to Edit': ''}}">
      {{ trimmedMultiSelectValue ? trimmedMultiSelectValue : (!readonly ? '--' : '')}}
    </span>
  </span>
    <span *ngIf="!isInClassicEditMode && !readonly" class="inline-editor-container" [ngClass]="{
        'empty-field':
        !multiselectSelectedValues.length
      }" #multiSelectPopover="bs-popover" title="Click to Edit" data-bs-container="body" data-bs-toggle="popover"
      placement="right" [popover]="templateMultiselect" data-trigger="click" [outsideClick]="false"
      (click)="isEditActive = true" [containerClass]="'templateMultiselect'">
      <abbr *ngIf="multiselectSelectedValues.length > 2" title="{{this.model[this.editConfig.fieldName] || '--'}} ({{ !readonly ? 'Click to Edit': ''}})">
        {{ trimmedMultiSelectValue }}
      </abbr>
      <span *ngIf="multiselectSelectedValues.length <= 2" title="{{ !readonly ? 'Click to Edit': ''}}">
        {{ trimmedMultiSelectValue ? trimmedMultiSelectValue : '--'}}
      </span>
    </span>

    <ng-template #templateMultiselect>
      <div class="flex-grow-1">
        <ng-select2 style="width: 100%" class="select2 select2-multiple" name="{{ editConfig.fieldName }}"
          [(ngModel)]="multiselectSelectedValues" [options]="optionsMultipleSelect"
          (ngModelChange)="onMultiSelectValueChanged()" [data]="multiSelectPossibleValues" [readonly]="readonly">
        </ng-select2>
      </div>
      <div class="mt-1 ms-5">
        <ng-container *ngTemplateOutlet="applyCancelButtons"></ng-container>
      </div>
    </ng-template>
  </div>

  <div class="col-6" *ngIf="editConfig.editorType == 'Select'">
    <select-input *ngIf="isInClassicEditMode || isEditActive" name="{{ editConfig.fieldName }}"
      [(ngModel)]="model.value" [readonly]="readonly" [options]="multiSelectPossibleValues"
      [optionValueKey]="'text'" [optionNameKey]="'text'">
    </select-input>
    <ng-container *ngTemplateOutlet="applyCancelButtons"></ng-container>

    <span class="inline-editor-container {{readonly ? 'readonly' : ''}}" [ngClass]="{
        'empty-field':
          model.value == null ||
          model.value == undefined
      }" title="{{ !readonly ? 'Click to Edit': ''}}" *ngIf="!isInClassicEditMode && !isEditActive" (click)="editClicked()">{{
      (model.value) || (!readonly ? '--' : '') }}</span>
  </div>

  <div class="col-6" *ngIf="editConfig.editorType == 'CheckBox'">
    <div class="form-check" *ngIf="isInClassicEditMode || isEditActive">
      <input class="form-check-input" type="checkbox" name="{{ editConfig.label }}" ToggleValue [true]="'true'"
        [false]="'false'" [(ngModel)]="model.value" [readonly]="readonly"/>
    </div>
    <ng-container *ngTemplateOutlet="applyCancelButtons" class="ms-2"></ng-container>
    <span class="inline-editor-container {{readonly ? 'readonly' : ''}}" [ngClass]="{
        'empty-field':
          model.value == null ||
          model.value == undefined
      }" title="{{ !readonly ? 'Click to Edit': ''}}" *ngIf="!isInClassicEditMode && !isEditActive" (click)="editClicked()">{{
      !isFieldNullOrUndefined(model.value)
      ? model.value === true ||
      model.value == 'true'
      ? 'Yes'
      : 'No'
      : '--'
      }}</span>
  </div>

  <div class="col-6" *ngIf="editConfig.editorType == 'RadioButton'">
    <div class="form-check" *ngIf="isInClassicEditMode || isEditActive">
      <input class="form-check-input" type="radio" name="{{ editConfig.label }}"
        [(ngModel)]="model.value" value="true" [readonly]="readonly"/>
    </div>
    <ng-container *ngTemplateOutlet="applyCancelButtons"></ng-container>
    <span class="inline-editor-container {{readonly ? 'readonly' : ''}}" [ngClass]="{
        'empty-field':
          model.value == null ||
          model.value == undefined
      }" title="{{ !readonly ? 'Click to Edit': ''}}" *ngIf="!isInClassicEditMode && !isEditActive" (click)="editClicked()">{{
      !isFieldNullOrUndefined(model.value)
      ? (model.value == true ||
      model.value == 'true'
      ? 'Yes'
      : 'No')
      : '--'
      }}</span>
  </div>
</div>

<ng-template #applyCancelButtons>
  <button class="btn p-0 border-0 bg-transparent ms-1" title="Apply" type="button" *ngIf="!isInClassicEditMode && isEditActive"
    (click)="applyClicked()">
    <i class="fa fa-check-circle text-success"></i>
  </button>
  <button class="btn p-0 border-0 bg-transparent ms-1" title="Cancel" type="button" *ngIf="!isInClassicEditMode && isEditActive"
    (click)="cancelClicked()">
    <i class="fa fa-times-circle text-danger"></i>
  </button>
</ng-template>
