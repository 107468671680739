import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ChatService } from 'src/app/services/chat.service';
import { phoneToPlainString, removeUsPhonePrefix, urlify } from 'src/utils';
import * as _ from 'lodash';
import * as moment from 'moment';
import { formatDate } from '@angular/common';
import { ConversationService } from '../../services/conversation.service';
import { ChatBorrower, ChatLead, ChatRelatedEntities } from '../../models/chat-related-entities.model';
import { ChatMedium, Conversation, ConversationParticipant, ParticipantType } from '../../models/conversation.model';
import { GlobalConfig } from 'src/app/models/config/global-config.model';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationMode, NavigationService } from 'src/app/services/navigation.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components/application-context-bound.component';
import { ConversationChatWindowComponent } from '../conversation-chat-window/conversation-chat-window.component';
import { MentionsUtils } from 'src/app/shared/services/mentions.utils';
import { ChannelService } from 'src/app/services/channel.service';
import { EnvironmentService } from 'src/app/core/services/environment/environment.service';
import { Mentionable } from 'src/app/shared/components/message-editor-with-mentions/mentionable.model';
import { Borrower, Company, LoanApplication } from 'src/app/models';
import { InternalContact } from 'src/app/modules/internal-contacts/models/internal-contact.model';
import { User } from 'src/app/models/user/user.model';
import { LoanService } from 'src/app/services/loan';
import { InternalContactsService } from 'src/app/modules/internal-contacts/services/internal-contacts.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VideoRecorderDialogComponent } from 'src/app/shared/modules/video-recorder/video-recorder-dialog/video-recorder-dialog.component';
import { Constants } from 'src/app/services/constants';
import { NgForm } from '@angular/forms';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { NgxSpinnerService } from 'ngx-spinner';
import { cloneDeep } from 'lodash';
import Swal from 'sweetalert2';
import { catchError, concatMap, finalize, firstValueFrom, of, tap } from 'rxjs';
import { Telephony } from 'src/app/modules/admin/dialer-config/models/telephony.model';
import { v4 as uuidv4 } from 'uuid';
import { Dropdown } from 'primeng/dropdown';
import { SharedUtilitiesService } from 'src/app/shared/services/utility.service';
import { ManualDialParams } from 'src/app/modules/dialer/models/manual-dial-params.model';
import { PhoneType } from 'src/app/modules/dialer/models/phone-type.model';
import { DialerEvent, DialerService } from 'src/app/modules/dialer/services/dialer.service';
import { DialerEventType } from 'src/app/modules/dialer/models/dialer-event.model';

@Component({
  selector: 'conversation-details',
  templateUrl: 'conversation-details.component.html',
  styleUrls: ['./conversation-details.component.scss'],
})
export class ConversationDetailsComponent extends ApplicationContextBoundComponent implements OnInit, OnDestroy {

  @ViewChild('chatWindow')
  chatWindow: ConversationChatWindowComponent;

  @ViewChild('fromPhoneNumberPicker')
  fromPhoneNumberPicker: Dropdown;

  @ViewChild("convoGroupForm") convoGroupForm: NgForm | undefined;

  @ViewChild('convoGroupPopover', { static: false }) convoGroupPopover: PopoverDirective;

  @Output()
  editBorrower: EventEmitter<ChatBorrower> = new EventEmitter<ChatBorrower>();

  @Output()
  editLead: EventEmitter<ChatLead> = new EventEmitter<ChatLead>();

  @Output()
  onArchiveConversation: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  conversationUpdated: EventEmitter<Conversation> = new EventEmitter<Conversation>();

  @Output()
  conversationGroupCreated: EventEmitter<Conversation> = new EventEmitter<Conversation>();

  @Output()
  smsConversationCreated: EventEmitter<Conversation> = new EventEmitter<Conversation>();

  @Input()
  globalConfig: GlobalConfig;

  @Input()
  userId: string;

  @Input()
  phoneNumbersAvailableToTextFrom: Telephony[] = [];

  @Input()
  set conversation(conversation: Conversation) {
    this._conversation = conversation;
    this.resetPhoneNumbers();
    this.tabIndex = 1;
    if (conversation) {
      conversation['onEdit'] = false;
      switch (conversation.chatMedium) {
        case ChatMedium.Sms:
          this.prepareUIForSmsConversationDetails(conversation);
          break;
        case ChatMedium.BorrowerMessage:
          this.loadBorrowerMessages(conversation);
          break;
        case ChatMedium.InternalMessage:
          this.loadInternalMessages(conversation);
          break;
        case ChatMedium.AgentMessage:
          this.loadAgentMessages(conversation);
          break;
        case ChatMedium.GroupMessage:
          this.loadGroupMessages(conversation);
      }
      this.prepareRelatedEntities();
    }
  }

  get conversation(): Conversation {
    return this._conversation;
  }

  relatedEntities: ChatRelatedEntities;

  defaultColor: string = "#025c90";
  tabIndex: number = 1;

  messages;
  applicationMode: string;

  usersThatCanBeMentioned: Mentionable[] = [];

  appId: number;

  application: LoanApplication;

  borrowers: Array<Borrower>;

  allUsers: User[] = [];

  singleMentionTrackingUrl: string;

  protected isMobileView: boolean = false;
  protected convoGroupPhoneNumber: string;
  protected convoGroupPhoneNumbers: string[] = [];
  protected newlyAddedNumbers: string[] = [];
  protected tempConvoGroupExternalName: string;
  protected isSubmitted: boolean = false;
  protected isSaving: boolean = false;
  protected currentUserName: string;
  protected isSaveEnabled: boolean = false;

  protected thereAreUnreadConversationsForPhoneNumber: boolean = false;

  protected fromPhoneNumberGuid: string = null;

  protected fromPhoneNumbersAll: FromPhoneNumberInfo[] = [];
  protected existingConversationPhoneNumbers: FromPhoneNumberInfo[] = [];
  protected myPhoneNumbers: FromPhoneNumberInfo[] = [];
  protected otherPhoneNumbers: FromPhoneNumberInfo[] = [];

  private _conversation: Conversation;
  private _convoGroupPhoneNumbersBeforeSaving: string[];

  private _internalContacts: InternalContact[] = [];

  private _conversationsInGroup: Conversation[] = [];
  private _subscriptionToDialerService: any;

  constructor(private readonly _chatService: ChatService,
    private readonly injector: Injector,
    private readonly _utilityService: SharedUtilitiesService,
    private readonly _conversationService: ConversationService,
    private readonly _navigationService: NavigationService,
    private readonly _notifsService: NotificationService,
    private readonly _environment: EnvironmentService,
    private readonly _channelService: ChannelService,
    private readonly _notifyService: NotificationService,
    private readonly _dialerService: DialerService,
    private readonly _loanService: LoanService,
    private readonly _modalService: NgbModal,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _internalContactsService: InternalContactsService) {
    super(injector);

    this._subscriptionToDialerService = this._dialerService.events.subscribe((e: DialerEvent) => {
      if (!e) {
        return;
      }
      switch (e.eventType) {
        case DialerEventType.loadCallPanel:
          this.adjustChatBoxHeight();
          break;
        case DialerEventType.closeCallPanel:
          document.getElementById('chat-box-right').style.maxHeight = "";
          break;
        default:
          break;
      }
    })
  }

  ngOnInit() {
    if (!this.userId) {
      this.userId = this.applicationContext.currentlyLoggedInUser.userId;
    }
    const user = this.applicationContext.globalConfig.usersAll.find(u => u.userCompanyGuid === this.userId);
    this.currentUserName = user ? user.firstName + ' ' + user.lastName : '';

    this.applicationMode = this._navigationService.applicationMode == ApplicationMode.Classic ? 'admin' :
      this._navigationService.applicationMode == ApplicationMode.NextGen ? 'loda-nextgen' : 'admin';
  }

  ngOnDestroy(): void {

    if (this._subscriptionToDialerService) {
      this._subscriptionToDialerService.unsubscribe();
    }
  }

  toggleMobileMenu = () => {
    this.isMobileView = !this.isMobileView;
  }

  dial = (phoneNumber: string) => {
    let data = {
      phoneNumber: phoneNumber,
      phoneType: PhoneType.mobile,
      firstName: null,
      lastName: null,
      recordType: null,
      recordId: null,
      applicationId: null,
      borrowerId: null
    } as ManualDialParams;

    this._dialerService.openCallControlPanel(undefined, undefined, undefined, undefined, undefined, undefined, undefined, true, data);
  }

  onFromPhoneNumberChanged = () => {
    const fromPhoneNumber = this.fromPhoneNumbersAll.find(c => c.guid == this.fromPhoneNumberGuid);
    if (fromPhoneNumber && fromPhoneNumber.conversationId) {
      const conversationToShow = this._conversationsInGroup.find(c => c.conversationId == fromPhoneNumber.conversationId);
      if (conversationToShow) {
        conversationToShow['onEdit'] = false;
        conversationToShow.conversations = this._conversationsInGroup;
        this._conversation = conversationToShow;
        this.loadSmsMessages(conversationToShow);
      }
    } else {
      // Create a new conversation here
      this.startANewSmsConversation(fromPhoneNumber);
    }
  }

  private loadSmsMessages = (conversation: Conversation) => {

    this.setLoading(true);
    const internalParticipant = conversation.participants.find(p => p.participantType === ParticipantType.Internal);
    const externalParticipant = conversation.participants.find(p => p.participantType === ParticipantType.External);
    this._chatService.getSmsChatMessages(internalParticipant.phoneNumber, externalParticipant.phoneNumber).subscribe((response) => {
      let conversationMessages = response.smsMessages

      conversationMessages.forEach(element => {
        element.groupDate = element.dateInserted ? formatDate(element.dateInserted, 'MM/dd/yyyy', 'en-US') : null;
        element.groupDate = element.groupDate ? moment(element.groupDate).unix() : element.groupDate;
        element.body = this.parseMessageBody(element.body);
        if (element.hasMediaFiles) {
          this.loadMediaFiles(element);
        }
      });

      conversation['conversationMessages'] = conversationMessages;
      this.setLoading(false);
      this.messages = conversationMessages;
      if (conversation.isUnread) {
        this.markConversationAsRead(conversation);
      }
    });
  }

  private loadGroupMessages = (conversation: Conversation) => {
    this.setLoading(true);
    this._chatService.getGroupChatMessages(conversation.conversationId).subscribe((response) => {
      let conversationMessages = response.groupMessages

      conversationMessages.forEach(element => {
        element.groupDate = element.dateInserted ? formatDate(element.dateInserted, 'MM/dd/yyyy', 'en-US') : null;
        element.groupDate = element.groupDate ? moment(element.groupDate).unix() : element.groupDate;
        element.body = this.parseMessageBody(element.body);
        if (element.hasMediaFiles) {
          this.loadMediaFiles(element);
        }
      });

      conversation['conversationMessages'] = conversationMessages;
      this.setLoading(false);
      this.messages = conversationMessages;
      if (conversation.isUnread)
        this.markConversationAsRead(conversation);
    });
  }

  onAttemptedToAddNumber = () => {
    if (!this._convoGroupPhoneNumbersBeforeSaving?.length) {
      this._convoGroupPhoneNumbersBeforeSaving = cloneDeep(this.convoGroupPhoneNumbers);
    }

    this.isSubmitted = true;
    this.convoGroupForm?.form.markAllAsTouched();
    if (!this.convoGroupForm.form.valid) {
      return;
    }

    const normalizedPhoneNumber = `+1${this.convoGroupPhoneNumber}`;

    if (this.convoGroupPhoneNumber && this.convoGroupPhoneNumbers.includes(normalizedPhoneNumber)) {
      this._notifyService.showInfo("This phone number is already added", "Info");
    } else {
      this.convoGroupPhoneNumber = `+1${this.convoGroupPhoneNumber}`
      this.convoGroupPhoneNumbers.push(this.convoGroupPhoneNumber);
      this.newlyAddedNumbers.push(this.convoGroupPhoneNumber);
      this.convoGroupPhoneNumber = '';
      this.isSubmitted = false;
      this.isSaveEnabled = this.checkNumbersInConversation();
    }
  }

  onAttemptedToDeleteNumber = (number: string) => {
    const internalParticipantPhoneNumber = this._conversation.participants.find(p => p.participantType === ParticipantType.Internal).phoneNumber;
    const externalNumbers = this.convoGroupPhoneNumbers.filter(num => num !== internalParticipantPhoneNumber);

    if (externalNumbers.length <= 1) {
      this._notifyService.showInfo("There must be at least one external participant", "Info");
      return;
    }

    this.convoGroupPhoneNumbers = this.convoGroupPhoneNumbers.filter(num => num !== number);
    this.isSaveEnabled = this.checkNumbersInConversation();
  }

  onConvoGroupCancelClicked = () => {
    if (this._convoGroupPhoneNumbersBeforeSaving?.length) {
      this.convoGroupPhoneNumbers = this._convoGroupPhoneNumbersBeforeSaving;
      this._convoGroupPhoneNumbersBeforeSaving = [];
    }
    this.convoGroupPhoneNumber = '';
    this.convoGroupPopover.hide();
  }

  onConvoGroupSaveClicked = () => {
    const convoParticipantsBeforeSaving = cloneDeep(this._conversation.participants);
    this.isSaving = true;

    this.convoGroupPhoneNumbers.forEach(number => {
      const existParticipant = this._conversation.participants.some(p => p.phoneNumber === number);

      if (!existParticipant) {
        const newParticipant = new ConversationParticipant();
        newParticipant.phoneNumber = number;
        newParticipant.participantType = ParticipantType.External;
        newParticipant.companyId = this._conversation.companyId;
        newParticipant['newParticipant'] = true;
        this._conversation.participants.push(newParticipant);
      }
    });

    this._conversation.participants = this._conversation.participants.filter(participant =>
      this.convoGroupPhoneNumbers.includes(participant.phoneNumber));

    const newAddedParticipants = this._conversation.participants.filter(p => p['newParticipant']);
    this._conversation.participants.forEach(p => {
      if (p['newParticipant']) {
        delete p['newParticipant'];
      }
    })

    if (this._conversation.conversationId) {
      this.updateConversation(convoParticipantsBeforeSaving, this._conversation, newAddedParticipants);
      return;
    }

    this._conversationService.saveConversation(this._conversation).pipe(
      concatMap((response) =>
        this.updateConversation(convoParticipantsBeforeSaving, response, newAddedParticipants, true),
      ),
    ).subscribe({
      error: (err) => {
        this.conversation.participants = convoParticipantsBeforeSaving;
        this.convoGroupPhoneNumbers = convoParticipantsBeforeSaving.map(c => c.phoneNumber);
        this._notifyService.showError(err ? (err.message ? err.message : '') : '', 'Error');
      }
    }).add(() => {
      this.isSaving = false;
      this.convoGroupPhoneNumber = '';
      this._convoGroupPhoneNumbersBeforeSaving = [];
      this.convoGroupPopover.hide();
      this._spinnerService.hide();
    });
  }

  loadBorrowerMessages = (conversation) => {
    this.setLoading(true);
    this._chatService.getBorrowerChatMessages(conversation.applicationId).subscribe((response) => {
      this.setLoading(false);
      conversation.externalName = response.displayName || conversation.externalName;
      conversation.conversationMessages = response.messages;

      const borrUserIds = response.borrowers.map(x => x.userId);

      conversation.conversationMessages.forEach(element => {
        element.direction = borrUserIds.indexOf(element.senderId) > -1 ? 'Inbound' : 'Outbound';
        element.groupDate = element.dateInserted ? formatDate(element.dateInserted, 'MM/dd/yyyy', 'en-US') : null;
        element.groupDate = element.groupDate ? moment(element.groupDate).unix() : element.groupDate;
        element.body = this.parseMessageBody(element.content);
        if (element.hasMediaFiles) {
          this.loadMediaFiles(element);
        }
      });
      this.setLoading(false);
      this.messages = response.messages;
      if (conversation.isUnread)
        this.markConversationAsRead(conversation);
    });
  }

  loadAgentMessages = (conversation) => {
    if (!conversation.applicationId) {
      return;
    }
    this.setLoading(true);
    this._chatService.getAgentChatMessages(conversation.applicationId, conversation.agentId).subscribe((response) => {
      this.setLoading(false);
      conversation.externalName = response.displayName || conversation.externalName;
      conversation.conversationMessages = response.messages;

      conversation.conversationMessages.forEach(element => {
        element.direction = (element.senderId === response.agent.userId) ? 'Inbound' : 'Outbound';
        element.groupDate = element.dateInserted ? formatDate(element.dateInserted, 'MM/dd/yyyy', 'en-US') : null;
        element.groupDate = element.groupDate ? moment(element.groupDate).unix() : element.groupDate;
        element.body = this.parseMessageBody(element.content);
        if (element.hasMediaFiles) {
          this.loadMediaFiles(element);
        }
      });
      this.setLoading(false);
      this.messages = response.messages;
      if (conversation.isUnread)
        this.markConversationAsRead(conversation);
    });
  }

  loadInternalMessages = (conversation) => {
    this.setLoading(true);
    this._chatService.getInternalChatMessages(conversation.applicationId).subscribe((response) => {
      conversation.externalName = response.displayName || conversation.externalName;
      conversation.conversationMessages = response.messages;
      conversation.conversationMessages.forEach(element => {
        element.direction = element.senderId == this.userId ? 'Outbound' : 'Inbound';
        element.groupDate = element.dateInserted ? formatDate(element.dateInserted, 'MM/dd/yyyy', 'en-US') : null;
        element.groupDate = element.groupDate ? moment(element.groupDate).unix() : element.groupDate;
        element.mediaFiles = [];
        element['mentionTrackingUrl'] = this._environment.apiInfo.apiBaseUrl + 'mentions/pixel/' +
          this.applicationContext.userPermissions.userId + '/' + element.mentionTrackingGuid;
        element = this.setImageIfExist(element);
      });
      this.setLoading(false);
      this.messages = response.messages;
      if (conversation.isUnread)
        this.markConversationAsRead(conversation);
    });
  }

  parseMessageBody = (text) => {
    if (!text) return "";

    if (text.includes("@")) {
      text = MentionsUtils.generateDisplayHtmlWithMentions(text);
    }

    if (this.isContainingUrl(text)) {
      return urlify(text);
    } else {
      return text.replaceAll('\n', '<br/>');
    }
  }

  onCommunicationClicked = () => {
    this.tabIndex = 1;
  }

  onDetailsClicked = () => {
    this.tabIndex = 2;
  }

  isContainingUrl = (text) => {
    return new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?").test(text);
  }

  private resetPhoneNumbers = () => {
    this.fromPhoneNumbersAll = null;
    this.existingConversationPhoneNumbers = null;
    this.myPhoneNumbers = null;
    this.otherPhoneNumbers = null;
  }

  private populatePhoneNumberOptionsUserCanTextFrom = (conversation: Conversation) => {
    this.resetPhoneNumbers();
    const fromPhoneNumbers: FromPhoneNumberInfo[] = [];
    conversation.conversations.forEach(convo => {
      const internalParticipant = convo.participants.find(p => p.participantType === ParticipantType.Internal);
      if (internalParticipant) {
        fromPhoneNumbers.push({
          guid: uuidv4(),
          phoneNumber: internalParticipant.phoneNumber,
          conversationId: convo.conversationId,
          isUnread: convo.isUnread,
          isMine: this.phoneNumbersAvailableToTextFrom.find(x => phoneToPlainString(removeUsPhonePrefix(x.fromPhoneNumber)) == phoneToPlainString(removeUsPhonePrefix(internalParticipant.phoneNumber)))?.userId == this.applicationContext.userPermissions.userId
        });
      }
    });

    this.phoneNumbersAvailableToTextFrom.forEach(telephony => {
      const phoneNumberWithoutPrefix = phoneToPlainString(removeUsPhonePrefix(telephony.fromPhoneNumber));
      // If the phone number is not already in the list of phone numbers user can text from, add it
      const existing = fromPhoneNumbers.find(p => phoneToPlainString(removeUsPhonePrefix(p.phoneNumber)) === phoneNumberWithoutPrefix);
      if (!existing) {
        fromPhoneNumbers.push({
          guid: uuidv4(),
          phoneNumber: telephony.fromPhoneNumber,
          conversationId: null,
          isUnread: false,
          isMine: telephony.userId == this.applicationContext.userPermissions.userId
        });
      }
    });

    this.fromPhoneNumbersAll = fromPhoneNumbers;

    this.myPhoneNumbers = _.orderBy(fromPhoneNumbers.filter(p => p.isMine && !p.conversationId),
      number => Number(number.phoneNumber?.replace(/\D/g, '')), 'asc');

    this.otherPhoneNumbers = _.orderBy(fromPhoneNumbers.filter(p => !p.isMine),
      number => Number(number.phoneNumber?.replace(/\D/g, '')), 'asc');

    this.existingConversationPhoneNumbers = _.orderBy(fromPhoneNumbers.filter(p => p.conversationId),
      number => Number(number.phoneNumber?.replace(/\D/g, '')), 'asc');
  }

  private prepareUIForSmsConversationDetails = (conversation: Conversation, afterNewSmsChatCreation: boolean = false) => {
    this._conversationsInGroup = conversation.conversations;
    this.thereAreUnreadConversationsForPhoneNumber = false;

    if (conversation.conversations && conversation.conversations.length > 1 && !afterNewSmsChatCreation) {
      // If there is at least one conversation with unread messages, show the blinker on the left side of the phone number picker
      const unreadConversations = conversation.conversations.filter(c => c.isUnread);
      this.thereAreUnreadConversationsForPhoneNumber = unreadConversations.length > 0;

      // Since there is more than one conversation, we need to show the first conversation with the most recent message
      // and the rest of the conversations will be shown in the dropdown
      const convoWithMostRecentMessage = unreadConversations.length ? unreadConversations.reduce((prev, current) => {
        return (prev.mostRecentMessageDate > current.mostRecentMessageDate) ? prev : current
      }) : null;
      if (convoWithMostRecentMessage) {
        this._conversation = convoWithMostRecentMessage;
      } else {
        this._conversation = conversation.conversations[0];
      }
    } else {
      this._conversation = conversation;
    }
    this.populatePhoneNumberOptionsUserCanTextFrom(conversation);
    setTimeout(() => {
      if (this._conversation) {
        const fromPhoneNumber = this.fromPhoneNumbersAll.find(p => p.conversationId === this._conversation.conversationId);
        if (fromPhoneNumber) {
          this.fromPhoneNumberGuid = fromPhoneNumber.guid;
        }
      }
      this.loadSmsMessages(this._conversation);
    });
  }

  private startANewSmsConversation = (fromPhoneNumber: FromPhoneNumberInfo) => {
    //todo: call api to register conversation
    const newConversation = new Conversation();
    newConversation.assignedTo = this.userId;
    const externalParticipants = [...this._conversation.participants.filter(p => p.participantType === ParticipantType.External)];
    externalParticipants.forEach(p => {
      p.conversationId = null;
      p.id = null;
    }
    );
    const internalParticipant = new ConversationParticipant();
    internalParticipant.participantType = ParticipantType.Internal;
    internalParticipant.companyId = this._conversation.companyId;
    internalParticipant.name = this.currentUserName;

    internalParticipant.phoneNumber = fromPhoneNumber.phoneNumber.startsWith('+1') ? fromPhoneNumber.phoneNumber : `+1${fromPhoneNumber.phoneNumber}`;
    newConversation.chatMedium = ChatMedium.Sms;
    newConversation.isUnread = true;
    newConversation.mostRecentIncomingMessageDate = new Date();
    newConversation.lastReadDate = newConversation.mostRecentIncomingMessageDate;
    newConversation.externalName = this._conversation.externalName;
    newConversation.mostRecentMessagePreview = " ";

    newConversation.participants = [internalParticipant, ...externalParticipants];

    // Call API to create conversation in DB
    this._spinnerService.show();
    this._conversationService.saveConversation(newConversation).subscribe({
      next: (newlyCreatedConversation) => {
        newlyCreatedConversation.contactAlias = this._conversation.externalName ? this._conversation.externalName.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase() :
          "N/A";
        this._conversationsInGroup.push(newlyCreatedConversation);
        newlyCreatedConversation.conversations = this._conversationsInGroup;
        newlyCreatedConversation.isUnread = true;
        this.prepareUIForSmsConversationDetails(newlyCreatedConversation, true);
        this.smsConversationCreated.emit(newlyCreatedConversation);
      },
      error: (err) => {
        this._notifyService.showError(err ? (err.data ? err.data.message : '') : '', 'Error');
      }
    }).add(() => this._spinnerService.hide());
  }

  private markConversationAsRead = (conversation: Conversation) => {
    if (!conversation.conversationId) {
      return;
    }
    this._conversationService.markAsRead(conversation.conversationId).subscribe({
      next: (response: Conversation) => {
        conversation = response;
        if (conversation.chatMedium === ChatMedium.Sms) {
          conversation.conversations = this._conversationsInGroup;
          var c = conversation.conversations.find(c => c.conversationId === conversation.conversationId);
          if (c) {
            c.isUnread = false;
          }
          this.thereAreUnreadConversationsForPhoneNumber = this._conversationsInGroup.some(c => c.isUnread);
          const phoneNumber = this.fromPhoneNumbersAll.find(p => p.conversationId === conversation.conversationId);
          if (phoneNumber) {
            phoneNumber.isUnread = false;
          }
        }
        this.conversation.isUnread = false;
        this.onConversationUpdated();
      },
      error: (error => {
        this._notifsService.showError(error.error ? error.error.message :
          "An error occurred while marking conversation as read.", "Error!");
      })
    })
  }

  private setImageIfExist = (message) => {
    if (
      message.content.includes('data:image/png;base64') ||
      message.content.includes('data:image/jpg;base64') ||
      message.content.includes('data:image/jpeg;base64')
    ) {
      message.hasMediaFiles = true;
      const base64Img = message.content.substring(message.content.indexOf('[') + 1, message.content.indexOf(']'))
      message.mediaFiles.push(base64Img);
      message.content = message.content.replace(`[${base64Img}]`, '');
      this.setImageIfExist(message);
    }
    else {
      message.body = this.parseMessageBody(message.content);
      return message;
    }
  };

  private loadMediaFiles = (chatConversation) => {
    if (!chatConversation.messageMediaFileUrls || chatConversation.messageMediaFileUrls.length == 0) return;
    chatConversation.mediaFiles = [];
    chatConversation.messageMediaFileUrls.forEach(c => {
      chatConversation.mediaFiles.push(null);
    })
    for (let i = 0; i <= chatConversation.messageMediaFileUrls.length - 1; i++) {
      this._utilityService.getImageFileContentAsBase64(chatConversation.messageMediaFileUrls[i] + "?v=" + uuidv4(), (response) => {
        chatConversation.mediaFiles[i] = response;
      });
    }
  }

  private prepareRelatedEntities = () => {
    this.relatedEntities = null;
    if (this._conversation.applicationId) {
      this.initializeApplication(this._conversation.applicationId);
      return;
    }
    const observer = {
      next: ((result: ChatRelatedEntities) => {
        this.relatedEntities = result;
        if (this.relatedEntities.leads) {
          this.relatedEntities.leads.forEach(lead => {
            const existingLead = this.globalConfig.leadStatus.find(lS => lS.loanStatusId === lead.leadStatusId);
            if (existingLead) {
              lead.loanStatusName = existingLead.loanStatusName;
            }
            const existingLoanPurpose = this.globalConfig.loanPurpose.find(lP => lP.loanPurposeId === lead.loanPurposeId);
            if (existingLoanPurpose) {
              lead.loanPurposeName = existingLoanPurpose.loanPurposeName;
            }
          });
        }
      }),
      error: ((error: any) => {
      })
    }

    const externalParticipant = this.conversation.participants.find(p => p.participantType === ParticipantType.External);
    if (externalParticipant) {
      this._conversationService.getRelatedEntities(externalParticipant.phoneNumber).subscribe(observer);
    }

    if (this.convoGroupPopover) {
      this.convoGroupPhoneNumber = '';
      this.convoGroupPhoneNumbers = [];
      this.convoGroupPopover.hide();
    }

    this._conversation['tempConvoGroupExternalName'] = this._conversation.externalName;

    this._conversation.participants.forEach(participant => {
      if (participant.phoneNumber) {
        this.convoGroupPhoneNumbers.push(participant.phoneNumber);
        this.sortConvoGroupPhoneNumbers();
      }
    });
  }

  checkNumberParticipantType = (number: string) => {
    const participant = this._conversation.participants.find(p => p.phoneNumber === number);
    return participant?.participantType === ParticipantType.Internal;
  }

  isNumberNewlyAdded(number: string): boolean {
    return this.newlyAddedNumbers.includes(number);
  }

  adjustChatBoxHeight = () => {
    const chatBox = document.getElementById('chat-box-right');
    const chatBoxTop = chatBox.getBoundingClientRect().top;
    const viewportHeight = window.innerHeight;

    // Calculate the available height for the message box
    const availableHeight = viewportHeight - chatBoxTop - 46; // 20px padding or margin as needed
    chatBox.style.maxHeight = `${availableHeight}px`;
  }

  private initializeApplication = (appId: number) => {
    if (appId && this.appId != appId) {
      this.setLoading(true);
      this.appId = appId;
      this._loanService.getApplicationModel(this.appId, false).subscribe({
        next: (appModel) => {
          this.application = appModel;
          this._loanService.getBorrowers(this.appId).subscribe({
            next: (borrowers: Borrower[]) => {
              this.borrowers = borrowers;
              this.loadInternalContacts(this.appId);
              if (this.application.externalCompanyId > 0) {
                this.allUsers = this.applicationContext.globalConfig.usersAll.concat(this.applicationContext.globalConfig.tpoUsers.filter(u => u.branchId === this.application.branchId));
              } else {
                this.allUsers = this.applicationContext.globalConfig.usersAll;
              }
              this.setLoading(false);
            },
            error: (error) => {
              this._notifyService.showError(error?.message || "Couldn't load application borrowers.", "Error!");
              this.setLoading(false);
            }
          })
        },
        error: (error) => {
          this._notifyService.showError(error?.message || "Couldn't load application.", "Error!");
          this.setLoading(false);
        }
      })
    }

    if (this.convoGroupPopover) {
      this.convoGroupPhoneNumber = '';
      this.convoGroupPhoneNumbers = [];
      this.convoGroupPopover.hide();
    }

    this._conversation['tempConvoGroupExternalName'] = this._conversation.externalName;

    this._conversation.participants.forEach(participant => {
      if (participant.phoneNumber) {
        this.convoGroupPhoneNumbers.push(participant.phoneNumber);
        this.sortConvoGroupPhoneNumbers();
      }
    });
  }

  private loadInternalContacts = (applicationId: number) => {
    this._internalContactsService.getInternalContacts(applicationId).subscribe(internalContacts => {
      if (internalContacts && internalContacts.length > 0) {
        this._internalContacts = internalContacts;
        this.initializeMentionables();
      }
    });
  }

  private initializeMentionables = () => {
    const usersThatCanBeMentioned = MentionsUtils.prepareUserMentionables(this._environment.apiInfo.apiBaseUrl, this.allUsers);
    const company = this.applicationContext.globalConfig.company.find(c => c.companyId == this.applicationContext.userPermissions.companyId);
    const rolesThatCanBeMentioned = this.prepareContactRoleMentionables(
      this.application.channel,
      company,
      this._internalContacts, this.allUsers);
    this.usersThatCanBeMentioned = usersThatCanBeMentioned.concat(rolesThatCanBeMentioned);
    this.usersThatCanBeMentioned.push(MentionsUtils.prepareInternalContactsMentionable());
    this.usersThatCanBeMentioned.push(MentionsUtils.prepareHereMentionable());
  }

  private prepareContactRoleMentionables = (channelName: string, company: Company, internalContacts: InternalContact[], allUsers: User[]): Mentionable[] => {
    let mentionables: Mentionable[] = [];

    const enabledChannels = this._channelService.getChannelsFromCommaDelimitedString(company.enabledChannels);
    const enabledChannel = enabledChannels.find(c => c.value === channelName);

    if (enabledChannel) {
      mentionables = MentionsUtils.prepareContactRoleMentionables(
        enabledChannel.value,
        this.applicationContext.globalConfig.channelRoles,
        internalContacts,
        allUsers);
    }
    return mentionables;
  }

  private setLoading = (isLoading: boolean) => {
    if (this.chatWindow) {
      this.chatWindow.showLoading = isLoading;
    }
  }

  onEditExternalNameClicked = () => {
    this._conversation['onEdit'] = true;
  }

  onExternalNameRenameCancelled = () => {
    this._conversation['tempConvoGroupExternalName'] = this.conversation.externalName;
    this._conversation['onEdit'] = false;
  }

  onExternalNameRenameConfirmed = () => {
    if (this._conversation.externalName && this.conversation['tempConvoGroupExternalName'].trim() === this._conversation.externalName.trim()) {
      this.conversation['tempConvoGroupExternalName'] = this._conversation.externalName;
      this._conversation['onEdit'] = false;
      return;
    }

    this._spinnerService.show();
    this._conversation.externalName = this.conversation['tempConvoGroupExternalName'];
    this.updateConversation(this._conversation.participants, this._conversation, []);
    this._conversation['onEdit'] = false;
  }

  onEditBorrowerClicked = (borrower: ChatBorrower) => {
    this.editBorrower.emit(borrower);
  }

  onEditLeadClicked = (lead: ChatLead) => {
    this.editLead.emit(lead);
  }

  archiveConversation = () => {
    this.onArchiveConversation.emit(!this.conversation.isHidden)
  }

  recordVideo = () => {
    const modalRef = this._modalService.open(VideoRecorderDialogComponent, { ...Constants.modalOptions.large, scrollable: false });
    modalRef.result.then(video => {
      this.chatWindow.addRecordedVideo(video);
    }, err => { });
  }

  onConversationUpdated = () => {
    this.conversationUpdated.emit(this.conversation)
  }

  protected onNewMessageWasSeen = (conversation: Conversation) => {
    this.markConversationAsRead(conversation);
  }

  private updateConversation = async (
    convoParticipantsBeforeSaving: ConversationParticipant[],
    conversation: Conversation,
    newAddedParticipants: ConversationParticipant[],
    saveAndUpdateOperation: boolean = false) => {
    const copyOfConversation = cloneDeep(conversation);

    const setExternalNameAndAlias = (convo: Conversation) => {
      let externalParticipants = convo.participants.filter(p => p.participantType === ParticipantType.External && p.name);
      if (externalParticipants.length === 1) {
        convo.externalName = externalParticipants[0].name;
      } else if (externalParticipants.length > 1) {
        let externalName = externalParticipants.slice(0, 3)
          .map(participant => participant.name.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase())
          .join(', ');
        if (externalParticipants.length > 3) {
          externalName += ' ...';
        }
        convo.externalName = externalName;
      }
      convo.contactAlias = externalParticipants[0].name.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase();
    };

    if (newAddedParticipants.length > 0) {
      if (!(await this.openSwalAlert(copyOfConversation))) {
        this.isSaving = false;
        this.convoGroupPhoneNumber = '';
        this.convoGroupPhoneNumbers = this._convoGroupPhoneNumbersBeforeSaving;
        this._conversation.participants = this._conversation.participants.filter(p => p.id);
        this.isSaveEnabled = this.checkNumbersInConversation();
        this._convoGroupPhoneNumbersBeforeSaving = [];
        this.convoGroupPopover.hide();
        return;
      }
    } else {
      if (!this._conversation['tempConvoGroupExternalName']) {
        setExternalNameAndAlias(conversation);
      } else {
        conversation.externalName = this._conversation['tempConvoGroupExternalName'];
        conversation.contactAlias = this._conversation['tempConvoGroupExternalName'].match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase();
      }
    }

    const conversationToUpdate = _.cloneDeep(newAddedParticipants.length > 0 ? copyOfConversation : conversation);
    delete conversationToUpdate.conversations;
    delete conversationToUpdate.conversationMessages;

    this._spinnerService.show();

    return firstValueFrom(
      this._conversationService.updateConversation(conversationToUpdate).pipe(
        finalize(() => {
          this.isSaving = false;
          this.convoGroupPhoneNumber = '';
          this._convoGroupPhoneNumbersBeforeSaving = [];
          this.convoGroupPopover.hide();
          this._spinnerService.hide();
        }),
        tap((response: Conversation) => {
          const { isActive, contactAlias, externalName, contactAvatarColor, conversationMessages } = conversationToUpdate;

          // rename or replace condition: create a new conversation group and overwrite the existing convo.
          if (conversationToUpdate.conversationId === response?.conversationId && !saveAndUpdateOperation) {
            conversation = response;

            const updatedProperties = newAddedParticipants.length > 0
              ? { isActive, contactAvatarColor }
              : { isActive, contactAlias, contactAvatarColor, conversationMessages };

            Object.assign(conversation, updatedProperties);
            conversation.conversations = this._conversationsInGroup;
            this.conversationUpdated.emit(conversation);
          }
          // regenerate condition: create a new conversation group with the new convoID, without touching the existing convo.
          else {
            conversation.isActive = false;
            conversation.participants = convoParticipantsBeforeSaving;
            response.externalName = externalName;
            response.isActive = isActive;
            response.contactAlias = contactAlias;
            response.mostRecentIncomingMessageDate = undefined;
            response.mostRecentOutgoingMessageDate = undefined;
            response.mostRecentMessageDate = undefined;
            response.lastReadDate = undefined;
            response.conversations = this._conversationsInGroup;
            response.mostRecentMessagePreview = undefined;
            response.conversationMessages = [];
            this.conversationGroupCreated.emit(response);
          }

          this.newlyAddedNumbers = [];
        }),
        catchError((err) => {
          this.conversation.participants = convoParticipantsBeforeSaving;
          this.convoGroupPhoneNumbers = convoParticipantsBeforeSaving.map(c => c.phoneNumber);
          this._notifyService.showError(err ? (err.message ? err.message : '') : '', 'Error');
          return of(undefined);
        }),
      ),
    );
  }

  private sortConvoGroupPhoneNumbers() {
    this.convoGroupPhoneNumbers.sort((a, b) => {
      const aIsInternal = this.checkNumberParticipantType(a);
      const bIsInternal = this.checkNumberParticipantType(b);

      if (aIsInternal && !bIsInternal) {
        return -1;
      } else if (!aIsInternal && bIsInternal) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  private checkNumbersInConversation = (): boolean => {
    if (this._convoGroupPhoneNumbersBeforeSaving.length !== this.convoGroupPhoneNumbers.length) {
      return true;
    }

    for (let i = 0; i < this.convoGroupPhoneNumbers.length; i++) {
      if (this.convoGroupPhoneNumbers[i] !== this._convoGroupPhoneNumbersBeforeSaving[i]) {
        return true;
      }
    }

    return false;
  }

  private async openSwalAlert(copyOfConversation: Conversation): Promise<boolean> {
    const result = await Swal.fire({
      text: `You've just changed the participants in ${this._conversation.externalName}. This action will create a new conversation. How do you want to name this new conversation?`,
      icon: 'info',
      input: 'text',
      showCancelButton: true,
      confirmButtonText: 'Save',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    })

    if (result.isDenied || !result.value) return false;

    copyOfConversation.externalName = result.value;
    copyOfConversation.contactAlias = result.value.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase();

    return true;
  }
}

export class FromPhoneNumberInfo {
  guid: string;
  phoneNumber: string;
  conversationId: number;
  isUnread: boolean;
  isMine: boolean;
}
