<div class="modal-header">
  <h5 class="modal-title">Field Mappings</h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <div class="card">
    <div class="card-body">
      <h5 class="card-title d-flex align-items-center justify-content-between">{{['ToExecuteRequest',
        'ToProductSearchRequest'].includes(mappingType) ? 'Pricing' : (mappingType == 'ToProduct' ? 'Product'
        : 'Quote')}} Field Mappings
        <button *ngIf="mappings.length" class="btn btn-soft-danger" (click)="onResetToDefaultMappings()">Reset to
          default mappings</button>
      </h5>
      <hr />
      <loan-pass-fields-mapper *ngIf="isLoadingCompleted"
        [isMappingFromLoanPassToLoda]="['ToProductSearchRequest','ToProduct', 'ToQuote'].includes(mappingType)"
        [fieldType]="['ToExecuteRequest','ToProductSearchRequest'].includes(mappingType) ? 'creditApplicationFields' : 'productFields'" 
        [loanPassFieldsConfig]="fieldsConfig" 
        [credentialId]="credentialId"
        [operators]="operators" 
        [expressionFields]="expressionFields" 
        [mappings]="mappings" 
        [vendor]="vendor"
        [mappingType]="mappingType"></loan-pass-fields-mapper>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary right" (click)="onCancelClicked()">
    Cancel
  </button>

  <button type="button" class="btn btn-primary right" (click)="onSaveClicked()">
    Save
  </button>
</div>
