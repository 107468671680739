import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs';
import { KeyDate, LoanApplication, LoanStatus, SubStatus } from 'src/app/models';
import { CheckListItemEvalDialogComponent } from 'src/app/modules/admin/checklists/dialogs/check-list-item-eval-dialog/check-list-item-eval-dialog.component';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { CheckListQuestion, CheckListService } from 'src/app/services/check-list.service';
import { Constants } from 'src/app/services/constants';
import { KeyDatesService } from 'src/app/services/key-dates.service';
import { LoanService } from 'src/app/services/loan';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';

@Component({
  selector: 'loan-status-summary',
  templateUrl: 'loan-status-summary.component.html',
  styleUrls: ['loan-status-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoanStatusSummaryComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input()
  set application(application: LoanApplication) {
    this._application = application ? _.cloneDeep(application) : null;
    if (this._application) {
      this._originalLoanStatusId = application.loanStatusId;
      this.isEmailSmsPaused = this._application.pauseMessages;
      this.loadNextStatuses(this._application);
    }
  }

  get application(): LoanApplication {
    return this._application;
  }

  nextStatuses: LoanStatus[] = undefined;

  subStatuses: SubStatus[] = [];

  isEmailSmsPaused: boolean = false;

  isPausingEmailAndSms: boolean = false;

  isSavingStatus: boolean = false;

  isSuspenseSubStatus: boolean = false;

  private _application: LoanApplication;

  private _originalLoanStatusId: number = null;
  private borrowerNameById: Map<number, string> = new Map<number, string>();

  constructor(private injector: Injector,
    private _changeDetectorRef: ChangeDetectorRef,
    private readonly _applicationContextService: ApplicationContextService,
    private readonly _notificationService: NotificationService,
    private readonly _checkListService: CheckListService,
    private readonly _keyDatesService: KeyDatesService,
    private readonly _modalService: NgbModal,
    private readonly _spinnerService: NgxSpinnerService,
    private _loanService: LoanService) {
    super(injector);
  }

  ngOnInit() {
    this._applicationContextService.context.subscribe(context => {
      this.subStatuses = context.subStatuses;
      context.borrowers.forEach(borr => {
        this.borrowerNameById.set(borr.borrowerId, `${borr.firstName} ${borr.lastName}`);
      });
    });

    this._applicationContextService.loanInfoChanges.subscribe(context => {
      this.subStatuses = context.subStatuses;
      this.setSubStatusSuspenseInfo();
      if (context.application) {
        this.loadNextStatuses(this._application);
      }
    });
    this.setSubStatusSuspenseInfo();
  }

  onPauseOrUnPauseEmailAndSmsClicked = () => {
    if (this.applicationContext.userPermissions.companyId == 165 && !this.applicationContext.userPermissions.admin) {
      this._notificationService.showWarning("You do not have permission to change this. Please contact your administrator.", "Warning!");
      return;
    }

    this.isPausingEmailAndSms = true;
    if (!this.isEmailSmsPaused) {
      this.pauseEmailAndSms();
    } else {
      this.unPauseEmailAndSms();
    }
  }

  onSubStatusChanged = () => {
    this.setSubStatusSuspenseInfo();
  }

  onSaveClicked = () => {
    this.isSavingStatus = true;
    this._spinnerService.show();
    this._checkListService.getChecklistQuestionsForApplicationAndStatus(
      this._application.loanStatusId,
      this._application.applicationId,
      true
    ).subscribe({
        next: (questions) => {
          if (questions && questions.length > 0) {
            this.isSavingStatus = false;
            this._spinnerService.hide();
            this._changeDetectorRef.detectChanges();
            this.showCheckListEvalModal(questions);
          } else {
            this.saveLoanStatus();
          }
        }, error: (err) => {
          this.isSavingStatus = false;
          this._spinnerService.hide();
          this._notificationService.showError(
            err ? err.message : 'Unable to get checklist.',
            'Error!'
          );
        }
      });
  }

  private showCheckListEvalModal = (checklistQuestions: Array<CheckListQuestion>) => {
    let modalRef = this._modalService.open(CheckListItemEvalDialogComponent, {
      ...Constants.modalOptions.large
    });
    modalRef.componentInstance.checklistQuestions = checklistQuestions;
    modalRef.componentInstance.applicationId = this._application.applicationId;
    modalRef.componentInstance.borrowerNameById = this.borrowerNameById;
    modalRef.componentInstance.isEvalTest = false;
    modalRef.result.then((allQuestionAnswered: boolean) => {
      if (allQuestionAnswered) {
        this.saveLoanStatus();
      }
    }, () => {
    });
  }

  private setSubStatusSuspenseInfo = () => {
    const selectedSubStatus = this.subStatuses.find(ss => ss.subStatusId == this._application.subStatusId);
    this.isSuspenseSubStatus = !!selectedSubStatus?.isSuspenseSubStatus;
  }

  private saveLoanStatus = () => {
    this.isSavingStatus = true;
    this._spinnerService.show();
    this._loanService.saveLoanStatus(this._application.applicationId, this._application.loanStatusId, this._application.subStatusId).subscribe(result => {
      this._changeDetectorRef.detectChanges();
      this._applicationContextService.updateLoanTasks();
      this._applicationContextService.updateApplication(this._application, true);
      this._notificationService.showSuccess("Successfully updated loan status.", "Success!");
      this.updateKeyDatesAfterChangeStatus();
    }, error => {
      this._application.loanStatusId = this._originalLoanStatusId;

      this._changeDetectorRef.detectChanges();
      const errorMessage = error?.error || error || 'Unable to update loan status.';
      this._notificationService.showError(
        errorMessage,
        'Error!'
      );
    }).add(() => {
      this.isSavingStatus = false;
      this._spinnerService.hide();
    })
  }

  private loadNextStatuses = (application: LoanApplication) => {
    this._loanService
      .getLoanStatusesForLoanPurpose(
        application.loanPurposeId,
        application.loanStatusId,
        application.applicationId,
        application.channel
      )
      .subscribe({
        next: (statuses) => {
          this.nextStatuses = statuses;
          this._changeDetectorRef.detectChanges();
        },
        error: (error) => {
          this._notificationService.showError(
            error ? error.message : 'Unable load next statuses.',
            'Error!'
          );
        }
      });
  };

  private pauseEmailAndSms = () => {
    this._loanService.pauseEmailAndSms(this._application.applicationId).subscribe(result => {
      this.isEmailSmsPaused = true;
      this.isPausingEmailAndSms = false;
      this._application.pauseMessages = true;
      this._applicationContextService.updateApplication(this._application);
      this._changeDetectorRef.detectChanges();
    }, error => {
      this.isPausingEmailAndSms = false;
      this._changeDetectorRef.detectChanges();
      this._notificationService.showError(
        error ? error.message : 'Unable to pause E-mail/SMS.',
        'Error!'
      );
    });
  }

  private unPauseEmailAndSms = () => {
    this._loanService.unPauseEmailAndSms(this.application.applicationId).subscribe(result => {
      this.isEmailSmsPaused = false;
      this.isPausingEmailAndSms = false;
      this._application.pauseMessages = false;
      this._changeDetectorRef.detectChanges();
      this._applicationContextService.updateApplication(this._application);
    }, error => {
      this._changeDetectorRef.detectChanges();
      this.isPausingEmailAndSms = false;
      this._notificationService.showError(
        error ? error.message : 'Unable to un-pause E-mail/SMS.',
        'Error!'
      );
    });
  }

  private updateKeyDatesAfterChangeStatus = () => {
    this._keyDatesService.getKeyDates(this.application.applicationId).subscribe({
      next: (keyDates) => {
        this._applicationContextService.updateKeyDates(keyDates as KeyDate[]);
      },
      error: (error) => {
        this._notificationService.showError(error?.message || "Couldn't load key dates.", "Error!");
      }
    })
  }
}

export class LoanStatusInfo {
  statusId: number;
  subStatusId: number;
}
