import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { ConversationChat } from 'src/app/models/chat-sms.model';
import { Conversation, ConversationParticipant, ConversationResponse } from '../models/conversation.model';
import { BorrowerChatRequested, InternalChatRequested, NewBorrowerMessagePostedModel, NewSmsHistoryPosted, SmsChatRequestedModel } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})
export class ConversationService {

  @Output()
  conversationUpdated: EventEmitter<Conversation> = new EventEmitter<Conversation>();

  @Output()
  smsChatRequested: EventEmitter<SmsChatRequestedModel> = new EventEmitter<SmsChatRequestedModel>();

  @Output()
  borrowerChatRequested: EventEmitter<BorrowerChatRequested> = new EventEmitter<BorrowerChatRequested>();

  @Output()
  internalChatRequested: EventEmitter<InternalChatRequested> = new EventEmitter<InternalChatRequested>();

  @Output()
  smsChatIncomingMessageAdded: EventEmitter<Partial<NewSmsHistoryPosted>> = new EventEmitter<Partial<NewSmsHistoryPosted>>();

  @Output()
  borrowerMessagingIncomingMessageAdded: EventEmitter<Partial<NewBorrowerMessagePostedModel>> = new EventEmitter<Partial<NewBorrowerMessagePostedModel>>();

  @Output()
  internalMessagingIncomingMessageAdded: EventEmitter<Partial<NewBorrowerMessagePostedModel>> = new EventEmitter<Partial<NewBorrowerMessagePostedModel>>();

  constructor(private _dataService: DataService) { }

  addBorrowerChat = (appId: number, borrowerId: number, borrMessage: NewBorrowerMessagePostedModel, openDrawer?: boolean, external?: boolean) => {
    const borrowerChat = {
      appId,
      borrowerId,
      borrMessage,
      openDrawer,
      external
    }
    this.borrowerChatRequested.emit(borrowerChat);
  }

  addInternalChat = (appId: number, borrMessage: NewBorrowerMessagePostedModel, openDrawer?: boolean, external?: boolean) => {
    const internalChat = {
      appId,
      borrMessage,
      openDrawer,
      external,
    }
    this.internalChatRequested.emit(internalChat);
  }
  
  addSmsMessageToChat = (messageModel: Partial<NewSmsHistoryPosted>) => {
    this.smsChatIncomingMessageAdded.emit(messageModel);
  }

  addBorrowerMessageToChat = (messageModel: Partial<NewBorrowerMessagePostedModel>) => {
    this.borrowerMessagingIncomingMessageAdded.emit(messageModel);
  }

  addInternalMessageToChat = (messageModel: Partial<NewBorrowerMessagePostedModel>) => {
    this.internalMessagingIncomingMessageAdded.emit(messageModel);
  }

  getConversationsForUser = (userCompanyGuid: string, pageNumber: number, pageSize: number, minutesToLookBack: number = null): Observable<ConversationResponse> => {
    let url = `api/conversation/user-conversations?userCompanyGuid=${userCompanyGuid}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    if (minutesToLookBack) {
      url += `&minutesToLookBack=${minutesToLookBack}`;
    }
    return this._dataService.get(url);
  }

  getRelatedEntities = (betweenPhone: string) => {
    const url = 'api/chat/GetRelatedEntities/';
    return this._dataService.post(url, { betweenPhone });
  }

  getSmsChatMessages = (userPhone: string, betweenPhone: string): Observable<ConversationChat> => {
    const url = 'api/chat/GetSmsChatMessages';
    return this._dataService.post(url, { userPhone, betweenPhone });
  }

  getMediaFile = (id: number) => {
    const url = 'api/Sms/media-file/' + id;
    return this._dataService.get(url);
  }

  openSmsChat = (smsChat: SmsChatRequestedModel) => {
    this.smsChatRequested.emit(smsChat);
  }

  markAsRead = (conversationId: number) => {
    const url = 'api/conversation/mark-as-read/' + conversationId;
    return this._dataService.patch(url, {});
  }

  changeHiddenStatus = (conversationId: number, hiddenStatus: boolean) => {
    const url = 'api/conversation/' + conversationId + '/hidden/' + hiddenStatus; 
    return this._dataService.patch(url, {});
  }

  saveConversation = (conversation: Conversation) => {
    const url = 'api/conversation';
    return this._dataService.post(url, conversation);
  }

  updateConversation = (conversation: Conversation) => {
    const url = 'api/conversation';
    return this._dataService.put(url, conversation);
  }
}
