<div class="alert alert-dismissible d-flex-row align-items-center mb-3"
  *ngIf="isSubmitSuccess || isSubmitError || validationErrors.length"
  [ngClass]="{'alert-outline-success': isSubmitSuccess, 'alert-outline-danger': isSubmitError || validationErrors.length}"
  role="alert">
  <div *ngIf="isSubmitSuccess">
    <span *ngIf="request.creditRequestType == 'Reissue'; else submit">
      <strong>Success:</strong> Credit re-issued successfully.
    </span>
    <ng-template #submit>
      <span>
        <strong>Success:</strong> Credit report generated successfully
      </span>
    </ng-template>
  </div>
  <div *ngIf="isSubmitError">
    <div *ngFor="let error of errorMessages">
      <strong>Error:</strong> {{error? error : 'Credit Request Failed.'}}
    </div>
  </div>

  <ng-container *ngIf="validationErrors.length">
    <div *ngFor="let validationError of validationErrors">
      <div><strong>Error:</strong> {{validationError}}</div>
    </div>
  </ng-container>

  <button type="button" class="btn-close" data-bs-dismiss="alert" (click)="closeErrors()" aria-label="Close"></button>
</div>
<form #loanCreditRunForm="ngForm" novalidate id="loanCreditRunForm" name="loanCreditRunForm">
  <div class="card mb-2">
    <div class="card-body">
      <ng-container *ngFor="let appIndex of appIndexes">
        <ng-container *ngIf="borrowersGroupedByAppIndex.get(appIndex) as creditBorrowers;">
          <div class="row mb-2">
            <div class="col-sm-9">
              <div class="row">
                <div *ngFor="let borrower of creditBorrowers; let index = index" class="col-sm-6 mb-2">
                  <div class="card mb-0">
                    <div class="card-header d-flex justify-content-between">
                      <span>{{borrower.firstName + ' '}} {{borrower.lastName ? borrower.lastName :
                        ' '}}</span>
                      <span>
                        {{borrower.last4Ssn ? 'XXX-XX-' + borrower.last4Ssn : '' }}
                        {{ borrower.last4Ssn && borrower.birthDate ? '/' : ''}}
                        {{borrower.birthDate | date}}
                        <button [hidden]="showRunCredit" class="btn btn-xs btn-soft-primary pb-0"
                          style="margin-right: -10px;" (click)="onPullIndividualCreditForBorrowerClicked(borrower)">
                          <b>Pull/Re-issue</b>
                        </button>
                      </span>

                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-sm-4 text-center"
                          [ngClass]="{'text-success': borrower['highlightScore'] == 'experian' }">
                          <b> {{borrower.latestCreditReport?.experian ?
                            borrower.latestCreditReport.experian : 'N/A'}} </b>
                          <br>
                          Experian
                        </div>
                        <div class="col-sm-4 text-center"
                          [ngClass]="{'text-success': borrower['highlightScore'] == 'equifax' }">
                          <b> {{borrower.latestCreditReport?.equifax ?
                            borrower.latestCreditReport.equifax : 'N/A'}} </b>
                          <br>
                          Equifax
                        </div>
                        <div class="col-sm-4 text-center"
                          [ngClass]="{'text-success': borrower['highlightScore'] == 'transUnion' }">
                          <b> {{borrower.latestCreditReport?.transUnion ?
                            borrower.latestCreditReport.transUnion : 'N/A'}} </b>
                          <br>
                          TransUnion
                        </div>
                      </div>

                    </div>
                    <div class="card-body pt-0 border-top" *ngIf="borrower.latestCreditReport">
                      <div class="row">
                        <div class="d-flex justify-content-center align-items-center mt-3">
                          <span class="me-3" *ngIf="borrower.latestCreditReport?.refNumber">
                            Ref #: <b>{{borrower.latestCreditReport?.refNumber}}</b>
                          </span>
                          <span class="flex-grow-1"> {{borrower.latestCreditReport?.date |
                            date: 'MM/dd/yyyy h:mma'}} </span>
                          <span class="pull-right">
                            <a *ngIf="borrower.latestCreditReport.loanDocId" class="me-2" title="Loan Doc"
                              (click)="onOpenLoanDocClicked(borrower.latestCreditReport.loanDocId)">
                              <i class="fa fa-lg far fa-file-pdf"></i>
                            </a>
                            <a *ngIf="borrower.latestCreditReport.refNumber && borrower.latestCreditReport.integrationHistoryId"
                              class="me-2" title="Re-issue"
                              (click)="onReissueClicked(borrower.latestCreditReport.integrationHistoryId)">
                              <i class='fas fa-undo-alt'></i>
                            </a>
                            <a *ngIf="borrower.latestCreditReport.successful === true && borrower.latestCreditReport.integrationHistoryId"
                              class="me-2" title="Report"
                              (click)="onOpenReportClicked(borrower.latestCreditReport.integrationHistoryId)">
                              <i class='fa-lg far fa-file-pdf'></i>
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-3 my-auto">
              <div class="d-flex justify-content-center align-items-center">
                <a *ngIf="(creditBorrowers.length == 1 || borrowerIsJointMap.get(creditBorrowers[0].borrowerId) ) && (creditBorrowers[0].latestCreditReport && creditBorrowers[0].latestCreditReport.loanDocId)"
                  (click)="onOpenLoanDocClicked(creditBorrowers[0].latestCreditReport.loanDocId)" title="Loan Doc">
                  <i class="fa fa-lg far fa-file-pdf"></i>
                </a>
                <a *ngIf="(creditBorrowers.length == 1 || borrowerIsJointMap.get(creditBorrowers[0].borrowerId) ) && creditBorrowers[0].latestCreditReport &&
                              creditBorrowers[0].latestCreditReport.refNumber && creditBorrowers[0].latestCreditReport.integrationHistoryId"
                  class="ms-2" title="Re-issue"
                  (click)="onReissueClicked(creditBorrowers[0].latestCreditReport.integrationHistoryId)">
                  <i class='fas fa-undo-alt'></i>
                </a>
                <a *ngIf="(creditBorrowers.length == 1 || borrowerIsJointMap.get(creditBorrowers[0].borrowerId) ) && creditBorrowers[0].latestCreditReport &&
                              creditBorrowers[0].latestCreditReport.successful === true && creditBorrowers[0].latestCreditReport.integrationHistoryId"
                  class="ms-2" title="Report"
                  (click)="onOpenReportClicked(creditBorrowers[0].latestCreditReport.integrationHistoryId)">
                  <i class='fa-lg far fa-file-pdf'></i>
                </a>

                <button [hidden]="showRunCredit" class="btn btn-outline-primary ms-2"
                  (click)="onPullCreditForBorrowersClicked(creditBorrowers)">
                  {{pullCreditIndividualOrJointButtonLabelsPerApplicationIndex.get(appIndex)}}
                </button>
              </div>
              <div *ngIf="(creditBorrowers.length == 1 || borrowerIsJointMap.get(creditBorrowers[0].borrowerId) )"
                class="d-flex justify-content-center align-items-center mt-3">
                <span class="me-3" *ngIf="creditBorrowers[0].latestCreditReport?.refNumber">
                  Ref #: <b>{{creditBorrowers[0].latestCreditReport?.refNumber}}</b>
                </span>
                <span> {{creditBorrowers[0].latestCreditReport?.date | date: 'MM/dd/yyyy h:mma'}}
                </span>
              </div>

            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <div *ngIf="showRunCredit">
      <hr class="mt-0 mb-1">
      <button type="button" class="btn-close pull-right me-2" (click)="onCancelPullCreditClicked()"></button>
      <div class="card-body py-1 row ms-0">
        <div class="col-md-12">
          <div class="text-muted mb-2" [innerHTML]="runCreditLabel">
          </div>
        </div>
        <div class="col-md-6">
          <label class="mb-1">
            <b> Credit Vendor </b>
          </label>
          <select class="form-select" name="creditVendor" [(ngModel)]="request.credentialId"
            style="width: 100%; height:36px;" #creditVendor="ngModel" required
            [ngClass]="{'is-invalid' : creditVendor && creditVendor.touched  && creditVendor.invalid}"
            (ngModelChange)='onSelectedCreditCredentialIdChanged($event)' #creditVendorComponent>
            <option [ngValue]="null"> Select </option>
            <option [ngValue]="-1">+ Add New Credit Credentials</option>
            <option *ngFor="let vendor of creditVendors" [ngValue]="vendor.credentialId">
              {{vendor.vendorDisplayName}}
            </option>
          </select>
          <div class="invalid-feedback ps-1">Please select a Credit Vendor.</div>
        </div>
        <div class="col-md-6">
          <label class="mb-1 ms-1">
            <b> Choose Bureaus </b>
          </label>
          <div style="padding: 0.35rem;"
            [ngClass]="{'invalid-border': creditRunClicked && (!request.experian && !request.equifax && !request.transUnion)}">
            <div class="form-check checkbox-primary form-check-inline">
              <input type="checkbox" class="form-check-input" name="experian" id="experian"
                [(ngModel)]="request.experian" [checked]="request.experian"
                [required]="!request.experian && !request.equifax && !request.transUnion">
              <label for="experian"> Experian </label>
            </div>
            <div class="form-check checkbox-primary form-check-inline">
              <input type="checkbox" class="form-check-input" name="equifax" id="equifax" [(ngModel)]="request.equifax"
                [checked]="request.equifax" [required]="!request.experian && !request.equifax && !request.transUnion">
              <label for="equifax"> Equifax </label>
            </div>
            <div class="form-check checkbox-primary form-check-inline">
              <input type="checkbox" class="form-check-input" name="transUnion" id="transUnion"
                [(ngModel)]="request.transUnion" [checked]="request.transUnion"
                [required]="!request.experian && !request.equifax && !request.transUnion">
              <label for="transUnion"> TransUnion </label>
            </div>
          </div>
          <div *ngIf="creditRunClicked && (!request.experian && !request.equifax && !request.transUnion)">
            <div class="invalid-message ps-1"> Please select at least one Bureau. </div>
          </div>
        </div>
        <div class="col-md-12 mt-4" *ngIf="showSoftPullReportType">
          <div class="col-md-6">
            <label class="mb-2">
              <b> Report Type </b>
            </label>
            <select class="form-select" [(ngModel)]="request.reportType" name="reportType"
              style="width: 100%; height:36px;" #pullType="ngModel" required
              [ngClass]="{'is-invalid' : pullType && pullType.touched  && pullType.invalid}">
              <option [ngValue]="null"> -- Select -- </option>
              <option value="{{reportType.value}}" *ngFor="let reportType of reportTypes"> {{
                reportType.name }} </option>
            </select>
            <div class="invalid-feedback"> Please select a Request Type. </div>
          </div>
          <div class="col-md-6" *ngIf="request.reportType && request.reportType != 'Prequal' && !reissue">
            <div class="alert alert-warning mt-2">
              FYI: This is going to result in a Hard Credit Inquiry on the consumer.
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-4" *ngIf="needHardSoftPullSelection">
          <div class="col-md-6">
            <label class="mb-2">
              <b> Report Type </b>
            </label>
            <select class="form-select" [(ngModel)]="request.reportType" name="reportTypeHardSoft"
              style="width: 100%; height:36px;" #reportTypeHardSoftField="ngModel" required
              [ngClass]="{'is-invalid' : reportTypeHardSoftField && reportTypeHardSoftField.touched  && reportTypeHardSoftField.invalid}">
              <option [ngValue]="null"> -- Select -- </option>
              <option [ngValue]="'Merge'"> Hard Pull </option>
              <option [ngValue]="'SoftPull'"> Soft Pull </option>
            </select>
            <div class="invalid-feedback"> Please select a Report Type. </div>
          </div>
        </div>
      </div>
      <div class="card-body py-1 row ms-0">

        <div class="col-md-12">
          <div class="form-check checkbox-primary form-check-inline">
            <input type="checkbox" class="form-check-input" name="enterPaymentInfo" id="enterPaymentInfo"
              [(ngModel)]="enterPaymentInfo" [checked]="enterPaymentInfo" (change)="onEnterPaymentInfoCheckChanged()">
            <label for="enterPaymentInfo"> Enter Payment Info </label>
          </div>
        </div>

        <label class="my-2" *ngIf="enterPaymentInfo">
          <b>Payment Info</b>
        </label>

        <div class="col-md-6" *ngIf="enterPaymentInfo">
          <div class="form-group">
            <label for="credit_payerName">Name on the Card</label>
            <input id="credit_payerName" name="credit_payerName" type="text" class="form-control" #payerName="ngModel"
              [ngClass]="{'is-invalid' : payerName && payerName.touched  && payerName.invalid}"
              [(ngModel)]="request.paymentInfo.payerName" required />
            <div class="invalid-feedback"> Name on the Card is required. </div>
          </div>
          <div class="form-group">
            <label for="credit_cardNumber">Card Number</label>
            <input id="credit_cardNumber" name="credit_cardNumber" type="text" class="form-control"
              #payerAccount="ngModel" [mask]="creditCardMask"
              [ngClass]="{'is-invalid' : payerAccount && payerAccount.touched && payerAccount.invalid}"
              [(ngModel)]="request.paymentInfo.payerAccount" (change)="onCardNumberChanged()" required />
            <div class="invalid-feedback"> Card Number is required. </div>
          </div>
          <div class="form-group">
            <label for="credit_cardType">Card Type</label>
            <select id="credit_cardType" name="credit_cardType" class="form-select" #ccType="ngModel"
              [ngClass]="{'is-invalid' : ccType && ccType.touched && ccType.invalid}"
              [(ngModel)]="request.paymentInfo.ccType" (change)="onCardTypeChanged()" required>
              <option [ngValue]="null">-- Select One --</option>
              <option value="AmericanExpress">AmericanExpress</option>
              <option value="Discover">Discover</option>
              <option value="MasterCard">MasterCard</option>
              <option value="Visa">Visa</option>
            </select>
            <div class="invalid-feedback"> Card Type is required. </div>
          </div>
          <div class="form-group">
            <label for="credit_ccExpirationDate">Expiration Date</label>
            <input id="credit_ccExpirationDate" name="credit_ccExpirationDate" type="text" class="form-control"
              #ccExpirationDate="ngModel"
              [ngClass]="{'is-invalid' : ccExpirationDate && ccExpirationDate.touched && ccExpirationDate.invalid}"
              maxlength="7" [(ngModel)]="request.paymentInfo.ccExpirationDate" (blur)="onExpirationDateBlurred()"
              (ngModelChange)="onExpirationDateChanged()" placeholder="mm/yyyy" required />
            <div class="invalid-feedback" *ngIf="ccExpirationDate?.touched && ccExpirationDate?.errors?.invalid">
              Expiration Date is
              invalid. </div>
            <div class="invalid-feedback" *ngIf="ccExpirationDate?.touched && ccExpirationDate?.errors?.required">
              Expiration Date is
              required. </div>
          </div>
          <div class="form-group">
            <label for="credit_amount">Amount</label>
            <currency-input name="credit_amount" #paymentAmount="ngModel" [(ngModel)]="request.paymentInfo.amount"
              [readonly]="false" [disabled]="false" [required]="false"></currency-input>
          </div>
        </div>
        <div class="col-md-6" *ngIf="enterPaymentInfo">
          <div class="form-group">
            <label for="credit_payerAddress">Address</label>
            <input id="credit_payerAddress" name="credit_payerAddress" type="text" class="form-control"
              #payerAddress="ngModel"
              [ngClass]="{'is-invalid' : payerAddress && payerAddress.touched  && payerAddress.invalid}"
              [(ngModel)]="request.paymentInfo.payerAddress" required />
            <div class="invalid-feedback"> Address is required. </div>
          </div>
          <div class="form-group">
            <label for="credit_payerAddress2">Address 2</label>
            <input id="credit_payerAddress2" name="credit_payerAddress2" type="text" class="form-control"
              [(ngModel)]="request.paymentInfo.payerAddress2" />
          </div>
          <div class="form-group">
            <label for="credit_payerCity">City</label>
            <input id="credit_payerCity" name="credit_payerCity" type="text" class="form-control" #payerCity="ngModel"
              [ngClass]="{'is-invalid' : payerCity && payerCity.touched  && payerCity.invalid}"
              [(ngModel)]="request.paymentInfo.payerCity" required />
            <div class="invalid-feedback"> City is required. </div>
          </div>
          <div class="form-group">
            <label for="credit_payerState">State</label>
            <input id="credit_payerState" name="credit_payerState" type="text" class="form-control"
              #payerState="ngModel" [ngClass]="{'is-invalid' : payerState && payerState.touched  && payerState.invalid}"
              [(ngModel)]="request.paymentInfo.payerState" required />
            <div class="invalid-feedback"> State is required. </div>
          </div>
          <div class="form-group">
            <label for="credit_payerZipCode">Zip Code</label>
            <input id="credit_payerZipCode" name="credit_payerZipCode" type="text" class="form-control"
              #payerZipCode="ngModel"
              [ngClass]="{'is-invalid' : payerZipCode && payerZipCode.touched  && payerZipCode.invalid}"
              [(ngModel)]="request.paymentInfo.payerZipCode" required />
            <div class="invalid-feedback"> Zip Code is required. </div>
          </div>

        </div>
      </div>
      <div class="card-body py-1 row ms-0">
        <div class="col-md-12">
          <label *ngIf="!(request.creditVendor == 'CredCoHardPull' || request.creditVendor == 'CredCoSoftPull' ||
                    request.creditVendor == 'FactualDataHardPull' || request.creditVendor == 'FactualDataSoftPull')">
            <b>Re-issue?</b>
          </label>

          <label *ngIf="(request.creditVendor == 'CredCoHardPull' || request.creditVendor == 'CredCoSoftPull' ||
                    request.creditVendor == 'FactualDataHardPull' || request.creditVendor == 'FactualDataSoftPull')">
            <b>Issue Type</b>
          </label>

          <div class="col-2 mb-2">
            <select required class="form-select" *ngIf="(request.creditVendor == 'CredCoHardPull' || request.creditVendor == 'CredCoSoftPull' ||
                    request.creditVendor == 'FactualDataHardPull' || request.creditVendor == 'FactualDataSoftPull')"
              [(ngModel)]="issueType" name="issueTypeSelect" #selectedIssueType="ngModel"
              [ngClass]="{'is-invalid' : selectedIssueType && selectedIssueType.touched  && selectedIssueType.invalid}"
              (ngModelChange)="onIssueTypeChanged()" [disabled]="isReissueOnly">
              <option value="Reissue"> Re-Issue </option>
              <option value="Upgrade"
                *ngIf="selectedBorrowersToPullCreditFor[0].latestCreditReport && selectedBorrowersToPullCreditFor[0].latestCreditReport.integrationHistoryId">
                Upgrade </option>
              <option value="Submit"> Submit </option>
            </select>
            <div class="invalid-feedback"> Please select an Issue Type. </div>
          </div>

          <div class="form-check form-switch" *ngIf="issueType !== 'Upgrade'" [ngClass]="{'ps-0': (request.creditVendor == 'CredCoHardPull' || request.creditVendor == 'CredCoSoftPull' ||
                    request.creditVendor == 'FactualDataHardPull' || request.creditVendor == 'FactualDataSoftPull')}">
            <input *ngIf="!(request.creditVendor == 'CredCoHardPull' || request.creditVendor == 'CredCoSoftPull' ||
                    request.creditVendor == 'FactualDataHardPull' || request.creditVendor == 'FactualDataSoftPull')"
              (click)="onReissueToggleChanged(creditBorrowers)" class="form-check-input" type="checkbox"
              [(ngModel)]="reissue" [checked]="reissue" id="reissue" name="reissue" [disabled]="isReissueOnly">

            <ng-container *ngIf="reissue">
              <div class="mb-2 col-md-4"
                [ngClass]="{'mx-4': !(request.creditVendor == 'CredCoHardPull' || request.creditVendor == 'CredCoSoftPull' ||
                      request.creditVendor == 'FactualDataHardPull' || request.creditVendor == 'FactualDataSoftPull')}">
                <label for="creditReferenceNumber" class="mb-1">
                  <b> <span
                      *ngIf="request.pullType == 'Individual' && selectedBorrowersToPullCreditFor.length && selectedBorrowersToPullCreditFor[0]">
                      {{selectedBorrowersToPullCreditFor[0].firstName
                      + ' '}} {{selectedBorrowersToPullCreditFor[0].lastName ?
                      selectedBorrowersToPullCreditFor[0].lastName : ' '}} -
                    </span>Credit Reference # </b> </label>
                <input class="form-control" id="creditReferenceNumber" [patterns]="creditRefNumberPattern"
                  mask="AAAAAAAAAAAAAAAAAAAAAAAAAAAAAA" [validation]="false" [(ngModel)]="request.creditReferenceNumber"
                  name="creditReferenceNumber"
                  [ngClass]="{'is-invalid' : creditRefNo && creditRefNo.touched  && creditRefNo.invalid}"
                  #creditRefNo="ngModel" required>
                <div class="invalid-feedback ps-1">Please enter a Credit Reference Number.</div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="col-md-12 mt-2" *ngIf="isTPO && isCompanyDeephaven && request.creditVendor == 'EncompassCredit'">
          <label for="importLiabilities">
            <b>Import Liabilities?</b>
          </label>

          <div class="d-flex">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" [(ngModel)]="forceImportLiabilities"
                [checked]="forceImportLiabilities" id="importLiabilities" name="importLiabilities" [disabled]="false">
            </div>
            <div *ngIf="forceImportLiabilities"
              class="alert custom-alert custom-alert-info icon-custom-alert shadow-sm my-3 ms-3" role="alert">
              <div class="media">
                <i class="la la-exclamation-triangle alert-icon text-info font-30 me-3 align-self-center"></i>
                <div class="media-body align-self-center">
                  <h5>Warning! This action with overwrite the URLA and REOs will need to be linked to imported
                    liabilities.</h5>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="text-center mb-3 mt-2">
        <button type="button" class="btn btn-secondary me-1" (click)="onCancelPullCreditClicked()"
          *ngIf="!pullingCreditReport">
          <span class="spinner-border spinner-border-sm" role="status" *ngIf="pullingCreditReport">
          </span>
          Cancel
        </button>
        <button type="button" class="btn btn-primary" (click)="onPullCreditClicked()"
          [disabled]="isLoanReadOnly && !reissue && issueType != 'Upgrade'" [ngShow]="!pullingCreditReport">
          <span class="spinner-border spinner-border-sm" role="status" *ngIf="pullingCreditReport">
          </span>
          {{!pullingCreditReport ? actionButtonLabel : 'Running...'}}
        </button>
      </div>
    </div>
  </div>
</form>
