import { Component, Injector, OnDestroy } from '@angular/core';
import { ApplicationContext } from 'src/app/models/application-context.model';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { FullSizeScrollableTableComponent } from './full-size-scrollable-table-base.component';
import { Subscription } from 'rxjs';

@Component({
  template: ''
})
export abstract class ApplicationContextBoundComponent
  extends FullSizeScrollableTableComponent implements OnDestroy {

  public applicationContext: ApplicationContext;

  protected applicationContextService: ApplicationContextService;

  private _contextSubscriptionBase: Subscription;
  private _contextChangesSubscriptionBase: Subscription;
  private _callControlPanelStatChangeSubscription: Subscription;

  constructor(private readonly _injector: Injector) {
    super();
    this.applicationContextService = this._injector.get(ApplicationContextService);
    this._contextSubscriptionBase = this.applicationContextService.context.subscribe((context: ApplicationContext) => {
      this.onContextReady(context);
    });
    this._contextChangesSubscriptionBase = this.applicationContextService.changes.subscribe((context: ApplicationContext) => {
      this.onContextChange(context);
    });
    this._callControlPanelStatChangeSubscription = this.applicationContextService.callControlPanelStatChanges.subscribe(() => {
      if (this.applicationContext) {
        this.onCallControlPanelStatChange(this.applicationContext.isCallControlPanelOpen);
      }
    });
  }

  ngOnDestroy(): void {
    if (this._contextSubscriptionBase) {
      this._contextSubscriptionBase.unsubscribe();
    }
    if (this._contextChangesSubscriptionBase) {
      this._contextChangesSubscriptionBase.unsubscribe();
    }
    if (this._callControlPanelStatChangeSubscription) {
      this._callControlPanelStatChangeSubscription.unsubscribe();
    }
  }

  protected onContextReady(context: ApplicationContext) {
    this.applicationContext = context;
  }

  protected onContextChange(context: ApplicationContext) {
    this.applicationContext = context;
  }

  protected onCallControlPanelStatChange(isCallControlPanelOpen: boolean) {
    // override this method in child components
  }
}
