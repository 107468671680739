import { Component, Input, OnInit } from '@angular/core';
import { CustomData } from 'src/app/models/loan';
import { LoanService } from 'src/app/services/loan';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'loan-custom-data',
  templateUrl: './loan-custom-data.component.html',
  styleUrls: ['./loan-custom-data.component.scss']
})
export class LoanCustomDataComponent implements OnInit {

  @Input() applicationId: number;

  isSaving: boolean = false;
  customData: CustomData[];

  constructor(private _loanService: LoanService,
    private _notifyService: NotificationService) { }

  ngOnInit(): void {

    this._loanService.getLoanCustomData(this.applicationId)
      .subscribe(customData => {
        this.customData = customData;
      }, (err) => {
        this._notifyService.showError("Error encountered while loading custom data of the loan", "Error!");
      });

  }

  save = () => {
    this.isSaving = true;
    this._loanService.saveLoanCustomData(this.applicationId, this.customData)
      .subscribe(customData => {
        this.customData = customData;
        this.isSaving = false;
        this._notifyService.showSuccess("Custom Data saved successfully.", "Successfull!");

      }, (err) => {
        this.isSaving = false;
        this._notifyService.showError("Error encountered while saving custom data", "Error!");
      })

  }
}
