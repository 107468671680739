import { DatePipe, formatPercent } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Injector, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Column, Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import * as _ from 'lodash';
import { ContextMenuComponent, ContextMenuService } from 'ngx-contextmenu';
import { SortEvent, SortMeta } from 'primeng/api';
import { MultiSelect } from 'primeng/multiselect';
import { Table } from 'primeng/table';
import { EnvironmentService } from 'src/app/core/services/environment/environment.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { Utils } from 'src/app/core/services/utils';
import { ContactList, CustomData, CustomDataConfig, Role, UserRolesFilter } from 'src/app/models';
import { PipelineItem } from 'src/app/models/pipeline/pipeline-item.model';
import { WebPreferences } from 'src/app/models/web-preferences.model';
import { ReassignInternalContactsDialogComponent } from 'src/app/modules/admin-pipeline/components/reassign-internal-contacts-dialog/reassign-internal-contacts-dialog.component';
import { AppDetailsService } from 'src/app/modules/app-details/services/app-details.service';
import { ApplicationService } from 'src/app/modules/applications/services/applications.service';
import { CustomDataService } from 'src/app/modules/expression-builder/services/custom-data.service';
import { CommonService } from 'src/app/services/common.service';
import { Constants } from 'src/app/services/constants';
import { ApplicationMode, NavigationService } from 'src/app/services/navigation.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { Column as TableColumn } from 'src/app/shared/models/table-config.model';
import { DiffChecker } from 'src/utils/diff-checker';

const initGlobalFields: string[] = [
  'loanPurposeName',
  'loanTypeName',
  'branchName',
  'applicationIdWithPadding',
]

@Component({
  selector: 'pipeline-table-v2',
  templateUrl: './pipeline-table-v2.component.html',
  styleUrls: ['./pipeline-table-v2.component.scss']
})
export class PipelineTableV2Component extends ApplicationContextBoundComponent implements OnInit, AfterViewInit {

  @Input()
  set pipelineData(pipelineData: PipelineItem[]) {
    if (!pipelineData) {
      return;
    }
    this._pipelineData = pipelineData || [];
    const dateFields = this.columns.filter(c => c.dataType === 'date');

    this._pipelineData.forEach(data => {
      data['applicationIdWithPadding'] = 1 + Utils.padLeft(data.applicationId + '', '0', 9);
      data['fullName'] = Utils.getPersonsFullName(data);
      data['fullNameWithComma'] = Utils.getPersonsDisplayName(data);
      if (data.interestRate != null || data.interestRate != undefined) {
        data.interestRate = data.interestRate / 100;
      }
      data['interestRate'] = !isNaN(data.interestRate) ? formatPercent(data.interestRate, "en-US", "1.2-3") : "" as any;
      data['ltv'] = data['ltv'] ? !isNaN(data['ltv']) ? formatPercent(data['ltv'] / 100, "en-US", "1.2-2") : "" : "" as any;

      data['borrowerId'] = data.primaryBorrowerId;
      data['branchName'] = this.getBranchName(data);

      if (data.lockExpirationDate) {
        (data.lockExpirationDate as any) = new Date(data.lockExpirationDate);
      }
      if (data.loanStatusChangedDate) {
        (data.loanStatusChangedDate as any) = new Date(data.loanStatusChangedDate);
      }

      data.keyDates?.forEach(keyDate => {
        if (keyDate.eventDate) {
          const rawKeyDateValue = new Date(keyDate.eventDate);
          data['KeyDate_' + keyDate.keyDateConfigurationId + "_ForSorting"] = rawKeyDateValue;
          data['KeyDate_' + keyDate.keyDateConfigurationId] = this._datePipe.transform(rawKeyDateValue, 'MM/dd/yyyy');;
        }
        if (keyDate.formattedEventDate) {
          data['KeyDate_' + keyDate.keyDateConfigurationId] = keyDate.formattedEventDate;
        }
      })

      dateFields.forEach(dateField => {
        if (data[dateField.field]) {
          data[dateField.field] = new Date(data[dateField.field]);
        }
      })
    });

    if (this.webPreferences) {
      this.populateSelectedColumns();
    }
    this.prepareEditConfigsForEachCustomField(this._pipelineData, (newPipelineData) => {
      this.filteredPipelineData = [...newPipelineData];
    });
  }

  @Input()
  set selectedColumns(selectedColumns: any) {
    this._selectedColumns = selectedColumns;
    if (selectedColumns) {
      this._selectedColumns.sort((a, b) => a.order - b.order);
      if (this.dt1) {
        setTimeout(() => {
          this.dt1.saveState();
        });
      }
    }

    this.setGlobalFilterFields();
  }

  @Input() isTpoUser: boolean;

  @Input()
  set singleChannelFilter(filter: string) {
    this._singleChannelFilter = filter
  };

  @Input()
  webPreferences: WebPreferences;

  @Input()
  filter: UserRolesFilter;

  @Output()
  loanDetailsClicked: EventEmitter<PipelineItem> = new EventEmitter<PipelineItem>();

  @Output()
  tableFilterRequestedByStatus: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  pipelineFilterChanged: EventEmitter<UserRolesFilter> = new EventEmitter<UserRolesFilter>();

  @ViewChild('dt1')
  dt1: Table;

  @ViewChild('contextMenuContacts', { static: true })
  contextMenuContacts: ContextMenuComponent;

  @ViewChild('mobileColumnSelector')
  mobileColumnSelector: MultiSelect;

  get selectedColumns(): TableColumn[] { return this._selectedColumns; }

  _singleChannelFilter: string = null;
  columns: TableColumn[] = [];
  selectedRows: any[];
  filteredPipelineData: PipelineItem[] = [];
  loanTeam: ContactList[] = null;

  globalSearchString: string = "";
  url: string = "";
  tableStateName = "pipeline-table-state";
  baseAvatarUrl: string;

  id: number;

  globalFilterFields: string[] = [...initGlobalFields];

  dialerEnabled: boolean = false;
  areChannelsFilteredToJustOne: boolean = false;

  currentLoanContact: ContactList;

  tableState: any;

  exportOnlyColumnsMapping: Map<string, string[]> = new Map<string, string[]>();

  colOrder: string[];

  init: boolean = true;

  private _selectedColumnsLocalStorageKeyName: string = "pipeline-selectedColumns";
  private _pipelineData: PipelineItem[] = [];
  private _selectedColumns: TableColumn[] = [];
  private pipelineSelectedColumnsName = 'pipelineSelectedColumns';
  private pipelineTableStateName = 'pipelineTableState';

  private _lastSortMetaData: SortMeta[] = null;

  constructor(
    private readonly injector: Injector,
    public _datePipe: DatePipe,
    private readonly _localStorageService: LocalStorageService,
    private readonly _contextMenuService: ContextMenuService,
    private readonly _notifyService: NotificationService,
    private readonly _service: ApplicationService,
    private readonly _appService: AppDetailsService,
    private readonly _modalService: NgbModal,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _environmentService: EnvironmentService,
    private zone: NgZone,
    private readonly _navigationService: NavigationService,
    private readonly _commonService: CommonService,
    private readonly _customDataService: CustomDataService,
  ) {
    super(injector);
    this.scrollOffset = 350;
    this.id = Utils.getUniqueId();
  }

  ngOnInit(): void {
    this.getScreenSize();
    if (this._navigationService.applicationMode == ApplicationMode.Classic) {
      this.url = "/admin/app-details/";
    } else {
      this.url = "/loda-nextgen/app-details/";
    }
    this.baseAvatarUrl = this._environmentService.apiInfo.apiBaseUrl;

    this.dialerEnabled = this.applicationContext.userPermissions.dialerEnabled;
    this.areChannelsFilteredToJustOne = this.applicationContext.userPermissions.enabledChannels === "Disabled";

    this.columns = [
      { field: 'lastName', header: 'Borrower / Property', visible: true },
      { field: 'mobilePhone', header: 'Contact Info', visible: this.isTpoUser ? false : true },
      { field: 'loanStatusName', header: 'Loan Status', visible: true },
      { field: 'loanAmount', header: 'Loan Amount', visible: true, minWidth: '122px' },
      { field: 'fico', header: 'FICO', visible: false, minWidth: '69px' },
      { field: 'lenderName', header: 'Lender / Channel / Product', visible: true },
      { field: 'primaryRoleContact', header: 'Loan Contact', visible: this.isTpoUser ? false : true },
      { field: 'lockStatus', header: 'Lock Status/Exp.', visible: false, minWidth: '142px' },
      { field: 'branchName', header: 'Branch', visible: false },
      { field: 'leadSource', header: 'Referral / Source / Ref', visible: false, minWidth: '176px' },
      { field: 'refNumber', header: 'LOS Loan Number', visible: true, minWidth: '176px' },
      { field: 'appCreateDate', header: 'Date Created', visible: true, dataType: 'date', minWidth: '146px' },
      { field: 'lastAttemptedContact', header: 'Success / Attempted Contact', visible: this.isTpoUser ? false : true, minWidth: '216px' },
      { field: 'interestRate', header: 'Interest Rate', visible: false },
      { field: 'docPrepFeesPaid', header: 'Amount Paid', visible: false },
      { field: 'nextAppointmentDate', header: 'Next Appointment Date', visible: false, minWidth: '100px' }
    ];

    var i = 1;
    this.applicationContext.globalConfig.customDataConfig?.forEach(cdConfig => { 
      const customDataFieldColumn = { field: cdConfig.fieldName.charAt(0).toLowerCase() + cdConfig.fieldName.slice(1), header: cdConfig.labelText ?? ('Custom Data ' + i), visible: false };
      customDataFieldColumn['isCustomField'] = true;
      customDataFieldColumn['customDataConfig'] = cdConfig;
      this.columns.push(customDataFieldColumn);
    });

    this.applicationContext.globalConfig.keyDates.filter(k => k.showOnPipeline).forEach(keyDate => {
      const keyDateField = `KeyDate_${keyDate.keyDateConfigurationId}`;
      this.columns.push({ field: keyDateField, sortField: keyDateField + '_ForSorting', header: keyDate.displayName, visible: true, dataType: 'keyDate', minWidth: '146px' });
    })

    this.columns.forEach((item, index) => item.order = index + 1);

    this.exportOnlyColumnsMapping.set("lastName", ["firstName", "lastName", "mailingCity", "mailingStreet", "mailingState", "mailingZip"]);
    this.exportOnlyColumnsMapping.set("mobilePhone", ["mobilePhone", "homePhone", "phone", "email"]);
    this.exportOnlyColumnsMapping.set("loanStatusName", ["loanStatusName", "subStatusName", "enteredLoanStatus"]);
    this.exportOnlyColumnsMapping.set("primaryRoleContact", ["primaryRoleContact"]);
    this.exportOnlyColumnsMapping.set("lockStatus", ["lockStatus", "lockExpirationDate"]);
    this.exportOnlyColumnsMapping.set("appCreateDate", ["appCreateDate", "lastUpdated"]);
    this.exportOnlyColumnsMapping.set("lastAttemptedContact", ["lastSuccessful", "lastAttempted"]);
    this.exportOnlyColumnsMapping.set("loanAmount", ["loanAmount", "ltv"]);
    this.exportOnlyColumnsMapping.set("leadSource", ["referralSource", "leadSource", "leadRefId"]);
    this.exportOnlyColumnsMapping.set("lenderName", ["lenderName", "channel", "productName"]);
    this.exportOnlyColumnsMapping.set("refNumber", ["refNumber"]);
    this.exportOnlyColumnsMapping.set("interestRate", ["interestRate"]);
    this.exportOnlyColumnsMapping.set("dates", ["lockExpirationDate", "estimatedClosingDate", "lastPaymentDate", "payoffExpirationDate", "closingDate", "fundingDate", "cdIssueDate", "cdSignedDate", "appCreateDate"]);
    this.exportOnlyColumnsMapping.set("customData", this.applicationContext.globalConfig.customDataConfig?.map(x => x.fieldName));

    if (this.isTpoUser) {
      if (!this._selectedColumnsLocalStorageKeyName.includes("-tpo")) {
        this._selectedColumnsLocalStorageKeyName = this._selectedColumnsLocalStorageKeyName + "-tpo";
      }

      if (!this.tableStateName.includes("-tpo")) {
        this.tableStateName = this.tableStateName + "-tpo";
      }
      this.pipelineSelectedColumnsName = this.pipelineSelectedColumnsName + 'ForTpo';
      this.pipelineTableStateName = this.pipelineTableStateName + 'ForTpo'
    }

    if (!this.webPreferences) {
      this._commonService.getWebPreferences().subscribe(response => {
        this.webPreferences = response || new WebPreferences();
        this.populateSelectedColumns();
        this.populateTableState();
      });
    } else {
      this.populateSelectedColumns();
      this.populateTableState();
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.webPreferences?.pipelineReferences[this.pipelineTableStateName]) {
        this.tableState = this.webPreferences.pipelineReferences[this.pipelineTableStateName]
      }
      this.globalSearchString = "";
      this.dt1.filterGlobal("", 'contains');
    });
  }

  onGlobalSearchStringChanged = () => {
    this.searchTable();
    this.dt1.first = 0;
  }

  onCustomExportToCSV = () => {

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Pipeline Results');

    let selectedRows = (this.selectedRows && this.selectedRows.length > 0) ? this.selectedRows : this.filteredPipelineData;
    let cols: Partial<Column>[] = [];

    const dateFieldsToExport = Array.from(this.exportOnlyColumnsMapping.get("dates"));
    const customFieldsToExport = Array.from(this.exportOnlyColumnsMapping.get("customData"));
    this.selectedColumns.forEach(col => {

      if (customFieldsToExport.includes(col.field)) {
        if (!cols.map(c => c.key).includes(col.field)) {
          cols.push({
            header: col.header,
            key: col.field
          });
        }
      } else if (dateFieldsToExport.includes(col.field)) {
        if (!cols.map(c => c.key).includes(col.field)) {
          cols.push({
            header: col.field,
            key: col.field
          });
        }
      } else if (Array.from(this.exportOnlyColumnsMapping.keys()).includes(col.field)) {
        let allFields = this.exportOnlyColumnsMapping.get(col.field);
        allFields.forEach(col2 => {
          if (!cols.map(c => c.key).includes(col2)) {
            cols.push({
              header: col2,
              key: col2
            });
          }
        })
      }
    });

    // add loan type and purpose columns as separeted columns
    cols.push({
      header: "Loan Type",
      key: 'loanTypeName'
    });
    cols.push({
      header: "Loan Purpose",
      key: 'loanPurposeName'
    });

    worksheet.columns = cols;

    selectedRows.forEach(row => {
      let excelRow = {};
      cols.forEach(c => {
        if (!row[c.key]) {
          excelRow[c.key] = '';
          return;
        }
        if (dateFieldsToExport.includes(c.key)) {
          if (c.key === 'appCreateDate') {
            excelRow[c.key] = this._datePipe.transform(row[c.key], 'MM/dd/yyyy hh:mm a');
          } else {
            const val = Utils.formatISODateWithoutTime(row[c.key]);
            excelRow[c.key] = this._datePipe.transform(val, 'MM/dd/yyyy');
          }
          return;
        }
        excelRow[c.key] = row[c.key];
      });
      worksheet.addRow(excelRow);
    })

    workbook.csv.writeBuffer().then(buffer => {
      saveAs(new Blob([buffer], { type: "application/octet-stream" }), "Pipeline Results.csv");
    });
  }

  customSort(event: SortEvent) {
    if (!event || !event['multisortmeta']) {
      return;
    }
    const sortChanged = this.checkIfSortMetaDataChanged(this._lastSortMetaData, event['multisortmeta']);
    if (!sortChanged) {
      return;
    }

    this._lastSortMetaData = event['multisortmeta'];

    const fields: string[] = [];
    const orders: any[] = [];
    event['multisortmeta'].forEach(metaData => {
      fields.push(metaData.field);
      orders.push(metaData.order > 0 ? 'asc' : 'desc');
      if (metaData.field === 'loanStatusName') {
        fields.push('loanStatusChangedDate');
        orders.push('asc');
      }
      if (metaData.field === 'lockStatus') {
        fields.push('lockExpirationDate');
        orders.push('desc');
      }
    })
    setTimeout(() => {
      this.filteredPipelineData = _.orderBy(this.filteredPipelineData, fields, orders);
    })
  }

  onSearchReset = () => {
    this.globalSearchString = "";
    this.filteredPipelineData = [...this._pipelineData];
  }

  onViewLoanDetailsClicked = (application: PipelineItem) => {
    this.loanDetailsClicked.emit(application);
  }

  onReassigContactsClicked = () => {
    if (this.selectedRows && this.selectedRows.length > 0) {
      let modalRef = this._modalService.open(ReassignInternalContactsDialogComponent, Constants.modalOptions.large);
      modalRef.componentInstance.channelFilter = this._singleChannelFilter == "Disabled" ? null : this._singleChannelFilter;
      modalRef.componentInstance.selectedRows = this.selectedRows;
    }
    else {
      this._notifyService.showError("You must select at least one loan to reassign!", "Error");
    }
  }

  onShowLoanTeam($event: MouseEvent, rowData: any) {
    this.showLoanTeam($event, rowData);
  }

  showLoanTeam($event: MouseEvent, rowData: any) {
    this.loanTeam = null;
    this.updateLoanTeamOnContextMenu($event);

    $event.preventDefault();
    $event.stopPropagation();

    this._service.getInternalContacts(rowData.applicationId).subscribe({
      next: (result) => {

        result = result.filter((contact) => contact.userId);
        const contactRoles = this.applicationContext.globalConfig.roles.filter(x => x.isLoanContact == true);

        this.loanTeam = [];
        this.zone.run(() => {
          this.loanTeam = result
            .map((contact) => {
              let user = this.applicationContext.globalConfig.users.find(
                (x) => x.userCompanyGuid === contact.userId
              );
              let role: Role = contactRoles.find(
                (x) => x.roleId === contact.roleId
              );
              const name = Utils.getPersonsDisplayName(user);
              const order = role && role.order;
              return {
                name: name,
                label: name,
                user: user,
                order: order,
                role: `${role && role.roleName}`,
              };
            })
            .filter((user) => user.user && user.name);

          this.loanTeam.sort((a: any, b: any) => (a.order > b.order ? 1 : -1));
          this.updateLoanTeamOnContextMenu($event);
        });
      },
      error: (error) => {
        this._notifyService.showError(error.error ? error.error.message : "An error occurred while loading internal contacts.", "Error!");
        this.loanTeam = [];
        this.updateLoanTeamOnContextMenu($event);
      }
    })
  }

  setCurrentLoanContact(player) {
    this.currentLoanContact = player;
  }

  getInternalContactsCount(internalContacts) {
    if (!internalContacts) return 0;
    return parseInt(internalContacts) - 1;
  }

  filterByStatus = (status: string) => {
    this.tableFilterRequestedByStatus.emit(status);
  }

  openColumnSelectorForMobile = () => {
    setTimeout(() => {
      this.mobileColumnSelector?.containerViewChild?.nativeElement?.click();
    })
  }

  onStateSave = (event) => {
    this.colOrder = event.columnOrder || [];
    if (this.init && this.colOrder.length > 0) {
      const columnsWithOrder = this.columns.filter(col => this.colOrder.indexOf(col.field) > -1).sort((a, b) => this.colOrder.indexOf(a.field) - this.colOrder.indexOf(b.field));
      const columnsWithoutOrder = this.columns.filter(col => this.colOrder.indexOf(col.field) === -1);
      this.columns = [...columnsWithOrder, ...columnsWithoutOrder].map((col, index) => ({
        ...col,
        order: col.order = index + 1
      }));
      this.init = false;
    }

    let localTableState = this._localStorageService.getItem(this.tableStateName) as any;
    this._localStorageService.removeItem(this.tableStateName);
    if (this.webPreferences) {
      this.webPreferences.pipelineReferences[this.pipelineSelectedColumnsName] = this._selectedColumns;
      this.webPreferences.pipelineReferences[this.pipelineTableStateName] = localTableState;
      this._commonService.saveWebPreferences(this.webPreferences).subscribe(response => { }, (err) => {
        this._localStorageService.setItem(this.tableStateName, localTableState);
        this._localStorageService.setItem(this._selectedColumnsLocalStorageKeyName, this.selectedColumns);
      });
    }
  }

  selectedColumnsChanged = () => {
    if (this.colOrder.length > 0) {
      const columnsWithOrder = this.columns.filter(col => this.colOrder.indexOf(col.field) > -1).sort((a, b) => this.colOrder.indexOf(a.field) - this.colOrder.indexOf(b.field));
      const columnsWithoutOrder = this.columns.filter(col => this.colOrder.indexOf(col.field) === -1);
      this.columns = [...columnsWithOrder, ...columnsWithoutOrder].map((col, index) => ({
        ...col,
        order: col.order = index + 1
      }));
    }

    if (this.filter) {
      const selectedColumnFieldsOtherThanKeyDates = this.selectedColumns.filter(sc => sc.dataType !== 'keyDate').map(sc => sc.field);
      const selectedKeyDateColumnFields = this.selectedColumns.filter(sc => sc.dataType === 'keyDate').map(sc => sc.field);
      const selectedKeyDateConfigIds: number[] = [];
      selectedKeyDateColumnFields.forEach(keyDateField => {
        const keyDateConfigId = parseInt(keyDateField.split('_')[1]);
        selectedKeyDateConfigIds.push(keyDateConfigId);
      });
      if (!selectedColumnFieldsOtherThanKeyDates.every(f => this.filter?.columnsToReturn?.includes(f)) ||
        !selectedKeyDateConfigIds.every(f => this.filter?.keyDatesConfigurationIdsToReturn?.includes(Number(f)))) {
        this.webPreferences.pipelineReferences[this.pipelineSelectedColumnsName] = this._selectedColumns;
        this.pipelineFilterChanged.emit(this.filter);
      }
    }
  }

  private getBranchName = (data) => {
    const branch = this.applicationContext.globalConfig.enabledBranches.find(eb => eb.branchId == data.branchId);
    return branch ? branch.branchName : '';
  }

  private setGlobalFilterFields = () => {
    this.globalFilterFields = [...initGlobalFields];
    this._selectedColumns.forEach(column => {
      if (column.field) {
        if (this.exportOnlyColumnsMapping?.has(column.field)) {
          // borrower details
          if (column.field === "lastName") {
            this.globalFilterFields.push('fullName');
            this.globalFilterFields.push('fullNameWithComma');
          }

          this.globalFilterFields.push(...this.exportOnlyColumnsMapping?.get(column.field));
        } else {
          this.globalFilterFields.push(column.field);

          // replace customData
          if (column.field?.includes("customData")) {
            this.setGlobalFilterFieldsForCustomData(column.field);
          }
        }
      }
    })

    this.searchTable();
  }

  private setGlobalFilterFieldsForCustomData = (field: string) => {
    const index = this.globalFilterFields?.indexOf(field) ?? -1;
    if (index === -1) return;
    this.globalFilterFields[index] = `${field}ToFilterWith`;
  }

  private updateLoanTeamOnContextMenu = ($event: any) => {
    this._cdr.detectChanges();
    this._contextMenuService.show.next({
      anchorElement: $event.target,
      contextMenu: this.contextMenuContacts,
      event: <any>$event,
      item: this.loanTeam,
    });
  }

  private populateSelectedColumns = () => {
    const localStorageSelectedColumns = <any[]>(
      this._localStorageService.getItem(this._selectedColumnsLocalStorageKeyName)
    );
    this._localStorageService.removeItem(this._selectedColumnsLocalStorageKeyName)

    let selectedColumns = this.webPreferences.pipelineReferences[this.pipelineSelectedColumnsName];

    if (localStorageSelectedColumns && localStorageSelectedColumns.length && localStorageSelectedColumns != selectedColumns) {
      selectedColumns = localStorageSelectedColumns;
      this.webPreferences.pipelineReferences[this.pipelineSelectedColumnsName] = localStorageSelectedColumns;
    }

    if (selectedColumns && selectedColumns.length) {
      const fields = this.columns.map(c => c.field);
      this._selectedColumns = selectedColumns.filter(column => fields.includes(column.field));
      this._selectedColumns = _.uniqBy(this._selectedColumns, 'field');
      this._selectedColumns.forEach(selectedColumn => {
        const column = this.columns.find(c => c.field == selectedColumn.field);
        const index = this.selectedColumns.findIndex(c => c.field == selectedColumn.field)
        this._selectedColumns[index] = column;
      });
      this.webPreferences.pipelineReferences[this.pipelineSelectedColumnsName] = this._selectedColumns;
      this._commonService.saveWebPreferences(this.webPreferences).subscribe(response => { }, (err) => {
        this._localStorageService.setItem(this._selectedColumnsLocalStorageKeyName, this._selectedColumns);
      });

    } else {
      this.selectedColumns = this.columns.filter(c => c.visible);
      this.selectedColumns = _.uniqBy(this.selectedColumns, 'field');
      this.webPreferences.pipelineReferences[this.pipelineSelectedColumnsName] = this.selectedColumns;
      this._commonService.saveWebPreferences(this.webPreferences).subscribe(response => { }, (err) => {
        this._localStorageService.setItem(this._selectedColumnsLocalStorageKeyName, this.selectedColumns);
      });
    }

    this.setGlobalFilterFields();
  }

  private populateTableState = () => {
    // fix row selection for exporting
    let localTableState = this._localStorageService.getItem(this.tableStateName) as any;
    this._localStorageService.removeItem(this.tableStateName)
    let tableState = this.webPreferences.pipelineReferences[this.pipelineTableStateName];

    if (localTableState && localTableState != tableState) {
      tableState = localTableState;
      this.webPreferences.pipelineReferences[this.pipelineTableStateName] = localTableState;
      this._commonService.saveWebPreferences(this.webPreferences).subscribe(response => { }, (err) => {
        this._localStorageService.setItem(this.tableStateName, tableState);
      });
    }

    if (tableState) {
      tableState.selection = [];
      for (let i = tableState.multiSortMeta.length - 1; i >= 0; i--) {
        if (!this.selectedColumns.map(sc => sc.field).includes(tableState.multiSortMeta[i].field)) {
          tableState.multiSortMeta.splice(i, 1);
        }
      }
      this.webPreferences.pipelineReferences[this.pipelineTableStateName] = tableState;
    }
  }

  private prepareEditConfigsForEachCustomField = (items: PipelineItem[], callback: Function) => {
    //TODO: DID WE NEED THIS? Assumption is it was used for filtering / sorting?
    items.forEach(item => {

      if (item.customFields?.length > 0) {
        if (item.customFields?.length > 0) {
          item.customFields.forEach(cf => { 
            const columName = cf.fieldName.charAt(0).toLowerCase() + cf.fieldName.slice(1);
            // YES - This needs to be done for filtering and sorting
            item[columName] = cf.value;
            if (!item['customData']) {
              item['customData'] = {};
            }
            item['customData'][cf.customDataFieldConfigId] = cf;
          });
        }
      }
    });

    callback(items);
  }

  private searchTable = () => {
    this.filteredPipelineData = Utils.filter(this.globalFilterFields, this.globalSearchString, this._pipelineData);
  }

  private checkIfSortMetaDataChanged = (sortMetaData1: SortMeta[], sortMetaData2: SortMeta[]): boolean => {
    const diffChecker = new DiffChecker(sortMetaData1, sortMetaData2, "sortMetaData");
    const differences = diffChecker.calculateDiff();
    return !!differences;
  }
}

