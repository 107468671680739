import { Component, Injector, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, finalize } from 'rxjs';
import { EnvironmentService } from 'src/app/core/services/environment/environment.service';
import { Utils } from 'src/app/core/services/utils';
import { ApplicationContext, ChannelRole, LoanApplication, UserProfile } from 'src/app/models';
import { GlobalConfig } from 'src/app/models/config/global-config.model';
import { Role } from 'src/app/models/role.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { TrackingInfo } from 'src/app/models/tracking-info.model';
import { User } from 'src/app/models/user/user.model';
import { InternalContactsPreferences, InternalContactsViewType, WebPreferences } from 'src/app/models/web-preferences.model';
import { ChannelService } from 'src/app/services/channel.service';
import { CommonService } from 'src/app/services/common.service';
import { Constants } from 'src/app/services/constants';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components/application-context-bound.component';
import { Alignment } from '../models/alignment.model';
import { InternalContactHistoryItemViewModel } from '../models/internal-contact-history-item.view.model';
import { InternalContact } from '../models/internal-contact.model';
import { InternalContactsService } from '../services/internal-contacts.service';
import { AvailableAlignmentsDialogComponent } from './available-alignments-dialog/available-alignments-dialog.component';
import { BranchReAssignmentConfirmationDialogComponent } from './branch-reassignment-confirmation-dialog/branch-reassignment-confirmation-dialog.component';
import { TasksReassignmentDialogComponent } from './tasks-reassignment-confimation-dialog/tasks-reassignment-confimation-dialog.component';
import { DrawerOptions, DrawerService, DrawerSize } from 'src/app/shared/services/drawer.service';
import Swal, { SweetAlertResult } from 'sweetalert2';

@Component({
  selector: 'internal-contacts',
  templateUrl: 'internal-contacts.component.html',
  styleUrls: ['./internal-contacts.component.scss']
})
export class InternalContactsComponent extends ApplicationContextBoundComponent implements OnInit {

  private _enabledChannels: EnumerationItem[];

  private _availableAlignments: Alignment[] = [];

  private _internalContacts: InternalContact[];

  private _currentApplication: LoanApplication;

  private _globalConfig: GlobalConfig;

  contactUserIdsByRole: any = {};

  branchUsers: UserProfile[];

  externalCompanyUsers: UserProfile[];

  contactRoles: Role[] = [];

  loadingContacts: boolean = true;

  users: User[];

  usersAll: User[] = [];

  externalCompanyId: number;

  isTpoUser: boolean;
  isSaving: boolean;

  showContactHistory: boolean = false;

  internalContactsHistory: InternalContactHistoryItemViewModel[] = [];

  baseAvatarUrl: string;

  templateType: InternalContactsViewType = null;

  contactUsersByRole: Map<number, any> = new Map<number, any>();

  protected emailRecipients: string;

  protected drawerOptionsXl: DrawerOptions = {
    size: DrawerSize.XLarge,
    containerWrapperId: 'drawer-wrapper'
  }

  get applicationId(): number {
    return this._currentApplication.applicationId;
  }

  private _webPreferences: WebPreferences;

  private _loanInfoChangesSubscription: Subscription;

  constructor(
    private readonly injector: Injector,
    private readonly _internalContactsService: InternalContactsService,
    private readonly _channelService: ChannelService,
    private readonly _environmentService: EnvironmentService,
    private readonly _commonService: CommonService,
    private readonly _modalService: NgbModal,
    private readonly _drawerService: DrawerService,
    private _notifyService: NotificationService,
    private readonly _spinner: NgxSpinnerService
  ) {
    super(injector);
    this.baseAvatarUrl = this._environmentService.apiInfo.apiBaseUrl;
    this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe((context) => {
      if (context.application) {
        this.loadingContacts = true;
        this.initialize(context);
      }
    });
  }

  ngOnInit(): void {
    if (this.applicationContext?.application?.applicationId) {
      this.initialize(this.applicationContext);
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
  }

  onSaveInternalContactClicked = () => {
    for (const key in this.contactUserIdsByRole) {
      let setSuccess = false;
      if (this.contactUserIdsByRole[key] !== null && this.contactUserIdsByRole[key] !== undefined) {
        this._internalContacts.forEach(contact => {
          if (contact.roleId.toString() == key) {
            contact.userId = this.contactUserIdsByRole[key];
            setSuccess = true;
          }
        })
        if (!setSuccess) {
          let newContact = new InternalContact(
            Number(key),
            this.contactUserIdsByRole[key],
            this._currentApplication.applicationId
          );
          this._internalContacts.push(newContact);
        }
      }
    }

    let internalContactsToSave = this._internalContacts.filter(c => c.userId != '0');

    const loanOfficerRole = this._globalConfig.roles.find(role => role.order == 1);

    const tasksReassignmentDialog = this._modalService.open(TasksReassignmentDialogComponent, Constants.modalOptions.medium);
    tasksReassignmentDialog.componentInstance.title = 'Reassign Tasks?';
    const shouldPromptForBranchReassignment = this.shouldPromptForBranchReassignment();
    tasksReassignmentDialog.result.then((response) => {
      if (response === 'cancel') {
        return;
      }
      const reassignTasks: boolean = response === 'yes';
      if (!shouldPromptForBranchReassignment) {
        this.updateInternalContacts(internalContactsToSave, reassignTasks, false, loanOfficerRole);
        return;
      }
      const branchReassignmentDialog = this._modalService.open(BranchReAssignmentConfirmationDialogComponent, Constants.modalOptions.medium);
      branchReassignmentDialog.componentInstance.title = 'Reassign Tasks?';
      branchReassignmentDialog.result.then((response) => {
        if (response === 'cancel') {
          return;
        }
        const reassignBranch: boolean = response === 'yes';
        this.updateInternalContacts(internalContactsToSave, reassignTasks, reassignBranch, loanOfficerRole);
      });
    }, (error) => {
    });
  }

  onRoleUserChanged = (role: Role) => {
    if (role.order == 1) {
      Swal.fire({
        title: 'Updated Alignment?',
        text: 'Do you want to update the alignment to the match the ' + role.roleName + ' alignment',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        reverseButtons: true
      }).then((result: SweetAlertResult) => {
        if (!result.value) {
          return;
        }
        this.updateInternalContactsBasedOnAlignment(this.contactUserIdsByRole[role.roleId]);
      });
    }
  }

  onShowAvailableAlignmentsClicked = () => {
    this._spinner.show();
    this._internalContactsService.getAvailableAlignments(this._currentApplication.applicationId,
      this._currentApplication.userId).subscribe(alignments => {
        if (alignments) {
          this._availableAlignments = alignments.filter(al => al.channel == this._currentApplication.channel);

          const modalRef = this._modalService.open(AvailableAlignmentsDialogComponent, Constants.modalOptions.medium)
          modalRef.componentInstance.title = 'Available Alignments';
          modalRef.componentInstance.roles = this.contactRoles;
          const users = this.usersAll.concat(this.applicationContext.globalConfig.tpoUsers);
          modalRef.componentInstance.users = users;

          const applicationUser = users.find(user => user.userCompanyGuid === this._currentApplication.userId);
          modalRef.componentInstance.selectedUser = applicationUser;

          modalRef.componentInstance.availableAlignments = this._availableAlignments;
          modalRef.result.then((data: any) => {
            Object.keys(data).forEach((roleId) => {
              this.contactUserIdsByRole[roleId] = data[roleId];
              this.populateContactUserByRole(Number(roleId));
            })
          }, (res) => {
          });
        }
      }, error => {
        const errorMessage = error ? error.message : "An error occurred while loading available alignments.";
        this._notifyService.showError(errorMessage, "Error!");
      }).add(() => this._spinner.hide());
  }

  onViewInternalHistoryClicked = () => {
    this.showContactHistory = true;
    this._spinner.show();
    this._internalContactsService.getInternalContactsHistory(this._currentApplication.applicationId).subscribe((result: TrackingInfo[]) => {
      if (result) {
        this.internalContactsHistory = result.map(item => new InternalContactHistoryItemViewModel(item.actionValue,
          this.applicationContext.globalConfig.getUserFullName(item.by), item.dateInserted))
        this._spinner.hide();
        this._drawerService.show("internalContactsHistoryDrawer", 200);
      }
    }, err => {
      this._spinner.hide();
    })
  }

  onHideInternalHistoryClicked = () => {
    this.showContactHistory = false;
    this._drawerService.hide("internalContactsHistoryDrawer", 200);
  }

  onChangeViewType = () => {
    if (this.templateType == InternalContactsViewType.list) {
      this.templateType = InternalContactsViewType.card;
    }
    else {
      this.templateType = InternalContactsViewType.list;
    }

    //
    if (!this._webPreferences.internalContactsPreferences) {
      this._webPreferences.internalContactsPreferences = new InternalContactsPreferences();
    }
    this._webPreferences.internalContactsPreferences.viewType = this.templateType;
    this.savePreferences();
  }

  onHistoryIconClicked = () => {
    if (!this.showContactHistory) {
      this.onViewInternalHistoryClicked();
    }
    else {
      this.onHideInternalHistoryClicked();
    }
  }

  onHistoryDrawerClosed = () => {
    this.onHideInternalHistoryClicked();
  }

  onLodaEmailClicked = (email: string) => {
    this.emailRecipients = email;
    this.applicationContextService.sendEmailDialogLaunched();
    this._drawerService.show("sendEmailDrawerForInternalContacts", 200);
  }

  onEmailSendCancelled = () => {
    this._drawerService.hide("sendEmailDrawerForInternalContacts", 200);
  }

  onEmailSentSuccessfully = () => {
    this._drawerService.hide("sendEmailDrawerForInternalContacts", 200);
  }

  private updateInternalContactsBasedOnAlignment = (userId: string) => {
    let alignment = this.applicationContext.globalConfig.alignment.filter(al => al.primaryRoleUserId == userId);
    if (alignment.length > 0) {
      for (let i = 0; i < alignment[0].usersInAlignment.length; i++) {
        const alig = alignment[0].usersInAlignment[i];
        this.contactUserIdsByRole[alig.roleId] = alig.userId;
        this.populateContactUserByRole(alig.roleId);
      }
    }
  }

  private checkIsTpoInternalContactsDropdownEnabled = (role: Role) => {
    if (this.isTpoUser) {
      const roleChannel = role.roleChannels.find(c => c.channel == this._currentApplication.channel);
      if (!roleChannel) {
        return false;
      }
      return roleChannel.isVisibleOnTpo;
    }
    return true;
  }

  private getRoleUser = (role: Role): any => {
    let users: any[] = [...(this.users || [])];

    users.push(...(this.usersAll || []));

    if (role.tpoEnabled) {
      users.push(...(this.branchUsers || []));
      users.push(...(this.externalCompanyUsers || []));
    }

    const userId = this.contactUserIdsByRole[role.roleId];
    if (!userId) {
      return null;
    }

    let matched = users.find((u: any) => u.userCompanyGuid == userId);
    if (matched) {
      return matched;
    }
    return null;
  }

  private getRoleUserFieldData = (role: Role, fieldName: string): string => {
    let matched = this.getRoleUser(role);
    if (matched) {
      if (fieldName == "displayName") {
        let displayName = Utils.getPersonsDisplayName(matched);
        displayName += !!matched.active ? "" : " (Inactive)";

        return displayName;
      }
      else if (fieldName == "phone") {
        return matched.cellPhone;
      }
      else if (fieldName == "email") {
        return matched.email;
      }
    }
    return null;
  }

  private initialize = (context: ApplicationContext) => {
    this._currentApplication = context.application;
    this._globalConfig = context.globalConfig;
    this.isTpoUser = context.isTpo;
    this.externalCompanyId = this._currentApplication.externalCompanyId;
    this.users = this._globalConfig.users;
    this.usersAll = this._globalConfig.usersAll;
    this._enabledChannels = this._channelService.getChannelsFromCommaDelimitedString(context.userPermissions.enabledChannels);
    this.loadContactRoles(this._currentApplication.channel);
    this.initializeFromPreferences();
  }

  private updateInternalContacts = (
    internalContactsToSave: InternalContact[],
    reassignTasks: boolean,
    reassignBranch: boolean, loanOfficerRole: Role
  ) => {
    this.isSaving = true;
    this._internalContactsService.saveInternalContact(this._currentApplication.applicationId,
      internalContactsToSave, reassignTasks, reassignBranch).pipe(finalize(() => {
        this.isSaving = false;
      })).subscribe(result => {
        if (result) {
          this.applicationContextService.updateSubStatuses();
          this._notifyService.showSuccess('Internal contact saved successfully.', 'Successful!');
        }
        if (loanOfficerRole) {
          const loanOfficer = this._internalContacts.find(c => c.roleId == loanOfficerRole.roleId);
          if (loanOfficer) {
            this._currentApplication.userId = loanOfficer.userId;
            this.applicationContextService.updateApplication(this._currentApplication);
          }
        }
      }, error => {
        const errorMessage = error ? error.message : "An error occurred while saving internal contact.";
        this._notifyService.showError(errorMessage, "Error!");
      })
  }

  private loadExternalCompanyUsers = () => {
    this._internalContactsService.getExternalCompanyUsers(this._currentApplication.companyId, this.externalCompanyId).subscribe(result => {
      this.externalCompanyUsers = result.sort((a, b) => a.lastName.toLowerCase().localeCompare(b.lastName.toLowerCase()));;
      this.branchUsers = this.externalCompanyUsers.filter(el => el.branchId == this._currentApplication.branchId);
      this.populateContactUsersMap();
    }, error => {
      const errorMessage = error ? error.message : "An error occurred while saving external contact.";
      this._notifyService.showError(errorMessage, "Error!");
    }).add(() => this.loadingContacts = false);
  }

  private getOrderOfSelectedChannel = (role: ChannelRole, selectedChannelName: string) => {
    const roleChannel = role.roleChannels && role.roleChannels.find(roleChannel => roleChannel.channel === selectedChannelName);
    if (!roleChannel) {
      return null;
    }
    return roleChannel.order;
  }

  private shouldPromptForBranchReassignment = () => {
    const branchesEnabled = this.applicationContext.userPermissions.branchesEnabled;
    const branches = this.applicationContext.globalConfig.branches;
    return branchesEnabled && branches.some((branch: any) => !branch.isArchived);
  }

  private loadContactRoles = (channelName: string): void => {
    let contactRoles: Role[] = [];
    this._internalContactsService.getInternalContacts(this._currentApplication.applicationId).subscribe(result => {
      this._internalContacts = result;

      if (this._internalContacts && this._internalContacts.length > 0) {
        this._internalContacts.forEach((contact: InternalContact) => {
          this.contactUserIdsByRole[contact.roleId] = contact.userId;
        });
      }

      if (this._enabledChannels && this._enabledChannels.length > 0 && !!channelName) {
        // Just lower case the first letter of channelName
        const channelNameToCheck = channelName.charAt(0).toLowerCase() + channelName.slice(1);
        this._globalConfig.channelRoles[channelNameToCheck].forEach((role: ChannelRole) => {
          if (role.isLoanContact) {
            role.orderByChannel = this.getOrderOfSelectedChannel(role, channelName);
            role.order = role.orderByChannel;
            contactRoles.push(role);
          }
        });
        contactRoles = _.orderBy(contactRoles, 'orderByChannel');
      } else {
        this._globalConfig.roles.forEach(role => {
          if (role.isLoanContact) {
            contactRoles.push(role);
          }
        })
        contactRoles = _.orderBy(contactRoles, 'order');
      }
      this.contactRoles = contactRoles;
      this.setTpoInternalContactsDropdownEnabledStatusesForRoles();
      if (this.externalCompanyId && this.externalCompanyId > 0) {
        this.loadExternalCompanyUsers();
      } else {
        this.populateContactUsersMap();
        this.loadingContacts = false;
      }
    }, error => {
      const errorMessage = error ? error.message : "An error occurred while loading contacts.";
      this._notifyService.showError(errorMessage, "Error!");
    }).add(() => this.loadingContacts = false);
  }

  private setTpoInternalContactsDropdownEnabledStatusesForRoles = () => {
    this.contactRoles.forEach(role => {
      role['isTpoInternalContactsDropdownEnabled'] = this.checkIsTpoInternalContactsDropdownEnabled(role);
    });
  }

  private populateContactUsersMap = () => {
    this.contactRoles.forEach(role => {
      this.populateContactUserByRole(role.roleId);
    });
  }

  private initializeFromPreferences() {
    this._commonService.getWebPreferences().subscribe({
      next: (response) => {
        this._webPreferences = response || new WebPreferences();
        if (this._webPreferences.internalContactsPreferences?.viewType) {
          const selectedViewType = this._webPreferences.internalContactsPreferences?.viewType;
          this.templateType = selectedViewType;
        }
        else {
          this.templateType = InternalContactsViewType.card;
        }
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  private savePreferences = () => {
    this._commonService.saveWebPreferences(this._webPreferences).subscribe();
  }

  private populateContactUserByRole = (roleId: number) => {
    let role = this.contactRoles.find(r => r.roleId == roleId);
    if (!role) {
      return;
    }

    let matched = this.getRoleUser(role);
    if (matched) {
      this.contactUsersByRole.set(role.roleId, {
        ...matched,
        displayName: this.getRoleUserFieldData(role, "displayName"),
        usingPhone: this.getRoleUserFieldData(role, "phone"),
        usingEmail: this.getRoleUserFieldData(role, "email")
      })
    }
  }
}
