import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbNav } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgxSpinnerService } from 'ngx-spinner';
import { combineLatest } from 'rxjs';
import { LeadStatus } from 'src/app/models';
import { DashboardTaskCounts } from 'src/app/models/task/dashboard-task-counts.model';
import { RecordType } from 'src/app/modules/dialer/models/dial-list-record-basic.model';
import { LeadEvent } from 'src/app/modules/leads/models/lead-event.model';
import { Tag } from 'src/app/modules/leads/models/lead-list.model';
import { Lead } from 'src/app/modules/leads/models/lead.model';
import { LeadsService } from 'src/app/modules/leads/services/leads.service';
import { TaskStatsGroup } from 'src/app/modules/tasks/models/task-stats-group.model';
import { Constants } from 'src/app/services/constants';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { CommonSendEmailSmsDialogComponent } from 'src/app/shared/components/common-send-email-sms-dialog/send-email-sms-dialog.component';
import { EmailInfo } from 'src/app/shared/components/common-send-email/send-email.component';
import { SmsInfo } from 'src/app/shared/components/common-send-sms/send-sms.component';
import { LogAttemptedContactDialogComponent } from '../dialogs/log-attempted-contact-dialog/log-attempted-contact-dialog.component';
import { LeadTasksComponent } from './lead-tasks/lead-tasks.component';
import { LeadEditorComponent } from '../../../lead-editor/lead-editor.component';
import Swal from 'sweetalert2';
import { getErrorMessageOrDefault } from 'src/app/shared/utils/error-utils';

class LeadTaskFilter {
  filter11: number;
  filter12: number;
  ct: number;
  filter10: number
}
@Component({
  selector: 'lead-actions',
  templateUrl: './lead-actions.component.html',
  styleUrls: ['./lead-actions.component.scss']
})
export class LeadActionsComponent extends ApplicationContextBoundComponent implements OnInit {

  @ViewChild("leadTasks") leadTasks: LeadTasksComponent;

  @ViewChild("leadEditor") leadEditor: LeadEditorComponent;

  @ViewChild("nav") nav: NgbNav;

  public get lead(): Lead {
    return this._lead;
  }

  @Input()
  public set lead(value: Lead) {
    this._lead = value;
    if (value) {
      this.initOnLead();
    }
  }

  public get leadTasksCounts(): DashboardTaskCounts {
    return this._leadTasksCounts;
  }

  @Input()
  public set leadTasksCounts(value: DashboardTaskCounts) {
    this._leadTasksCounts = value;
    this.setTaskGroups();
  }

  @Input()
  isDrawer: boolean = false;

  @Input()
  isManualDial: boolean = false;

  @Input()
  impersonateUserId: string = null;

  @Output() tasksCountersUpdated = new EventEmitter<any>();

  @Output()
  closeDrawer: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  leadUpdated: EventEmitter<Lead> = new EventEmitter<Lead>();

  @Output()
  dialPhoneClicked: EventEmitter<any> = new EventEmitter<any>();

  allLeadLists: Tag[] = [];
  taskCountLoaded: boolean = false;
  smsEnabled: boolean = false;
  updatingStatus: boolean = false;
  isActivityAdded: boolean = false;

  leadLists: Tag[] = [];
  taskFilter: LeadTaskFilter = {
    filter11: 11,
    filter12: 12,
    ct: 10,
    filter10: 10
    // mt_g2: 2,
    // mt_l2: 1,
    // cpa: 16,
    // ot_g2: 4,
    // ot_l2: 3,
    // bt_s: 6,
    // bt_p: 5,
    // bt_c: 7
  };
  selectedTaskFilterId: number = 11;

  leadStatuses: LeadStatus[] = [];
  newListTag: string = null;

  taskGroup: TaskStatsGroup;
  selectedTab: number = 1;

  multiSelectSettings: IDropdownSettings = {
    idField: 'leadListId',
    textField: 'name',
    allowSearchFilter: true,
    clearSearchFilter: true,
    noDataAvailablePlaceholderText: "No tags available",
    closeDropDownOnSelection: true,
    enableCheckAll: false
  }

  private _lead: Lead = null;
  private _leadTasksCounts: DashboardTaskCounts = null;

  constructor(private _leadsService: LeadsService,
    private readonly _notifyService: NotificationService,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _modalService: NgbModal,
    private readonly injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this._spinnerService.show(); // show spinner on init when waiting for a lead

    this.applicationContextService.context.subscribe(context => {
      this.smsEnabled = context.userPermissions.smsAlertsEnabled;
    });

    if (!this.isDrawer) {
      this.getAllLeadLists();
    }
  }

  selectTag = (event: { leadListId: number, name: string }) => {
    let matchedList = this.allLeadLists.find(l => l.leadListId == event.leadListId);
    if (matchedList) {
      this.addList(matchedList);
    }
  }

  deSelectTag = (event: { leadListId: number, name: string }) => {
    let matchedList = this.allLeadLists.find(l => l.leadListId == event.leadListId);
    if (matchedList) {
      this.deleteFromList(matchedList);
    }
  }

  setTaskGroups = () => {
    this.taskGroup = new TaskStatsGroup("Tasks", [
      {
        name: "Due Today",
        value: this.leadTasksCounts ? this.leadTasksCounts.filter11.toString() : undefined,
        id: this.taskFilter.filter11
      },
      {
        name: "Due Later",
        value: this.leadTasksCounts ? this.leadTasksCounts.filter12.toString() : undefined,
        id: this.taskFilter.filter12
      },
      {
        name: "Completed",
        value: this.leadTasksCounts ? this.leadTasksCounts['filter10'].toString() : undefined,
        id: this.taskFilter.filter10
      },
    ], "#e65027");
  }

  getAllLeadLists = () => {
    this._spinnerService.show();

    this._leadsService.getLeadLists()
      .subscribe(lists => {
        this.allLeadLists = lists;
        this._spinnerService.hide();

      }, (err) => {
        this._spinnerService.hide();

        this._notifyService.showError("Error encountered while loading lists", "Error!");
      });
  }

  getLoadedLeadLists = () => {
    this._leadsService.getLeadListsById(this.lead.leadId)
      .subscribe(lists => {
        this.leadLists = lists;
      }, (err) => {
        this._notifyService.showError("Error encountered while loading lists", "Error!");
      });
  }

  deleteFromList = (tag: Tag) => {

    this._spinnerService.show();

    this._leadsService.deleteLeadListById(this.lead.leadId, tag.tagId)
      .subscribe(() => {
        this._notifyService.showSuccess("Lead List deleted successfully", "Success");
        this.leadLists = this.leadLists.filter(l => l.leadListId != tag.tagId);
        this._spinnerService.hide();
      }, (err) => {
        this._spinnerService.hide();
        this._notifyService.showError("Error encountered while deleting", "Error");
      });

  }

  // assign list to lead
  addList = (tag: Tag) => {
    this._spinnerService.show();

    this._leadsService.addLeadListById(this.lead.leadId, tag.tagId)
      .subscribe(() => {
        this._notifyService.showSuccess("Lead List assigned to lead successfully", "Success");
        this.leadLists = _.uniqBy([...this.leadLists, tag], "leadListId");
        this._spinnerService.hide();
      }, (err) => {
        this._spinnerService.hide();
        this._notifyService.showError("Error encountered while assigning the list to lead", "Error");
      });
  }

  addNewTag = () => {

    let isExist = this.allLeadLists.some(list => list.name?.toLowerCase() === this.newListTag.toLowerCase());
    if (isExist) {
      this._notifyService.showWarning("This tag has already exist in the list", "Warning");
      return
    }

    this._spinnerService.show();
    this._leadsService.addLeadList(this.newListTag)
      .subscribe((response: Tag) => {
        this.newListTag = null;
        this._spinnerService.hide();
        this._notifyService.showSuccess("New Tag created successfully", "Success");

        this.allLeadLists = [...this.allLeadLists, response];

        this.selectTag({ leadListId: response.leadListId, name: response.name });


      }, (err) => {
        this._spinnerService.hide();

        this._notifyService.showError("Error encountered while creating tag", "Error");
      });
  }

  statSelected = (statId: number) => {
    this.selectedTab = 5;
    this.selectedTaskFilterId = statId;
  }

  validate = (): boolean => {
    if (this.leadEditor) {
      return this.leadEditor.validate();
    }

    const isValid = this.validateFormManually();

    if (!isValid) {
      this.selectedTab = 1;
      setTimeout(() => this.leadEditor.validate(), 100);
      return false;
    }

    return true;
  }

  sendEmailClicked = () => {

    this.isActivityAdded = false;

    const modalRef = this._modalService.open(CommonSendEmailSmsDialogComponent, Constants.modalOptions.xlarge);
    modalRef.componentInstance.recordId = this.lead.leadId;
    modalRef.componentInstance.recordType = RecordType.Lead;
    modalRef.componentInstance.record = this.lead;

    modalRef.result.then((info: EmailInfo | SmsInfo) => {

      let event: LeadEvent;

      if (info instanceof EmailInfo) {
        event = {
          leadId: this.lead.leadId,
          type: "email",
          note: 'Sent an email to ' + info.to + '. Subject: "' + info.subject + '".'
        } as LeadEvent;
      }
      else {
        event = {
          leadId: this.lead.leadId,
          type: "sms",
          note: 'Sent sms to ' + info.toPhoneNumber + '. Message: "' + info.body + "."
        } as LeadEvent
      }

      // add email/sms event
      this._leadsService.addLeadEvent(event)
        .subscribe(newEvent => {
          this.isActivityAdded = true;

          this._notifyService.showSuccess("Event added succussfully", "Successful");
        }, () => {
          this._notifyService.showError("Error encountered while adding event", "Error!");
        });
    }, err => {});
  }

  logAttemptedContactClicked = () => {

    this.isActivityAdded = false;

    const modalRef = this._modalService.open(LogAttemptedContactDialogComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.lead = this.lead;

    modalRef.result.then(() => {
      this.isActivityAdded = true;
    });

  }

  updateLeadStatus = () => {

    if (!this.lead.leadId || this.lead.leadId == 0) {
      return;
    }

    this.updatingStatus = true;
    this.isActivityAdded = false;

    this._leadsService.updateLead(this.lead)
      .subscribe(response => {

        if (!response) {
          this._notifyService.showError("Error updating lead status", "Error!");
          this.updatingStatus = false;
          return;
        }

        this.isActivityAdded = true;

        this.lead = response;
        this.getLeadstatuses(response);

        this._notifyService.showSuccess("Lead Status updated!", "Successful!");
        this.onLeadUpdated(this.lead);
        this.updatingStatus = false;
        this.leadEditor.onLeadStatusSaved();
      }, (err) => {
        this._notifyService.showError(err ? err.message || err : '', "Error!");
        this.updatingStatus = false;
      });
  }

  getLeadstatuses = (lead: Lead) => {
    this._leadsService.getLeadStatusesForLoanPurpose(lead.loanPurposeId, lead.leadStatusId)
      .subscribe(statuses => {
        this.leadStatuses = statuses;
      });
  }

  generateMonsterInsightsUrl = () => {
    this._spinnerService.show();
    this._leadsService.getMonsterInsightsUrl(this.lead.leadId).subscribe(monster => {
      if (monster && monster.length) {
        if (monster[0].insightUrl) {
          this._lead.monsterInsightsUrl = monster[0].insightUrl;
        } else {
          this._lead.monsterInsightsUrl = null;
        }
      } else {
        this._lead.monsterInsightsUrl = null;
      }
    }, err => {
      const errorMessage = getErrorMessageOrDefault(err, {
        defaultMessage: 'An error occurred while generating Monster Insights URL.',
      });
      this._notifyService.showError(errorMessage, "Error");
    }).add(() => {
      this._spinnerService.hide();
    });
  }

  taskStatusUpdatedTrigger = (e: boolean) => {
    this.tasksCountersUpdated.emit(e);
  }

  onLeadUpdated = (lead: Lead) => {
    this.leadUpdated.emit(lead);
  }

  onLeadEditorDrawerClosed = () => {
    this.closeDrawer.emit();
  }

  dialClicked = (e) => {
    this.dialPhoneClicked.emit(e);
  }

  navChange = (event) => {
    if (this.leadEditor) {
      if (this.leadEditor.isDirty()) {
        event.preventDefault();
        this.showNotSavedDialog(event);
      }
    }
  }

  private showNotSavedDialog = async (event) => {
    const result = await Swal.fire({
      showDenyButton: true,
      title: 'Are you sure?',
      text: `You have changes that are not saved. Would you like to save your changes? If you choose Discard below, your changes will be lost.`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Save & Continue!',
      cancelButtonText: 'Cancel',
      cancelButtonColor: "#DD6B55",
      denyButtonText: `Discard & Continue!`,
      reverseButtons: true
    });

    if (result.isConfirmed) {
      if (this.leadEditor.validate()) {
        this.save(event);
      }
    }
    if (result.isDenied) {
      this.leadEditor.cancelUpdates();
      this.nav.select(event.nextId);
    }

  }

  private save = async (event) => {
    await this.leadEditor.save();
    this.nav.select(event.nextId);
  }

  private initOnLead = () => {
    this._spinnerService.show();

    const combined = combineLatest([
      this._leadsService.getLeadListsById(this.lead.leadId),
      this._leadsService.getLeadStatusesForLoanPurpose(this.lead.loanPurposeId, this.lead.leadStatusId)
    ])

    combined.subscribe({
      next: ([lists, statuses]) => {
        this.leadLists = lists;
        this.leadStatuses = statuses;
      },
      error: (error) => {

      }
    }).add(() => this._spinnerService.hide())
  }

  private validateFormManually = (): boolean => {
    // all required fields here
    const isValid = !!this.lead.channel && !!this.lead.loanPurposeId && !!this.lead.firstName && !!this.lead.lastName;
    return isValid;
  }
}
