import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataService } from "../core/services/data.service";
import { ReferralSource } from "../models/referral-source.model";
import { Agent, AgentFull } from "../modules/app-details/models/agent.model";
import { CacheService } from "./cache.service";

@Injectable()
export class AgentService {
  constructor(
    private readonly _dataService: DataService,
    private readonly _cacheService: CacheService
  ) {}

  getAllReferralSources = (): Observable<ReferralSource[]> => {
    const url = "api/Agent/AllReferralSources";
    return this._cacheService.wrap<ReferralSource[]>(url, this._dataService.get(url), 60, true);
  };

  getTitleReferralSources = (): Observable<ReferralSource[]> => {
    const url = "api/Agent/AllReferralSources?agentTypeFilter=Title";
    return this._cacheService.wrap<ReferralSource[]>(url, this._dataService.get(url), 60, true);
  };

  getHoiReferralSources = (): Observable<ReferralSource[]> => {
    const url = "api/Agent/AllReferralSources?agentTypeFilter=HOI";
    return this._cacheService.wrap<ReferralSource[]>(url, this._dataService.get(url), 60, true);
  };

  getEscrowReferralSources = (): Observable<ReferralSource[]> => {
    const url = "api/Agent/AllReferralSources?agentTypeFilter=Escrow";
    return this._cacheService.wrap<ReferralSource[]>(url, this._dataService.get(url), 60, true);
  };

  getAgent = (agentId: number): Observable<AgentFull> => {
    const url = "api/Agent/GetAgent/" + agentId;
    return this._dataService.get(url);
  };

  getAllAgents = (): Observable<Agent[]> => {
    const url = "api/Agent/AllAgent";
    return this._cacheService.wrap<Agent[]>(url, this._dataService.get(url), 60, false, true);
  };
}
