import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApplicationContextBoundComponent } from 'src/app/shared/components/application-context-bound.component';
import { LoanPurpose } from 'src/app/models/config/loan-purpose.model';
import { CountByFilter } from 'src/app/models/pipeline/counting-by-filter-model';
import { UserRolesFilter, UserRolesFilterUser } from 'src/app/models/pipeline/requests/user-roles-filter.model';
import { RoleUserAppCounts } from 'src/app/models/pipeline/role-user-app-counts.model';
import { Role } from 'src/app/models/role.model';
import { Utils } from 'src/app/core/services/utils';
import { PipelineService } from 'src/app/services/pipeline.service';
import { LoanStatus } from 'src/app/models/loan';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ChannelService } from 'src/app/services/channel.service';
import { Branch, PipelineFilter } from 'src/app/models';

@Component({
  selector: 'pipeline-filter',
  templateUrl: 'pipeline-filter.component.html',
  styleUrls: ['pipeline-filter.component.scss'],
})
export class PipelineFilterComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input()
  channelFilteringEnabled: boolean = true;

  @Input()
  activeFilter: UserRolesFilter;

  @Input()
  pipelineFiltersList: PipelineFilter[];

  @Input()
  activeFilterName: string;

  @Input()
  loanStatusesThatCanBeUsedForFiltering: LoanStatusFilterItem[] = null;

  @Input()
  loanPurposesThatCanBeUsedForFiltering: LoanPurposeFilterItem[] = null;

  @Input()
  channelsThatCanBeUsedForFiltering: ChannelFilterItem[] = null;

  @Output()
  pipelineFilterChanged: EventEmitter<UserRolesFilter> = new EventEmitter<UserRolesFilter>();

  @Output()
  filterSectionVisibleChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  addEditPipelineFilterClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  selectPipelineFilterClicked: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  deletePipelineFilterClicked: EventEmitter<number> = new EventEmitter<number>();

  channels: ChannelFilterItem[] = [];
  branches: BranchFilterItem[] = [];

  roles: Role[] = [];
  users: any[] = [];

  // private activeFilter = new UserRolesFilter();

  private _allRoles: Role[] = [];
  private _applicationCountsByFilterCriteria: CountByFilter;
  private _allUsersGroupedByRole: RoleUserAppCounts[] = [];

  applicationCountsByLoanStatus: { [key: number]: number } = {};
  applicationCountsByLoanPurpose: { [key: number]: number } = {};
  applicationCountsByChannel: { [key: number]: number } = {};
  applicationCountsByBranch: { [key: number]: number } = {};

  showDisabledBranches: boolean = false;

  constructor(
    private readonly injector: Injector,
    private readonly _pipelineService: PipelineService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _channelService: ChannelService
  ) {
    super(injector);
    this.scrollOffset = 172;
  }

  ngOnInit() {
    this.updateMenuHeight();
    if (!this.loanPurposesThatCanBeUsedForFiltering) {
      this.loanPurposesThatCanBeUsedForFiltering = this.applicationContext.globalConfig.loanPurpose.map(p => ({ ...p, isChecked: false }));
    }
    if (!this.loanStatusesThatCanBeUsedForFiltering) {
      this.loanStatusesThatCanBeUsedForFiltering = this.applicationContext.globalConfig.loanStatus.map(s => ({ ...s, isChecked: false }));
    }

    if (!this.channelsThatCanBeUsedForFiltering) {
      this.channelsThatCanBeUsedForFiltering = this.applicationContext.globalConfig.enabledChannels.map(c => ({ ...c, isChecked: false }));
    }

    this.channels = this._channelService
      .getChannelsFromCommaDelimitedString(this.applicationContext.userPermissions.enabledChannels, true)
      .map(c => ({ ...c, isChecked: false }));

    this.branches = this.applicationContext.globalConfig.enabledBranches
      .map(b => ({ ...b, isChecked: false }));

    if (!this.applicationContext.isTpo) {
      this._allRoles = this.applicationContext.globalConfig.roles
        .filter((r) => r.isLoanContact)
        .sort((r1, r2) => r1.order - r2.order);
    } else {
      this._allRoles = this.applicationContext.globalConfig.roles
        .filter((r) => r.tpoEnabled)
        .sort((r1, r2) => r1.order - r2.order);
    }
    this._pipelineService
      .getDistinctUserRoles(new UserRolesFilter())
      .subscribe((usersGroupedByRole) => {
        this._allUsersGroupedByRole = usersGroupedByRole;
        this.loadRoleAndUserFilters(this._allUsersGroupedByRole);
        this.checkOrUncheckFilterItems();
      });
    this.refreshCounts();
  }

  clearAll = () => {
    this.activeFilter = {
      loanPurposes: [],
      loanStatuses: [],
      loanSubstatuses: [],
      users: [],
      channels: [],
      branches: [],
      lenders: [],
      roleOrderIdToShowInContacts: null
    };
    this.changeFilter(this.activeFilter);
  }

  changeFilter = (filter: UserRolesFilter) => {
    this.activeFilter = filter;
    this.pipelineFilterChanged.emit(this.activeFilter);
    this.refreshCounts();
  }

  onChannelCheckChanged = ($event: any, channel: EnumerationItem) => {
    const checked = $event.target.checked;
    if (checked) {
      if (!this.activeFilter.channels.includes(channel.value)) {
        this.activeFilter.channels.push(channel.value);
      }
    } else {
      const index = this.activeFilter.channels.indexOf(channel.value);
      if (index >= 0) {
        this.activeFilter.channels.splice(index, 1);
      }
    }
    this.pipelineFilterChanged.emit(this.activeFilter);
    this.refreshCounts();
  }

  onBranchCheckChanged = ($event: any, branch: Branch) => {
    const checked = $event.target.checked;
    if (checked) {
      if (!this.activeFilter.branches.includes(branch.branchId)) {
        this.activeFilter.branches.push(branch.branchId);
      }
    } else {
      const index = this.activeFilter.branches.indexOf(branch.branchId);
      if (index >= 0) {
        this.activeFilter.branches.splice(index, 1);
      }
    }
    this.pipelineFilterChanged.emit(this.activeFilter);
    this.refreshCounts();
  }

  onLoanPurposeCheckChanged = ($event: any, loanPurpose: LoanPurpose) => {
    const checked = $event.target.checked;
    if (checked) {
      if (!this.activeFilter.loanPurposes.includes(loanPurpose.loanPurposeId)) {
        this.activeFilter.loanPurposes.push(loanPurpose.loanPurposeId);
      }
    } else {
      const index = this.activeFilter.loanPurposes.indexOf(loanPurpose.loanPurposeId);
      if (index >= 0) {
        this.activeFilter.loanPurposes.splice(index, 1);
      }
    }
    this.pipelineFilterChanged.emit(this.activeFilter);
    this.refreshCounts();
  }

  onUserRoleCheckChanged = ($event: any, role: Role, user: any) => {
    const checked = $event.target.checked;
    const existing = this.activeFilter.users.find(u => u.roleId == role.roleId && u.userId == user.id);
    if (checked) {
      if (!existing) {
        const userFilter = new UserRolesFilterUser(role.roleId, user.id);
        this.activeFilter.users.push(userFilter);
      }
    } else {
      if (existing) {
        const index = this.activeFilter.users.indexOf(existing);
        if (index >= 0) {
          this.activeFilter.users.splice(index, 1);
        }
      }
    }
    this.pipelineFilterChanged.emit(this.activeFilter);
    this.refreshCounts();
  }

  onLoanStatusCheckChanged = ($event: any, loanStatus: LoanStatus) => {
    const checked = $event.target.checked;
    if (checked) {
      if (!this.activeFilter.loanStatuses.includes(loanStatus.loanStatusId)) {
        this.activeFilter.loanStatuses.push(loanStatus.loanStatusId);
      }
    } else {
      const index = this.activeFilter.loanStatuses.indexOf(loanStatus.loanStatusId);
      if (index >= 0) {
        this.activeFilter.loanStatuses.splice(index, 1);
      }
    }
    this.pipelineFilterChanged.emit(this.activeFilter);
    this.refreshCounts();
  }

  onClickedFilterSectionVisible = () => {
    this.filterSectionVisibleChanged.emit();
  }

  addEditPipelineFilter = (action: string, index: number) => {
    const object = {
      action: action,
      index: index
    }
    this.addEditPipelineFilterClicked.emit(object);
  }

  selectPipelineFilter = (index: number) => {
    this.selectPipelineFilterClicked.emit(index);
  }

  deletePipelineFilter = (index: number) => {
    this.deletePipelineFilterClicked.emit(index);
  }

  toggleShowDisabledBranches = () => {
    this.showDisabledBranches = !this.showDisabledBranches
  }

  private refreshCounts = () => {
    this._spinner.show();
    this.loadRoleAndUserFilters(this._allUsersGroupedByRole);
    this._pipelineService
      .getCountByFilter(this.activeFilter)
      .subscribe((filteredResults) => {
        this._applicationCountsByFilterCriteria = filteredResults;

        this.setApplicationCountsByLoanPurpose();
        this.setApplicationCountsByLoanStatus();
        this.setApplicationCountsByChannel();
        this.setApplicationCountsByBranch();

        this._pipelineService.getDistinctUserRoles(this.activeFilter).subscribe(usersGroupedByRole => {
          this.updateUserAppCounts(usersGroupedByRole);
          this.checkOrUncheckFilterItems();
        });
      });
  }

  private updateUserAppCounts = (userGroupsByRole: RoleUserAppCounts[]) => {
    this.users.forEach(user => {
      user.appCount = null;
    });
    userGroupsByRole.forEach(user => {
      user.userAppCounts.forEach(userApp => {
        var displayUser = this.users.find(x => x.id == userApp.userId && x.roleId == user.roleId);
        if (displayUser)
          displayUser.appCount = userApp.appCount;
      });
    });
  }

  private setApplicationCountsByLoanPurpose = () => {
    this.loanPurposesThatCanBeUsedForFiltering.forEach((loanPurpose) => {
      const count = this._applicationCountsByFilterCriteria.byLoanPurpose.find(
        (c) => c.filterCriteriaId == loanPurpose.loanPurposeId
      );
      const countToSet = count ? count.total : 0;
      this.applicationCountsByLoanPurpose[loanPurpose.loanPurposeId] = countToSet;
    });
  };

  private setApplicationCountsByLoanStatus = () => {
    this.loanStatusesThatCanBeUsedForFiltering.forEach((loanStatus) => {
      const count = this._applicationCountsByFilterCriteria.byLoanStatus.find(
        (c) => c.filterCriteriaId == loanStatus.loanStatusId
      );
      const countToSet = count ? count.total : 0;
      this.applicationCountsByLoanStatus[loanStatus.loanStatusId] = countToSet;
    });
  };

  private setApplicationCountsByChannel = () => {
    this.channels.forEach((channel) => {
      const count = this._applicationCountsByFilterCriteria.byChannel.find(
        (c) => c.filterCriteriaId == channel.value
      );
      const countToSet = count ? count.total : 0;
      this.applicationCountsByChannel[channel.value] = countToSet;
    });
  };

  private setApplicationCountsByBranch = () => {
    this.branches.forEach((branch) => {
      const count = this._applicationCountsByFilterCriteria.byBranch.find(
        (c) => c.filterCriteriaId == branch.branchId
      );
      const countToSet = count ? count.total : 0;
      this.applicationCountsByBranch[branch.branchId] = countToSet;
    });
  };

  private loadRoleAndUserFilters = (usersGroupedByRole: RoleUserAppCounts[]) => {

    let roleIds = [];
    this.users = [];
    this.roles = [];

    usersGroupedByRole.forEach((roleUserGroup) => {
      roleUserGroup.userAppCounts.forEach((user) => {
        if (user.userId == '') {
          this.users.push({
            id: '',
            firstName: '',
            lastName: '-Unassigned',
            roleId: roleUserGroup.roleId,
            active: false,
            unassigned: true,
            appCount: user.appCount,
            displayName: '-Unassigned'
          });
          if (roleIds.indexOf(roleUserGroup.roleId) < 0)
            roleIds.push(roleUserGroup.roleId);
        } else {
          const userDetails = this.applicationContext.globalConfig.getUser(user.userId);
          if (userDetails) {
            this.users.push({
              id: userDetails.userCompanyGuid,
              firstName: userDetails.firstName,
              lastName: userDetails.lastName,
              roleId: roleUserGroup.roleId,
              active: userDetails.active,
              appCount: user.appCount,
              displayName: Utils.getPersonsDisplayName(userDetails),
            });
            if (roleIds.indexOf(roleUserGroup.roleId) < 0)
              roleIds.push(roleUserGroup.roleId);
          }
        }
      });
    });

    this._allRoles.forEach(role => {
      if (roleIds.indexOf(role.roleId) > -1) {
        let wholesaleRole = role.roleChannels.find(x => x.channel == 'Wholesale');
        if (wholesaleRole) {
          role.order = wholesaleRole.order;
        }
        this.roles.push(role);
      }
    });
  };

  private checkOrUncheckFilterItems = () => {
    this.channels.forEach(channel => {
      channel.isChecked = this.activeFilter.channels.indexOf(channel.value) >= 0;
    });
    this.branches.forEach(branch => {
      branch.isChecked = this.activeFilter.branches.indexOf(branch.branchId) >= 0;
    });
    this.loanPurposesThatCanBeUsedForFiltering.forEach(loanPurpose => {
      loanPurpose.isChecked = this.activeFilter.loanPurposes.indexOf(loanPurpose.loanPurposeId) >= 0;
    });
    this.loanStatusesThatCanBeUsedForFiltering.forEach(loanStatus => {
      loanStatus.isChecked = this.activeFilter.loanStatuses.indexOf(loanStatus.loanStatusId) >= 0;
    });
    this.users.forEach(user => {
      const userInFilter = this.activeFilter.users.find(u => u.userId == user.id && u.roleId == user.roleId);
      user.isChecked = userInFilter != null;
    });
  }

  private updateMenuHeight = () => {
    const pipelineFilterMenu = document.getElementById('pipeline-filter-accordion');
    if (pipelineFilterMenu != null) {
      pipelineFilterMenu.style.height = this.scrollHeight;
    }
  }
}

export class LoanPurposeFilterItem extends LoanPurpose {
  isChecked: boolean = false;
}

export class LoanStatusFilterItem extends LoanStatus {
  isChecked: boolean = false;
}

export class ChannelFilterItem extends EnumerationItem {
  isChecked: boolean = false;
}

export class BranchFilterItem extends Branch {
  isChecked: boolean = false;
}
