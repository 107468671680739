import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {LosInfoComponent} from './los-info.component';
import {LosLoanCreateComponent} from './los-loan-create/los-loan-create.component';
import {LosLoanLinkComponent} from './los-loan-link/los-loan-link.component';
import {LosLoanReferenceComponent} from './los-loan-reference/los-loan-reference.component';
import {LosTransactionHistoryComponent} from './los-transaction-history/los-transaction-history.component';
import {DatePipe} from '@angular/common';
import {LosService} from '../../services/los.service';
import {AppDetailsService} from '../app-details/services/app-details.service';
import {LoanDocsService} from '../loan-docs/services/loan-docs.service';
import {LosSyncDirectionComponent} from './los-sync-direction/los-sync-direction.component';
import {LosSyncDocumentComponent} from './los-sync-document/los-sync-document.component';
import {TableModule} from 'primeng/table';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        SharedModule,
        TableModule,
        NgbTooltipModule
    ],
    declarations: [
        LosInfoComponent,
        LosLoanCreateComponent,
        LosLoanLinkComponent,
        LosLoanReferenceComponent,
        LosTransactionHistoryComponent,
        LosSyncDirectionComponent,
        LosSyncDocumentComponent
    ],
    exports: [
        LosInfoComponent,
        LosLoanCreateComponent,
        LosLoanLinkComponent,
        LosLoanReferenceComponent,
        LosTransactionHistoryComponent,
        LosSyncDirectionComponent,
        LosSyncDocumentComponent
    ],
    providers: [
        LosService,
        AppDetailsService,
        LoanDocsService,
        DatePipe
    ]
})
export class LosInfoModule {
  constructor() {

  }
}
