<div #container class="emoji-picker-container">
    <button (click)="toggled()">
        😊
    </button>

    <emoji-mart 
        class="picker" 
        *ngIf="isOpened" 
        title="Pick your emoji…"
        (emojiSelect)="emojiSelected($event)" 
        [isNative]="true"
        [darkMode]="false">
    </emoji-mart>
</div>
