import { Component, Injector, Input, OnInit } from '@angular/core';
import { combineLatest, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Configuration } from '../../../../../models/configuration.model';
import { LoanStatus, ThirdPartyCredential, ThirdPartyCredentialType } from '../../../../../models';
import { PricingService } from '../../../../pricing/services/pricing.service';
import { NotificationService } from '../../../../../services/notification.service';
import { SystemLevelService } from '../../../../../services/system-level.service';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ApplicationContextBoundComponent } from '../../../../../shared/components';
import { NgxSpinnerService } from 'ngx-spinner';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { OBVendorEditorDialogComponent } from './ob-vendor-editor-dialog/ob-vendor-editor-dialog.component';
import { LenderPriceVendorEditorDialogComponent } from './lenderprice-vendor-editor-dialog/lenderprice-vendor-editor-dialog.component';
import { Constants } from 'src/app/services/constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MortechVendorEditorDialogComponent } from './mortech-vendor-editor-dialog/mortech-vendor-editor-dialog.component';
import * as _ from 'lodash';
import { LoanPassVendorEditorDialogComponent } from './loanpass-vendor-editor-dialog/loanpass-vendor-editor-dialog.component';
import { PollyVendorEditorDialogComponent } from './polly-vendor-editor-dialog/polly-vendor-editor-dialog.component';
import { MeridianVendorEditorDialogComponent } from './meridian-vendor-editor-dialog/meridian-vendor-editor-dialog.component';
import { PricingVendor } from '../../../../../models/pricing/pricing-vendor';
import { LoanPassIFrameVendorEditorDialogComponent } from './loanpass-iframe-vendor-editor-dialog/loanpass-iframe-vendor-editor-dialog.component';
import { VendorPickerModalComponent } from '../vendor-picker-modal/vendor-picker-modal.component';

@Component({
  selector: 'pricing',
  templateUrl: './pricing.component.html'
})
export class PricingComponent extends ApplicationContextBoundComponent implements OnInit {
  @Input() pricingEnabled: Configuration;
  @Input() externalLocksEnabled: Configuration;
  @Input() defaultPricingVendor: Configuration;
  @Input() lockDeskEmailAddress: Configuration;
  @Input() vendors: ThirdPartyCredential[] = [];
  @Input() loanStatus: LoanStatus[];
  @Input() pricingScenarioRefreshTime: Configuration;
  @Input() pricingScenarioRefreshDays: Configuration;
  @Input() pricingScenarioRefreshLoanStatuses: Configuration;
  @Input() pricingEnabledInNewApp: Configuration;

  refreshTime: Date;
  refreshDays: string[];
  refreshLoanStatuses: number[];

  lenderPriceKeyValuePairs = new LenderPriceKeyValuePairs();

  daysOfWeekArray: EnumerationItem[] = [
    { value: "Monday", name: "Monday" },
    { value: "Tuesday", name: "Tuesday" },
    { value: "Wednesday", name: "Wednesday" },
    { value: "Thursday", name: "Thursday" },
    { value: "Friday", name: "Friday" },
    { value: "Saturday", name: "Saturday" },
    { value: "Sunday", name: "Sunday" },
  ];

  isSaving: boolean;

  filteredPricingVendors: EnumerationItem[] = [];
  missingPricingVendors: EnumerationItem[] = [];
  vendorsWithCreds: EnumerationItem[] = [];

  constructor(
    private readonly injector: Injector,
    private readonly _pricingService: PricingService,
    private readonly _notificationService: NotificationService,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _enumerationService: EnumerationService,
    private readonly _modalService: NgbModal
  ) {
    super(injector);
  }

  ngOnInit(): void {
    if (!this.lockDeskEmailAddress) {
      this.lockDeskEmailAddress = new Configuration("LockDeskEmailAddress");
    }

    if(!this.pricingEnabledInNewApp){
      this.pricingEnabledInNewApp = new Configuration("PricingEnabledInNewApp");
    }

    this.getEnabledVendors();

    const refreshTime = this.pricingScenarioRefreshTime.valueStr;
    const refreshDays = this.pricingScenarioRefreshDays.valueStr;
    const refreshLoanStatuses = this.pricingScenarioRefreshLoanStatuses.valueStr;
    this.refreshTime = this.calculateInitialStartTime(refreshTime);
    this.refreshDays = refreshDays?.split(",") || [];
    this.refreshLoanStatuses = refreshLoanStatuses?.split(",").map(ls => +ls) || [];
  }

  onRefreshTimeChanged = () => {
    this.pricingScenarioRefreshTime.valueStr = this.parseHHmm(this.refreshTime);
    console.log(this.pricingScenarioRefreshTime)
  }

  onRefreshDaysChanged = () => {
    this.pricingScenarioRefreshDays.valueStr = this.refreshDays.join(",");
  }

  onRefreshLoanStatusesChanged = () => {
    this.pricingScenarioRefreshLoanStatuses.valueStr = this.refreshLoanStatuses.join(",");
  }

  save(): void {
    this.isSaving = true;
    combineLatest([
      this._systemLevelService.saveConfiguration(this.pricingEnabled),
      this._systemLevelService.saveConfiguration(this.pricingEnabledInNewApp),
      this._systemLevelService.saveConfiguration(this.defaultPricingVendor),
      this._systemLevelService.saveConfiguration(this.pricingScenarioRefreshTime),
      this._systemLevelService.saveConfiguration(this.pricingScenarioRefreshDays),
      this._systemLevelService.saveConfiguration(this.pricingScenarioRefreshLoanStatuses),
      this._systemLevelService.saveConfiguration(this.externalLocksEnabled),
      this._systemLevelService.saveConfiguration(this.lockDeskEmailAddress)
    ]).pipe(finalize(() => {
      this.isSaving = false;
    })).subscribe({
      next: res => {
        this._notificationService.showSuccess(`Settings saved successfully.`, 'System Level');
      },
      error: error => {
        this._notificationService.showError(`${error ? error.message : 'Unable to save.'}`, 'System Level');
      }
    });
  }

  getEnabledVendors = (): void => {
    this.filteredPricingVendors = this._enumerationService.pricingVendors
      .filter(vendor => vendor.value != PricingVendor.Unknown && vendor.value != PricingVendor.Lodasoft);

    // We DO NOT want to call the API to check enabled state for loanPass, so filter it out here...
    const pricingVendorsToCheckEnabledStateFor = this.filteredPricingVendors.filter(vendor => vendor.value !== PricingVendor.LoanPass);

    this._spinner.show();
    const apiCallsToMake: any[] = [];

    for (let i = 0; i <= pricingVendorsToCheckEnabledStateFor.length - 1; i++) {
      const apiCallForVendor = this._pricingService.getEnabledPricingVendor(pricingVendorsToCheckEnabledStateFor[i].name, true);
      apiCallsToMake.push(apiCallForVendor);
    }

    if (apiCallsToMake.length > 0) {
      forkJoin(apiCallsToMake).subscribe((enabledStates: any) => {
        for (let i = 0; i <= enabledStates.length - 1; i++) {
          const vendor = pricingVendorsToCheckEnabledStateFor[i];
          if (enabledStates[i]) {
            let vendorCredential = this.vendors.find(v => v.vendorName == vendor.name);
            if (!vendorCredential || vendor.name === 'LenderPrice') {
              const missingVendor = new EnumerationItem(vendor.name, vendor.value);
              this.missingPricingVendors.push(missingVendor);
            }
          } else {
            const indexOfVendorOnVendorDropdown = this.filteredPricingVendors.findIndex(v => v.name === vendor.name);
            if (indexOfVendorOnVendorDropdown >= 0) {
              this.filteredPricingVendors.splice(indexOfVendorOnVendorDropdown, 1);
              const vendorIndex = this.vendors.findIndex(v => v.vendorName === vendor.name);
              if (vendorIndex >= 0) {
                this.vendors.splice(vendorIndex, 1);
              }
            }
          }
        }
        // check if loan pass creds are missing, if so add it to the available vendors.
        const loanPassCreds = this.vendors.find(v => v.vendorName == PricingVendor.LoanPass);
        if (!loanPassCreds) {
          this.missingPricingVendors.push(new EnumerationItem('LoanPass', PricingVendor.LoanPass));
        }
        const loanPassIframeCreds = this.vendors.find(v => v.vendorName == PricingVendor.LoanPassIframe);
        if (!loanPassIframeCreds) {
          this.missingPricingVendors.push(new EnumerationItem('LoanPassIframe', PricingVendor.LoanPassIframe));
        }
        const lenderPriceIframeCreds = this.vendors.find(v => v.vendorName == PricingVendor.LenderPriceIframe);
        if (!lenderPriceIframeCreds) {
          this.missingPricingVendors.push(new EnumerationItem('LenderPriceIframe', PricingVendor.LenderPriceIframe));
        }

        if (!this.defaultPricingVendor) {
          this.defaultPricingVendor = new Configuration("DefaultPricingVendor");
        }
        this.populateDefaultVendorProvider();
      }, err => {
        this._notificationService.showError(err.message || 'Unable to get enable vendor', 'Get Enable Vendor');
      }).add(() => this._spinner.hide());
    }
  }

  openVendorEditor = (e: ThirdPartyCredential) => {
    const ModalComponent = this.getVendorModalComponent(e.vendorName as PricingVendor);
    const modalRef = this._modalService.open(ModalComponent, { ...Constants.modalOptions.xlarge, scrollable: false });
    modalRef.componentInstance.vendor = _.cloneDeep(e);

    modalRef.result.then(
      (result: ThirdPartyCredential) => {
        let existingVendorIndex = this.vendors.findIndex(v => v.credentialId == result.credentialId);
        if (existingVendorIndex >= 0) {
          this.vendors[existingVendorIndex] = result;
        }
      },
      () => { }
    );
  }

  openVendorPicker = () => {
    const modalRef = this._modalService.open(VendorPickerModalComponent, { ...Constants.modalOptions.medium, scrollable: false });
    modalRef.componentInstance.type = "Pricing";
    modalRef.componentInstance.vendors = this.missingPricingVendors;

    modalRef.result.then((selectedVendor: string) => {
      const ModalComponent = this.getVendorModalComponent(selectedVendor as PricingVendor);
      const modalRef = this._modalService.open(ModalComponent, { ...Constants.modalOptions.xlarge, scrollable: false });
      const vendor = new ThirdPartyCredential(ThirdPartyCredentialType.PricingVendor, selectedVendor);
      modalRef.componentInstance.vendor = vendor;
      modalRef.componentInstance.inEditMode = false;

      modalRef.result.then(
        (result: ThirdPartyCredential) => {
          this.vendors.push(result);
          this.populateDefaultVendorProvider();
          //lender price should not be removed from missing vendor array to allow multiple creds insert.
          if (result.vendorName === 'LenderPrice') {
            return;
          }
          const matchingRecordIndex = this.missingPricingVendors.findIndex(vendor => vendor.name === result.vendorName)
          if (matchingRecordIndex === -1) {
            return;
          }
          this.missingPricingVendors.splice(matchingRecordIndex, 1);
        },
        () => { }
      );
    },
      () => { }
    );
  }

  onCredsDelete = (deletedCred: ThirdPartyCredential) => {
    this.populateDefaultVendorProvider();

    const matchingVendor = this._enumerationService.pricingVendors.find(vendor => vendor.name === deletedCred.vendorName);
    if (!matchingVendor || deletedCred.vendorName === 'LenderPrice') {
      return;
    }
    this.missingPricingVendors.push({ name: matchingVendor.name, value: matchingVendor.value });
  }

  trackByValue(index, vendor) {
    return vendor.value;
  }

  private populateDefaultVendorProvider() {
    this.vendorsWithCreds = _.chain(this.vendors)
      .map(v => ({
        name: v.vendorName,
        value: v.vendorName,
      }))
      .uniqBy(v => v.value)
      .value();
  }

  private calculateInitialStartTime = (hhmm: string): Date => {
    const date = new Date();
    if (!hhmm) {
      date.setHours(0);
      date.setMinutes(0);
      return date;
    }
    const hours = hhmm.split(":")[0];
    const minutes = hhmm.split(":")[1];
    date.setHours(+hours);
    date.setMinutes(+minutes);
    return date;
  }

  private parseHHmm = (dateTime: Date): string => {
    const hours = dateTime.getHours() < 13 ? dateTime.getHours() : dateTime.getHours() - 12;
    let minutes: any = dateTime.getMinutes();
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return `${hours}:${minutes}`;
  }

  private getVendorModalComponent(type: PricingVendor) {
    if (type === PricingVendor.OptimalBlue) return OBVendorEditorDialogComponent
    if (type === PricingVendor.Mortech) return MortechVendorEditorDialogComponent
    if (type === PricingVendor.LenderPrice) return LenderPriceVendorEditorDialogComponent
    if (type === PricingVendor.LenderPriceIframe) return LenderPriceVendorEditorDialogComponent
    if (type === PricingVendor.LoanPass) return LoanPassVendorEditorDialogComponent
    if (type === PricingVendor.LoanPassIframe) return LoanPassIFrameVendorEditorDialogComponent
    if (type === PricingVendor.Polly) return PollyVendorEditorDialogComponent
    if (type === PricingVendor.MeridianLink) return MeridianVendorEditorDialogComponent
  }
}

export class LenderPriceKeyValuePairs {
  apiKey: string;
  lenderPriceCompanyId: string;
  useLegacyAuth: string;
  isProductCodeUnique: string;
  oAuthBaseUrl: string;
}

export class PollyKeyValuePairs {
  clientId: string;
  clientSecret: string;
  tickerSymbol: string;
}

export class MeridianLinkKeyValuePairs {
  appCode: string;
  authUrl: string;
  clientId: string;
  clientSecret: string;
  priceGroupWholesale: string;
  priceGroupCorrespondent: string;
  priceGroupNondelegatedCorrespondent: string;
}
