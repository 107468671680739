export class CustomData {
  customDataFieldValueId: number;
  customDataFieldConfigId: number;
  leadId: number;
  applicationId: number;
  value: string;
  companyId: number;
  insertedBy: string;
  dateInserted: Date;
  updatedBy: string;
  dateUpdated: Date;
}

export class CustomDataFieldPipelineValueViewModel {
  customDataFieldConfigId: number;
  fieldName: string;
  value: string;
}