import {
  AfterViewInit,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { GlobalEmailTemplateModel } from 'src/app/models/user/global-email-template.model';
import { DefinedEmailTemplate, MapDirection, RoleBCC, RoleCC } from 'src/app/modules/email-configuration/models';
import { NotificationService } from 'src/app/services/notification.service';
import { ProfileService } from '../../../profile.service';
import { ApplicationContextBoundComponent } from '../../../../../shared/components';
import { EnvironmentService } from 'src/app/core/services/environment/environment.service';
import { combineLatest, Subscription } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { Utils } from 'src/app/core/services/utils';
import { cloneDeep, concat, isArray } from 'lodash';
import { DrawerOptions, DrawerService, DrawerSize, DynamicComponentInfo } from '../../../../../shared/services/drawer.service';
import { StripoEmailEditorComponent, StripoEditorSaveClickedEvent } from '../../../../../shared/components/stripo-email-editor/stripo-email-editor.component';
import { DrawerComponent } from '../../../../../shared/components/drawer/drawer.component';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { NgForm } from '@angular/forms';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-email-templates',
  templateUrl: './email-templates.component.html',
  styleUrls: ['./email-templates.component.scss']
})
export class EmailTemplatesComponent extends ApplicationContextBoundComponent
  implements OnChanges, OnInit, AfterViewInit, OnDestroy {

  @ViewChild('editEmailTemplateDrawer')
  protected editEmailTemplateDrawer: DrawerComponent;

  @ViewChild('formElement')
  protected form: NgForm;

  protected editEmailTemplateDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXXXLarge,
    containerWrapperId: null,
  };

  protected collapsibleSectionVisibilityStatuses: Record<string, boolean> = {};

  /**
   * The defined email template object. Only the first assignment is taken into account. Subsequent
   * changes are ignored.
   * The component should be re-initialized to change the email template.
   */
  @Input()
  definedEmailTemplate: DefinedEmailTemplate;
  @Output()
  definedEmailTemplateChange = new EventEmitter<DefinedEmailTemplate>();

  protected effectiveEmailTemplate: DefinedEmailTemplate;

  loadingEmailTemplates: boolean = false;

  defaultEmailTemplates: GlobalEmailTemplateModel[] = [];
  availableMergeFields: EnumerationItem[] = [];
  appMergeFieldsWithCurrentNote: EnumerationItem[] = [];
  restrictedEmailTemplates: string = '';

  roles: EnumerationItem[] = [];

  inviteRolesCC: Array<RoleCC> = []
  inviteRolesBCC: Array<RoleBCC> = []
  requestRolesCC: Array<RoleCC> = []
  requestRolesBCC: Array<RoleBCC> = []
  reminderRolesCC: Array<RoleCC> = []
  reminderRolesBCC: Array<RoleBCC> = []

  private readonly _companyGuid: string;
  private readonly _baseApiUrl: string;

  private _currentNoteMergeField = {
    name: 'Current Note',
    value: 'CurrentNote',
    groupName: ''
  };

  private _dynamicEventSubscriptions: Subscription[] = [];
  private _formValueChangesSubscription?: Subscription | null = null;

  constructor(
    injector: Injector,
    private readonly _environmentService: EnvironmentService,
    private readonly _profileService: ProfileService,
    private readonly _configService: ConfigurationService,
    private readonly _notificationService: NotificationService,
    private readonly _dataService: DataService,
    private readonly _drawerService: DrawerService,
  ) {
    super(injector);

    this._companyGuid = this.applicationContext.globalConfig.company[0].companyGUID;
    this._baseApiUrl = this._environmentService.apiInfo.apiBaseUrl;
  }

  ngOnChanges(changes: SimpleChanges) {
    const definedEmailTemplateChange = changes.definedEmailTemplate;
    if (definedEmailTemplateChange && definedEmailTemplateChange.firstChange) {
      const currentValue = definedEmailTemplateChange.currentValue as DefinedEmailTemplate | undefined;
      this.resetEffectiveEmailTemplate(currentValue);
    }
  }

  private resetEffectiveEmailTemplate(definedEmailTemplate?: DefinedEmailTemplate): void {
    this.effectiveEmailTemplate = cloneDeep(definedEmailTemplate) || new DefinedEmailTemplate();
    this.replaceMergeFields(this.effectiveEmailTemplate, MapDirection.load);
    this.mapCCAndBCCRolesOnInit();

    // add missing properties required by ckeditor
    if (!this.effectiveEmailTemplate.inviteEmail) {
      this.effectiveEmailTemplate.inviteEmail = ""
    }
  }

  ngOnInit(): void {
    this.roles = concat(
      this.applicationContext.globalConfig.roles.map(r => ({ id: r.roleId, value: `IC_${r.roleId}`, name: r.roleName, groupName: "Internal Contacts" })),
      this.applicationContext.globalConfig.agentType.map(a => ({ id: a.agentTypeId, value: `EC_${a.agentTypeId}`, name: a.agentTypeName, groupName: "External Contacts" }))
    );
    if (this.defaultEmailTemplates == null) {
      this.resetEffectiveEmailTemplate();
    }

    this.loadMergeFields();
    this.loadDefaultEmailTemplates();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    this.subscribeToFormValueChanges();
  }

  ngOnDestroy() {
    this._dynamicEventSubscriptions.forEach(s => s?.unsubscribe());
    this._formValueChangesSubscription?.unsubscribe();

    super.ngOnDestroy();
  }

  private subscribeToFormValueChanges(): void {
    this._formValueChangesSubscription?.unsubscribe();

    this._formValueChangesSubscription = this.form.valueChanges.pipe(
      finalize(() => this._formValueChangesSubscription = null),
    ).subscribe((value: unknown) => {
      this.emitDefinedEmailTemplateChange(value as DefinedEmailTemplate);
    });
  }

  private emitDefinedEmailTemplateChange(value?: DefinedEmailTemplate): void {
    value ??= cloneDeep(this.effectiveEmailTemplate) ?? new DefinedEmailTemplate();
    this.replaceMergeFields(value, MapDirection.save);
    this.definedEmailTemplateChange.emit(this.effectiveEmailTemplate);
  }

  protected populateDefaultEmailTemplate(
    isChecked: boolean,
    templateType: string,
    subject: string,
    email: string,
    forceReload: boolean = false,
  ): void {
    if (!isChecked) {
      return;
    }

    const matchingTemplate = this.defaultEmailTemplates.find(template => template.templateType === templateType);
    if (matchingTemplate) {
      if (forceReload || !this.effectiveEmailTemplate[subject]) {
        this.effectiveEmailTemplate[subject] = matchingTemplate.subject;
      }
      if (forceReload || !this.effectiveEmailTemplate[email]) {
        this.effectiveEmailTemplate[email] = matchingTemplate.email;
        this.replaceMergeFieldsForImages(this.effectiveEmailTemplate, email, MapDirection.load);
      }

      // This should be done regardless of `forceReload` because iframe content is removed when the template is changed.
      this.loadEmailTemplatePreview(email);
    }
  }

  loadMergeFields(): void {
    const combined = combineLatest([
      this._configService.getCompanyConfiguration("RestrictedUserEmailTemplateOverrides"),
      this._dataService.get('api/configuration/document-templates/app-global-merge-field-keys'),
      this._dataService.get('api/configuration/document-templates/agent-global-merge-field-keys'),
      this._dataService.get('api/configuration/document-templates/borrower-global-merge-field-keys')
    ]);

    combined.subscribe({
      next: ([restrictedConfig, appMergeFields, agentMergeFields, borrowerMergeFields]) => {
        this.restrictedEmailTemplates = restrictedConfig?.valueStr || '';
        this.appMergeFieldsWithCurrentNote = appMergeFields.map(field => ({
          name: Utils.spinalTapCaseString(field ? field : null),
          value: field,
          groupName: ''
        })).concat(this._currentNoteMergeField);

        this.availableMergeFields = [
          ...appMergeFields,
          // ...agentMergeFields,
          // ...borrowerMergeFields
        ].map(field => ({
          name: Utils.spinalTapCaseString(field ? field : null),
          value: field,
          groupName: ''
        })
        );
      }
    });
  }

  private replaceMergeFieldsForImages(
    definedEmailTemplate: DefinedEmailTemplate,
    type: string,
    mode: MapDirection,
  ): void {
    if (!definedEmailTemplate[type]) {
      this[type] = [];
    } else {
      if (mode == MapDirection.load) {
        definedEmailTemplate[type] = definedEmailTemplate[type]?.replace('${CompanyGuid}', this._companyGuid);
        definedEmailTemplate[type] = definedEmailTemplate[type]?.replace('${BaseApiUrl}', this._baseApiUrl);
      }
      else {
        definedEmailTemplate[type] = definedEmailTemplate[type]?.replace(this._companyGuid, '${CompanyGuid}');
        definedEmailTemplate[type] = definedEmailTemplate[type]?.replace(this._baseApiUrl, '${BaseApiUrl}');
      }
    }
  }

  protected onEditTemplateClicked(html: string, documentId: string): void {
    this._drawerService.hide('editEmailTemplateDrawer');
    let dynamicComponentInfo = new DynamicComponentInfo();
    dynamicComponentInfo.componentType = StripoEmailEditorComponent;
    dynamicComponentInfo.parameters.set('html', html);
    dynamicComponentInfo.parameters.set('documentId', documentId);
    const mergeFields = [...this.availableMergeFields];

    dynamicComponentInfo.parameters.set('mergeFields', mergeFields);
    this._drawerService.show('editEmailTemplateDrawer', 100, 'Edit Email Template', dynamicComponentInfo).then(() => {
      const subscription = this.editEmailTemplateDrawer.componentInstance.saveClicked.subscribe((saveInfo: StripoEditorSaveClickedEvent) => {
        this.effectiveEmailTemplate[documentId] = saveInfo.html;
        this.loadEmailTemplatePreview(documentId);

        this.emitDefinedEmailTemplateChange();

        this._drawerService.hide('editEmailTemplateDrawer', 100);
      });
      const subscription2 = this.editEmailTemplateDrawer.componentInstance.cancelClicked.subscribe(() => {
        this._drawerService.hide('editEmailTemplateDrawer', 100);
      });
      this._dynamicEventSubscriptions.push(subscription);
      this._dynamicEventSubscriptions.push(subscription2);
    });
  }

  protected onEmailTemplateEditorVisibilityChanged(templateType: string): void {
    this.collapsibleSectionVisibilityStatuses[templateType] = !this.collapsibleSectionVisibilityStatuses[templateType];
    this.loadEmailTemplatePreview(templateType);
  }

  private loadEmailTemplatePreview(templateType: string): void {
    setTimeout(() => {
      let iframe = document.getElementById(`${templateType}Iframe`) as HTMLIFrameElement;
      if (!iframe) {
        return;
      }
      let doc = iframe.contentDocument;
      doc.open();
      doc.write(this.effectiveEmailTemplate[templateType]);
      doc.close();
    });
  }

  private loadDefaultEmailTemplates = () => {
    this.loadingEmailTemplates = true;
    this._profileService.loadDefaultEmailTemplates().subscribe({
      next: (res) => {
        this.defaultEmailTemplates = res;

        this.loadingEmailTemplates = false;
      },
      error: err => {
        this._notificationService.showError(
          err?.message || 'Unable to load example email templates',
          'Email Templates'
        );
        this.loadingEmailTemplates = false;
      }
    })
  }

  private replaceMergeFields(definedEmailTemplate: DefinedEmailTemplate, mode: MapDirection): void {
    const replace = (type: string) => {
      this.replaceMergeFieldsForImages(definedEmailTemplate, type, mode);
    };

    [
      'inviteEmail',
      'inviteAgentEmail',
      'requestEmail',
      'reminderEmail',
      'onlineAppRegistrationEmail',
      'onlineAppStartedEmail',
      'onlineAppSubmissionEmail',
    ].forEach(replace);
  }

  private mapCCAndBCCRolesOnInit = () => {
    this.mapRolesForCCAndBCC("inviteRolesCC");
    this.mapRolesForCCAndBCC("inviteRolesBCC");

    this.mapRolesForCCAndBCC("requestRolesCC");
    this.mapRolesForCCAndBCC("requestRolesBCC");

    this.mapRolesForCCAndBCC("reminderRolesCC");
    this.mapRolesForCCAndBCC("reminderRolesBCC");
  }

  protected onRoleMultiselectChanged(type: string) {
    this.mapCCAndBCCRolesForDefinedTemplate(type);
    this.emitDefinedEmailTemplateChange();
  }

  private mapCCAndBCCRolesForDefinedTemplate = (type: string) => {
    this.effectiveEmailTemplate[type] = isArray(this[type]) ? this[type].join(',') : null;
  }

  private mapRolesForCCAndBCC = (type: string) => {
    if (!this.effectiveEmailTemplate[type]) {
      this[type] = [];
    } else {
      this[type] = this.effectiveEmailTemplate[type]?.split(",");
    }
  }
}
