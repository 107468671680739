<ng-template #pageActionBarLeft>
  <bread-crum class="tpo-bread-crumb"></bread-crum>
</ng-template>

<ng-template #mainContent>
  <div class="row">
    <div class="col-3">
      <pipeline-filter
        [channelFilteringEnabled]="false"
        [loanStatusesThatCanBeUsedForFiltering]="loanStatusesThatCanBeUsedForFiltering"
        [loanPurposesThatCanBeUsedForFiltering]="loanPurposesThatCanBeUsedForFiltering"
        [channelsThatCanBeUsedForFiltering]="channelsThatCanBeUsedForFiltering"
        [activeFilter]="filter">
      </pipeline-filter>
    </div>
    <div class="col-9">
      <div class="row">
        <div class="col-lg-4">
          <pipeline-stats [title]="'Pipeline Value'" [description]="'Current Pipeline'" color="'yellow'"
            [value]="loanCount" [text]="formattedTotalPipelineDollarValue"></pipeline-stats>
        </div>
        <div class="col-lg-4">
          <pipeline-stats [title]="'Upcoming Closings'" [description]="'Loans closing in 15 days'" color="'green'"
            [value]="upcomingClosingCount" [text]="formattedtotalLoanAmountUpcoming"></pipeline-stats>
        </div>
        <div class="col-lg-4">
          <pipeline-stats [title]="'Locks Expiring'" [description]="'Locks expiring in 7 days'" color="'red'"
            [value]="lockExpiringCount" [text]="formattedtotalLoanAmountLockExpiring"></pipeline-stats>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Pipeline Table</h4>
            </div>
            <div class="card-body">
              <div class="row" *ngIf="pipelineData">
                <pipeline-table-v2 [pipelineData]="pipelineData" [isTpoUser]="true"
                  [webPreferences]="webPreferences" [filter]="filter" 
                  (pipelineFilterChanged)="onPipelineFilterChanged($event)" (loanDetailsClicked)="onLoanDetailsClicked($event)">
                </pipeline-table-v2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<tpo-page-template [pageMainContentTemplate]="mainContent" [pageActionBarLeftContentTemplate]="pageActionBarLeft">
</tpo-page-template>
