import { Borrower, CustomData } from "src/app/models";
import { ConfigurationApplication } from "src/app/models/config/configuration-application.model";

export class NewApplicationRequest {
    application: ConfigurationApplication;
    customData: CustomData[];
    primaryRoleUserId: string;
    coBorrower: Borrower;

    constructor() {
        this.application = new ConfigurationApplication();
        this.customData = [];
        this.primaryRoleUserId = '';
        this.coBorrower = new Borrower();
    }
}
