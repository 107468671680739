import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DataService } from '../core/services/data.service';
import { AgentPortalContent, BorrowerPortalContent, CustomDataConfig, LpaProperty, SystemLevel, ThirdPartyCredential, WizardUrl } from '../models';
import { Configuration } from '../models/configuration.model';
import { DefinedEmailTemplate, EmailServiceModel } from '../modules/email-configuration/models';
import { PrequalGenerationSettings } from '../modules/prequal-letter-settings/models';
import { DefinedSmsTemplate, TelephonyServiceModel } from '../modules/sms-configuration/models';
import { DDAImportedFields } from '../modules/system-level/components/integrations/ocr/ocr.component';

@Injectable()
export class SystemLevelService {

  private lpaProperties = new LpaProperty();

  private readonly _configurationSaveSubject = new Subject<Configuration>();
  public get configurationSave() {
    return this._configurationSaveSubject.asObservable();
  }

  constructor(private readonly _dataService: DataService) {
  }

  public getSystemLevel = (): Observable<SystemLevel> => {
    return this._dataService.get(`api/Admin/GetSystemLevel`);
  };

  public getSystemLevelSessionTimeout = (): Observable<Configuration> => {
    return this._dataService.get(`api/Admin/SystemLevel/session-timeout`);
  };

  public getAgentPortalContent = (): Observable<AgentPortalContent> => {
    return this._dataService.get(`api/Admin/SystemLevel/agent-portal-content`);
  };

  public getBorrowerPortalContent = (): Observable<BorrowerPortalContent> => {
    return this._dataService.get(`api/Admin/SystemLevel/borrower-portal-content`);
  };

  public getLpaProperties = (thirdPartyKeyValuePairs): LpaProperty => {
    thirdPartyKeyValuePairs.forEach((keyValuePair) => {
      switch (keyValuePair.key) {
        case 'SellerServicerNumber':
          this.lpaProperties.sellerServicerNumber = keyValuePair.value;
          break;
        case 'LpaUserId':
          this.lpaProperties.lpaUserId = keyValuePair.value;
          break;
        case 'LpaPassword':
          this.lpaProperties.lpaPassword = keyValuePair.value;
          break;
        case 'FhaLenderId':
          this.lpaProperties.fhaLenderId = keyValuePair.value;
          break;
        case 'FhaSponsorId':
          this.lpaProperties.fhaSponsorId = keyValuePair.value;
          break;
        case 'NonOriginatingThirdPartyId':
          this.lpaProperties.nonOriginatingThirdPartyId = keyValuePair.value;
          break;
        case 'ThirdPartyOriginatorId':
          this.lpaProperties.thirdPartyOriginatorId = keyValuePair.value;
          break;
        case 'EnableMismo34':
          this.lpaProperties.enableMismo34 = keyValuePair.value;
          break;
        case 'ThirdPartyOriginatorIsCorrespondentLender':
          this.lpaProperties.thirdPartyOriginatorIsCorrespondentLender =
            keyValuePair.value;
          break;
        default:
          break;
      }
    });
    return this.lpaProperties;
  };

  public sendTestEmail = (data): Observable<SystemLevel> => {
    return this._dataService.post(`api/Email/SendTestEmail`, data);
  };

  public sendTestEmailForThirdParty = (data, serviceType: string, fromEmail: string): Observable<SystemLevel> => {
    let url = `api/Email/SendTestEmailViaThirdPartyCredentials?EmailServiceType=${serviceType}`;
    if (fromEmail) {
      url += `&from=${encodeURIComponent(fromEmail)}`;
    }
    return this._dataService.post(url, data);
  };

  public autoDiscoverExchangeUrl = (data): Observable<string> => {
    return this._dataService.post(`api/Email/AutoDiscoverExchangeUrl`, data);
  };

  public testLosCredential = (data): Observable<any> => {
    return this._dataService.post(`api/Admin/LosConfig/user/test`, data);
  };

  public getGlobalServices = (service): Observable<any> => {
    return this._dataService.get(`api/Admin/service-model/${service}`);
  };

  public saveGlobalService = (url, body): Observable<any> => {
    return this._dataService.post(`api/Admin/service-model/${url}`, body);
  };

  public deleteGlobalService = (service, id): Observable<any> => {
    return this._dataService.delete(`api/Admin/service-model/${service}/${id}`);
  };

  public saveEmailService = (emailService: EmailServiceModel): Observable<EmailServiceModel> => {
    return this._dataService.post(`api/Admin/SystemLevel/EmailServiceSettings`, emailService)
  }

  public saveConfiguration = (configuration: Configuration): Observable<Configuration> => {
    return this._dataService.post(`api/Admin/SystemLevel/SaveConfiguration`, configuration)
      .pipe(
        tap((response) => this._configurationSaveSubject.next(response))
      );
  };

  public saveDefinedEmailTemplate = (definedEmailTemplate: DefinedEmailTemplate): Observable<DefinedEmailTemplate> => {
    return this._dataService.post(`api/Admin/SystemLevel/DefinedEmailTemplate`, definedEmailTemplate);
  };

  public sendTestSms = (data): Observable<any> => {
    data['message'] = 'Lodasoft Test SMS Notification';
    return this._dataService.post(`api/Sms/SendTestSms`, data);
  };

  public saveTelephonyService = (telephonyService: TelephonyServiceModel): Observable<TelephonyServiceModel> => {
    return this._dataService.post(`api/Admin/SystemLevel/telephonyService`, telephonyService);
  };

  public saveDefinedSmsTemplate = (definedSmsTemplate: DefinedSmsTemplate): Observable<DefinedSmsTemplate> => {
    return this._dataService.post(`api/Admin/SystemLevel/DefinedSmsTemplate`, definedSmsTemplate);
  };

  public saveCustomDataConfig = (customDataConfigModel: CustomDataConfig[]): Observable<CustomDataConfig[]> => {
    return this._dataService.post(`api/Admin/SystemLevel/CustomDataConfig`, customDataConfigModel);
  };

  public saveBorrowerPortalContent = (borrowerPortalContent): Observable<any> => {
    return this._dataService.post(`api/Admin/SystemLevel/BorrowerPortalContent`, borrowerPortalContent);
  };

  public saveAgentPortalContent = (agentPortalContent): Observable<any> => {
    return this._dataService.post(`api/Admin/SystemLevel/AgentPortalContent`, agentPortalContent);
  };

  public savePrequalGenerationSettings = (prequalGenerationSettings: PrequalGenerationSettings): Observable<PrequalGenerationSettings> => {
    return this._dataService.post(`api/Admin/SystemLevel/PrequalGenerationSettings`, prequalGenerationSettings);
  };

  public saveOnlineApplicationSettings = (onlineApplicationSettings): Observable<any> => {
    return this._dataService.post(`api/Admin/SystemLevel/OnlineApplicationSettings`, onlineApplicationSettings);
  };

  public saveDocumentExportFormat = (documentExportFormat: Configuration): Observable<Configuration> => {
    return this._dataService.post(`api/Admin/SystemLevel/SaveConfiguration`, documentExportFormat)
      .pipe(
        tap((response) => this._configurationSaveSubject.next(response)),
      );
  };

  public saveCompanyExtended = (companyExtended): Observable<any> => {
    return this._dataService.post(`api/Admin/SystemLevel/CompanyExtended`, companyExtended);
  };

  public toggleActivateOnlineAppFlow = (onlineApp: WizardUrl): Observable<any> => {
    return this._dataService.post(`api/flow-builder/${onlineApp.flowId}/toggle/${onlineApp.isActive}`, {});
  };

  public toggleDefaultOnlineAppFlow = (onlineApp: WizardUrl): Observable<any> => {
    return this._dataService.put(`api/flow-builder/default/${onlineApp.flowId}`, {});
  };

  public deleteOnlineAppFlow = (onlineApp: WizardUrl): Observable<any> => {
    return this._dataService.delete(`api/flow-builder/${onlineApp.flowId}`);
  };

  public saveCredential = (credential: ThirdPartyCredential): Observable<ThirdPartyCredential> => {
    return this._dataService.put(`api/third-party-credential/${credential.credentialId || 0}`, credential);
  };

  public deleteCredential = (credential: ThirdPartyCredential): Observable<any> => {
    return this._dataService.delete(`api/third-party-credential/${credential.credentialId}`);
  };

  public removeCustomDataConfig = (fieldIndex: number): Observable<any> => {
    return this._dataService.post(`api/Admin/SystemLevel/CustomDataConfig/Clear/${fieldIndex}`, {});
  };

  public importDDAData = (username: string, secret: string, site: string): Observable<DDAImportedFields> => {
    return this._dataService.get(`api/dda/import?username=${encodeURIComponent(username)}&secret=${encodeURIComponent(secret)}&siteAddress=${encodeURIComponent(site)}`);
  }
}
