import { Component, EventEmitter, Input, OnInit, Output, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { PercentPipe } from '@angular/common';
import { NgModel } from '@angular/forms';
import { AbstractValueAccessor, MakeProvider } from 'src/app/core/abstract-value-accessor';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { EditorMode } from '../currency-input/currency-input.component';

@Component({
  selector: 'percent-input',
  templateUrl: 'percent-input.component.html',
  providers: [MakeProvider(PercentInputComponent)],
  viewProviders: [formViewProvider]
})
export class PercentInputComponent extends AbstractValueAccessor implements OnInit {

  @ViewChild('model')
  model: NgModel;

  @ViewChild('control')
  input: ElementRef<HTMLInputElement>;

  @Input()
  readonly: boolean;

  @Input()
  disabled: boolean;

  @Input()
  name: string;

  @Input()
  maxValue: number = 9999999;

  @Input()
  required: boolean;

  @Input()
  editorMode: EditorMode = EditorMode.Classic;

  @Input()
  digitsInfo: string | undefined = "1.3-3";

  @Input()
  maxWholeDigits: number | undefined = 3;

  @Input()
  inlineTextClass: string;

  @Input()
  shiftInputToLeftWhenEditingInline: boolean = false;

  @Input()
  placeholder: string = "%";

  @Input()
  rateIsTrueToTextValue: boolean = true;

  @Output()
  blur: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  focus: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  keydown: EventEmitter<any> = new EventEmitter<any>();

  id: string;

  isEditActive: boolean = false;

  mask = "999.000";

  private _originalValue: any;

  constructor(private readonly _renderer2: Renderer2,
    private readonly _percentPipe: PercentPipe) {
    super();
  }

  ngOnInit(): void {
    this.id = this.name;
    this.name = this.name + Math.floor(Math.random() * Date.now());
    if (this.digitsInfo) {
      this.configureMask();
    }
    if (this.rateIsTrueToTextValue) {
      this.value = this.value / 100;
    }
  }

  onKeyDown = ($event: any) => {
    this.keydown.emit($event);
  }

  onFocus = ($event: any) => {
    this.isEditActive = true;
    this.focus.emit($event);
  }

  onBlur = ($event: any) => {
    if ($event.target.value == null) {
      this.value = null;
      return;
    }
    const textValue = $event.target.value.replace(/[^\d|\-+|\.+]/g, '').trim();
    if (!textValue) {
      this.value = null;
    } else {
      const percentValue = Number(textValue);
      if (this.rateIsTrueToTextValue) {
        this.value = percentValue;
      } else {
        this.value = percentValue / 100;
      }

      console.log(this.rateIsTrueToTextValue, textValue, this.value);
      if (this.maxValue && this.value >= this.maxValue) {
        this.value = this.maxValue;
      }
    }
    this.blur.emit($event);
    if (this.isEditActive) {
      this.apply();
    }
  }

  onEditModeToggledOn = () => {
    this._originalValue = this._value;

    setTimeout(() => {
      this.input.nativeElement.focus();
    });
    this.isEditActive = true;
  }

  override writeValue(value: any) {

    this._value = value;
    //this.onChange(value);
    this._originalValue = this.value; // for initializing
  }

  onCancelClicked = (event: any) => {
    event.preventDefault();
    this.isEditActive = false;
    this._renderer2.setProperty(this.input.nativeElement, 'value', this._percentPipe.transform(this._originalValue / (this.rateIsTrueToTextValue ? 100 : 1), this.digitsInfo));
  }

  onApplyClicked = (event: any) => {
    this.model.control.markAsTouched();
    event.preventDefault();
    this.apply();
  }

  private apply = () => {
    if (this.model.valid) {
      this._originalValue = this.value;
      this.isEditActive = false;
    }
  }

  private configureMask = () => {
    let maxFractionDigits = 3;
    if (!this.maxWholeDigits) {
      this.maxWholeDigits = 3;
    }
    if (this.digitsInfo) {
      const wholeAndFractionParts = this.digitsInfo.split('.');
      if (wholeAndFractionParts.length === 2) {
        const fractionDigitInfoParts = wholeAndFractionParts[1].split('-');
        if (fractionDigitInfoParts.length === 2) {
          maxFractionDigits = Number(fractionDigitInfoParts[1]);
        }
      }
    }
    let mask = "";
    for (let i = 1; i <= this.maxWholeDigits; i++) {
      mask += '9';
    }
    mask += '.';
    for (let i = 1; i <= maxFractionDigits; i++) {
      mask += '0';
    }
    this.mask = mask;
  }
}
