import { Component, Input, OnInit } from '@angular/core';
import { LoanApplication } from 'src/app/models';
import { ValidationResult } from 'src/app/models/loan/validation-result.model';

@Component({
  selector: 'submission-data-validation',
  templateUrl: './submission-data-validation.component.html',
  styleUrls: ['./submission-data-validation.component.scss']
})
export class SubmissionDataValidationComponent implements OnInit {

  @Input()
  application: LoanApplication;

  @Input()
  set validationResults(results: ValidationResult[]) {
    this._validationResults = results.filter(x => x.validationType != 'Task' && x.validationType != 'CustomTask' && !x.passed);
    this.populateValidationData();
  }

  @Input()
  isUrlaInvalid: boolean = false;

  get validationResults(): ValidationResult[] {
    return this._validationResults || [];
  }

  errors: Array<any> = [];

  warnings: Array<any> = [];

  private _validationResults: ValidationResult[];

  constructor() { }

  ngOnInit(): void {

  }

  populateValidationData = () => {

    this.errors = [];

    const isCompanyDeephaven = [229, 276].includes(this.application.companyId);
    const isCorrespondent = this.application.channel == 'Correspondent';

    const isDeephavenCorrespondent = isCompanyDeephaven && isCorrespondent;
  
    if (isCompanyDeephaven && this.isUrlaInvalid == true) {
      this.errors.push({ message: 'URLA Data Validation Error. Please go back to URLA and clear all red fields. ', link: 'urla' });
    }
    
    this.validationResults?.forEach(x => {
      this.errors.push({ message: x.errorMessage, link: x.link });
    });

    if (this.application.companyId != 128) {
      if (this.application.productPricing.price > 0 && this.application.productPricing.assignDate && ["Accepted", "Requested"].indexOf(this.application.productPricing.lockStatus) < 0)
        this.warnings.push({ message: "Loan is not locked. You are submitting a file with a lock status of FLOAT.", link: "pricing" });
    }
  }
}
