import { Component, ElementRef, Injector, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { set, startCase, toLower } from 'lodash';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { combineLatest, finalize, forkJoin } from 'rxjs';
import { EnvironmentService } from 'src/app/core/services/environment/environment.service';
import { Utils } from 'src/app/core/services/utils';
import { ApplicationContext, DocFile, DocumentType, LoanApplication, LoanDoc, Role, UserPermissions } from 'src/app/models';
import { FileDto } from 'src/app/models/borrower/file-dto.model';
import { LoanDocDashboardTask } from 'src/app/models/borrower/loan-doc-dashboard-task.model';
import { GlobalConfig, TaskPriority } from 'src/app/models/config/global-config.model';
import { TaskMessage } from 'src/app/models/message/task-message.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { GatherTask } from 'src/app/models/task/gather-task.model';
import { TrackingInfo } from 'src/app/models/tracking-info.model';
import { UserProfile } from 'src/app/models/user/user-profile.model';
import { User } from 'src/app/models/user/user.model';
import { ZipCodeLookupResult } from 'src/app/models/zipcode-lookup-result.model';
import { BorrowerDto } from 'src/app/modules/contacts/models/borrower-dto.model';
import { ExternalContactsService } from 'src/app/modules/external-contacts/services/external-contacts.service';
import { InternalContact } from 'src/app/modules/internal-contacts/models/internal-contact.model';
import { InternalContactsService } from 'src/app/modules/internal-contacts/services/internal-contacts.service';
import { DocumentSigningOrder } from 'src/app/modules/loan-docs/models/document-signing-order.model';
import { DocumentSigningVendor } from 'src/app/modules/loan-docs/models/document-signing-vendor.model';
import { ExternalContact } from 'src/app/modules/loan-docs/models/external-contact.model';
import { LoanDocESign } from 'src/app/modules/loan-docs/models/loan-doc-sign.model';
import { NotifyPartyLoanContact } from 'src/app/modules/loan-docs/models/notify-party-loan-contact.model';
import { OriginalTask } from 'src/app/modules/loan-docs/models/original-task.model';
import { LoanDocsService } from 'src/app/modules/loan-docs/services/loan-docs.service';
import { ChannelService } from 'src/app/services/channel.service';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { FileService } from 'src/app/services/file.service';
import { LoanService } from 'src/app/services/loan';
import { LoanDocService } from 'src/app/services/loan-doc.service';
import { MentionsService } from 'src/app/services/mentions.service';
import { MessageService } from 'src/app/services/message.service';
import { NotificationService } from 'src/app/services/notification.service';
import { TaskService } from 'src/app/services/task.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { EsignatureCancelOrderDialogComponent } from 'src/app/shared/components/esignature-cancel-order-dialog/esignature-cancel-order-dialog.component';
import { Mentionable } from 'src/app/shared/components/message-editor-with-mentions/mentionable.model';
import { MentionsUtils } from 'src/app/shared/services/mentions.utils';
import { DocumentSigningDialogComponent } from '../edit-task-dialog/components/document-signing-dialog/document-signing-dialog.component';
import { EsignDocumentDialogComponent } from '../edit-task-dialog/components/esign-document-dialog/esign-document-dialog.component';
import { LinkExistingDocumentsDialogComponent } from '../edit-task-dialog/components/link-existing-documents-dialog/link-existing-documents-dialog.component';
import { AgentService } from 'src/app/services/agent.service';
import { SharedUtilitiesService } from 'src/app/shared/services/utility.service';

@Component({
  selector: 'task-editor-esign',
  templateUrl: './task-editor-esign.component.html',
  styleUrls: ['./task-editor-esign.component.scss']
})
export class TaskEditorEsignComponent extends ApplicationContextBoundComponent implements OnInit {
  @Input()
  task: LoanDocDashboardTask;

  @Input()
  filterId: number;

  @Input()
  hostedInModal: boolean = true;

  @Input()
  taskReadonly: boolean;

  @Input()
  startESign: boolean;

  @Input()
  startDocumentGeneration: boolean;

  @Input()
  openFileUpload: boolean;

  @Input()
  refreshMentions: boolean = false;

  @Output()
  taskRefreshed: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('editTaskForm')
  editTaskForm: NgForm | undefined;

  @ViewChild('gatherTaskForm')
  gatherTaskForm: NgForm | undefined;

  @ViewChild('addNewDocFileBtn')
  addNewDocFileBtn: ElementRef<HTMLInputElement>;

  appId: number;

  gatherTask: GatherTask;

  isLoggedInUserTpo: boolean = false;
  isLoanTpo: boolean = false;

  internalNotes: Array<TaskMessage> = [];

  borrowerNotes: Array<TaskMessage> = [];

  historyVisible: boolean = false;

  taskTrackingInfo: TrackingInfo[];

  columns: any[];

  taskStatusOptions: any[] = [];

  borrowers: BorrowerDto[] = [];

  externalCompanyUsers: UserProfile[] = [];
  branchUsers: UserProfile[] = [];

  internalContacts: InternalContact[] = [];

  externalContacts: ExternalContact[] = [];

  notifyPartyLoanContacts: NotifyPartyLoanContact[] = [];

  globalConfig: GlobalConfig;

  users: User[] = [];

  usersAll: User[] = [];

  roles: Role[];

  recipientBorrowerContactIds: string[] = [];

  recipientInternalContactIds: string[] = [];

  recipientExternalContactIds: string[] = [];

  eSignOrder: DocumentSigningOrder;

  uploadedFiles: Array<File> = [];

  isEsignProcessCompleted: boolean;

  isEsignDocumentWithNoLoanDocTaskId: boolean = false;

  canEditReviewStatus: boolean;

  allowCancelEsign: boolean;

  allowCorrectEsign: boolean;

  taskPriority: TaskPriority[] = [];

  paymentError: boolean = false;

  refreshingEsignOrder: boolean = false;

  uploadingFiles: boolean = false;

  activeDeletionIndex: number = -1;

  isMyTaskSelected: boolean;

  isBorrowerTaskSelected: boolean;

  isEsignTaskStatusVisible: boolean = false;

  mentionables: Mentionable[] = [];

  dueDayOrDate: string = 'day';

  dueDate: string;

  today: string = new Date().toISOString();

  snoozeDurationInMinutes: number = null;

  willBeSnoozed: boolean = false;

  documentTypes: DocumentType[];

  isRefreshed: boolean = false;

  states: EnumerationItem[];

  internalNoteImageProcessStatuses: Map<string, TaskImageProcessStatus> = new Map<string, TaskImageProcessStatus>();

  agentNameAndPortalAddress: string = '';

  borrowerFacingNote: string = '';

  private _currentApplication: LoanApplication;

  private _originalTask = new OriginalTask();

  private _loanDocForEsignTask: LoanDocESign;

  private _userPermissions: UserPermissions;

  private _internalContactsLoaded: boolean = false;

  private _externalCompanyUsersLoaded: boolean = false;

  optionsMultipleSelect = {
    width: '100%',
    multiple: true,
    theme: 'classic',
    closeOnSelect: false,
    dropdownParent: '#editTaskDialog',
  };

  optionsSelect = {
    width: '100%',
    multiple: false,
    closeOnSelect: true,
    dropdownParent: '#editTaskDialog',
  };

  readonly ngxPlacesOptions: any = {
    types: ['geocode'],
    componentRestrictions: { country: 'us' }
  }

  constructor(
    private readonly injector: Injector,
    public activeModal: NgbActiveModal,
    private readonly _modalService: NgbModal,
    private readonly _taskService: TaskService,
    private readonly _messageService: MessageService,
    private readonly _fileService: FileService,
    private readonly _enumerationService: EnumerationService,
    private readonly _internalContactsService: InternalContactsService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifyService: NotificationService,
    private readonly _loanDocsService: LoanDocsService,
    private readonly _loanDocService: LoanDocService,
    private readonly _loanService: LoanService,
    private readonly _externalContactsService: ExternalContactsService,
    private readonly _channelService: ChannelService,
    private readonly _mentionsService: MentionsService,
    private readonly _environment: EnvironmentService,
    private readonly _enumsService: EnumerationService,
    private readonly _utilityService: SharedUtilitiesService,
    private readonly _agentService: AgentService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    if (this.task.taskType === 'QuickClientUpdateReferralLead' || this.task.taskType === 'QuickClientUpdatePreApproved' || this.task.taskType === 'QuickClientUpdateInProcess')
      return;

    this.appId = this.task.applicationId;

    this.fetchAssociatedAgent();
    this.isMyTaskSelected = this.filterId == 1 || this.filterId == 2;
    this.isBorrowerTaskSelected = this.filterId == 5 || this.filterId == 6;

    this.task.note = '';
    this.task.borrowerFacingNote = '';

    if (this.task.dueDays > 0 && this.task.requestDate) {
      let newDate: any = new Date(this.task.requestDate);
      newDate.setDate(newDate.getDate() + Number(this.task.dueDays));
      this.dueDate = newDate.toISOString();
    } else {
      this.dueDate = this.task.requestDate ? this.task.requestDate : this.today;
    }

    if (this.task.taskType === 'GatherPayment') {
      this.gatherTask = new GatherTask();
      this.setOriginalTask();
      if (this.task && this.task.loanDocTaskId !== 0) {
        this.getGatherPaymentTaskBalance();
      }
    }

    this.columns = [
      { field: 'actionValue', header: 'Action' },
      { field: 'dateCreated', header: 'Date Created' },
      { field: 'by', header: 'By' },
    ];
    this.initializeContextBasedState(this.applicationContext);
    this.initialize();
  }

  onTaskConditionChanged = () => {
    if (this.task.condition) {
      if (!this.task.conditionStatus) {
        this.task.conditionStatus = "ConditionPending";
      }
    } else {
      this.task.conditionStatus = null;
    }
  }

  toggleTaskView = () => {
    this.historyVisible = !this.historyVisible;

    if (this.historyVisible && !this.taskTrackingInfo) {
      this._taskService.getTaskTracking(this.task.loanDocTaskId).subscribe(
        (response) => {
          this.taskTrackingInfo = _.orderBy(response, ['dateCreated'], ['desc']);
        },
        (err) => { }
      );
    }
  };

  private initializeContextBasedState = (context: ApplicationContext) => {
    this.globalConfig = context.globalConfig;
    this.states = Object.keys(this.globalConfig.states).map(key => new EnumerationItem(this.globalConfig.states[key], key));
    this.users = context.globalConfig.users.map(u => ({
      ...u,
      ['displayName']: Utils.getPersonsDisplayName(u),
    }));
    this.usersAll = context.globalConfig.usersAll;
    this.roles = context.globalConfig.roles;
    this._userPermissions = context.userPermissions;
    this.isLoggedInUserTpo = this._userPermissions.userType.toString() == 'TPO';
    this.taskPriority = context.globalConfig.taskPriority;
    if (!this.task.notifyPartyId) {
      this.task.notifyPartyId = '';
    }
    this.documentTypes = this.globalConfig.documentType.filter(doc => doc.documentTypeId > 0);
  };

  private initialize = () => {
    this.populateDataForEsignTask();
    this.loadTaskNotes();
    if (
      this.task &&
      this.task.taskType === 'EsignDocument' &&
      this.task.loanDocTaskId === 0
    ) {
      this.isEsignDocumentWithNoLoanDocTaskId = true;
    }

    const completedStatus =
      this.task.taskStatus == 'Completed' ||
      this.task.taskStatus == 'NotApplicable';

    this.canEditReviewStatus = !completedStatus && (!this.task.requiresReview ||
      this._userPermissions.userId == this.task.reviewPartyId);

    this.taskReadonly = this.taskReadonly || (this._userPermissions.readOnlyTasks && this.task.userId != this._userPermissions.userId);
    this.allowCancelEsign = this._userPermissions.allowCancelEsign;
    this.allowCorrectEsign = true;

    if (this.task.applicationId) {
      this._loanService.getApplicationModel(this.task.applicationId, false).subscribe(application => {
        this._currentApplication = application;
        this.isLoanTpo = this._currentApplication.externalCompanyId > 0;
        let allUsers = this.users;
        if (this.isLoanTpo) {
          allUsers = allUsers.concat(this.globalConfig.tpoUsers);
        }
        this.mentionables = this.mentionables.concat(MentionsUtils.prepareUserMentionables(this._environment.apiInfo.apiBaseUrl,
          allUsers));
        this.mentionables.push(MentionsUtils.prepareInternalContactsMentionable());
        this.mentionables.push(MentionsUtils.prepareHereMentionable());
        if (this._currentApplication.externalCompanyId) {
          this.loadExternalCompanyUsers(this._currentApplication.externalCompanyId);
        } else {
          this._externalCompanyUsersLoaded = true;
        }
        this.loadInternalContacts();
      });

      this._loanService
        .getBorrowers(this.task.applicationId)
        .subscribe(borrowers => {
          this.borrowers = borrowers;
          if (this.borrowers) {
            this.borrowers.forEach(borrower => {
              borrower.fullName = borrower.firstName ? borrower.firstName + ' ' : '';
              borrower.fullName += borrower.lastName ? borrower.lastName : '';
            })
          }
        });
    } else {
      this.mentionables = this.mentionables.concat(MentionsUtils.prepareUserMentionables(this._environment.apiInfo.apiBaseUrl,
        this.users));
      this.mentionables.push(MentionsUtils.prepareInternalContactsMentionable());
      this.mentionables.push(MentionsUtils.prepareHereMentionable());
    }

    this.populateStatusDropdown();

    this.setOriginalTask();
    setTimeout(() => {
      this.initControls();
    }, 200);
  };

  private loadTaskNotes = () => {
    if (!this.task.loanDocTaskId) {
      return;
    }

    const combined = combineLatest([
      this._messageService.getInternalTaskMessages(this.task.loanDocTaskId),
      this._messageService.getBorrowerTaskMessage(this.task.loanDocTaskId)
    ]);

    combined.subscribe(([internalNotes, borrowerNotes]) => {

      this.internalNotes = internalNotes;

      const originalInternalNotes = _.cloneDeep(internalNotes);

      for (let i = 0; i <= this.internalNotes.length - 1; i++) {
        const note = this.internalNotes[i];
        note.content = MentionsUtils.generateDisplayHtmlWithMentions(note.content);
        note['uniqueId'] = uuidv4();
        originalInternalNotes[i]['uniqueId'] = note['uniqueId'];
        const internalNotesImgCount = this.replaceImagesWithLoaderIcons(note);
        const taskImageProcessingStatus: TaskImageProcessStatus = { numberOfImagesLeftToProcess: internalNotesImgCount, processedContent: note.content }
        this.internalNoteImageProcessStatuses.set(note['uniqueId'], taskImageProcessingStatus);
      }

      originalInternalNotes.forEach(note => {
        note.content = MentionsUtils.generateDisplayHtmlWithMentions(note.content);
        this.generateDisplayHtmlWithImages(note.content, note);
      });

      this.borrowerNotes = borrowerNotes;
      this.borrowerNotes.forEach(note => {
        note.content = MentionsUtils.generateDisplayHtmlWithMentions(note.content);
        this.generateDisplayHtmlWithImages(note.content, note);
      });

      // refresh mentions from alerts
      if (this.refreshMentions) {
        this._mentionsService.publish({ type: "reload" });
      }
    })
  };

  private initControls = () => {
    if (this.openFileUpload) {
      this.onAddNewDocFileClicked();
    } else if (this.startDocumentGeneration) {
      this.onGenerateDocumentClicked();
    } else if (this.startESign) {
      this.signDocument();
    }
  };

  private populateDataForEsignTask = () => {
    this.isEsignProcessCompleted = !!this.task.loanDocTaskId;
    this.isEsignDocumentWithNoLoanDocTaskId = !this.isEsignProcessCompleted;
    this.isEsignTaskStatusVisible = this.isEsignTask(this.task) && !!this.eSignOrder;
    if (this.task.taskType == 'EsignDocument' && this.shouldShowEsignTaskAction()) {
      this.borrowers = this.borrowers.filter((borrower) => borrower.email);
      this.loadExternalContacts();
    }

    if (this.isEsignProcessCompleted && this.isEsignTask(this.task)) {
      this._loanDocsService.getSigningOrder(this.task.loanDocTaskId).subscribe(
        (result) => {
          this.eSignOrder = result && result.length > 0 ? result[0] : null;
          this.isEsignTaskStatusVisible =
            this.isEsignTask(this.task) && !!this.eSignOrder;

          if (this.isEsignTaskStatusVisible) {
            this.allowCorrectEsign = this.eSignOrder.status == 'Pending' || this.eSignOrder.status == 'Sent' || this.eSignOrder.status == 'Declined';
          }
        },
        (error) => {
          this._notifyService.showError(
            error.message ||
            `Error getting eSign order for task: ${this.task.loanDocTaskId}.`,
            'Failure'
          );
        }
      );
    }
  };

  private onAddNewDocFileClicked = () => {
    if (!this.addNewDocFileBtn?.nativeElement) return;
    this.addNewDocFileBtn.nativeElement.click();
  };

  shouldShowEsignTaskAction = () => {
    return (
      this.isEsignTask(this.task) &&
      !(
        this.task.taskStatus == 'Rejected' ||
        this.task.taskStatus == 'NotApplicable' ||
        this.task.taskStatus == 'Completed'
      ) && !this.eSignOrder
    );
  };

  onMessageChanged = (message: string, noteField: string) => {
    set(this.task, noteField, message);
  };

  onZipCodeRelatedInfoChanged = (zipCode: ZipCodeLookupResult) => {
    if (zipCode) {
      this.gatherTask.billingState = zipCode.state.toLowerCase();
      this.gatherTask.billingCity = startCase(toLower(zipCode.city)); // titleCase string
      this.gatherTask.billingZip = zipCode.zipcode;
    }
  }

  handleAddressChange = (e: any) => {
    setTimeout(() => {
      e.address_components.forEach((component: { types: any; long_name: any; short_name: any; }) => {
        let types = component.types;
        types.forEach((type: string) => {
          if (type === 'locality' || type === 'sublocality') {
            this.gatherTask.billingCity = component.long_name;
          }
          if (type === 'administrative_area_level_1') {
            const state = this._enumsService.states.find(s => s.name === component.short_name);
            if (state) {
              this.gatherTask.billingState = state.value;
            }
          }
          if (type === 'postal_code') {
            this.gatherTask.billingZip = component.long_name;
          }
          if (type === 'street_number') {
            this.gatherTask.billingAddress = component.short_name;
          }
          if (type === 'route') {
            this.gatherTask.billingAddress += " " + component.short_name;
          }
        });
      });
    }, 200);
  }

  submitGatherTask = () => {
    if (this.gatherTaskForm) {
      this.gatherTaskForm.form.markAllAsTouched();
      if (!this.gatherTaskForm.form.valid) return;

      const data = _.cloneDeep(this.gatherTask)

      data.creditCardExpirationMonth = Number(this.gatherTask.creditCardExpirationDate.slice(0, 2));
      data.creditCardExpirationYear = Number(this.gatherTask.creditCardExpirationDate.slice(2, 6));

      delete data.taskBalance;
      delete data.creditCardExpirationDate;

      this._spinner.show();
      this._taskService.postGatherPayment(this.task.loanDocTaskId, data).subscribe((response) => {
        this._spinner.hide();
        this._notifyService.showSuccess('Task submitted', 'Success');
        this.activeModal.close('save');
      }, (err) => {
        this._spinner.hide();
        this._notifyService.showError(err ? err.message : "Couldn't submit the task.", "Error")
      });

    }
  }

  private getGatherPaymentTaskBalance = () => {
    this._taskService
      .getGatherPaymentTaskBalance(this.task.loanDocTaskId)
      .subscribe(
        (response) => {
          this.gatherTask.taskBalance = response;
        },
        (err) => {
          this.paymentError = true;
          this._notifyService.showError(
            err ? err.message : "Couldn't submit the task.",
            'Error'
          );
        }
      );
  };

  private getContactRoles = (channel: string): Role[] => {
    let contactRoles = [];
    if (this._userPermissions.enabledChannels && channel) {
      const enabledChannels = this._channelService.getChannelsFromCommaDelimitedString(this._userPermissions.enabledChannels)
        .map((c) => c.value);
      if (enabledChannels.includes(channel)) {
        this.globalConfig.channelRoles[channel.toLowerCase()].forEach(role => {
          if (role.isLoanContact) {
            const roleChannel = role.roleChannels.find(roleChannel => roleChannel.channel === channel);
            role.orderByChannel = roleChannel.order || null;
            role.order = role.orderByChannel;
            contactRoles.push(role);
          }
        }
        );
      }
    } else {
      this.globalConfig.roles.forEach((role) => {
        if (role.isLoanContact) {
          contactRoles.push(role);
        }
      });
    }
    contactRoles.sort((a, b) => a.orderByChannel - b.orderByChannel);
    return contactRoles;
  };

  private buildNotifyPartyLists = () => {
    if (this._currentApplication) {
      const contactRoles = this.getContactRoles(this._currentApplication.channel);
      contactRoles.forEach(contactRole => {
        const internalContact = this.internalContacts.find(contact => contact.roleId === contactRole.roleId);
        if (internalContact && internalContact.userId) {
          const userAsInternalContact = this.users.find(user => user.userCompanyGuid === internalContact.userId) ||
            this.externalCompanyUsers.find(externalUser => externalUser.userCompanyGuid === internalContact.userId) ||
            this.branchUsers.find(externalUser => externalUser.userCompanyGuid === internalContact.userId);
          if (userAsInternalContact) {
            this.notifyPartyLoanContacts.push(
              new NotifyPartyLoanContact(
                userAsInternalContact.userCompanyGuid,
                contactRole.roleId,
                userAsInternalContact.firstName,
                userAsInternalContact.lastName,
                contactRole.roleName,
                contactRole.order
              )
            );
            const fullName = (userAsInternalContact.firstName + ' ' + userAsInternalContact.lastName).trim();
            const contactRoleMentionable: Mentionable = {
              id: '@' + fullName,
              name: contactRole.roleName,
              avatarUrl: 'assets/images/male.png',
              isOnline: true,
              mentionCode: `@{${fullName}:User:${userAsInternalContact.userCompanyGuid}}`,
            };
            this.addToMentionables(MentionsUtils.toMentionable(this._environment.apiInfo.apiBaseUrl, userAsInternalContact));
            this.mentionables.push(contactRoleMentionable);
          }
        }
      });
      this.branchUsers = this.branchUsers.filter(
        branchUser => !this.notifyPartyLoanContacts.find(contact => contact.userId === branchUser.userCompanyGuid)
      );
      this.branchUsers.forEach(branchUser => {
        this.addToMentionables(MentionsUtils.toMentionable(this._environment.apiInfo.apiBaseUrl, branchUser));
      });
    }
  };

  private loadInternalContacts = () => {
    this._internalContactsService
      .getInternalContacts(this.task.applicationId)
      .subscribe((result) => {
        this.internalContacts = result;
        if (
          this.task.taskType == 'EsignDocument' &&
          this.shouldShowEsignTaskAction()
        ) {
          this.internalContacts = this.getExtendedInternalContacts(result);

          this.internalContacts.forEach((internalContact) => {
            if (internalContact.email) {
              return;
            }
            const user = this.usersAll.find(
              (user) => user.userCompanyGuid == internalContact.userId
            );
            if (user) {
              internalContact.email = user.email;
            }
          });
          this.internalContacts = this.internalContacts.filter(
            (internalContact) => internalContact.email
          );
        }
        //we need the external company user loaded before we can build notify party list
        if (this._externalCompanyUsersLoaded) {
          this.buildNotifyPartyLists();
        }
      }).add(() => this._internalContactsLoaded = true);
  };

  private loadExternalContacts = () => {
    this._externalContactsService
      .getExternalContacts(this.task.applicationId)
      .subscribe(
        (response) => {
          this.externalContacts = response;
          this.externalContacts.forEach(contact => {
            contact.fullName = contact.firstName ? contact.firstName + ' ' : '';
            contact.fullName += contact.lastName ? contact.lastName : '';
            if (contact.email) {
              return;
            }
            const user = this.usersAll.find(user => user.userCompanyGuid === contact.userId);
            if (user) {
              contact.email = user.email;
            }
            this.addToMentionables(MentionsUtils.convertToMentionable(contact));
          });
        }, (err) => {
          this._notifyService.showError(
            err?.message || 'Unable to load external contacts.',
            'Error!'
          );
        }
      );
    this.externalContacts = this.externalContacts.filter(c => c.email);
    console.log(this.externalContacts);
  };

  private getExtendedInternalContacts = (
    internalContacts: InternalContact[]
  ) => {
    return internalContacts
      .filter((internalContact) => !!internalContact.userId)
      .map((internalContact) =>
        Object.assign(
          internalContact,
          this.getInternalContactNameAndOrder(internalContact)
        )
      );
  };

  private getInternalContactNameAndOrder = (
    internalContact: InternalContact
  ) => {
    const user = this.usersAll.find(
      (user) => user.userCompanyGuid == internalContact.userId
    );
    const role = this.roles.find(
      (role) => role.roleId == internalContact.roleId
    );

    if (!user) {
      return { order: role.order, displayName: '' };
    }
    return {
      order: role.order,
      displayName: `${user.firstName} ${user.lastName} ${role ? `(${role.roleName})` : ''
        }`,
    };
  };

  private setOriginalTask = () => {
    this._originalTask.taskStatus = this.task.taskStatus;
    this._originalTask.requiresReview = this.task.requiresReview;
    this._originalTask.reviewPartyId = this.task.reviewPartyId;
  };

  private addToMentionables = (mentionable: Mentionable) => {
    const existing = this.mentionables.find(m => m.mentionCode === mentionable.mentionCode);
    if (existing) {
      return;
    }
    this.mentionables.push(mentionable);
  }

  private loadExternalCompanyUsers = (externalCompanyId: number) => {
    this._internalContactsService.getExternalCompanyUsers(this._currentApplication.companyId, externalCompanyId)
      .subscribe(users => {
        this.externalCompanyUsers = users.filter(u => u.branchId != this._currentApplication.branchId);
        this.branchUsers = users.filter(u => u.branchId == this._currentApplication.branchId);
        this.externalCompanyUsers.forEach(externalCompanyUser => {
          this.addToMentionables(MentionsUtils.toMentionable(this._environment.apiInfo.apiBaseUrl, externalCompanyUser));
        })
        //we need the internal contacts loaded before we can build notify party list
        if (this._internalContactsLoaded) {
          this.buildNotifyPartyLists();
        }
      },
        (error) => { }
      ).add(() => this._externalCompanyUsersLoaded = true);
  };

  isInNotifyPartyList = (userId: string) => {
    return (
      this.notifyPartyLoanContacts &&
      !!this.notifyPartyLoanContacts.find((user) => user.userId == userId)
    );
  };

  requiresReviewChanged = () => {
    if (this.task.requiresReview) {
      if (this._originalTask.requiresReview) {
        this.task.reviewPartyId = this._originalTask.reviewPartyId;
        this.task.taskStatus = this._originalTask.taskStatus;
      }
      if (!this.task.reviewPartyId) {
        const contact = this.notifyPartyLoanContacts.find((u) => u.userId === this.task.userId);
        if (contact) {
          this.task.reviewPartyId = contact.userId;
        } else {
          this.task.reviewPartyId = '';
        }
      }
    } else {
      this.task.reviewPartyId = '';
      if (this.task.taskStatus == 'ReviewReady') {
        this.task.taskStatus = 'Completed';
      }
    }
    this.populateStatusDropdown();
  };

  private onRequestFromBorrowerChange = () => {
    if (this.task.requestBorrower && this.task.taskStatus === "ConditionImportPending"
      && this.globalConfig.losOptions.find(o => o.name === "conditionAutopasteTextToBorrowerFacingNote" && o.vendorName === this.applicationContext.application.losVendor)?.value == "True") {
      this.borrowerFacingNote = this.task.conditionText;
    }
    else {
      this.task.borrowerFacingNote = '';
      this.borrowerFacingNote = '';
    }

    this.populateStatusDropdown();
  };

  private populateStatusDropdown = () => {
    if (this.task.requestBorrower) {
      this._enumerationService.taskStatuses.forEach((taskStatus) => {
        if (
          (taskStatus.id === 404 && this.task.taskType === 'EsignDocument') ||
          this.task.taskType === 'LosEsign'
        ) {
          return;
        }
        if (this.task.taskStatus == 'Pending') {
          if (
            !this.task.requiresReview &&
            [400, 401, 403, 404].indexOf(taskStatus.id) > -1
          ) {
            this.taskStatusOptions.push(taskStatus);
          } else if (
            this.task.requiresReview &&
            [400, 401].indexOf(taskStatus.id) > -1
          ) {
            this.taskStatusOptions.push(taskStatus);
          }
        } else if (this.task.taskStatus == 'Submitted') {
          if (
            !this.task.requiresReview &&
            [401, 402, 403, 404].indexOf(taskStatus.id) > -1
          ) {
            this.taskStatusOptions.push(taskStatus);
          } else if (
            this.task.requiresReview &&
            [401, 402, 423].indexOf(taskStatus.id) > -1
          ) {
            this.taskStatusOptions.push(taskStatus);
          }
        } else if (this.task.taskStatus == 'ReviewReady') {
          if ([402, 403, 423].indexOf(taskStatus.id) > -1) {
            this.taskStatusOptions.push(taskStatus);
          }
        } else if (this.task.taskStatus == 'Rejected') {
          if (
            !this.task.requiresReview &&
            [401, 402, 403, 404].indexOf(taskStatus.id) > -1
          ) {
            this.taskStatusOptions.push(taskStatus);
          } else if (
            this.task.requiresReview &&
            [401, 402].indexOf(taskStatus.id) > -1
          ) {
            this.taskStatusOptions.push(taskStatus);
          }
        } else if (this.task.taskStatus == 'NotApplicable') {
          if ([400, 404].indexOf(taskStatus.id) > -1) {
            this.taskStatusOptions.push(taskStatus);
          }
        } else if (this.task.taskStatus == 'Approved') {
          if (
            !this.task.requiresReview &&
            [400, 401, 402, 403].indexOf(taskStatus.id) > -1
          ) {
            this.taskStatusOptions.push(taskStatus);
          } else if (
            this.task.requiresReview &&
            [400, 401, 402, 403, 423].indexOf(taskStatus.id) > -1
          ) {
            this.taskStatusOptions.push(taskStatus);
          }
        } else {
          if (
            !this.task.requiresReview &&
            [400, 421, 422].indexOf(taskStatus.id) > -1
          ) {
            this.taskStatusOptions.push(taskStatus);
          } else if (
            this.task.requiresReview &&
            [400, 421, 422].indexOf(taskStatus.id) > -1
          ) {
            this.taskStatusOptions.push(taskStatus);
          }
        }
      });
    } else {
      this._enumerationService.taskStatuses.forEach((taskStatus) => {
        if (
          (taskStatus.id === 404 && this.task.taskType === 'EsignDocument') ||
          this.task.taskType === 'LosEsign'
        ) {
          return;
        }
        if (
          this.task.taskStatus == 'Pending' ||
          this.task.taskStatus == 'Requested'
        ) {
          if (
            !this.task.requiresReview &&
            [400, 404, 421, 422].indexOf(taskStatus.id) > -1
          ) {
            this.taskStatusOptions.push(taskStatus);
          } else if (
            this.task.requiresReview &&
            [400, 421, 423].indexOf(taskStatus.id) > -1
          ) {
            this.taskStatusOptions.push(taskStatus);
          }
        } else if (this.task.taskStatus == 'ReviewReady') {
          if ([402, 403, 423].indexOf(taskStatus.id) > -1) {
            this.taskStatusOptions.push(taskStatus);
          }
        } else if (this.task.taskStatus == 'Rejected') {
          if ([402, 423].indexOf(taskStatus.id) > -1) {
            this.taskStatusOptions.push(taskStatus);
          }
        } else if (this.task.taskStatus == 'NotApplicable') {
          if ([400, 404].indexOf(taskStatus.id) > -1) {
            this.taskStatusOptions.push(taskStatus);
          }
        } else if (this.task.taskStatus == 'Approved') {
          if (
            !this.task.requiresReview &&
            [400, 403].indexOf(taskStatus.id) > -1
          ) {
            this.taskStatusOptions.push(taskStatus);
          } else if (
            this.task.requiresReview &&
            [400, 402, 403, 423].indexOf(taskStatus.id) > -1
          ) {
            this.taskStatusOptions.push(taskStatus);
          }
        } else if (this.task.taskStatus == 'ChangeOfCircumstancePending') {
          if ([426, 427].indexOf(taskStatus.id) > -1) {
            this.taskStatusOptions.push(taskStatus);
          }
        } else if (this.task.taskStatus == 'ChangeOfCircumstanceRejected') {
          if ([425, 427].indexOf(taskStatus.id) > -1) {
            this.taskStatusOptions.push(taskStatus);
          }
        } else if (this.task.taskStatus == 'ChangeOfCircumstanceApproved') {
          if ([425, 426].indexOf(taskStatus.id) > -1) {
            this.taskStatusOptions.push(taskStatus);
          }
        } else if (this.task.taskStatus == 'EscalationPending') {
          if ([422].indexOf(taskStatus.id) > -1) {
            this.taskStatusOptions.push(taskStatus);
          }
        } else {
          if (
            !this.task.requiresReview &&
            [400, 422].indexOf(taskStatus.id) > -1
          ) {
            this.taskStatusOptions.push(taskStatus);
          } else if (
            this.task.requiresReview &&
            [400, 421, 422].indexOf(taskStatus.id) > -1
          ) {
            this.taskStatusOptions.push(taskStatus);
          }
        }
      });
    }
  };

  setBorrowerIds = (value: string[]) => {
    this.recipientBorrowerContactIds = value;
  };

  setInternalContactIds = (value: string[]) => {
    this.recipientInternalContactIds = value;
  };

  setExternalContactIds = (value: string[]) => {
    this.recipientExternalContactIds = value;
  };

  onDocFilesChanged = (event: any) => {
    event.target.files.forEach((targetFile) => {
      const file = targetFile;
      let docFile = new FileDto();
      docFile.fileName = file.name;
      docFile.loanDocId = this.task.loanDocId;
      docFile.note = '';
      docFile.borrowerNote = '';
      docFile.guid = null;
      docFile.active = true;
      docFile.file = file;
      this.task.docFiles.push(docFile);
    });
  };

  isMergeVisible = () => {
    if (!this.task.loanDocId || !_.isArray(this.task.docFiles)) {
      return false;
    }
    const actionFiles = this.task.docFiles.filter((x) => x.selectedForAction == true);
    if (actionFiles.length < 2) {
      return false;
    }
    return !actionFiles.some(x => !x.guid);
  };

  isMergeDocInfoTextVisible = () => {
    return this.task.docFiles?.filter((x) => x.selectedForAction == true).length > 1 && !this.isMergeVisible();
  }

  isValid = () => {
    if (this.task.taskStatus == 'ConditionImportPending') {
      return !!this.task.documentTypeId;
    } else {
      return true;
    }
  };

  saveTask = () => {
    if (!this.task.documentTypeId) {
      this.task.documentTypeId = null;
    }
    if (
      (!this.task.reviewPartyId || this.task.reviewPartyId == '') &&
      this.task.requiresReview
    ) {
      return this._notifyService.showError(
        'You must select a Review Party',
        'Error'
      );
    }

    if (
      (!this.task.loanDocTaskId || this.task.loanDocTaskId == 0) &&
      !this.task.multipleBorrower &&
      this.task.requestBorrower
    ) {
      return this._notifyService.showError(
        'You must assign a Borrower to this task.',
        'Error'
      );
    }

    if (this.task.notifyPartyId == '') {
      this.task.notifyPartyId = null;
    }

    if (
      (this.task.reviewPartyId && this.task.reviewPartyId == '') ||
      !this.task.requiresReview
    ) {
      this.task.reviewPartyId = null;
      this.task.requiresReview = false;
    }

    if (this.task.loanDocTaskId != 0) {
      //TODO - what is this twisted logic? Why are we getting the task back from the database???
      this._spinner.show();
      this._taskService.getTaskDashboardViewById(this.task.loanDocTaskId)
        .subscribe({
          next: (response) => {
            this.task.loanDocId = response.loanDocId;
            if (this.task.followUpDate != response.followUpDate) {
              this.setFollowUpDate();
            }

            this.createOrUpdateTaskWithUploadedFiles(
              this.task,
              this.uploadedFiles,
            );
          },
          error: (error) => {
            this._spinner.hide();
            this._notifyService.showError(
              error && error.error
                ? error.error.message
                : 'An error occurred while saving the task.',
              'Error!'
            );
          }
        });
    } else {
      this.setFollowUpDate();
      this.createOrUpdateTaskWithUploadedFiles(this.task, this.uploadedFiles);
    }
  };

  addDays = (date: Date, days: number): Date => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  onDeleteFileClicked = (docFile: FileDto) => {
    docFile.confirmDelete = true;
  };

  onDeleteFileCancelClicked = (docFile: FileDto) => {
    docFile.confirmDelete = false;
  };

  onDeleteFileConfirmClicked = (docFile: FileDto) => {
    docFile.active = false;
  };

  removeFileFormLoanDoc = (docFiles: DocFile[], index: number) => {
    let file = docFiles[index];
    if (file != null) {
      if (file.guid == null) {
        docFiles.splice(index, 1);
      } else {
        file.active = false;
      }
    }
  };

  onOpenLoanDocClicked = (docFile: FileDto) => {
    if (!docFile.guid && docFile.file) {
      const reader = new FileReader();
      reader.readAsArrayBuffer(docFile.file);
      reader.onload = (event) => {
        const blob = new Blob([reader.result], { type: docFile.file.type });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      };
      return;
    }
    this._spinner.show();
    this._loanDocsService.viewLoanDocContent(docFile.guid).subscribe(
      (data) => {
        this._spinner.hide();
        const blob = new Blob([data], { type: (data as any).type });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      },
      (error) => {
        this._spinner.hide();
      }
    );
  };

  // E-Sign

  onDownloadESignSentDocumentClicked = () => {
    this._spinner.show();
    const mimeType = "application/pdf";
    this._loanDocsService.getSigningOrderCombinedContent(this.task.loanDocTaskId)
      .subscribe({
        next: (result) => {
          this._spinner.hide();
          const blob = new Blob([result], { type: mimeType });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        }, error: (err) => {
          this._spinner.hide();
          this._notifyService.showError(err.message || 'Error Getting eSign Document!', 'Failure');
        }
      });
  }

  onRefreshESignOrderClicked = () => {
    this._spinner.show();
    this.refreshingEsignOrder = true;
    this._loanDocsService.refreshSigningOrder(this.eSignOrder, this.task.loanDocTaskId)
      .pipe(finalize(() => {
        this._spinner.hide();
        this.refreshingEsignOrder = false;
      }))
      .subscribe({
        next: (result) => {
          this.eSignOrder.thirdPartyOrderId = result.thirdPartyOrderId || null;
          this.eSignOrder.status = result.status || null;
          this._notifyService.showSuccess('Esign Order Refreshed Successfully!', 'Success');
          this.isRefreshed = true;
          this.taskRefreshed.emit(this.isRefreshed);
        },
        error: (err) => {
          this._notifyService.showError(err.message || 'Error Refreshing Order!', 'Failure');
        }
      });
  }

  onCorrectESignOrderClicked = () => {
    this._spinner.show();
    this.refreshingEsignOrder = true;

    this.openDocumentSenderView({ documentSigningOrderId: this.eSignOrder.documentSigningOrderId, documentSigningVendor: this.eSignOrder.documentSigningVendor, isCorrectView: true, isEditView: false });

    this.refreshingEsignOrder = false;
    this._spinner.hide();
  }

  onEditESignOrderClicked = () => {
    this._spinner.show();
    this.refreshingEsignOrder = true;

    this.openDocumentSenderView({ documentSigningOrderId: this.eSignOrder.documentSigningOrderId, documentSigningVendor: this.eSignOrder.documentSigningVendor, isCorrectView: false, isEditView: true });

    this.refreshingEsignOrder = false;
    this._spinner.hide();
  }

  onCancelESignOrderClicked = () => {
    let modalRef = this._modalService.open(EsignatureCancelOrderDialogComponent, {
      ...Constants.modalOptions.medium,
    });
    modalRef.result.then(
      (response) => {
        this._spinner.show();
        this._taskService.esignCancelOrder(this.task.loanDocTaskId, response.reason)
          .pipe(finalize(() => this._spinner.hide()))
          .subscribe({
            next: () => {
              this._notifyService.showSuccess('eSign order cancelled', 'Success');
              this.activeModal.close('save');
            },
            error: (err) => {
              this._notifyService.showError(
                err ? err.message : "Couldn't cancel eSign order.",
                'Error'
              );
            }
          });
        () => { }
      });
  }

  createLoanDocAndUploadFiles = () => {
    this.uploadingFiles = true;
    this._loanDocForEsignTask = new LoanDocESign(
      null,
      this.task.loanDocTaskId || 0,
      this._currentApplication.applicationId,
      this.task.documentTypeId,
      this.task.description,
      this.task.note,
      ''
    );

    if (this._loanDocForEsignTask.loanDocId) {
      this.uploadFiles();
      return;
    }

    let loanDoc = new LoanDoc(this._loanDocForEsignTask.applicationId);
    loanDoc.loanDocId = this._loanDocForEsignTask.loanDocId;
    loanDoc.documentTypeId = this._loanDocForEsignTask.documentTypeId;
    loanDoc.description = this._loanDocForEsignTask.description;
    loanDoc.note = this._loanDocForEsignTask.note;
    loanDoc.expirationDate = this._loanDocForEsignTask.expirationDate;
    this._loanDocsService.upsertLoanDoc(loanDoc).subscribe(
      (response) => {
        this._loanDocForEsignTask = Object.assign(
          this._loanDocForEsignTask,
          response
        );
        this.task.loanDocId = response.loanDocId;

        this.uploadFiles();
      },
      (err) => {
        this.uploadingFiles = false;
        this._notifyService.showError(
          err ? err.message || err : 'Save loan doc fail',
          'Failure'
        );
      }
    );
  };

  private isEsignTask = (task: LoanDocDashboardTask): boolean => {
    return (
      this.task.taskType == 'EsignDocument' || this.task.taskType == 'LosEsign'
    );
  };

  //~ E-Sign

  onDownloadDocumentClicked = (docFile: DocFile) => {
    this._spinner.show();
    this._loanDocService.getLoanDoc(docFile.loanDocId).subscribe(
      (result) => {
        if (result && result.docFiles.length > 0) {
          let file = result.docFiles.find(
            (dfile) => dfile.guid == docFile.guid
          );
          let fileGuid = file.guid;
          let mimeType = file.mimeType;
          this._loanDocService
            .downloadFile(fileGuid, mimeType)
            .subscribe((data) => {
              this._spinner.hide();
              const blob = new Blob([data], { type: mimeType });
              const url = window.URL.createObjectURL(blob);
              window.open(url);
            });
        } else {
          this._spinner.hide();
        }
      },
      (error) => {
        this._notifyService.showError(error.message, 'Error');
        this._spinner.hide();
      }
    );
  };

  requestDateChange = (date) => {
    let newDate = new Date(date)
    this.task.requestDate = newDate.toISOString();
  }

  upsertLoanDocTask = (data) => {
    this._spinner.show();
    this._taskService.upsertLoanDocTask(data).subscribe((responseTask) => {
      const newlyCreatedTask = data.loanDocTask.loanDocTaskId == 0;
      if (this.task.docFiles.length == 0) {
        this._spinner.hide();
        this._notifyService.showSuccess("Task has been successfully saved.",
          'Success!'
        );

        if (this.willBeSnoozed && this.snoozeDurationInMinutes) {
          this.updateSnoozeTask(() => {
            this.activeModal.close('save');
          });
        }
        else {
          this.activeModal.close('save');
        }
      } else {
        let promises = [];
        this.task.docFiles.forEach((file) => {
          if (file.guid == null) {
            let autoTransition =
              this._originalTask.taskStatus == 'ConditionImportPending'
                ? 'false'
                : newlyCreatedTask
                  ? 'false'
                  : 'true';
            file.loanDocId = responseTask.loanDocId
              ? responseTask.loanDocId
              : 0;
            promises.push(
              this._fileService.uploadFileAndSingleModel(
                [file.file],
                'api/File/UpsertFileFromTask/' +
                responseTask.loanDocTaskId +
                '?autoTransition=' +
                autoTransition +
                '&autoConvertToPdf=' +
                this._userPermissions.autoConvertToPdf +
                '&useDynamicCompression=' +
                this._userPermissions.useDynamicCompression,
                file
              )
            );
          } else if (file.guid != null && file.active == false) {
            promises.push(this._fileService.removeFile(file.guid));
          } else if (file.linkWithTask) {
            let autoTransition =
              this._originalTask.taskStatus == 'ConditionImportPending'
                ? 'false'
                : newlyCreatedTask
                  ? 'false'
                  : 'true';
            let loanDocTaskId =
              this.task.loanDocTaskId || responseTask.loanDocTaskId;
            promises.push(
              this._fileService.linkFileToTask(
                loanDocTaskId,
                file.guid,
                autoTransition
              )
            );
          }
        });
        if (promises.length === 0) {
          this._spinner.hide();
          this.activeModal.close('save');
          this._notifyService.showSuccess("Task has been successfully saved.",
            'Success!'
          );
          return;
        }
        this._spinner.show();
        forkJoin(promises).subscribe(
          (responses) => {
            let bug = 0;
            responses.forEach((response) => {
              if (response == false) {
                bug++;
              }
            });
            this._spinner.hide();
            this._notifyService.showSuccess("Task has been successfully saved.",
              'Success!'
            );

            if (this.willBeSnoozed && this.snoozeDurationInMinutes) {
              this.updateSnoozeTask(
                () => {
                  this.activeModal.close('save');
                }
              );
            }
            else {
              this.activeModal.close('save');
            }
          },
          (error) => {
            this._spinner.hide();
            this.activeModal.dismiss(this.isRefreshed);
            this._notifyService.showError(
              error && error.error
                ? error.error.message
                : 'An error occurred while saving the task.',
              'Error!'
            );
          }
        );
      }
    },
      (error) => {
        this._spinner.hide();
        this._notifyService.showError(
          error && error.error
            ? error.error.message
            : 'An error occurred while saving the task.',
          'Error!'
        );
      }
    );
  };

  updateSnoozeTask = (cb: Function) => {
    this._taskService.updateSnoozeDate(this.task.loanDocTaskId, this.snoozeDurationInMinutes).subscribe((response) => {
      this.task.snoozeDate = response.snoozeDate;
      this._notifyService.showSuccess('Task snoozed successfully.', 'Success!');
      cb();
    }, (err) => {
      this._notifyService.showError(err ? err.message || err : "An error occurred while snoozing tasks.", "Error!");
    })
  };

  getSnoozeDurationTime = (time: number): string => {
    return time < 60 ? time + " mins" : (Math.ceil(time / 60) + " hours");
  }

  checkPopulateDescription = () => {
    this.documentTypes.forEach((documentType) => {
      if (documentType.documentTypeId == this.task.documentTypeId)
        this.task.description = documentType.documentTypeName;
    });
  };

  onDocumentGenerated = () => {
    this.task.taskStatus = 'Completed';
    this.saveTask();
  }

  onDocumentGeneratedCanceled = () => {
    this.activeModal.dismiss();
  }

  onGenerateDocumentClicked = () => {
    this.startDocumentGeneration = true;

    const selfDialog = document.querySelector(".modal-dialog") as HTMLElement;
    if (!selfDialog) return;

    selfDialog.style.width = "50%";
    selfDialog.style.maxWidth = "50%";
  }

  requestBorrowerChanged = () => {
    this.populateStatusDropdown();
  };

  removeFileFromLoanDoc = (docFiles, index) => {
    var file = docFiles[index];
    if (file != undefined && file != null) {
      if (file.guid == null) {
        docFiles.splice(index, 1);
      } else {
        file.active = false;
      }
    }
  };

  onLinkExistingDocumentsClicked = () => {
    const modalRef = this._modalService.open(
      LinkExistingDocumentsDialogComponent,
      Constants.modalOptions.medium
    );
    modalRef.componentInstance.task = _.cloneDeep(this.task);
    modalRef.componentInstance.globalConfig = _.cloneDeep(this.applicationContext.globalConfig);

    modalRef.result.then((result) => {
      if (result && result != 'cancel') {
        this.task.docFiles = result.docFiles;
      }
    });
  };

  onConvertToPdfClicked = (file: FileDto) => {
    this._spinner.show();
    this._fileService.convertToPdf(file.guid).subscribe(
      (result) => {
        this._spinner.hide();
        this._notifyService.showSuccess('Converted file to PDF', 'Success!');
        const index = this.task.docFiles.indexOf(file);
        if (index >= 0) {
          this.task.docFiles[index].convertedToPdf = true;
        }
        file.fileName = result;
      },
      (err) => {
        this._spinner.hide();
        this._notifyService.showError(
          err ? (err.error ? err.error.message : '') : '',
          'Error'
        );
      }
    );
  };

  onMergeFilesClicked = () => {
    this._spinner.show();
    let docGuids = this.task.docFiles
      .filter((x) => x.selectedForAction == true)
      .map((x) => x.guid);
    this._loanDocsService
      .setMergeFiles(this.task.applicationId, this.task.loanDocId, docGuids)
      .subscribe(
        (result) => {
          this._spinner.hide();
          this.task.docFiles = result.docFiles;
          this._notifyService.showSuccess(
            'Successfully merged ' + docGuids.length + ' documents!',
            'Success'
          );
        },
        (err) => {
          this._spinner.hide();
          this._notifyService.showError(
            err.message || 'Error while merging documents!' + err
              ? err.error.message || err
              : '',
            'Failure'
          );
        }
      );
  };

  toggleFileSelected = (file: DocFile) => {
    file.selectedForAction = file.selectedForAction == true ? false : true;
  };

  signDocument = () => {
    const modalRef = this._modalService.open(
      EsignDocumentDialogComponent,
      Constants.modalOptions.xlarge
    );
    modalRef.componentInstance.loanDocTaskId = this.task.loanDocTaskId;
    modalRef.componentInstance.documentSigningVendor = this.eSignOrder?.documentSigningVendor;

    modalRef.result.then((result) => {
      this.activeModal.close();
    });
  };

  addOrUpdateTask = () => {
    if (this.task.taskType !== 'EsignDocument') {
      this.saveTask();
      return;
    }
  };

  onFileUploadChanged = (event) => {
    event.forEach((targetFile) => {
      const file = targetFile;
      let docFile = new FileDto();
      docFile.fileName = file.name;
      docFile.loanDocId = this.task.loanDocId;
      docFile.note = '';
      docFile.borrowerNote = '';
      docFile.guid = null;
      docFile.active = true;
      docFile.file = file;
      docFile.status = 0;
      this.task.docFiles.push(docFile);
    });

    if (this.uploadedFiles.length > 0) {
      this.uploadedFiles = [];
    }
  };

  dueDateChange = () => {
    if (this.task.requestDate && this.dueDate < this.task.requestDate) {
      this.dueDate = this.task.requestDate;
    } else if (this.dueDate < this.today) {
      this.dueDate = this.today;
    }
    const time = new Date(this.dueDate).getTime() - new Date(this.task.requestDate ? this.task.requestDate : this.today).getTime();

    this.task.dueDays = Math.ceil(time / (1000 * 3600 * 24));
    this.task.dueDate = new Date(this.dueDate).toISOString();
  }

  dueDaysChange = () => {
    if (isNaN(this.task.dueDays)) return;

    let newDate: any;
    if (this.task.requestDate) {
      newDate = new Date(this.task.requestDate);
    } else {
      newDate = new Date(this.today);
    }

    newDate.setDate(newDate.getDate() + Number(this.task.dueDays));
    this.dueDate = newDate.toISOString();
    this.task.dueDate = this.dueDate;
  }

  uploadFiles = () => {
    const promises = [];
    this.uploadingFiles = true;

    this.addUploadedFilesToLoanDocTask(this.task, this.uploadedFiles);
    this.task.docFiles.filter(docFile => docFile.active)
      .forEach((docFile) => {
        const promise =
          docFile.guid == null
            ? this._fileService.uploadFileAndSingleModel(
              [(<any>docFile).file],
              'api/File/UpsertFileFromLoanDoc/' +
              this._loanDocForEsignTask.loanDocId +
              '?autoConvertToPdf=' +
              this._userPermissions.autoConvertToPdf +
              '&useDynamicCompression=' +
              this._userPermissions.useDynamicCompression,
              docFile
            )
            : this._fileService.linkLoanDocToFile(
              this.task.loanDocId,
              docFile.guid
            );

        docFile.status = 0;

        promises.push(promise);
      });

    forkJoin(promises).subscribe(
      (responses) => {
        responses.forEach((response, index) => {
          this.task.docFiles[index] = Object.assign(
            this.task.docFiles[index],
            response
          );
          if (this.task.docFiles[index].active == false) {
            let indexDocFiles = this.task.docFiles.indexOf(
              this.task.docFiles[index]
            );
            this.task.docFiles.splice(indexDocFiles, 1);
          } else {
            this.task.docFiles[index].status = 1;
          }
        });
        this._notifyService.showSuccess(
          'Uploaded files in loan doc success',
          'Success'
        );
        this.openDocumentSenderView({ isCorrectView: false, isEditView: false });
        this.uploadingFiles = false;
      },
      (err) => {
        this._notifyService.showError(
          err ? err.error || err.message : 'Error(s) have been detected',
          'Failure'
        );
        this.uploadingFiles = false;
      }
    );
  };

  openDocumentSenderView = ({
    documentSigningOrderId = null,
    documentSigningVendor = DocumentSigningVendor.DocuSign,
    isCorrectView,
    isEditView
  }: {
    documentSigningOrderId?: number,
    documentSigningVendor?: DocumentSigningVendor,
    isCorrectView: boolean,
    isEditView: boolean
  }) => {
    const modalRef = this._modalService.open(
      DocumentSigningDialogComponent,
      Constants.modalOptions.xlarge
    );

    modalRef.componentInstance.appId = this.task.applicationId;
    modalRef.componentInstance.loanDocId = this.task.loanDocId;
    modalRef.componentInstance.documentSigningOrderId = documentSigningOrderId;
    modalRef.componentInstance.documentSigningVendor = documentSigningVendor;
    modalRef.componentInstance.isCorrectView = isCorrectView;
    modalRef.componentInstance.isEditView = isEditView;
    modalRef.componentInstance.recipientBorrowerContactIds = this.recipientBorrowerContactIds;
    modalRef.componentInstance.recipientInternalContactIds = this.recipientInternalContactIds;
    modalRef.componentInstance.recipientExternalContactIds = this.recipientExternalContactIds;
    modalRef.componentInstance.hasCondition = this.task.condition;

    modalRef.result.then((result) => {
      if (result === 'cancel') {
        return;
      }

      if (this.task.requiresReview) {
        result.loanDocTasks.forEach((loanDocTask) => {
          this._taskService.addReviewRequiredUser(loanDocTask.loanDocTaskId, this.task.reviewPartyId)
            .subscribe({
              next: (response) => {
                loanDocTask = response;
              },
              error: (err) => {
                this._notifyService.showError(err?.message || `Error setting review party on task: ${this.task.loanDocTaskId}.`, 'Error');
              }
            });
        });
      }

      if (this.task.borrowerFacingNote) {
        const req = {
          borrowerNote: this.task.borrowerFacingNote
        };
        result.loanDocTasks.forEach((loanDocTask) => {
          this._taskService.addTaskNote(loanDocTask.loanDocTaskId, req)
            .subscribe(() => { });
        });
      }

      const task = _.cloneDeep(this.task);
      this.task = Object.assign(this.task, result.loanDocTasks[0]);

      // keep data updated
      if (task.condition) {
        this.task.condition = task.condition;
        this.task.conditionId = task.conditionId;
        this.task.conditionType = task.conditionType;
        this.task.conditionText = task.conditionText;
      }
      this.fetchAssociatedAgent()
      this.populateDataForEsignTask();
    });
  };

  hasFilesForUploading = () => {
    return _.some(
      this.task.docFiles.filter((file) => file.confirmDelete !== true)
    );
  };


  snoozeTask = () => {
    if (this.snoozeDurationInMinutes) {
      this.willBeSnoozed = true;
    }
  }

  private setFollowUpDate = () => {
    if (!this.task.followUpDate) return;

    const followUpDate = new Date(this.task.followUpDate);
    const now = new Date();
    const date = new Date(followUpDate.getFullYear(), followUpDate.getMonth(), followUpDate.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());
    this.task.followUpDate = this.addDays(date, 1).toISOString();
  }

  private addUploadedFilesToLoanDocTask = (
    task: LoanDocDashboardTask,
    uploadedFiles: Array<File>
  ) => {
    let docFiles = [];
    uploadedFiles.forEach((file) => {
      docFiles.push({
        guid: null,
        file: file,
        fileName: file.name,
        loanDocId: task.loanDocId,
        note: '',
        borrowerNote: '',
        active: true,
      });
    });
    task.docFiles = task.docFiles.concat(docFiles);
  };

  private createOrUpdateTaskWithUploadedFiles = (
    task: LoanDocDashboardTask,
    uploadedFiles: File[],
  ) => {
    this.addUploadedFilesToLoanDocTask(task, uploadedFiles);

    const data = {
      loanDocTask: task,
      multipleBorrower: task.multipleBorrower,
      numFiles: task.docFiles.length,
      daysToPostpone: task.followUpDate
        ? moment(new Date(task.followUpDate), 'MM/DD/YYYY').diff(
          moment().startOf('day'),
          'days'
        )
        : 0,
    };
    this.upsertLoanDocTask(data);
  };

  private generateDisplayHtmlWithImages = (displayText: string, note) => {

    const regexp = new RegExp(
      /<img class="task-note-image" src="(([\w]|[^a-zA-Z0-9\">])+)/g
    );
    let results = regexp.exec(displayText);
    if (!results || results.length < 2) {
      note.content = displayText;
      return displayText;
    }

    this._utilityService.getImageFileContentAsBase64(results[1], (response) => {
      results[1] = response.changingThisBreaksApplicationSecurity;

      const displayHtml = displayText.replace(
        results[0],
        `<img class="note-image" src="${results[1]}`
      );

      let imageProcessingStatus = this.internalNoteImageProcessStatuses.get(note.uniqueId);
      if (imageProcessingStatus) {
        if (imageProcessingStatus.numberOfImagesLeftToProcess) {
          imageProcessingStatus.numberOfImagesLeftToProcess--;
          if (imageProcessingStatus.numberOfImagesLeftToProcess === 0) {
            imageProcessingStatus.processedContent = displayHtml;
            return;
          }
        }
      }
      this.generateDisplayHtmlWithImages(displayHtml, note);
    })
  };

  private replaceImagesWithLoaderIcons = (note): number => {

    const imageTags = note.content.match(/<img\s+[^>]*src="([^"]*)"[^>]*>/g);

    if (imageTags && imageTags.length) {
      imageTags.forEach(img => {
        note.content = note.content.replace(
          img,
          `<i class="fas fa-sync-alt fa-spin mx-1 mt-1" style="font-size: 1rem; margin-top: 4px; margin-bottom: 4px;"></i>`
        );
      });
      return imageTags.length;
    }
    return 0;
  };

  fetchAssociatedAgent() {
    if (!this.task.agentId) {
      return;
    }
    this._agentService.getAgent(this.task.agentId)
      .subscribe({
        next: (agent) => {
          if (!agent?.agent) {
            this.agentNameAndPortalAddress = '';
            return;
          }
          const agentFullName = `${agent.agent.firstName} ${agent.agent.lastName}`;
          this.agentNameAndPortalAddress = agent.portalUserName
            ? `: ${agentFullName}(${agent.portalUserName})`
            : agentFullName
        }, error: (err) => {
          this._notifyService.showError(err.message || "Error encountered when fetching agent info!", "Error!")
        }
      });
  }
}

export class TaskImageProcessStatus {
  processedContent: string;
  numberOfImagesLeftToProcess: number;
}
