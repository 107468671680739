<div *ngIf="!isLoading; else loading">

  <div class="row" *ngFor="let cdItem of customDataFiltered">
    <div class="col-md-6" *ngIf="cdItem.col1">
      <custom-field-editor [model]="cdItem.customData1" [editConfig]="cdItem.col1"
        [dataType]="cdItem.col1.fieldType" [isInClassicEditMode]="isInClassicEditMode"
        [templateType]="templateType" [labelAlignment]="labelAlignment">
      </custom-field-editor>
    </div>
    <div class="col-md-6" *ngIf="cdItem.col2">
      <custom-field-editor [model]="cdItem.customData2" [editConfig]="cdItem.col2"
        [dataType]="cdItem.col2.fieldType" [isInClassicEditMode]="isInClassicEditMode"
        [templateType]="templateType" [labelAlignment]="labelAlignment">
      </custom-field-editor>
    </div>
  </div>
</div>

<ng-template #loading>
  <loading-indicator loadingMessage="Loading..."></loading-indicator>
</ng-template>
