
<div id="container loan-activity">
  <img *ngIf="singleMentionTrackingUrl" [src]="singleMentionTrackingUrl" class="tracker-image" width="1"
    height="1" border="0">

  <ngx-spinner bdColor="rgba(51,51,51,0.3)" size="large" color="#fff" type="ball-scale-multiple" [fullScreen]="false"
    [name]="loanActivitySpinner">
    <p style="font-size: 20px; color: white">Please wait...</p>
  </ngx-spinner>
  <div class="d-flex justify-content-end" *ngIf="!isTpo && showBorrowerLink">
    <span class="spinner-border spinner-border-sm" role="status" *ngIf="primaryBorrowerLoading"></span>
    <span *ngIf="!primaryBorrowerLoading">
      <i class="fas fa-user user-icon"></i>
        <a [routerLink]="urlForLoan">
          Borrower:
          <span class="cursor">
            <em class="text-primary"> {{primaryBorrowerFullName}} </em>
          </span>
        </a>
    </span>
  </div>
  <div class="d-flex" *ngIf="showFilters">
    <div class="special-radio-btn-group flex-grow-1">
      <div class="container-fluid p-0">
        <div class="row pb-2">
          <div class="col-md-12 d-flex">
            <div class="btn-group btn-group-left p-1" role="group" style="display: block;">

              <input type="radio" class="btn-check" name="btnradio" id="all" [(ngModel)]="activityTab" value="All"
                autocomplete="off" checked>
              <label class="btn btn-outline-secondary mt-1" for="all"><i class="fa fa-home"></i> All</label>

              <input *ngIf="tabCounts['Lead'] > 0" type="radio" class="btn-check" name="btnradio" id="leadActivities"
                [(ngModel)]="activityTab" value="Lead" autocomplete="off">
              <label *ngIf="tabCounts['Lead'] > 0" class="btn btn-outline-secondary mt-1" for="leadActivities"><i
                  class="fa fa-flag"></i>
                Leads Activities</label>

              <input *ngIf="tabCounts['InternalMessage'] > 0" type="radio" class="btn-check" name="btnradio"
                id="internalMessages" [(ngModel)]="activityTab" value="InternalMessage" autocomplete="off">
              <label *ngIf="tabCounts['InternalMessage'] > 0" class="btn btn-outline-secondary mt-1"
                for="internalMessages"><i class="fa fa-comments"></i> Internal Messages</label>

              <input *ngIf="tabCounts['BorrowerMessage'] > 0" type="radio" class="btn-check" name="btnradio"
                id="borrowerMessages" [(ngModel)]="activityTab" value="BorrowerMessage" autocomplete="off">
              <label *ngIf="tabCounts['BorrowerMessage'] > 0" class="btn btn-outline-secondary mt-1"
                for="borrowerMessages"><i class="fa fa-comments"></i> Borrower Messages</label>

              <input *ngIf="tabCounts['DocumentFile'] > 0" type="radio" class="btn-check" name="btnradio" id="documents"
                [(ngModel)]="activityTab" value="DocumentFile" autocomplete="off">
              <label *ngIf="tabCounts['DocumentFile'] > 0" class="btn btn-outline-secondary mt-1" for="documents"><i
                  class="fa fa-paperclip"></i>
                Documents</label>

              <input *ngIf="tabCounts['LoanChangeStatus'] > 0" type="radio" class="btn-check" name="btnradio"
                id="loanStatuses" [(ngModel)]="activityTab" value="LoanChangeStatus" autocomplete="off">
              <label *ngIf="tabCounts['LoanChangeStatus'] > 0" class="btn btn-outline-secondary mt-1"
                for="loanStatuses"><i class="fa fa-arrows-alt-h"></i> Loan Statuses</label>

              <input *ngIf="tabCounts['Email'] > 0" type="radio" class="btn-check" name="btnradio" id="email"
                [(ngModel)]="activityTab" value="Email" autocomplete="off">
              <label *ngIf="tabCounts['Email'] > 0" class="btn btn-outline-secondary mt-1" for="email"><i
                  class="fa fa-envelope"></i>
                Email</label>

              <input *ngIf="tabCounts['Sms'] > 0" type="radio" class="btn-check" name="btnradio" id="sms"
                [(ngModel)]="activityTab" value="Sms" autocomplete="off">
              <label *ngIf="tabCounts['Sms'] > 0" class="btn btn-outline-secondary mt-1" for="sms"><i
                  class="las la-comments"></i>
                SMS</label>

              <input *ngIf="tabCounts['Voice'] > 0" type="radio" class="btn-check" name="btnradio" id="voice"
                [(ngModel)]="activityTab" value="Voice" autocomplete="off">
              <label *ngIf="tabCounts['Voice'] > 0" class="btn btn-outline-secondary mt-1" for="voice"><i
                  class="las la-phone"></i>
                Voice</label>

              <input *ngIf="tabCounts['Paused'] > 0" type="radio" class="btn-check" name="btnradio" id="paused"
                [(ngModel)]="activityTab" value="Paused" autocomplete="off">
              <label *ngIf="tabCounts['Paused'] > 0" class="btn btn-outline-secondary mt-1" for="paused"><i
                  class="fas fa-play"></i>
                Paused</label>

              <input *ngIf="tabCounts['LosMessage'] > 0" type="radio" class="btn-check" name="btnradio" id="losMessages"
                [(ngModel)]="activityTab" value="LosMessage" autocomplete="off">
              <label *ngIf="tabCounts['LosMessage'] > 0" class="btn btn-outline-secondary mt-1" for="losMessages"><i
                  class="fa fa-comments"></i>
                LOS Messages</label>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-center px-2" style="height: 100%">
    <div class="col-lg-12 card" style="height: 100%">
      <div class="analytic-dash-activity activities-list" data-simplebar>
        <div class="activity">
          <div class="activity-info" *ngFor="let log of activityLogs | sort : 'desc': 'dateCreated'"
            [ngSwitch]="log.category" [hidden]="(activityTab != 'All' && activityTabs[activityTab].indexOf(log.category) < 0) ||
            (showOnlyActivityLog && activityTab == 'InternalMessage')">

            <div class="icon-info-activity" *ngSwitchCase="'phoneSuccess'">
              <i class="las la-phone bg-soft-primary"></i>
            </div>
            <div class="icon-info-activity" *ngSwitchCase="'phoneAttempt'">
              <i class="las la-phone bg-soft-primary"></i>
            </div>
            <div class="icon-info-activity" *ngSwitchCase="'route'">
              <i class="las la-phone bg-soft-primary"></i>
            </div>
            <div class="icon-info-activity" *ngSwitchCase="'Voice'">
              <i class="las la-phone bg-soft-primary"></i>
            </div>
            <div class="icon-info-activity" *ngSwitchCase="'call'">
              <i class="las la-phone bg-soft-primary"></i>
            </div>
            <div class="icon-info-activity" *ngSwitchCase="'Call'">
              <i class="las la-phone bg-soft-primary"></i>
            </div>
            <div class="icon-info-activity" *ngSwitchCase="'Rvm'">
              <i class="las la-phone bg-soft-primary"></i>
            </div>
            <div class="icon-info-activity" *ngSwitchCase="'note'">
              <i class="las la-book bg-soft-primary"></i>
            </div>
            <div class="icon-info-activity" *ngSwitchCase="'sms'">
              <i class="las la-comments bg-soft-primary"></i>
            </div>
            <div class="icon-info-activity" *ngSwitchCase="'Sms'">
              <i class="las la-comments bg-soft-primary"></i>
            </div>
            <div class="icon-info-activity" *ngSwitchCase="'LoanChangeStatus'">
              <i class="las la-arrows-alt-h bg-soft-primary"></i>
            </div>
            <div class="icon-info-activity" *ngSwitchCase="'LeadChangeStatus'">
              <i class="las la-arrows-alt-h bg-soft-primary"></i>
            </div>
            <div class="icon-info-activity" *ngSwitchCase="'BorrowerMessage'">
              <i class="las la-comments bg-soft-primary"></i>
            </div>
            <div class="icon-info-activity" *ngSwitchCase="'InternalMessage'">
              <i class="las la-comments bg-soft-primary"></i>
            </div>
            <div class="icon-info-activity" *ngSwitchCase="'Lead'">
              <i class="las la-flag bg-soft-primary"></i>
            </div>
            <div class="icon-info-activity" *ngSwitchCase="'DocumentFile'">
              <i class="las la-paperclip bg-soft-primary"></i>
            </div>
            <div class="icon-info-activity" *ngSwitchCase="'Email'">
              <i class="las la-envelope bg-soft-primary"></i>
            </div>
            <div class="icon-info-activity" *ngSwitchCase="'email'">
              <i class="las la-envelope bg-soft-primary"></i>
            </div>
            <div class="icon-info-activity" *ngSwitchCase="'LosMessage'">
              <i class="las la-comments bg-soft-primary"></i>
            </div>
            <div class="icon-info-activity" *ngSwitchCase="'PauseEmailSms'">
              <i *ngIf="log.activityType === 'LoanPausedEmailSms'" class="las la-pause bg-soft-primary"></i>
              <i *ngIf="log.activityType === 'LoanUnpausedEmailSms'" class="las la-play bg-soft-primary"></i>
            </div>
            <div class="icon-info-activity" *ngSwitchDefault>
              <i class="las la-book bg-soft-primary"></i>
            </div>
            <div class="activity-info-text mt-2">
              <div class="d-flex justify-content-between">

                <div *ngSwitchCase="'phoneSuccess'" class="w-75">
                  <p class="text-muted mb-0 font-13 w-75">
                    A <strong>successful</strong> phonecall was made.
                  </p>
                  <p [hidden]="!log.note" class="text-muted mb-0 font-13 w-75">
                    <strong>Note: </strong><span>{{ log.note }}</span>
                  </p>
                </div>

                <div *ngSwitchCase="'phoneAttempt'" class="w-75">
                  <p class="text-muted mb-0 font-13 w-75">
                    A phonecall was <strong>attempted</strong>.
                  </p>
                  <p [hidden]="!log.note" class="text-muted mb-0 font-13 w-75">
                    <strong>Note: </strong><span>{{ log.note }}</span>
                  </p>
                </div>

                <p *ngSwitchCase="'route'" class="text-muted mb-0 font-13 w-75">
                  The lead was routed to <strong>{{ log.userName}}</strong> at
                  <strong>{{ log.dateInserted | date:'MMM d, y h:mm:ss a'}}'</strong>
                  {{ log.leadRouteType == 'Redistribution' ? ' via Redistribution ' : ' '
                  }}
                  {{ log.distributionMode == 'FreeForAll' ? '(Free For All)' : ''}}
                  {{ log.leadRouteName ? ' on route: ' + log.leadRouteName : '' }}
                  {{ '. Accepted - ' + ((log.dateUpdated || log.dateInserted) | date:'MMM
                  d, y
                  h:mm:ss a')}}
                </p>

                <div *ngSwitchCase="'note'" class="w-75">
                  <p class="text-muted mb-0 font-13 w-75">
                    A note was added to the account.
                  </p>
                  <p [hidden]="!log.note" class="text-muted mb-0 font-13 w-75">
                    <strong>Note: </strong><span>{{ log.note }}</span>
                  </p>
                </div>

                <div *ngSwitchCase="'Sms'" class="w-75">
                  <p class="text-muted mb-0 font-13 w-75">
                    An sms was sent to the
                    <ng-template [ngIf]="log.to" [ngIfElse]="SmsContent">
                      <span *ngFor="let to of log.to.split(','); trackBy: index">
                        <strong>{{ to }}</strong>
                      </span>
                      <span class="text-warning ms-1"><strong>{{log.status === 'Paused' ? '[Paused]' : ''}}</strong></span>
                    </ng-template>
                    <ng-template #SmsContent>
                      <strong>undefined</strong>
                    </ng-template>
                    from <strong>{{ log.from }}</strong>.
                  </p>
                  <p><strong>Message: </strong> {{ log.body | limitTo: 300 }}
                    <a class="btn btn-xs btn-soft-primary" href="javascript: void(0);"
                      (click)="showEmailSmsDetails(log)">
                      <i class="fa fa-eye"></i> View
                    </a>
                  </p>
                </div>

                <div *ngSwitchCase="'sms'" class="w-75">
                  <p class="text-muted mb-0 font-13 w-75">
                    An sms was sent to the
                    <ng-template [ngIf]="log.to" [ngIfElse]="smsContent">
                      <span *ngFor="let to of log.to.split(','); trackBy: index">
                        <strong>{{ to }}</strong>
                      </span>
                      <span class="text-warning ms-1"><strong>{{log.status === 'Paused' ? '[Paused]' : ''}}</strong></span>
                    </ng-template>
                    <ng-template #smsContent>
                      <strong>undefined</strong>
                    </ng-template>
                    from <strong>{{ log.from }}</strong>.
                  </p>
                  <p class="text-muted mb-0 font-13 w-75">
                    <strong>Message: </strong> {{ log.body | limitTo: 300 }}
                    <a class="btn btn-xs btn-soft-primary" href="javascript: void(0);"
                      (click)="showEmailSmsDetails(log)">
                      <i class="fa fa-eye"></i> View
                    </a>
                  </p>
                </div>

                <div *ngSwitchCase="'Call'" class="w-75">
                  <p [hidden]="log.recordHistory" class="text-muted mb-0 font-13 w-75">
                    A call was placed.
                  </p>
                  <p [hidden]="!log.recordHistory">A call was placed on
                    <strong>{{ log.recordHistory.dateInserted | date:'MMM d, y h:mm:ss
                      a'}}</strong> by
                    <strong>{{ log.userName }}</strong>
                    lasting <span>{{secondsToHms(log.recordHistory.durationInSeconds)}}.</span>
                  </p>
                  <p [hidden]="!log.recordHistory || log.recordHistory.callRecorded">
                    The call was not recorded.
                  </p>
                  <p [hidden]="!log.recordHistory || !log.recordHistory.callRecorded">
                    <a (click)="loadRecordingDetails(log.recordHistory.voiceHistoryId)"
                      class="btn btn-xs btn-soft-primary" href="javascript: void(0);">
                      <i class="fa fa-play-circle" aria-hidden="true"></i>&nbsp;
                      Play Recording
                    </a>
                    <a (click)="downloadRecordingDetails(log.recordHistory.voiceHistoryId)"
                      class="btn btn-xs btn-soft-primary" href="javascript: void(0);">
                      <i class="fa fa-download" aria-hidden="true"></i>&nbsp;
                      Download Recording
                    </a>
                  </p>
                </div>

                <div *ngSwitchCase="'call'" class="w-75">
                  <p [hidden]="log.recordHistory" class="text-muted mb-0 font-13 w-75">
                    A call was placed.
                  </p>
                  <p [hidden]="!log.recordHistory">A call was placed on
                    <strong>{{ log.recordHistory.dateInserted | date:'MMM d, y h:mm:ss
                      a'}}</strong> by
                    <strong>{{ log.userName }}</strong>
                    lasting <span>{{secondsToHms(log.recordHistory.durationInSeconds)}}.</span>
                  </p>
                  <p [hidden]="!log.recordHistory || log.recordHistory.callRecorded">
                    The call was not recorded.
                  </p>
                  <p [hidden]="!log.recordHistory || !log.recordHistory.callRecorded">
                    <a (click)="loadRecordingDetails(log.recordHistory.voiceHistoryId)"
                      class="btn btn-xs btn-soft-primary" href="javascript: void(0);">
                      <i class="fa fa-play-circle" aria-hidden="true"></i>&nbsp;
                      Play Recording
                    </a>
                    <a (click)="downloadRecordingDetails(log.recordHistory.voiceHistoryId)"
                      class="btn btn-xs btn-soft-primary" href="javascript: void(0);">
                      <i class="fa fa-download" aria-hidden="true"></i>&nbsp;
                      Download Recording
                    </a>
                  </p>
                </div>

                <p *ngSwitchCase="'LoanChangeStatus'" class="text-muted mb-0 font-13 w-75">
                  <span>Loan Status</span> changed to
                  <span>{{ log.displayText}}</span>
                  by
                  <span>{{ log.userName || 'Unknown' }}</span>.
                </p>

                <div *ngSwitchCase="'DocumentFile'" class="w-75">
                  <p *ngIf="loanFiles[log.fileId]" class="text-muted mb-0 font-13 w-75">
                    The file <span>{{ loanFiles[log.fileId].fileName }}</span>
                    was <span>{{ log.displayText }}</span>
                    by <span>{{ log.userName || 'Unknown' }}</span>.
                  </p>
                  <p *ngIf="loanFiles[log.fileId]">
                    <a class="btn btn-xs btn-soft-primary" href="javascript: void(0);"
                      (click)="viewDocument(loanFiles[log.fileId].loanDocId)">
                      <i class="fa fa-eye"></i> View
                    </a>
                    <a class="btn btn-xs btn-soft-primary ms-1" href="javascript: void(0);"
                      (click)="downloadDocument(loanFiles[log.fileId].loanDocId)">
                      <i class="fa fa-download"></i> Download
                    </a>
                  </p>
                  <p *ngIf="!loanFiles[log.fileId]" class="text-muted mb-0 font-13 w-75">
                    An unknown file with fileId <span>{{log.fileId}}</span> was
                    <span>{{ log.displayText }}</span> by
                    <span>{{ log.userName || 'Unknown' }}</span>.
                  </p>
                </div>

                <p *ngSwitchCase="'LeadChangeStatus'" class="text-muted mb-0 font-13 w-75">
                  <span>Lead Status</span> changed to
                  <span>{{ log.displayText}}</span>
                  by
                  <span>{{ log.userName }}</span>.
                </p>

                <p *ngSwitchCase="'Lead'" class="text-muted mb-0 font-13 w-75">
                  <ng-container *ngIf="log.activityType === 'LeadSms'">
                    A Lead SMS was sent: <br>
                    <span>{{ log.displayText}}</span>
                    by
                    <span>{{ log.userName }}</span>.
                  </ng-container>
                  <ng-container *ngIf="log.activityType !== 'LeadSms'">
                    <span>Lead Status</span> changed to
                    <span>{{ log.displayText}}</span>
                    by
                    <span>{{ log.userName }}</span>.
                  </ng-container>
                </p>

                <div *ngSwitchCase="'InternalMessage'" class="w-75">
                  <p class="text-muted mb-0 font-13 w-75">
                    Internal message
                  </p>
                  <p [hidden]="!log.displayText" class="mb-0 font-13 w-75" [innerHTML]="log.displayText | safeHtml">
                  </p>
                  <img [src]="log['mentionTrackingUrl']" *ngIf="log.mentionTrackingGuid" class="tracker-image" width="1"
                    height="1" border="0">
                  <div class="d-flex flex-wrap" *ngIf="log['hasMediaFiles']">
                    <!-- <img [src]="log['base64Img']" height="150" border="0" style="margin: 8px 0; cursor: pointer;"> -->
                    <div class="img-container" *ngFor="let mediaFile of log['mediaFiles']">
                      <i *ngIf="!mediaFile" class="d-block task-value fas fa-sync-alt fa-spin mx-1 mt-1"
                        style="font-size: 25px; margin-top: 4px; margin-bottom: 4px;"></i>
                      <ng-container *ngIf="mediaFile">
                        <img [src]="mediaFile" height="150" border="0" style="margin: 8px 0; cursor: pointer;" (click)="viewImage(mediaFile)">
                      </ng-container>
                    </div>
                  </div>
                  <small class="text-muted">- {{ log.userId === userId ? 'you' :
                    (log.userName || 'Unknown')
                    }}</small>
                </div>

                <div *ngSwitchCase="'BorrowerMessage'" class="w-75">
                  <p class="text-muted mb-0 font-13 w-75">
                    Borrower message from
                    <span>{{ log.userId === userId ? 'you' : (log.userName || 'Unknown')
                      }}</span>.
                  </p>
                  <p [hidden]="!log.displayText" class="text-muted mb-0 font-13 w-75">
                    {{ log.displayText }}
                  </p>
                  <small class="text-muted">{{log.dateCreated | date : 'short' }}</small>
                </div>

                <div *ngSwitchCase="'Email'" class="w-75">
                  An email was sent to the
                  <br />
                  <ng-template [ngIf]="log.to" [ngIfElse]="EmailContent">
                    <div *ngFor="let to of log.to.split(','); trackBy: index">
                      <strong>{{ to }}</strong>
                    </div>
                  </ng-template>
                  <ng-template #EmailContent>
                    <strong>undefined</strong>
                  </ng-template>
                  from <strong>{{ log.from }}</strong>
                  <br />
                  by <strong>{{ log.userName }}</strong>.
                  <p><strong>Subject: </strong> {{ log.subject | slice:0:300 }}
                    <span class="text-warning"><strong>{{log.status === 'Paused' ? '[Paused]' : ''}}</strong></span>
                  </p>
                  <p><a class="btn btn-xs btn-soft-primary" (click)="showEmailSmsDetails(log)"
                      href="javascript: void(0);">
                      <i class="fa fa-eye"></i> View
                    </a></p>
                </div>

                <div *ngSwitchCase="'email'" class="w-75">
                  An email was sent to the
                  <br />
                  <ng-template [ngIf]="log.to" [ngIfElse]="emailContent">
                    <div *ngFor="let to of log.to.split(','); trackBy: index">
                      <strong>{{ to }}</strong>
                    </div>
                  </ng-template>
                  <ng-template #emailContent>
                    <strong>undefined</strong>
                  </ng-template>
                  from <strong>{{ log.from }}</strong>
                  <br />
                  by
                  <strong>{{ log.userName }}</strong>.
                  <p><strong>Subject: </strong> {{ log.subject | slice:0:300 }}
                    <span class="text-warning"><strong>{{log.status === 'Paused' ? '[Paused]' : ''}}</strong></span>
                  </p>
                  <a class="btn btn-xs btn-soft-primary" (click)="showEmailSmsDetails(log)" href="javascript: void(0);">
                    <i class="fa fa-eye"></i> View
                  </a>
                </div>

                <div *ngSwitchCase="'PauseEmailSms'" class="w-75">
                  <p class="text-muted mb-0 font-13 w-75">
                    All Email/SMS <span>{{log.activityType === 'LoanPausedEmailSms' ?
                      'PAUSED' :
                      'UNPAUSED'}}</span> by <strong>{{ log.userId === userId ? 'you'
                      : (log.userName ||
                      'Unknown') }}</strong>.
                  </p>
                  <small class="text-muted">{{log.dateCreated | date : 'short' }}</small>
                </div>

                <div *ngSwitchCase="'LosMessage'" class="w-75">
                  <p class="text-muted mb-0 font-13 w-75">
                    LOS message from
                    <span>{{ log.userId === userId ? 'you' : (log.userName || 'Unknown')
                      }}</span>.
                  </p>
                  <p [hidden]="!log.displayText" class="text-muted mb-0 font-13 w-75">
                    {{ log.displayText }}
                  </p>
                  <small class="text-muted">{{log.dateCreated | date : 'short' }}</small>
                </div>

                <div *ngSwitchDefault class="w-75">
                  <p class="text-muted mb-0 font-13 w-75">
                    <span>{{ log.userId === userId ? 'you' : (log.userName || 'Unknown')
                      }}</span>.
                  </p>
                  <p [hidden]="!log.displayText" class="text-muted mb-0 font-13 w-75">
                    {{ log.displayText }}
                  </p>
                  <small class="text-muted">{{log.dateCreated | date : 'short' }}</small>
                </div>

                <div *ngSwitchCase="'Rvm'" class="w-75">
                  A ringless voicemail was sent to <br />
                  <div *ngIf="log.to">
                    <strong>{{ to }}</strong>
                  </div> from <strong>{{ log.from }}</strong> <br />
                  by <strong>{{ log.userName }}</strong>.
                </div>

                <span class="text-muted d-block"><i>{{log.dateCreated | date : 'short' }}</i></span>
              </div>
            </div>
          </div>
          <div *ngIf="showOnlyActivityLog && activityTab == 'InternalMessage'" class="chat-box-right">
            <div class="chat-body">
              <div class="chat-detail">
                <div class="media" *ngFor="let log of activityLogs | sort: 'asc':'dateCreated'"
                  [hidden]="log.category != 'InternalMessage'" id="{{log.messageId}}">
                  <div class="media-img" *ngIf="log.userId != userId">
                    <img [src]="log.avatarUrl"
                      class="rounded-circle thumb-md" />
                  </div>
                  <div class="media-body" [ngClass]="{'reverse': log.userId == userId}">
                    <div class="chat-msg">
                      <p [innerHTML]="log.displayText | safeHtml"></p>
                    </div>
                    <div class="chat-time">{{log.dateCreated | date: 'MMM d, y h:mm:ss a'}}
                      <span *ngIf="log.userId != userId">- from <strong>{{log.userName || 'Unknown'}}</strong></span>
                    </div>
                  </div>
                  <div class="reverse media-img" *ngIf="log.userId == userId">
                    <img [src]="log.avatarUrl"
                      class="rounded-circle thumb-md" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
<div class="rightnav-activity-container" style="bottom: 0px;">
  <div class="row align-items-end rightnav-indicator">
    <div class="col-md-12">
      <div id="loan-activity-internal-message" class="mb-3">
        <label class="form-label" for="internalMessage">Internal Message</label>
        <message-editor-with-mentions #messageEditor [mentionables]="usersThatCanBeMentioned" (enterHit)="onEnterHit()"
          (messageChanged)="onMessageChanged($event)">
        </message-editor-with-mentions>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 text-end mb-5">
      <div class="form-check form-check-inline text-end mx-2" *ngIf="isSendingWhenHitEnterConfigurable">
        <input class="form-check-input" type="checkbox" id="pressEnter" [(ngModel)]="sendWhenHitEnter">
        <label class="form-check-label" for="pressEnter">Press <strong> ENTER </strong> to
          send
        </label>
      </div>
      <button type="submit" class="btn btn-primary mx-2" (click)="submitNewInternalMessage()">
        Send
      </button>
    </div>
  </div>
</div>
