<div class="modal-header" *ngIf="isModal">
  <h5 class="modal-title"> Send {{sendEmail ? 'Email' : 'SMS'}} </h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div [ngClass]="{'modal-body': isModal}" class="modal-body mb-1" id="sendEmailSms">

  <ng-container *ngIf="!isShowOnlyEmail && !isShowOnlyMMS">
    <div style="padding-bottom: 10px;" *ngIf="applicationContext.userPermissions.smsAlertsEnabled">
      <a class="nav-link float-end" style="padding:0" (click)="sendEmail = !sendEmail;">Switch to {{sendEmail ? 'SMS' : 'Email'}}</a>
    </div>
    <hr class="hr-dashed">
  </ng-container>

  <send-email *ngIf="sendEmail"
    #emailSender
    [body]="body"
    [to]="to"
    [showBorrowerPicker]="showBorrowerPicker"
    [borrowers]="showBorrowerPicker ? borrowers : []"
    [loanId]="applicationId"
    [attachments]="attachments"
    [forSendingVideoEmailFromTemplate]="isVideo"
    [uploadedVideoBlob] = "uploadedVideoBlob"
    >
  </send-email>
  <div *ngIf="!sendEmail">
    <send-sms #smsSender [borrowers]="borrowers" [loanId]="applicationId" [attachments]="fileAttachments" [isShowOnlyMMS]="isShowOnlyMMS"></send-sms>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="onCancelClicked()" data-bs-dismiss="modal"> Cancel
  </button>
  <button type="button" class="btn btn-primary" (click)="onSendClicked()" data-bs-dismiss="modal"> Send
  </button>
</div>
