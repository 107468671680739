import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { ReviewDataComponent } from './components/review-data/review-data.component';
import { LoanCharacteristicsComponent } from './components/loan-characteristics/loan-characteristics.component';
import { BorrowerCharacteristicsComponent } from './components/borrower-characteristics/borrower-characteristics.component';
import { SubmissionComponent } from './components/submission/submission.component';
import { NewApplicationComponent } from './components/new-application/new-application.component';
import { FormsModule } from '@angular/forms';
import { NgWizardModule, NgWizardConfig, THEME } from 'ng-wizard';
import { ImportMismoComponent } from './components/upload-file/import-mismo/import-mismo.component';
import { ImportDuComponent } from './components/upload-file/import-du/import-du.component';
import { ImportFromLosComponent } from './components/upload-file/import-from-los/import-from-los.component';
import { StartNewAppComponent } from './components/upload-file/start-new-app/start-new-app.component';
import { NewApplicationService } from './services/new-application.service';
import { NewApplicationRoutingModule } from './new-application-routing.module';
import { SharedModule } from '../../shared/shared.module'
import { NewApplicationDialogComponent } from './components/new-application-dialog/new-application-dialog.component';
import { BranchService } from '../admin/company/services/branch.service';
import { ImportFannieComponent } from './components/upload-file/import-fannie/import-fannie.component';
import { MissingInformationComponent } from './components/missing-information/missing-information.component';
import { ReviewDataBorrowersComponent } from './components/review-data-borrowers/review-data-borrowers.component';
import { InternalContactsModule } from '../internal-contacts/internal-contacts.module';
import { AlignmentModule } from '../alignment/alignment.module';
import { RoleBasedInternalContactsModule } from './components/upload-file/role-based-internal-contacts/role-based-internal-contacts.module';
import { KeyDatesComponent } from './components/key-dates/key-dates.component';
import { PricingModule } from '../pricing/pricing.module';

const ngWizardConfig: NgWizardConfig = {
  theme: THEME.default
};

@NgModule({
  declarations: [
    UploadFileComponent,
    ReviewDataComponent,
    LoanCharacteristicsComponent,
    BorrowerCharacteristicsComponent,
    SubmissionComponent,
    NewApplicationComponent,
    MissingInformationComponent,
    ImportMismoComponent,
    ImportDuComponent,
    ImportFromLosComponent,
    StartNewAppComponent,
    NewApplicationDialogComponent,
    ImportFannieComponent,
    ReviewDataBorrowersComponent,
    KeyDatesComponent
  ],
  imports: [
    SharedModule,
    NewApplicationRoutingModule,
    CommonModule,
    FormsModule,
    InternalContactsModule,
    AlignmentModule,
    PricingModule,
    RoleBasedInternalContactsModule,
    NgWizardModule.forRoot(ngWizardConfig),
  ],
  exports: [
    NewApplicationComponent
  ],
  providers: [
    NewApplicationService,
    BranchService
  ]
})
export class NewApplicationModule { }
