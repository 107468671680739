import { GlobalConfig } from "src/app/models/config/global-config.model";
import { ContactListColumnDefinition } from "src/app/models/contact-list.model";
import { CustomField } from "src/app/models/expressions/custom-field.model";
import { ExpressionGroup } from "src/app/models/expressions/expression-group.model";
import { OperatorType } from "src/app/models/expressions/expression.model";
import { ChannelService } from "src/app/services/channel.service";
import { Constants } from "src/app/services/constants";
import { EnumerationService } from "src/app/services/enumeration-service";

export class ExpressionService {
    private _channelService: ChannelService;

    constructor(public globalConfig: GlobalConfig,
        private _enumService: EnumerationService
    ) {
        this._channelService = new ChannelService();
    }

    getExpressionHtml(expressionGroup: ExpressionGroup, expressionFields: ContactListColumnDefinition[]): string {
        let list = '';
        if (expressionGroup && expressionGroup.expressions) {
            expressionGroup.expressions.forEach((expression, expressionIndex) => {
                if (expressionIndex > 0 && expression.expressionTerms.length) {
                    list += '<div class="text-center">' + expressionGroup.conditionalOperator + '</div>'
                }
                expression.expressionTerms.forEach((expressionTerm, expressionTermIndex) => {
                    if (expressionTerm.virtualFieldName || expressionTerm.field) {
                        var field = null;
                        if (expressionTerm.virtualFieldName) {
                            let found = expressionFields.find(x => x.columnDefinition.name == expressionTerm.virtualFieldName);
                            field = found ? found.columnDefinition : null;
                        } else {
                            field = expressionTerm.field;
                        }
                        if (field) {
                            expressionTerm.field = {
                                name: field.name,
                                fieldPath: field.fieldPath || field.name,
                                fieldType: field.fieldType,
                                dataType: field.dataType
                            } as CustomField;
                        } else {
                            expressionTerm.field = new CustomField();
                        }
                    }

                    if (expressionTermIndex > 0 && expression.expressionTerms.length) {
                        list += '<span class="text-center">' + ' ' + expression.conditionalOperator + ' ' + '</span>'
                    }
                    if (expressionTermIndex === 0) {
                        list += '<span> ( </span>';
                    }

                    list += '<span>' + expressionTerm['field']['fieldPath'] + ' ' + this.fetchOperator(expressionTerm['operatorType']) + ' ' + this.fetchOperatorValues(expressionTerm['values'], expressionTerm['operatorType'], expressionTerm['field']['fieldType'], expressionTerm['field']['dataType']) + '</span>';
                    if (expressionTermIndex === expression.expressionTerms.length - 1) {
                        list += '<span> ) <br/> </span>';
                    }
                });
            });
        }
        return list;
    }

    private fetchOperator(operator: OperatorType): string {
        switch (operator) {
            case 'Equal':
                return '==';
            case 'NotEqual':
                return '!=';
            case 'GreaterThan':
                return '>';
            case 'GreaterThanOrEqual':
                return '>=';
            case 'LessThan':
                return '<';
            case 'LessThanOrEqual':
                return '<=';
            case 'In':
                return 'In';
            case 'NotIn':
                return 'Not In';
            case 'StartsWith':
                return 'Starts With';
            case 'EndsWith':
                return 'Ends With';
            case 'Contains':
                return 'Contains';
            case 'RegexMatch':
                return 'Regex Match';
            case 'IsEmpty':
                return 'Is Null Or Empty';
            case 'NotEmpty':
                return 'Not Null Or Empty';
            case 'Between':
                return 'Between';
            case 'xPreviousDays':
                return 'x Previous Days';
            case 'xNextDays':
                return 'x Next Days';
            case 'xPreviousHours':
                return 'x Previous Hours';
            case 'xNextHours':
                return 'x Next Hours';
            case 'Today':
                return "= \'Today\'";
            case 'IsAnniversary':
                return "= \'Is Anniversary\'";
            case 'ContainsFlag':
                return "Contains Flag";
            case 'NotContainsFlag':
                return "Not Contains Flag";
            case 'ArrayContainsAny':
                return "Contains Any";
            case 'ArrayContainsAll':
                return "Contains All";
            default:
                return 'Invalid Operator';
        }
    }

    fetchOperatorValues(values: string[], operator: string, fieldType: string, dataType: string): string | number | boolean {
        if (!values.length) {
            return '';
        }

        let valueToShow: string | number | boolean = '';
        switch (operator) {
            case 'Equal':
            case 'NotEqual':
            case 'GreaterThan':
            case 'GreaterThanOrEqual':
            case 'LessThan':
            case 'LessThanOrEqual':
            case 'StartsWith':
            case 'EndsWith':
            case 'Contains':
            case 'RegexMatch':
            case 'xPreviousDays':
            case 'xNextDays':
            case 'xPreviousHours':
            case 'xNextHours':
            case 'ContainsFlag':
            case 'NotContainsFlag':
                valueToShow = values[0];
                break;
            case 'IsEmpty':
            case 'NotEmpty':
            case 'Today':
            case 'IsAnniversary':
                valueToShow = '';
                break;
            case 'Between':
                valueToShow = values[0] + ' - ' + values[1]
                break;
            case 'In':
            case 'NotIn':
                valueToShow = values.join(',');
                break;
        }

        switch (fieldType) {
            case 'Rate':
                valueToShow = valueToShow !== '' ? Number(valueToShow) + '%' : '';
                break;
            case 'Currency':
                valueToShow = valueToShow !== '' ? '$' + valueToShow : '';
                break;
            case 'LoanType':
                valueToShow = '';
                values.forEach((expressionValue: string, index: number) => {
                    const matchingRecord = this.globalConfig.loanType.find(loanType => loanType.loanTypeId === Number(expressionValue));
                    if (matchingRecord) {
                        valueToShow += index > 0 ? ', ' + matchingRecord.loanTypeName : matchingRecord.loanTypeName;
                    } else {
                        valueToShow += index > 0 ? ', ' + expressionValue : expressionValue;
                    }
                });
                valueToShow = valueToShow ? "\'" + valueToShow + "\'" : '';
                break;
            case 'LoanPurpose':
                valueToShow = '';
                values.forEach((expressionValue: string, index: number) => {
                    const matchingRecord = this.globalConfig.loanPurpose.find(ele => ele.loanPurposeId === Number(expressionValue));
                    if (matchingRecord) {
                        valueToShow += index > 0 ? ', ' + matchingRecord.loanPurposeName : matchingRecord.loanPurposeName;
                    } else {
                        valueToShow += index > 0 ? ', ' + expressionValue : expressionValue;
                    }
                });
                valueToShow = valueToShow ? "\'" + valueToShow + "\'" : '';
                break;
            case 'LoanStatus':
                valueToShow = '';
                values.forEach((expressionValue: string, index: number) => {
                    const matchingRecord = this.globalConfig.loanStatus.find(ele => ele.loanStatusId === Number(expressionValue));
                    if (matchingRecord) {
                        valueToShow += index > 0 ? ', ' + matchingRecord.loanStatusName : matchingRecord.loanStatusName;
                    } else {
                        valueToShow += index > 0 ? ', ' + expressionValue : expressionValue;
                    }
                });
                valueToShow = valueToShow ? "\'" + valueToShow + "\'" : '';
                break;
            case 'ExternalCompany':
                valueToShow = '';
                values.forEach((expressionValue: string, index: number) => {
                    const matchingRecord = this.globalConfig.externalCompanies.find(ele => ele.externalCompanyId === Number(expressionValue));
                    if (matchingRecord) {
                        valueToShow += index > 0 ? ', ' + matchingRecord.name : matchingRecord.name;
                    } else {
                        valueToShow += index > 0 ? ', ' + expressionValue : expressionValue;
                    }
                });
                valueToShow = valueToShow ? "\'" + valueToShow + "\'" : '';
                break;
            case 'LeadStatus':
                valueToShow = '';
                values.forEach((expressionValue: string, index: number) => {
                    const matchingRecord = this.globalConfig.leadStatus.find(ele => ele.loanStatusId == Number(expressionValue));
                    if (matchingRecord) {
                        valueToShow += index > 0 ? ', ' + matchingRecord.loanStatusName : matchingRecord.loanStatusName;
                    } else {
                        valueToShow += index > 0 ? ', ' + expressionValue : expressionValue;
                    }
                });
                valueToShow = valueToShow ? "\'" + valueToShow + "\'" : '';
                break;
            case 'User':
                valueToShow = '';
                values.forEach((expressionValue: string, index: number) => {
                    const matchingRecord = this.globalConfig.users.find(ele => ele.userCompanyGuid == expressionValue);
                    if (matchingRecord) {
                        valueToShow += index > 0 ? ', ' + (matchingRecord.firstName + ' ' + matchingRecord.lastName) : (matchingRecord.firstName + ' ' + matchingRecord.lastName);
                    } else {
                        valueToShow += index > 0 ? ', ' + expressionValue : expressionValue;
                    }
                });
                valueToShow = valueToShow ? "\'" + valueToShow + "\'" : '';
                break;
            case 'Product':
                valueToShow = '';
                values.forEach((expressionValue: string, index: number) => {
                    const matchingRecord = this.globalConfig.product.find(ele => ele.productId == Number(expressionValue));
                    if (matchingRecord) {
                        valueToShow += index > 0 ? ', ' + matchingRecord.productName : matchingRecord.productName;
                    } else {
                        valueToShow += index > 0 ? ', ' + expressionValue : expressionValue;
                    }
                });
                valueToShow = valueToShow ? "\'" + valueToShow + "\'" : '';
                break;
            case 'Lender':
                valueToShow = '';
                values.forEach((expressionValue: string, index: number) => {
                    const matchingRecord = this.globalConfig.lender.find(ele => ele.lenderId == Number(expressionValue));
                    if (matchingRecord) {
                        valueToShow += index > 0 ? ', ' + matchingRecord.name : matchingRecord.name;
                    } else {
                        valueToShow += index > 0 ? ', ' + expressionValue : expressionValue;
                    }
                });
                valueToShow = valueToShow ? "\'" + valueToShow + "\'" : '';
                break;
            case 'LookupList':
                valueToShow = '';
                values.forEach((expressionValue: string, index: number) => {
                    const matchingRecord = this.globalConfig.lookup.find(ele => ele.id == Number(expressionValue));
                    if (matchingRecord) {
                        valueToShow += index > 0 ? ', ' + matchingRecord.lookupDescription : matchingRecord.lookupDescription;
                    } else {
                        valueToShow += index > 0 ? ', ' + expressionValue : expressionValue;
                    }
                });
                valueToShow = valueToShow ? "\'" + valueToShow + "\'" : '';
                break;
            case 'LeadCampaign':
                valueToShow = '';
                values.forEach((expressionValue: string, index: number) => {
                    const matchingRecord = this.globalConfig.leadCampaigns.find(ele => ele.leadCampaignId == Number(expressionValue));
                    if (matchingRecord) {
                        valueToShow += index > 0 ? ', ' + matchingRecord.name : matchingRecord.name;
                    } else {
                        valueToShow += index > 0 ? ', ' + expressionValue : expressionValue;
                    }
                });
                valueToShow = valueToShow ? "\'" + valueToShow + "\'" : '';
                break;
            case 'Branch':
                valueToShow = '';
                values.forEach((expressionValue: string, index: number) => {
                    const matchingRecord = this.globalConfig.branches.find(ele => ele.branchId == Number(expressionValue));
                    if (matchingRecord) {
                        valueToShow += index > 0 ? ', ' + matchingRecord.branchName : matchingRecord.branchName;
                    } else {
                        valueToShow += index > 0 ? ', ' + expressionValue : expressionValue;
                    }
                });
                valueToShow = valueToShow ? "\'" + valueToShow + "\'" : '';
                break;
            case 'LeadRoute':
                valueToShow = '';
                values.forEach((expressionValue: string, index: number) => {
                    const matchingRecord = this.globalConfig.lookupLeadRoutes
                        ? this.globalConfig.lookupLeadRoutes.find(ele => ele.value == expressionValue)
                        : '';
                    if (matchingRecord) {
                        valueToShow += index > 0 ? ', ' + matchingRecord.name : matchingRecord.name;
                    } else {
                        valueToShow += index > 0 ? ', ' + expressionValue : expressionValue;
                    }
                });
                valueToShow = valueToShow ? "\'" + valueToShow + "\'" : '';
                break;
            case 'LeadRouteGroup':
                valueToShow = '';
                values.forEach((expressionValue: string, index: number) => {
                    const matchingRecord = this.globalConfig.lookupLeadRouteGroups
                        ? this.globalConfig.lookupLeadRouteGroups.find(ele => ele.value == expressionValue)
                        : '';
                    if (matchingRecord) {
                        valueToShow += index > 0 ? ', ' + matchingRecord.name : matchingRecord.name;
                    } else {
                        valueToShow += index > 0 ? ', ' + expressionValue : expressionValue;
                    }
                });
                valueToShow = valueToShow ? "\'" + valueToShow + "\'" : '';
                break;
            case 'RedistributionRoute':
                valueToShow = '';
                values.forEach((expressionValue: string, index: number) => {
                    const matchingRecord = this.globalConfig.lookupRedistributionRoutes
                        ? this.globalConfig.lookupRedistributionRoutes.find(ele => ele.value == expressionValue)
                        : '';
                    if (matchingRecord) {
                        valueToShow += index > 0 ? ', ' + matchingRecord.name : matchingRecord.name;
                    } else {
                        valueToShow += index > 0 ? ', ' + expressionValue : expressionValue;
                    }
                });
                valueToShow = valueToShow ? "\'" + valueToShow + "\'" : '';
                break;
            case 'RedistributionRouteGroup':
                valueToShow = '';
                values.forEach((expressionValue: string, index: number) => {
                    const matchingRecord = this.globalConfig.lookupRedistributionRouteGroups
                        ? this.globalConfig.lookupRedistributionRouteGroups.find(ele => ele.value == expressionValue)
                        : '';
                    if (matchingRecord) {
                        valueToShow += index > 0 ? ', ' + matchingRecord.name : matchingRecord.name;
                    } else {
                        valueToShow += index > 0 ? ', ' + expressionValue : expressionValue;
                    }
                });
                valueToShow = valueToShow ? "\'" + valueToShow + "\'" : '';
                break;
            case 'SubStatus':
                valueToShow = '';
                values.forEach((expressionValue: string, index: number) => {
                    const matchingRecord = this.globalConfig.subStatus
                        ? this.globalConfig.subStatus.find(ele => ele.subStatusId == Number(expressionValue))
                        : '';
                    if (matchingRecord) {
                        valueToShow += index > 0 ? ', ' + matchingRecord.subStatusName : matchingRecord.subStatusName;
                    } else {
                        valueToShow += index > 0 ? ', ' + expressionValue : expressionValue;
                    }
                });
                valueToShow = valueToShow ? "\'" + valueToShow + "\'" : '';
                break;
            case 'Channel':
                valueToShow = '';
                values.forEach((expressionValue: string, index: number) => {
                    let channelValue = expressionValue;
                    // backwards compatybility
                    if (isNaN(expressionValue as any)) {
                        channelValue = this._channelService.getChannelsNumber([expressionValue]) + "";
                    }
                    const matchingRecord = this.globalConfig.enabledChannels
                        ? this.globalConfig.enabledChannels.find(channel => channel.value === Number(channelValue))
                        : null;
                    if (matchingRecord) {
                        valueToShow += index > 0 ? ', ' + matchingRecord.name : matchingRecord.name;
                    } else {
                        let displayValue = expressionValue;
                        // backwards compatybility
                        if (isNaN(expressionValue as any)) {
                            displayValue = this._channelService.getChannels(parseInt(expressionValue))[0]?.value
                        }
                        valueToShow += index > 0 ? ', ' + displayValue : displayValue;
                    }
                });
                valueToShow = valueToShow ? "\'" + valueToShow + "\'" : '';
                break;
            case 'CompanyStatus':
                valueToShow = '';
                values.forEach((expressionValue: string, index: number) => {
                    const matchingRecord = this.globalConfig.companyStatuses
                        .find(status => status.value === Number(expressionValue));
                    if (matchingRecord) {
                        valueToShow += index > 0 ? ', ' + matchingRecord.name : matchingRecord.name;
                    } else {
                        valueToShow += index > 0 ? ', ' + expressionValue : expressionValue;
                    }
                });
                valueToShow = valueToShow ? "\'" + valueToShow + "\'" : '';
                break;
            case 'MortgageEntityType':
                valueToShow = '';
                values.forEach((expressionValue: string, index: number) => {
                    const matchingRecord = this.globalConfig.mortgageEntityTypes
                        .find(status => status.value == expressionValue);
                    if (matchingRecord) {
                        valueToShow += index > 0 ? ', ' + matchingRecord.name : matchingRecord.name;
                    } else {
                        valueToShow += index > 0 ? ', ' + expressionValue : expressionValue;
                    }
                });
                valueToShow = valueToShow ? "\'" + valueToShow + "\'" : '';
                break;
            case 'MortgagePartyType':
                valueToShow = '';
                values.forEach((expressionValue: string, index: number) => {
                    const matchingRecord = this.globalConfig.mortgagePartyTypes
                        .find(status => status.value == expressionValue);
                    if (matchingRecord) {
                        valueToShow += index > 0 ? ', ' + matchingRecord.name : matchingRecord.name;
                    } else {
                        valueToShow += index > 0 ? ', ' + expressionValue : expressionValue;
                    }
                });
                valueToShow = valueToShow ? "\'" + valueToShow + "\'" : '';
                break;
            case 'ApplicationCopyReason':
                valueToShow = '';
                values.forEach((expressionValue: string, index: number) => {
                    const matchingRecord = this.globalConfig.applicationCopyReasons
                        .find(status => status.value == expressionValue);
                    if (matchingRecord) {
                        valueToShow += index > 0 ? ', ' + matchingRecord.name : matchingRecord.name;
                    } else {
                        valueToShow += index > 0 ? ', ' + expressionValue : expressionValue;
                    }
                });
                valueToShow = valueToShow ? "\'" + valueToShow + "\'" : '';
                break;
            case 'AgentTags':
                valueToShow = '';
                values.forEach((expressionValue: string, index: number) => {
                    const matchingRecord = this.globalConfig.agentList
                        .find(status => status.agentListId === Number(expressionValue));
                    if (matchingRecord) {
                        valueToShow += index > 0 ? ', ' + matchingRecord.name : matchingRecord.name;
                    } else {
                        valueToShow += index > 0 ? ', ' + expressionValue : expressionValue;
                    }
                });
                valueToShow = valueToShow ? "\'" + valueToShow + "\'" : '';
                break;
            case 'ContactTrackingTag':
                valueToShow = '';
                values.forEach((expressionValue: string, index: number) => {
                    const matchingRecord = this.globalConfig.tags
                        .find(t => t.tagId === Number(expressionValue));
                    if (matchingRecord) {
                        valueToShow += index > 0 ? ', ' + matchingRecord.name : matchingRecord.name;
                    } else {
                        valueToShow += index > 0 ? ', ' + expressionValue : expressionValue;
                    }
                });
                valueToShow = valueToShow ? "\'" + valueToShow + "\'" : '';
                break;
            case 'CompensationType':
                valueToShow = '';
                values.forEach((expressionValue: string, index: number) => {
                    const matchingRecord = this.globalConfig.compensationTypes
                        .find(ct => ct.value === Number(expressionValue));
                    if (matchingRecord) {
                        valueToShow += index > 0 ? ', ' + matchingRecord.name : matchingRecord.name;
                    } else {
                        valueToShow += index > 0 ? ', ' + expressionValue : expressionValue;
                    }
                });
                valueToShow = valueToShow ? "\'" + valueToShow + "\'" : '';
                break;
            case 'DisclosurePath':
                valueToShow = '';
                values.forEach((expressionValue: string, index: number) => {
                    const matchingRecord = this.globalConfig.disclosurePaths
                        .find(ct => ct.value === Number(expressionValue));
                    if (matchingRecord) {
                        valueToShow += index > 0 ? ', ' + matchingRecord.name : matchingRecord.name;
                    } else {
                        valueToShow += index > 0 ? ', ' + expressionValue : expressionValue;
                    }
                });
                valueToShow = valueToShow ? "\'" + valueToShow + "\'" : '';
                break;
            case 'None':
            case 'ChannelFlags':
            case 'Email':
            case 'MobilePhone':
            case 'LandLinePhone':
            case 'FaxPhone':
            case 'TimeZone':
                valueToShow = valueToShow !== '' ? "\'" + valueToShow + "\'" : '';
                break;
            default:
                valueToShow = ''
        }

        if (dataType === 'Boolean') {
            valueToShow = valueToShow.replaceAll('\'', '').replaceAll('\"', '');
            valueToShow = !!Number(valueToShow);
        }
        return valueToShow;
    }
}
