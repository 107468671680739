<div class="accordion" *ngIf="definedEmailTemplate">
  <div class="accordion-item">
    <h5 class="accordion-header m-0">
      <button class="accordion-button fw-semibold" type="button" data-bs-toggle="collapse"
        [ngClass]="{ collapsed: !collapsibleSectionVisibilityStatuses.inviteEmail }"
        (click)="onEmailTemplateEditorVisibilityChanged('inviteEmail')" data-bs-target="#invite-override"
        [attr.aria-expanded]="collapsibleSectionVisibilityStatuses.inviteEmail" aria-controls="collapse1">
        Borrower Invite Template<span *ngIf="definedEmailTemplate.inviteOverride === true" class="text-success ms-1">-
          (Overridden)</span>
      </button>
    </h5>
    <div id="invite-override" class="accordion-collapse collapse"
      [ngClass]="{ show: collapsibleSectionVisibilityStatuses.inviteEmail }">
      <div class="accordion-body">
        <div class="form-group mb-3 row">
          <div class="col-sm-2"></div>
          <div class="col-sm-10">
            <div class="form-check form-check-inline">
              <input id="inviteOverride" class="form-check-input" type="checkbox"
                (change)="populateDefaultEmailTemplate(definedEmailTemplate.inviteOverride, 'BorrowerInvite', 'inviteSubject', 'inviteEmail')"
                [(ngModel)]="definedEmailTemplate.inviteOverride">
              <label class="form-check-label" for="inviteOverride">Override Borrower Invite Template</label>
            </div>
          </div>
        </div>
        <ng-container *ngIf="definedEmailTemplate.inviteOverride">
          <div class="form-group mb-3 row">
            <div class="col-sm-2"></div>
            <div class="col-sm-10">
              <div class="form-check form-check-inline">
                <input id="inviteIncludeEmailSignature" class="form-check-input" type="checkbox"
                  [(ngModel)]="definedEmailTemplate.inviteIncludeEmailSignature">
                <label class="form-check-label" for="inviteIncludeEmailSignature">Include Email Signature </label>
              </div>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">CC</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="CC" type="text" [(ngModel)]="definedEmailTemplate.inviteCC">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">BCC</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="BCC" type="text" [(ngModel)]="definedEmailTemplate.inviteBCC">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Subject</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="Subject" type="text"
                [(ngModel)]="definedEmailTemplate.inviteSubject"
                (contextmenu)="onContextMenu($event, definedEmailTemplate, 'inviteSubject')">
            </div>
          </div>
          <hr>
          <div class="form-group mb-3 row">
            <div class="col-sm-12 form-label align-self-center mb-1 d-flex justify-content-between">
              <div class="d-flex">
                <div class="special-radio-btn-group flex-grow-1">
                  <div class="btn-group">
                    <button type="button" class="btn btn-outline-primary tippy-btn mb-xs-1 me-1"
                      (click)="onEditTemplateClicked(definedEmailTemplate.inviteEmail, 'inviteEmail', definedEmailTemplate.inviteUsingStripoEditor)">
                      <div class="d-flex">
                        <span class="fw-bolder button-item-text flex-grow-1">
                          <i class="fa fa-pencil-alt"></i>
                        </span>
                        <span class="fw-bolder button-item-text filter-label flex-grow-1 ms-1">
                          Edit
                        </span>
                      </div>
                    </button>
                    <button type="button" class="btn btn-outline-primary tippy-btn mb-xs-1"
                      (click)="populateDefaultEmailTemplate(definedEmailTemplate.inviteOverride, 'BorrowerInvite', 'inviteSubject', 'inviteEmail', true)">
                      <div class="d-flex">
                        <span class="fw-bolder button-item-text flex-grow-1">
                          <i class="fas fa-redo-alt fa-rotate-180"></i>
                        </span>
                        <span class="fw-bolder button-item-text filter-label flex-grow-1 ms-1">
                          Reload Default
                        </span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <iframe style="width:100%; height: 700px; border:1px solid #e3ebf6; border-radius: 0.25rem;"
                id="inviteEmailIframe">
              </iframe>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h5 class="accordion-header m-0">
      <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse"
        [ngClass]="{ collapsed: !collapsibleSectionVisibilityStatuses.inviteAgentEmail }"
        (click)="onEmailTemplateEditorVisibilityChanged('inviteAgentEmail')"
        data-bs-target="#invite-agent-override" [attr.aria-expanded]="collapsibleSectionVisibilityStatuses.inviteAgentEmail"
        aria-expanded="true" aria-controls="collapse1">
        Agent Invite Template <span *ngIf="definedEmailTemplate.inviteAgentOverride === true"
          class="text-success ms-1">- (Overridden)</span>
      </button>
    </h5>
    <div id="invite-agent-override" class="accordion-collapse collapse">
      <div class="accordion-body">
        <div class="form-group mb-3 row">
          <div class="col-sm-2"></div>
          <div class="col-sm-10">
            <div class="form-check form-check-inline">
              <input class="form-check-input" id="inviteAgentOverride" type="checkbox"
                [(ngModel)]="definedEmailTemplate.inviteAgentOverride"
                (change)="populateDefaultEmailTemplate(definedEmailTemplate.inviteAgentOverride, 'AgentInvite', 'inviteAgentSubject', 'inviteAgentEmail')">
              <label class="form-check-label" for="inviteAgentOverride">Override Agent Invite Template</label>
            </div>
          </div>
        </div>
        <ng-container *ngIf="definedEmailTemplate.inviteAgentOverride">
          <div class="form-group mb-3 row">
            <div class="col-sm-2"></div>
            <div class="col-sm-10">
              <div class="form-check form-check-inline">
                <input id="inviteAgentIncludeEmailSignature" class="form-check-input" type="checkbox"
                  [(ngModel)]="definedEmailTemplate.inviteAgentIncludeEmailSignature">
                <label class="form-check-label" for="inviteAgentIncludeEmailSignature">Include Email Signature </label>
              </div>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">CC</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="CC" type="text" [(ngModel)]="definedEmailTemplate.inviteAgentCC">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">BCC</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="BCC" type="text"
                [(ngModel)]="definedEmailTemplate.inviteAgentBCC">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Subject</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="Subject" type="text"
                [(ngModel)]="definedEmailTemplate.inviteAgentSubject"
                (contextmenu)="onContextMenu($event, definedEmailTemplate, 'inviteAgentSubject')">
            </div>
          </div>
          <hr>
          <div class="form-group mb-3 row">
            <div class="col-sm-12 form-label align-self-center mb-1 d-flex justify-content-between">
              <div class="d-flex">
                <div class="special-radio-btn-group flex-grow-1">
                  <div class="btn-group">
                    <button type="button" class="btn btn-outline-primary tippy-btn mb-xs-1 me-1"
                      (click)="onEditTemplateClicked(definedEmailTemplate.inviteAgentEmail, 'inviteAgentEmail', definedEmailTemplate.inviteAgentUsingStripoEditor)">
                      <div class="d-flex">
                        <span class="fw-bolder button-item-text flex-grow-1">
                          <i class="fa fa-pencil-alt"></i>
                        </span>
                        <span class="fw-bolder button-item-text filter-label flex-grow-1 ms-1">
                          Edit
                        </span>
                      </div>
                    </button>
                    <button type="button" class="btn btn-outline-primary tippy-btn mb-xs-1"
                      (click)="populateDefaultEmailTemplate(definedEmailTemplate.inviteAgentOverride, 'AgentInvite', 'inviteAgentSubject', 'inviteAgentEmail', true)">
                      <div class="d-flex">
                        <span class="fw-bolder button-item-text flex-grow-1">
                          <i class="fas fa-redo-alt fa-rotate-180"></i>
                        </span>
                        <span class="fw-bolder button-item-text filter-label flex-grow-1 ms-1">
                          Reload Default
                        </span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <iframe style="width:100%; height: 700px; border:1px solid #e3ebf6; border-radius: 0.25rem;"
                id="inviteAgentEmailIframe"></iframe>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h5 class="accordion-header m-0">
      <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse"
        [ngClass]="{ collapsed: !collapsibleSectionVisibilityStatuses.requestEmail }"
        (click)="onEmailTemplateEditorVisibilityChanged('requestEmail')"
        [attr.aria-expanded]="collapsibleSectionVisibilityStatuses.requestEmail"
        data-bs-target="#request-override" aria-expanded="true" aria-controls="collapse1">
        Task Request / Status Update Template <span *ngIf="definedEmailTemplate.requestOverride === true"
          class="text-success ms-1">- (Overridden)</span>
      </button>
    </h5>
    <div id="request-override" class="accordion-collapse collapse">
      <div class="accordion-body">
        <div class="form-group mb-3 row">
          <div class="col-sm-2"></div>
          <div class="col-sm-10">
            <div class="form-check form-check-inline">
              <input class="form-check-input" id="requestOverride" type="checkbox"
                [(ngModel)]="definedEmailTemplate.requestOverride"
                (change)="populateDefaultEmailTemplate(definedEmailTemplate.requestOverride, 'TaskRequestStatusUpdate', 'requestSubject', 'requestEmail')">
              <label class="form-check-label" for="requestOverride">Override Request Template</label>
            </div>
          </div>
        </div>
        <ng-container *ngIf="definedEmailTemplate.requestOverride">
          <div class="form-group mb-3 row">
            <div class="col-sm-2"></div>
            <div class="col-sm-10">
              <div class="form-check form-check-inline">
                <input id="requestIncludeEmailSignature" class="form-check-input" type="checkbox"
                  [(ngModel)]="definedEmailTemplate.requestIncludeEmailSignature">
                <label class="form-check-label" for="requestIncludeEmailSignature">Include Email Signature </label>
              </div>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">CC</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="CC" type="text" [(ngModel)]="definedEmailTemplate.requestCC">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">BCC</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="BCC" type="text" [(ngModel)]="definedEmailTemplate.requestBCC">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">CC</label>
            <div class="col-sm-10">
              <ng-select width="100%" name="request-roles-cc" bindLabel="name" bindValue="value"
              placeholder="-- Select --" [closeOnSelect]="false" [multiple]="true" groupBy="groupName"
              [(ngModel)]="requestRolesCC" [items]="roles" appendTo="body"
              (ngModelChange)="onRoleMultiselectChanged('requestRolesCC')">
            </ng-select>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">BCC</label>
            <div class="col-sm-10">
              <ng-select width="100%" name="request-roles-bcc" bindLabel="name" bindValue="value"
              placeholder="-- Select --" [closeOnSelect]="false" [multiple]="true" groupBy="groupName"
              [(ngModel)]="requestRolesBCC" [items]="roles" appendTo="body"
              (ngModelChange)="onRoleMultiselectChanged('requestRolesBCC')">
            </ng-select>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Subject</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="Subject" type="text"
                [(ngModel)]="definedEmailTemplate.requestSubject"
                (contextmenu)="onContextMenu($event, definedEmailTemplate, 'requestSubject')">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <div class="col-sm-12 form-label align-self-center mb-1 d-flex justify-content-between">
              <div class="d-flex">
                <div class="special-radio-btn-group flex-grow-1">
                  <div class="btn-group">
                    <button type="button" class="btn btn-outline-primary tippy-btn mb-xs-1 me-1"
                      (click)="onEditTemplateClicked(definedEmailTemplate.requestEmail, 'requestEmail', definedEmailTemplate.requestUsingStripoEditor)">
                      <div class="d-flex">
                        <span class="fw-bolder button-item-text flex-grow-1">
                          <i class="fa fa-pencil-alt"></i>
                        </span>
                        <span class="fw-bolder button-item-text filter-label flex-grow-1 ms-1">
                          Edit
                        </span>
                      </div>
                    </button>
                    <button type="button" class="btn btn-outline-primary tippy-btn mb-xs-1"
                      (click)="populateDefaultEmailTemplate(definedEmailTemplate.requestOverride, 'TaskRequestStatusUpdate', 'requestSubject', 'requestEmail', true)">
                      <div class="d-flex">
                        <span class="fw-bolder button-item-text flex-grow-1">
                          <i class="fas fa-redo-alt fa-rotate-180"></i>
                        </span>
                        <span class="fw-bolder button-item-text filter-label flex-grow-1 ms-1">
                          Reload Default
                        </span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <iframe style="width:100%; height: 700px; border:1px solid #e3ebf6; border-radius: 0.25rem;"
                id="requestEmailIframe"></iframe>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h5 class="accordion-header m-0">
      <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse"
        [ngClass]="{ collapsed: !collapsibleSectionVisibilityStatuses.reminderEmail }"
        (click)="onEmailTemplateEditorVisibilityChanged('reminderEmail')"
        [attr.aria-expanded]="collapsibleSectionVisibilityStatuses.reminderEmail"
        data-bs-target="#reminder-email-enabled" aria-expanded="true" aria-controls="collapse1">
        Task Reminder Template <span *ngIf="definedEmailTemplate.reminderOverride === true" class="text-success ms-1">-
          (Overridden)</span>
      </button>
    </h5>
    <div id="reminder-email-enabled" class="accordion-collapse collapse">
      <div class="accordion-body">
        <ng-container *ngIf="type === 'system-level'">
          <div class="form-group mb-3 row">
            <div class="col-sm-2"></div>
            <div class="col-sm-10">
              <div class="form-check form-check-inline">
                <input class="form-check-input" id="reminderEmailEnabled" type="checkbox" name="reminderEmailEnabled"
                  ToggleValue [true]="1" [false]="0" [(ngModel)]="reminderEmailEnabled.value">
                <label class="form-check-label" for="reminderEmailEnabled">Enable Reminder Emails</label>
              </div>
            </div>
          </div>
          <div class="form-group mb-3 row" *ngIf="1">
            <div class="form-group mb-3 row" *ngIf="reminderEmailEnabled.value == 1">
              <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Reminder Interval (in days)</label>
              <div class="col-sm-10">
                <input type="number" class="form-control numeric-without-arrows" name="reminderEmailInterval"
                  placeholder="Reminder Interval (in days)" numeric [allowNegative]="false"
                  [(ngModel)]="reminderEmailInterval.value">
              </div>
            </div>
          </div>
        </ng-container>
        <div class="form-group mb-3 row">
          <div class="col-sm-2"></div>
          <div class="col-sm-10">
            <div class="form-check form-check-inline">
              <input class="form-check-input" id="overrideReminderTemplate" type="checkbox"
                [(ngModel)]="definedEmailTemplate.reminderOverride"
                (change)="populateDefaultEmailTemplate(definedEmailTemplate.reminderOverride, 'TaskReminder', 'reminderSubject', 'reminderEmail')">
              <label class="form-check-label" for="overrideReminderTemplate">Override Reminder Template</label>
            </div>
          </div>
        </div>
        <ng-container *ngIf="definedEmailTemplate.reminderOverride">
          <div class="form-group mb-3 row">
            <div class="col-sm-2"></div>
            <div class="col-sm-10">
              <div class="form-check form-check-inline">
                <input id="reminderIncludeEmailSignature" class="form-check-input" type="checkbox"
                  [(ngModel)]="definedEmailTemplate.reminderIncludeEmailSignature">
                <label class="form-check-label" for="reminderIncludeEmailSignature">Include Email Signature </label>
              </div>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">CC</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="CC" type="text" [(ngModel)]="definedEmailTemplate.reminderCC">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">BCC</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="BCC" type="text" [(ngModel)]="definedEmailTemplate.reminderBCC">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">CC</label>
            <div class="col-sm-10">
             <ng-select width="100%" name="reminder-roles-cc" bindLabel="name" bindValue="value"
              placeholder="-- Select --" [closeOnSelect]="false" [multiple]="true" groupBy="groupName"
              [(ngModel)]="reminderRolesCC" [items]="roles" appendTo="body"
              (ngModelChange)="onRoleMultiselectChanged('reminderRolesCC')">
            </ng-select>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">BCC</label>
            <div class="col-sm-10">
              <ng-select width="100%" name="reminder-roles-bcc" bindLabel="name" bindValue="value"
              placeholder="-- Select --" [closeOnSelect]="false" [multiple]="true" groupBy="groupName"
              [(ngModel)]="reminderRolesBCC" [items]="roles" appendTo="body"
              (ngModelChange)="onRoleMultiselectChanged('reminderRolesBCC')">
            </ng-select>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Subject</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="Subject" type="text"
                [(ngModel)]="definedEmailTemplate.reminderSubject"
                (contextmenu)="onContextMenu($event, definedEmailTemplate, 'reminderSubject')">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <div class="col-sm-12 form-label align-self-center mb-1 d-flex justify-content-between">
              <div class="d-flex">
                <div class="special-radio-btn-group flex-grow-1">
                  <div class="btn-group">
                    <button type="button" class="btn btn-outline-primary tippy-btn mb-xs-1 me-1"
                      (click)="onEditTemplateClicked(definedEmailTemplate.reminderEmail, 'reminderEmail', definedEmailTemplate.reminderUsingStripoEditor)">
                      <div class="d-flex">
                        <span class="fw-bolder button-item-text flex-grow-1">
                          <i class="fa fa-pencil-alt"></i>
                        </span>
                        <span class="fw-bolder button-item-text filter-label flex-grow-1 ms-1">
                          Edit
                        </span>
                      </div>
                    </button>
                    <button type="button" class="btn btn-outline-primary tippy-btn mb-xs-1"
                      (click)="populateDefaultEmailTemplate(definedEmailTemplate.reminderOverride, 'TaskReminder', 'reminderSubject', 'reminderEmail', true)">
                      <div class="d-flex">
                        <span class="fw-bolder button-item-text flex-grow-1">
                          <i class="fas fa-redo-alt fa-rotate-180"></i>
                        </span>
                        <span class="fw-bolder button-item-text filter-label flex-grow-1 ms-1">
                          Reload Default
                        </span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <iframe style="width:100%; height: 700px; border:1px solid #e3ebf6; border-radius: 0.25rem;"
                id="reminderEmailIframe"></iframe>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="accordion-item" *ngIf="applicationContext.userPermissions.onlineApplicationEnabled">
    <h5 class="accordion-header m-0">
      <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse"
        [ngClass]="{ collapsed: !collapsibleSectionVisibilityStatuses.onlineAppRegistrationEmail }"
        (click)="onEmailTemplateEditorVisibilityChanged('onlineAppRegistrationEmail')"
        [attr.aria-expanded]="collapsibleSectionVisibilityStatuses.onlineAppRegistrationEmail"
        data-bs-target="#app-registration" aria-expanded="true" aria-controls="collapse1">
        Online App Registration Template <span *ngIf="definedEmailTemplate.onlineAppRegistrationOverride === true"
          class="text-success ms-1">- (Overridden)</span>
      </button>
    </h5>
    <div id="app-registration" class="accordion-collapse collapse">
      <div class="accordion-body">
        <div class="form-group mb-3 row">
          <div class="col-sm-2"></div>
          <div class="col-sm-10">
            <div class="form-check form-check-inline">
              <input class="form-check-input" id="onlineAppRegistrationOverride" type="checkbox"
                [(ngModel)]="definedEmailTemplate.onlineAppRegistrationOverride"
                (change)="populateDefaultEmailTemplate(definedEmailTemplate.onlineAppRegistrationOverride, 'OnlineAppRegistration', 'onlineAppRegistrationSubject', 'onlineAppRegistrationEmail')">
              <label class="form-check-label" for="onlineAppRegistrationOverride">Override Online App Registration
                Template</label>
            </div>
          </div>
        </div>
        <ng-container *ngIf="definedEmailTemplate.onlineAppRegistrationOverride">
          <div class="form-group mb-3 row">
            <div class="col-sm-2"></div>
            <div class="col-sm-10">
              <div class="form-check form-check-inline">
                <input id="onlineAppRegistrationIncludeEmailSignature" class="form-check-input" type="checkbox"
                  [(ngModel)]="definedEmailTemplate.onlineAppRegistrationIncludeEmailSignature">
                <label class="form-check-label" for="onlineAppRegistrationIncludeEmailSignature">Include Email Signature
                </label>
              </div>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">CC</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="CC" type="text"
                [(ngModel)]="definedEmailTemplate.onlineAppRegistrationCC">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">BCC</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="BCC" type="text"
                [(ngModel)]="definedEmailTemplate.onlineAppRegistrationBCC">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Subject</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="Subject" type="text"
                [(ngModel)]="definedEmailTemplate.onlineAppRegistrationSubject"
                (contextmenu)="onContextMenu($event, definedEmailTemplate, 'onlineAppRegistrationSubject')">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <div class="col-sm-12 form-label align-self-center mb-1 d-flex justify-content-between">
              <div class="d-flex">
                <div class="special-radio-btn-group flex-grow-1">
                  <div class="btn-group">
                    <button type="button" class="btn btn-outline-primary tippy-btn mb-xs-1 me-1"
                      (click)="onEditTemplateClicked(definedEmailTemplate.onlineAppRegistrationEmail, 'onlineAppRegistrationEmail', definedEmailTemplate.onlineAppRegistrationUsingStripoEditor)">
                      <div class="d-flex">
                        <span class="fw-bolder button-item-text flex-grow-1">
                          <i class="fa fa-pencil-alt"></i>
                        </span>
                        <span class="fw-bolder button-item-text filter-label flex-grow-1 ms-1">
                          Edit
                        </span>
                      </div>
                    </button>
                    <button type="button" class="btn btn-outline-primary tippy-btn mb-xs-1"
                      (click)="populateDefaultEmailTemplate(definedEmailTemplate.onlineAppRegistrationOverride, 'OnlineAppRegistration', 'onlineAppRegistrationSubject', 'onlineAppRegistrationEmail', true)">
                      <div class="d-flex">
                        <span class="fw-bolder button-item-text flex-grow-1">
                          <i class="fas fa-redo-alt fa-rotate-180"></i>
                        </span>
                        <span class="fw-bolder button-item-text filter-label flex-grow-1 ms-1">
                          Reload Default
                        </span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <iframe style="width:100%; height: 700px; border:1px solid #e3ebf6; border-radius: 0.25rem;"
                id="onlineAppRegistrationEmailIframe"></iframe>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="accordion-item" *ngIf="applicationContext.userPermissions.onlineApplicationEnabled">
    <h5 class="accordion-header m-0">
      <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse"
        [ngClass]="{ collapsed: !collapsibleSectionVisibilityStatuses.onlineAppStartedEmail }"
        (click)="onEmailTemplateEditorVisibilityChanged('onlineAppStartedEmail')"
        [attr.aria-expanded]="collapsibleSectionVisibilityStatuses.onlineAppStartedEmail"
        data-bs-target="#app-started" aria-expanded="true" aria-controls="collapse1">
        Online App Started Template <span *ngIf="definedEmailTemplate.onlineAppStartedOverride === true"
          class="text-success ms-1">- (Overridden)</span>
      </button>
    </h5>
    <div id="app-started" class="accordion-collapse collapse">
      <div class="accordion-body">
        <div class="form-group mb-3 row">
          <div class="col-sm-2"></div>
          <div class="col-sm-10">
            <div class="form-check form-check-inline">
              <input class="form-check-input" id="onlineAppStartedOverride" type="checkbox"
                [(ngModel)]="definedEmailTemplate.onlineAppStartedOverride"
                (change)="populateDefaultEmailTemplate(definedEmailTemplate.onlineAppStartedOverride, 'OnlineAppStarted', 'onlineAppStartedSubject', 'onlineAppStartedEmail')">
              <label class="form-check-label" for="onlineAppStartedOverride">Override Online App Started
                Template</label>
            </div>
          </div>
        </div>
        <ng-container *ngIf="definedEmailTemplate.onlineAppStartedOverride">
          <div class="form-group mb-3 row">
            <div class="col-sm-2"></div>
            <div class="col-sm-10">
              <div class="form-check form-check-inline">
                <input id="onlineAppStartedIncludeEmailSignature" class="form-check-input" type="checkbox"
                  [(ngModel)]="definedEmailTemplate.onlineAppStartedIncludeEmailSignature">
                <label class="form-check-label" for="onlineAppStartedIncludeEmailSignature">Include Email Signature
                </label>
              </div>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">CC</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="CC" type="text"
                [(ngModel)]="definedEmailTemplate.onlineAppStartedCC">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">BCC</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="BCC" type="text"
                [(ngModel)]="definedEmailTemplate.onlineAppStartedBCC">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">CC</label>
            <div class="col-sm-10">
              <ng-select width="100%" name="online-app-started-roles-cc" bindLabel="name" bindValue="value"
              placeholder="-- Select --" [closeOnSelect]="false" [multiple]="true" groupBy="groupName"
              [(ngModel)]="onlineAppStartedRolesCC" [items]="roles" appendTo="body"
              (ngModelChange)="onRoleMultiselectChanged('onlineAppStartedRolesCC')">
            </ng-select>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">BCC</label>
            <div class="col-sm-10">
              <ng-select width="100%" name="online-app-started-roles-bcc" bindLabel="name" bindValue="value"
              placeholder="-- Select --" [closeOnSelect]="false" [multiple]="true" groupBy="groupName"
              [(ngModel)]="onlineAppStartedRolesBCC" [items]="roles" appendTo="body"
              (ngModelChange)="onRoleMultiselectChanged('onlineAppStartedRolesBCC')">
            </ng-select>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Subject</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="Subject" type="text"
                [(ngModel)]="definedEmailTemplate.onlineAppStartedSubject"
                (contextmenu)="onContextMenu($event, definedEmailTemplate, 'onlineAppStartedSubject')">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <div class="col-sm-12 form-label align-self-center mb-1 d-flex justify-content-between">
              <div class="d-flex">
                <div class="special-radio-btn-group flex-grow-1">
                  <div class="btn-group">
                    <button type="button" class="btn btn-outline-primary tippy-btn mb-xs-1 me-1"
                      (click)="onEditTemplateClicked(definedEmailTemplate.onlineAppStartedEmail, 'onlineAppStartedEmail', definedEmailTemplate.onlineAppStartedUsingStripoEditor)">
                      <div class="d-flex">
                        <span class="fw-bolder button-item-text flex-grow-1">
                          <i class="fa fa-pencil-alt"></i>
                        </span>
                        <span class="fw-bolder button-item-text filter-label flex-grow-1 ms-1">
                          Edit
                        </span>
                      </div>
                    </button>
                    <button type="button" class="btn btn-outline-primary tippy-btn mb-xs-1"
                      (click)="populateDefaultEmailTemplate(definedEmailTemplate.onlineAppStartedOverride, 'OnlineAppStarted', 'onlineAppStartedSubject', 'onlineAppStartedEmail', true)">
                      <div class="d-flex">
                        <span class="fw-bolder button-item-text flex-grow-1">
                          <i class="fas fa-redo-alt fa-rotate-180"></i>
                        </span>
                        <span class="fw-bolder button-item-text filter-label flex-grow-1 ms-1">
                          Reload Default
                        </span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <iframe style="width:100%; height: 700px; border:1px solid #e3ebf6; border-radius: 0.25rem;"
                id="onlineAppStartedEmailIframe"></iframe>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="accordion-item" *ngIf="applicationContext.userPermissions.onlineApplicationEnabled">
    <h5 class="accordion-header m-0">
      <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse"
        [ngClass]="{ collapsed: !collapsibleSectionVisibilityStatuses.onlineAppSubmissionEmail }"
        (click)="onEmailTemplateEditorVisibilityChanged('onlineAppSubmissionEmail')"
        [attr.aria-expanded]="collapsibleSectionVisibilityStatuses.onlineAppSubmissionEmail"
        data-bs-target="#app-submission" aria-expanded="true" aria-controls="collapse1">
        Online App Submission Template <span *ngIf="definedEmailTemplate.onlineAppSubmissionOverride === true"
          class="text-success ms-1">- (Overridden)</span>
      </button>
    </h5>
    <div id="app-submission" class="accordion-collapse collapse">
      <div class="accordion-body">
        <div class="form-group mb-3 row">
          <div class="col-sm-2"></div>
          <div class="col-sm-10">
            <div class="form-check form-check-inline">
              <input class="form-check-input" id="onlineAppSubmissionOverride" type="checkbox"
                [(ngModel)]="definedEmailTemplate.onlineAppSubmissionOverride"
                (change)="populateDefaultEmailTemplate(definedEmailTemplate.onlineAppSubmissionOverride, 'OnlineAppSubmission', 'onlineAppSubmissionSubject', 'onlineAppSubmissionEmail')">
              <label class="form-check-label" for="onlineAppSubmissionOverride">Override Online App Submission
                Template</label>
            </div>
          </div>
        </div>
        <ng-container *ngIf="definedEmailTemplate.onlineAppSubmissionOverride">
          <div class="form-group mb-3 row">
            <div class="col-sm-2"></div>
            <div class="col-sm-10">
              <div class="form-check form-check-inline">
                <input id="onlineAppSubmissionIncludeEmailSignature" class="form-check-input" type="checkbox"
                  [(ngModel)]="definedEmailTemplate.onlineAppSubmissionIncludeEmailSignature">
                <label class="form-check-label" for="onlineAppSubmissionIncludeEmailSignature">Include Email Signature
                </label>
              </div>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">CC</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="CC" type="text"
                [(ngModel)]="definedEmailTemplate.onlineAppSubmissionCC">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">BCC</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="BCC" type="text"
                [(ngModel)]="definedEmailTemplate.onlineAppSubmissionBCC">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">CC</label>
            <div class="col-sm-10">
              <ng-select width="100%" name="online-app-submission-roles-cc" bindLabel="name" bindValue="value"
              placeholder="-- Select --" [closeOnSelect]="false" [multiple]="true" groupBy="groupName"
              [(ngModel)]="onlineAppSubmissionRolesCC" [items]="roles" appendTo="body"
              (ngModelChange)="onRoleMultiselectChanged('onlineAppSubmissionRolesCC')">
            </ng-select>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">BCC</label>
            <div class="col-sm-10">
              <ng-select width="100%" name="online-app-submission-roles-bcc" bindLabel="name" bindValue="value"
              placeholder="-- Select --" [closeOnSelect]="false" [multiple]="true" groupBy="groupName"
              [(ngModel)]="onlineAppSubmissionRolesBCC" [items]="roles" appendTo="body"
              (ngModelChange)="onRoleMultiselectChanged('onlineAppSubmissionRolesBCC')">
            </ng-select>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Subject</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="Subject" type="text"
                [(ngModel)]="definedEmailTemplate.onlineAppSubmissionSubject"
                (contextmenu)="onContextMenu($event, definedEmailTemplate, 'onlineAppSubmissionSubject')">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <div class="col-sm-12 form-label align-self-center mb-1 d-flex justify-content-between">
              <div class="d-flex">
                <div class="special-radio-btn-group flex-grow-1">
                  <div class="btn-group">
                    <button type="button" class="btn btn-outline-primary tippy-btn mb-xs-1 me-1"
                      (click)="onEditTemplateClicked(definedEmailTemplate.onlineAppSubmissionEmail, 'onlineAppSubmissionEmail', definedEmailTemplate.onlineAppSubmissionUsingStripoEditor)">
                      <div class="d-flex">
                        <span class="fw-bolder button-item-text flex-grow-1">
                          <i class="fa fa-pencil-alt"></i>
                        </span>
                        <span class="fw-bolder button-item-text filter-label flex-grow-1 ms-1">
                          Edit
                        </span>
                      </div>
                    </button>
                    <button type="button" class="btn btn-outline-primary tippy-btn mb-xs-1"
                      (click)="populateDefaultEmailTemplate(definedEmailTemplate.onlineAppSubmissionOverride, 'OnlineAppSubmission', 'onlineAppSubmissionSubject', 'onlineAppSubmissionEmail', true)">
                      <div class="d-flex">
                        <span class="fw-bolder button-item-text flex-grow-1">
                          <i class="fas fa-redo-alt fa-rotate-180"></i>
                        </span>
                        <span class="fw-bolder button-item-text filter-label flex-grow-1 ms-1">
                          Reload Default
                        </span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <iframe style="width:100%; height: 700px; border:1px solid #e3ebf6; border-radius: 0.25rem;"
                id="onlineAppSubmissionEmailIframe"></iframe>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h5 class="accordion-header m-0">
      <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse"
        [ngClass]="{ collapsed: !collapsibleSectionVisibilityStatuses.agentEsignEmail }"
        (click)="onEmailTemplateEditorVisibilityChanged('agentEsignEmail')"
        [attr.aria-expanded]="collapsibleSectionVisibilityStatuses.agentEsignEmail"
        data-bs-target="#agent-esign" aria-expanded="true" aria-controls="collapse1">
        Agent eSign Template <span *ngIf="definedEmailTemplate.agentEsignOverride === true" class="text-success ms-1">-
          (Overridden)</span>
      </button>
    </h5>
    <div id="agent-esign" class="accordion-collapse collapse">
      <div class="accordion-body">
        <div class="form-group mb-3 row">
          <div class="col-sm-2"></div>
          <div class="col-sm-10">
            <div class="form-check form-check-inline">
              <input class="form-check-input" id="agentEsignOverride" type="checkbox"
                [(ngModel)]="definedEmailTemplate.agentEsignOverride"
                (change)="populateDefaultEmailTemplate(definedEmailTemplate.agentEsignOverride, 'AgentEsign', 'agentEsignSubject', 'agentEsignEmail')">
              <label class="form-check-label" for="agentEsignOverride">Override Agent eSign Template</label>
            </div>
          </div>
        </div>
        <ng-container *ngIf="definedEmailTemplate.agentEsignOverride">
          <div class="form-group mb-3 row">
            <div class="col-sm-2"></div>
            <div class="col-sm-10">
              <div class="form-check form-check-inline">
                <input id="agentEsignIncludeEmailSignature" class="form-check-input" type="checkbox"
                  [(ngModel)]="definedEmailTemplate.agentEsignIncludeEmailSignature">
                <label class="form-check-label" for="agentEsignIncludeEmailSignature">Include Email Signature </label>
              </div>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">CC</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="CC" type="text" [(ngModel)]="definedEmailTemplate.agentEsignCC">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">BCC</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="BCC" type="text"
                [(ngModel)]="definedEmailTemplate.agentEsignBCC">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Subject</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="Subject" type="text"
                [(ngModel)]="definedEmailTemplate.agentEsignSubject"
                (contextmenu)="onContextMenu($event, definedEmailTemplate, 'agentEsignSubject')">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <div class="col-sm-12 form-label align-self-center mb-1 d-flex justify-content-between">
              <div class="d-flex">
                <div class="special-radio-btn-group flex-grow-1">
                  <div class="btn-group">
                    <button type="button" class="btn btn-outline-primary tippy-btn mb-xs-1 me-1"
                      (click)="onEditTemplateClicked(definedEmailTemplate.agentEsignEmail, 'agentEsignEmail', definedEmailTemplate.agentEsignUsingStripoEditor)">
                      <div class="d-flex">
                        <span class="fw-bolder button-item-text flex-grow-1">
                          <i class="fa fa-pencil-alt"></i>
                        </span>
                        <span class="fw-bolder button-item-text filter-label flex-grow-1 ms-1">
                          Edit
                        </span>
                      </div>
                    </button>
                    <button type="button" class="btn btn-outline-primary tippy-btn mb-xs-1"
                      (click)="populateDefaultEmailTemplate(definedEmailTemplate.agentEsignOverride, 'AgentEsign', 'agentEsignSubject', 'agentEsignEmail', true)">
                      <div class="d-flex">
                        <span class="fw-bolder button-item-text flex-grow-1">
                          <i class="fas fa-redo-alt fa-rotate-180"></i>
                        </span>
                        <span class="fw-bolder button-item-text filter-label flex-grow-1 ms-1">
                          Reload Default
                        </span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <iframe style="width:100%; height: 700px; border:1px solid #e3ebf6; border-radius: 0.25rem;"
                id="agentEsignEmailIframe"></iframe>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h5 class="accordion-header m-0">
      <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse"
        [ngClass]="{ collapsed: !collapsibleSectionVisibilityStatuses.borrowerEsignEmail }"
        (click)="onEmailTemplateEditorVisibilityChanged('borrowerEsignEmail')"
        [attr.aria-expanded]="collapsibleSectionVisibilityStatuses.borrowerEsignEmail"
        data-bs-target="#borrower-esign" aria-expanded="true" aria-controls="collapse1">
        Borrower eSign Template <span *ngIf="definedEmailTemplate.borrowerEsignOverride === true"
          class="text-success ms-1">- (Overridden)</span>
      </button>
    </h5>
    <div id="borrower-esign" class="accordion-collapse collapse">
      <div class="accordion-body">
        <div class="form-group mb-3 row">
          <div class="col-sm-2"></div>
          <div class="col-sm-10">
            <div class="form-check form-check-inline">
              <input class="form-check-input" id="borrowerEsignOverride" type="checkbox"
                [(ngModel)]="definedEmailTemplate.borrowerEsignOverride"
                (change)="populateDefaultEmailTemplate(definedEmailTemplate.borrowerEsignOverride, 'BorrowerEsign', 'borrowerEsignSubject', 'borrowerEsignEmail')">
              <label class="form-check-label" for="borrowerEsignOverride">Override Borrower eSign Template</label>
            </div>
          </div>
        </div>
        <ng-container *ngIf="definedEmailTemplate.borrowerEsignOverride">
          <div class="form-group mb-3 row">
            <div class="col-sm-2"></div>
            <div class="col-sm-10">
              <div class="form-check form-check-inline">
                <input id="borrowerEsignIncludeEmailSignature" class="form-check-input" type="checkbox"
                  [(ngModel)]="definedEmailTemplate.borrowerEsignIncludeEmailSignature">
                <label class="form-check-label" for="borrowerEsignIncludeEmailSignature">Include Email Signature
                </label>
              </div>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">CC</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="CC" type="text"
                [(ngModel)]="definedEmailTemplate.borrowerEsignCC">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">BCC</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="BCC" type="text"
                [(ngModel)]="definedEmailTemplate.borrowerEsignBCC">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Subject</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="Subject" type="text"
                [(ngModel)]="definedEmailTemplate.borrowerEsignSubject"
                (contextmenu)="onContextMenu($event, definedEmailTemplate, 'borrowerEsignSubject')">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <div class="col-sm-12 form-label align-self-center mb-1 d-flex justify-content-between">
              <div class="d-flex">
                <div class="special-radio-btn-group flex-grow-1">
                  <div class="btn-group">
                    <button type="button" class="btn btn-outline-primary tippy-btn mb-xs-1 me-1"
                      (click)="onEditTemplateClicked(definedEmailTemplate.borrowerEsignEmail, 'borrowerEsignEmail', definedEmailTemplate.borrowerEsignUsingStripoEditor)">
                      <div class="d-flex">
                        <span class="fw-bolder button-item-text flex-grow-1">
                          <i class="fa fa-pencil-alt"></i>
                        </span>
                        <span class="fw-bolder button-item-text filter-label flex-grow-1 ms-1">
                          Edit
                        </span>
                      </div>
                    </button>
                    <button type="button" class="btn btn-outline-primary tippy-btn mb-xs-1"
                      (click)="populateDefaultEmailTemplate(definedEmailTemplate.borrowerEsignOverride, 'BorrowerEsign', 'borrowerEsignSubject', 'borrowerEsignEmail', true)">
                      <div class="d-flex">
                        <span class="fw-bolder button-item-text flex-grow-1">
                          <i class="fas fa-redo-alt fa-rotate-180"></i>
                        </span>
                        <span class="fw-bolder button-item-text filter-label flex-grow-1 ms-1">
                          Reload Default
                        </span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <iframe style="width:100%; height: 700px; border:1px solid #e3ebf6; border-radius: 0.25rem;"
                id="borrowerEsignEmailIframe"></iframe>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h5 class="accordion-header m-0">
      <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse"
        [ngClass]="{ collapsed: !collapsibleSectionVisibilityStatuses.internalEsignEmail }"
        (click)="onEmailTemplateEditorVisibilityChanged('internalEsignEmail')"
        [attr.aria-expanded]="collapsibleSectionVisibilityStatuses.internalEsignEmail"
        data-bs-target="#internal-esign" aria-expanded="true" aria-controls="collapse1">
        Internal Users eSign Template <span *ngIf="definedEmailTemplate.internalEsignOverride === true"
          class="text-success ms-1">- (Overridden)</span>
      </button>
    </h5>
    <div id="internal-esign" class="accordion-collapse collapse">
      <div class="accordion-body">
        <div class="form-group mb-3 row">
          <div class="col-sm-2"></div>
          <div class="col-sm-10">
            <div class="form-check form-check-inline">
              <input class="form-check-input" id="internalEsignOverride" type="checkbox"
                [(ngModel)]="definedEmailTemplate.internalEsignOverride"
                (change)="populateDefaultEmailTemplate(definedEmailTemplate.internalEsignOverride, 'InternalEsign', 'internalEsignSubject', 'internalEsignEmail')">
              <label class="form-check-label" for="internalEsignOverride">Override Internal Users eSign Template</label>
            </div>
          </div>
        </div>
        <ng-container *ngIf="definedEmailTemplate.internalEsignOverride">
          <div class="form-group mb-3 row">
            <div class="col-sm-2"></div>
            <div class="col-sm-10">
              <div class="form-check form-check-inline">
                <input id="internalEsignIncludeEmailSignature" class="form-check-input" type="checkbox"
                  [(ngModel)]="definedEmailTemplate.internalEsignIncludeEmailSignature">
                <label class="form-check-label" for="internalEsignIncludeEmailSignature">Include Email Signature
                </label>
              </div>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">CC</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="CC" type="text"
                [(ngModel)]="definedEmailTemplate.internalEsignCC">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">BCC</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="BCC" type="text"
                [(ngModel)]="definedEmailTemplate.internalEsignBCC">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Subject</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="Subject" type="text"
                [(ngModel)]="definedEmailTemplate.internalEsignSubject"
                (contextmenu)="onContextMenu($event, definedEmailTemplate, 'internalEsignSubject')">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <div class="col-sm-12 form-label align-self-center mb-1 d-flex justify-content-between">
              <div class="d-flex">
                <div class="special-radio-btn-group flex-grow-1">
                  <div class="btn-group">
                    <button type="button" class="btn btn-outline-primary tippy-btn mb-xs-1 me-1"
                      (click)="onEditTemplateClicked(definedEmailTemplate.internalEsignEmail, 'internalEsignEmail', definedEmailTemplate.internalEsignUsingStripoEditor)">
                      <div class="d-flex">
                        <span class="fw-bolder button-item-text flex-grow-1">
                          <i class="fa fa-pencil-alt"></i>
                        </span>
                        <span class="fw-bolder button-item-text filter-label flex-grow-1 ms-1">
                          Edit
                        </span>
                      </div>
                    </button>
                    <button type="button" class="btn btn-outline-primary tippy-btn mb-xs-1"
                      (click)="populateDefaultEmailTemplate(definedEmailTemplate.internalEsignOverride, 'InternalEsign', 'internalEsignSubject', 'internalEsignEmail', true)">
                      <div class="d-flex">
                        <span class="fw-bolder button-item-text flex-grow-1">
                          <i class="fas fa-redo-alt fa-rotate-180"></i>
                        </span>
                        <span class="fw-bolder button-item-text filter-label flex-grow-1 ms-1">
                          Reload Default
                        </span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <iframe style="width:100%; height: 700px; border:1px solid #e3ebf6; border-radius: 0.25rem;"
                id="internalEsignEmailIframe"></iframe>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h5 class="accordion-header m-0">
      <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse"
        [ngClass]="{ collapsed: !collapsibleSectionVisibilityStatuses.esignCompletedEmail }"
        (click)="onEmailTemplateEditorVisibilityChanged('esignCompletedEmail')"
        [attr.aria-expanded]="collapsibleSectionVisibilityStatuses.esignCompletedEmail"
        data-bs-target="#esign-completed" aria-expanded="true" aria-controls="collapse1">
        Esign Completed Template <span *ngIf="definedEmailTemplate.esignCompletedOverride === true"
          class="text-success ms-1">- (Overridden)</span>
      </button>
    </h5>
    <div id="esign-completed" class="accordion-collapse collapse">
      <div class="accordion-body">
        <div class="form-group mb-3 row">
          <div class="col-sm-2"></div>
          <div class="col-sm-10">
            <div class="form-check form-check-inline">
              <input class="form-check-input" id="esignCompletedOverride" type="checkbox"
                [(ngModel)]="definedEmailTemplate.esignCompletedOverride"
                (change)="populateDefaultEmailTemplate(definedEmailTemplate.esignCompletedOverride, 'EsignCompleted', 'esignCompletedSubject', 'esignCompletedEmail')">
              <label class="form-check-label" for="esignCompletedOverride">Override Esign Completed Template</label>
            </div>
          </div>
        </div>
        <ng-container *ngIf="definedEmailTemplate.esignCompletedOverride">
          <div class="form-group mb-3 row">
            <div class="col-sm-2"></div>
            <div class="col-sm-10">
              <div class="form-check form-check-inline">
                <input id="esignCompletedIncludeEmailSignature" class="form-check-input" type="checkbox"
                  [(ngModel)]="definedEmailTemplate.esignCompletedIncludeEmailSignature">
                <label class="form-check-label" for="esignCompletedIncludeEmailSignature">Include Email Signature
                </label>
              </div>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">CC</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="CC" type="text"
                [(ngModel)]="definedEmailTemplate.esignCompletedCC">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">BCC</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="BCC" type="text"
                [(ngModel)]="definedEmailTemplate.esignCompletedBCC">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">CC</label>
            <div class="col-sm-10">
              <ng-select width="100%" name="esign-completed-roles-cc" bindLabel="name" bindValue="value"
              placeholder="-- Select --" [closeOnSelect]="false" [multiple]="true" groupBy="groupName"
              [(ngModel)]="esignCompletedRolesCC" [items]="roles" appendTo="body"
              (ngModelChange)="onRoleMultiselectChanged('esignCompletedRolesCC')">
            </ng-select>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">BCC</label>
            <div class="col-sm-10">
              <ng-select width="100%" name="esign-completed-roles-bcc" bindLabel="name" bindValue="value"
              placeholder="-- Select --" [closeOnSelect]="false" [multiple]="true" groupBy="groupName"
              [(ngModel)]="esignCompletedRolesBCC" [items]="roles" appendTo="body"
              (ngModelChange)="onRoleMultiselectChanged('esignCompletedRolesBCC')">
            </ng-select>
            </div>
          </div>
          <div class="form-group mb-3 row" *ngIf="definedEmailTemplate.esignCompletedOverride">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Subject</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="Subject" type="text"
                [(ngModel)]="definedEmailTemplate.esignCompletedSubject"
                (contextmenu)="onContextMenu($event, definedEmailTemplate, 'esignCompletedSubject')">
            </div>
          </div>
          <div class="form-group mb-3 row" *ngIf="definedEmailTemplate.esignCompletedOverride">
            <div class="col-sm-12 form-label align-self-center mb-1 d-flex justify-content-between">
              <div class="d-flex">
                <div class="special-radio-btn-group flex-grow-1">
                  <div class="btn-group">
                    <button type="button" class="btn btn-outline-primary tippy-btn mb-xs-1 me-1"
                      (click)="onEditTemplateClicked(definedEmailTemplate.esignCompletedEmail, 'esignCompletedEmail', definedEmailTemplate.esignCompletedUsingStripoEditor)">
                      <div class="d-flex">
                        <span class="fw-bolder button-item-text flex-grow-1">
                          <i class="fa fa-pencil-alt"></i>
                        </span>
                        <span class="fw-bolder button-item-text filter-label flex-grow-1 ms-1">
                          Edit
                        </span>
                      </div>
                    </button>
                    <button type="button" class="btn btn-outline-primary tippy-btn mb-xs-1"
                      (click)="populateDefaultEmailTemplate(definedEmailTemplate.esignCompletedOverride, 'EsignCompleted', 'esignCompletedSubject', 'esignCompletedEmail', true)">
                      <div class="d-flex">
                        <span class="fw-bolder button-item-text flex-grow-1">
                          <i class="fas fa-redo-alt fa-rotate-180"></i>
                        </span>
                        <span class="fw-bolder button-item-text filter-label flex-grow-1 ms-1">
                          Reload Default
                        </span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <iframe style="width:100%; height: 700px; border:1px solid #e3ebf6; border-radius: 0.25rem;"
                id="esignCompletedEmailIframe"></iframe>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <!-- TODO -->
  <div class="accordion-item">
    <h5 class="accordion-header m-0">
      <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse"
        [ngClass]="{ collapsed: !collapsibleSectionVisibilityStatuses.losEsignCompletedEmail }"
        (click)="onEmailTemplateEditorVisibilityChanged('losEsignCompletedEmail')"
        [attr.aria-expanded]="collapsibleSectionVisibilityStatuses.losEsignCompletedEmail"
        data-bs-target="#los-esign" aria-expanded="true" aria-controls="collapse1">
        Los Esign Completed Template <span *ngIf="definedEmailTemplate.losEsignCompletedOverride === true"
          class="text-success ms-1">- (Overridden)</span>
      </button>
    </h5>
    <div id="los-esign" class="accordion-collapse collapse">
      <div class="accordion-body">
        <div class="form-group mb-3 row">
          <div class="col-sm-2"></div>
          <div class="col-sm-10">
            <div class="form-check form-check-inline">
              <input class="form-check-input" id="losEsignCompletedOverride" type="checkbox"
                [(ngModel)]="definedEmailTemplate.losEsignCompletedOverride"
                (change)="populateDefaultEmailTemplate(definedEmailTemplate.losEsignCompletedOverride, 'LosEsignCompleted', 'losEsignCompletedSubject', 'losEsignCompletedEmail')">
              <label class="form-check-label" for="losEsignCompletedOverride">Override Esign Completed Template</label>
            </div>
          </div>
        </div>
        <ng-container *ngIf="definedEmailTemplate.losEsignCompletedOverride">
          <div class="form-group mb-3 row">
            <div class="col-sm-2"></div>
            <div class="col-sm-10">
              <div class="form-check form-check-inline">
                <input id="losEsignCompletedIncludeEmailSignature" class="form-check-input" type="checkbox"
                  [(ngModel)]="definedEmailTemplate.losEsignCompletedIncludeEmailSignature">
                <label class="form-check-label" for="losEsignCompletedIncludeEmailSignature">Include Email Signature
                </label>
              </div>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">CC</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="CC" type="text"
                [(ngModel)]="definedEmailTemplate.losEsignCompletedCC">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">BCC</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="BCC" type="text"
                [(ngModel)]="definedEmailTemplate.losEsignCompletedBCC">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">CC</label>
            <div class="col-sm-10">
              <ng-select width="100%" name="los-esign-completed-roles-cc" bindLabel="name" bindValue="value"
              placeholder="-- Select --" [closeOnSelect]="false" [multiple]="true" groupBy="groupName"
              [(ngModel)]="losEsignCompletedRolesCC" [items]="roles" appendTo="body"
              (ngModelChange)="onRoleMultiselectChanged('losEsignCompletedRolesCC')">
            </ng-select>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">BCC</label>
            <div class="col-sm-10">
              <ng-select width="100%" name="los-esign-completed-roles-bcc" bindLabel="name" bindValue="value"
              placeholder="-- Select --" [closeOnSelect]="false" [multiple]="true" groupBy="groupName"
              [(ngModel)]="losEsignCompletedRolesBCC" [items]="roles" appendTo="body"
              (ngModelChange)="onRoleMultiselectChanged('losEsignCompletedRolesBCC')">
            </ng-select>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Subject</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="Subject" type="text"
                [(ngModel)]="definedEmailTemplate.losEsignCompletedSubject"
                (contextmenu)="onContextMenu($event, definedEmailTemplate, 'losEsignCompletedSubject')">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <div class="col-sm-12 form-label align-self-center mb-1 d-flex justify-content-between">
              <div class="d-flex">
                <div class="special-radio-btn-group flex-grow-1">
                  <div class="btn-group">
                    <button type="button" class="btn btn-outline-primary tippy-btn mb-xs-1 me-1"
                      (click)="onEditTemplateClicked(definedEmailTemplate.losEsignCompletedEmail, 'losEsignCompletedEmail', defaultEmailTemplates.losEsignCompletedUsingStripoEditor)">
                      <div class="d-flex">
                        <span class="fw-bolder button-item-text flex-grow-1">
                          <i class="fa fa-pencil-alt"></i>
                        </span>
                        <span class="fw-bolder button-item-text filter-label flex-grow-1 ms-1">
                          Edit
                        </span>
                      </div>
                    </button>
                    <button type="button" class="btn btn-outline-primary tippy-btn mb-xs-1"
                      (click)="populateDefaultEmailTemplate(definedEmailTemplate.losEsignCompletedOverride, 'LosEsignCompleted', 'losEsignCompletedSubject', 'losEsignCompletedEmail', true)">
                      <div class="d-flex">
                        <span class="fw-bolder button-item-text flex-grow-1">
                          <i class="fas fa-redo-alt fa-rotate-180"></i>
                        </span>
                        <span class="fw-bolder button-item-text filter-label flex-grow-1 ms-1">
                          Reload Default
                        </span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <iframe style="width:100%; height: 700px; border:1px solid #e3ebf6; border-radius: 0.25rem;"
                id="losEsignCompletedEmailIframe"></iframe>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h5 class="accordion-header m-0">
      <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse"
        [ngClass]="{ collapsed: !collapsibleSectionVisibilityStatuses.lockRequestEmail }"
        (click)="onEmailTemplateEditorVisibilityChanged('lockRequestEmail')"
        [attr.aria-expanded]="collapsibleSectionVisibilityStatuses.lockRequestEmail"
        data-bs-target="#rate-lock-requested" aria-expanded="true" aria-controls="collapse1">
        Rate Lock Requested <span *ngIf="definedEmailTemplate.lockRequestOverride === true" class="text-success ms-1">-
          (Overridden)</span>
      </button>
    </h5>
    <div id="rate-lock-requested" class="accordion-collapse collapse">
      <div class="accordion-body">
        <div class="form-group mb-3 row">
          <div class="col-sm-2"></div>
          <div class="col-sm-10">
            <div class="form-check form-check-inline">
              <input class="form-check-input" id="lockRequestOverride" type="checkbox"
                [(ngModel)]="definedEmailTemplate.lockRequestOverride"
                (change)="populateDefaultEmailTemplate(definedEmailTemplate.lockRequestOverride, 'LockRequest', 'lockRequestSubject', 'lockRequestEmail')">
              <label class="form-check-label" for="lockRequestOverride">Override Rate Lock Requested Template</label>
            </div>
          </div>
        </div>
        <ng-container *ngIf="definedEmailTemplate.lockRequestOverride">
          <div class="form-group mb-3 row">
            <div class="col-sm-2"></div>
            <div class="col-sm-10">
              <div class="form-check form-check-inline">
                <input id="lockRequestIncludeEmailSignature" class="form-check-input" type="checkbox"
                  [(ngModel)]="definedEmailTemplate.lockRequestIncludeEmailSignature">
                <label class="form-check-label" for="lockRequestIncludeEmailSignature">Include Email Signature </label>
              </div>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">To</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="To" type="text" [(ngModel)]="definedEmailTemplate.lockRequestTo">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">CC</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="CC" type="text" [(ngModel)]="definedEmailTemplate.lockRequestCC">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">BCC</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="BCC" type="text"
                [(ngModel)]="definedEmailTemplate.lockRequestBCC">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">To</label>
            <div class="col-sm-10">
              <ng-select width="100%" name="lock-request-roles-to" bindLabel="name" bindValue="value"
              placeholder="-- Select --" [closeOnSelect]="false" [multiple]="true" groupBy="groupName"
              [(ngModel)]="lockRequestRolesTo" [items]="roles" appendTo="body"
              (ngModelChange)="onRoleMultiselectChanged('lockRequestRolesTo')">
            </ng-select>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">CC</label>
            <div class="col-sm-10">
              <ng-select width="100%" name="lock-request-roles-cc" bindLabel="name" bindValue="value"
              placeholder="-- Select --" [closeOnSelect]="false" [multiple]="true" groupBy="groupName"
              [(ngModel)]="lockRequestRolesCC" [items]="roles" appendTo="body"
              (ngModelChange)="onRoleMultiselectChanged('lockRequestRolesCC')">
            </ng-select>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">BCC</label>
            <div class="col-sm-10">
              <ng-select width="100%" name="lock-request-roles-bcc" bindLabel="name" bindValue="value"
              placeholder="-- Select --" [closeOnSelect]="false" [multiple]="true" groupBy="groupName"
              [(ngModel)]="lockRequestRolesBCC" [items]="roles" appendTo="body"
              (ngModelChange)="onRoleMultiselectChanged('lockRequestRolesBCC')">
            </ng-select>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Subject</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="Subject" type="text"
                [(ngModel)]="definedEmailTemplate.lockRequestSubject"
                (contextmenu)="onContextMenu($event, definedEmailTemplate, 'lockRequestSubject')">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <div class="col-sm-12 form-label align-self-center mb-1 d-flex justify-content-between">
              <div class="d-flex">
                <div class="special-radio-btn-group flex-grow-1">
                  <div class="btn-group">
                    <button type="button" class="btn btn-outline-primary tippy-btn mb-xs-1 me-1"
                      (click)="onEditTemplateClicked(definedEmailTemplate.lockRequestEmail, 'lockRequestEmail', definedEmailTemplate.lockRequestUsingStripoEditor)">
                      <div class="d-flex">
                        <span class="fw-bolder button-item-text flex-grow-1">
                          <i class="fa fa-pencil-alt"></i>
                        </span>
                        <span class="fw-bolder button-item-text filter-label flex-grow-1 ms-1">
                          Edit
                        </span>
                      </div>
                    </button>
                    <button type="button" class="btn btn-outline-primary tippy-btn mb-xs-1"
                      (click)="populateDefaultEmailTemplate(definedEmailTemplate.lockRequestOverride, 'LockRequest', 'lockRequestSubject', 'lockRequestEmail', true)">
                      <div class="d-flex">
                        <span class="fw-bolder button-item-text flex-grow-1">
                          <i class="fas fa-redo-alt fa-rotate-180"></i>
                        </span>
                        <span class="fw-bolder button-item-text filter-label flex-grow-1 ms-1">
                          Reload Default
                        </span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <iframe style="width:100%; height: 700px; border:1px solid #e3ebf6; border-radius: 0.25rem;"
                id="lockRequestEmailIframe"></iframe>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h5 class="accordion-header m-0">
      <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse"
        [ngClass]="{ collapsed: !collapsibleSectionVisibilityStatuses.qcuLeadReferralEmail }"
        (click)="onEmailTemplateEditorVisibilityChanged('qcuLeadReferralEmail')"
        [attr.aria-expanded]="collapsibleSectionVisibilityStatuses.qcuLeadReferralEmail"
        data-bs-target="#qcu-lead-ref-override" aria-expanded="true" aria-controls="collapse1">
        Quick Client Update Lead Referral <span *ngIf="definedEmailTemplate.qcuLeadReferralOverride === true"
          class="text-success ms-1">- (Overridden)</span>
      </button>
    </h5>
    <div id="qcu-lead-ref-override" class="accordion-collapse collapse">
      <div class="accordion-body">
        <div class="form-group mb-3 row">
          <div class="col-sm-2"></div>
          <div class="col-sm-10">
            <div class="form-check form-check-inline">
              <input class="form-check-input" id="qcuLeadReferralOverride" type="checkbox"
                [(ngModel)]="definedEmailTemplate.qcuLeadReferralOverride"
                (change)="populateDefaultEmailTemplate(definedEmailTemplate.qcuLeadReferralOverride, 'QuickClientUpdateReferralLead', 'qcuLeadReferralSubject', 'qcuLeadReferralEmail')">
              <label class="form-check-label" for="qcuLeadReferralOverride">Override QCU Lead Referral</label>
            </div>
          </div>
        </div>
        <ng-container *ngIf="definedEmailTemplate.qcuLeadReferralOverride">
          <div class="form-group mb-3 row">
            <div class="col-sm-2"></div>
            <div class="col-sm-10">
              <div class="form-check form-check-inline">
                <input id="qcuLeadReferralIncludeEmailSignature" class="form-check-input" type="checkbox"
                  [(ngModel)]="definedEmailTemplate.qcuLeadReferralIncludeEmailSignature">
                <label class="form-check-label" for="qcuLeadReferralIncludeEmailSignature">Include Email Signature
                </label>
              </div>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">CC</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="CC" type="text"
                [(ngModel)]="definedEmailTemplate.qcuLeadReferralCC">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">BCC</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="BCC" type="text"
                [(ngModel)]="definedEmailTemplate.qcuLeadReferralBCC">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">CC</label>
            <div class="col-sm-10">
              <ng-select width="100%" name="qcu-lead-referral-roles-cc" bindLabel="name" bindValue="value"
              placeholder="-- Select --" [closeOnSelect]="false" [multiple]="true" groupBy="groupName"
              [(ngModel)]="qcuLeadReferralRolesCC" [items]="roles" appendTo="body"
              (ngModelChange)="onRoleMultiselectChanged('qcuLeadReferralRolesCC')">
            </ng-select>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">BCC</label>
            <div class="col-sm-10">
              <ng-select width="100%" name="qcu-lead-referral-roles-bcc" bindLabel="name" bindValue="value"
              placeholder="-- Select --" [closeOnSelect]="false" [multiple]="true" groupBy="groupName"
              [(ngModel)]="qcuLeadReferralRolesBCC" [items]="roles" appendTo="body"
              (ngModelChange)="onRoleMultiselectChanged('qcuLeadReferralRolesBCC')">
            </ng-select>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Subject</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="Subject" type="text"
                [(ngModel)]="definedEmailTemplate.qcuLeadReferralSubject"
                (contextmenu)="onContextMenu($event, definedEmailTemplate, 'qcuLeadReferralSubject', 'lead')">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <div class="col-sm-12 form-label align-self-center mb-1 d-flex justify-content-between">
              <div class="d-flex">
                <div class="special-radio-btn-group flex-grow-1">
                  <div class="btn-group">
                    <button type="button" class="btn btn-outline-primary tippy-btn mb-xs-1 me-1"
                      (click)="onEditTemplateClicked(definedEmailTemplate.qcuLeadReferralEmail, 'qcuLeadReferralEmail', definedEmailTemplate.qcuLeadReferralUsingStripoEditor)">
                      <div class="d-flex">
                        <span class="fw-bolder button-item-text flex-grow-1">
                          <i class="fa fa-pencil-alt"></i>
                        </span>
                        <span class="fw-bolder button-item-text filter-label flex-grow-1 ms-1">
                          Edit
                        </span>
                      </div>
                    </button>
                    <button type="button" class="btn btn-outline-primary tippy-btn mb-xs-1"
                      (click)="populateDefaultEmailTemplate(definedEmailTemplate.qcuLeadReferralOverride, 'QuickClientUpdateReferralLead', 'qcuLeadReferralSubject', 'qcuLeadReferralEmail', true)">
                      <div class="d-flex">
                        <span class="fw-bolder button-item-text flex-grow-1">
                          <i class="fas fa-redo-alt fa-rotate-180"></i>
                        </span>
                        <span class="fw-bolder button-item-text filter-label flex-grow-1 ms-1">
                          Reload Default
                        </span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <iframe style="width:100%; height: 700px; border:1px solid #e3ebf6; border-radius: 0.25rem;"
                id="qcuLeadReferralEmailIframe"></iframe>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h5 class="accordion-header m-0">
      <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse"
        [ngClass]="{ collapsed: !collapsibleSectionVisibilityStatuses.qcuPreApprovedEmail }"
        (click)="onEmailTemplateEditorVisibilityChanged('qcuPreApprovedEmail')"
        [attr.aria-expanded]="collapsibleSectionVisibilityStatuses.qcuPreApprovedEmail"
        data-bs-target="#qcu-pre-approved-override" aria-expanded="true" aria-controls="collapse1">
        Quick Client Update Pre Approved <span *ngIf="definedEmailTemplate.qcuPreApprovedOverride === true"
          class="text-success ms-1">- (Overridden)</span>
      </button>
    </h5>
    <div id="qcu-pre-approved-override" class="accordion-collapse collapse">
      <div class="accordion-body">
        <div class="form-group mb-3 row">
          <div class="col-sm-2"></div>
          <div class="col-sm-10">
            <div class="form-check form-check-inline">
              <input class="form-check-input" id="qcuPreApprovedOverride" type="checkbox"
                [(ngModel)]="definedEmailTemplate.qcuPreApprovedOverride"
                (change)="populateDefaultEmailTemplate(definedEmailTemplate.qcuPreApprovedOverride, 'QuickClientUpdatePreApproved', 'qcuPreApprovedSubject', 'qcuPreApprovedEmail')">
              <label class="form-check-label" for="qcuPreApprovedlOverride">Override QCU Pre Approved</label>
            </div>
          </div>
        </div>
        <ng-container *ngIf="definedEmailTemplate.qcuPreApprovedOverride">
          <div class="form-group mb-3 row">
            <div class="col-sm-2"></div>
            <div class="col-sm-10">
              <div class="form-check form-check-inline">
                <input id="qcuPreApprovedIncludeEmailSignature" class="form-check-input" type="checkbox"
                  [(ngModel)]="definedEmailTemplate.qcuPreApprovedIncludeEmailSignature">
                <label class="form-check-label" for="qcuPreApprovedIncludeEmailSignature">Include Email Signature
                </label>
              </div>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">CC</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="CC" type="text"
                [(ngModel)]="definedEmailTemplate.qcuPreApprovedCC">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">BCC</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="BCC" type="text"
                [(ngModel)]="definedEmailTemplate.qcuPreApprovedBCC">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">CC</label>
            <div class="col-sm-10">
              <ng-select width="100%" name="qcu-pre-approved-roles-cc" bindLabel="name" bindValue="value"
              placeholder="-- Select --" [closeOnSelect]="false" [multiple]="true" groupBy="groupName"
              [(ngModel)]="qcuPreApprovedRolesCC" [items]="roles" appendTo="body"
              (ngModelChange)="onRoleMultiselectChanged('qcuPreApprovedRolesCC')">
            </ng-select>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">BCC</label>
            <div class="col-sm-10">
              <ng-select width="100%" name="qcu-pre-approved-roles-bcc" bindLabel="name" bindValue="value"
              placeholder="-- Select --" [closeOnSelect]="false" [multiple]="true" groupBy="groupName"
              [(ngModel)]="qcuPreApprovedRolesBCC" [items]="roles" appendTo="body"
              (ngModelChange)="onRoleMultiselectChanged('qcuPreApprovedRolesBCC')">
            </ng-select>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Subject</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="Subject" type="text"
                [(ngModel)]="definedEmailTemplate.qcuPreApprovedSubject"
                (contextmenu)="onContextMenu($event, definedEmailTemplate, 'qcuPreApprovedSubject')">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <div class="col-sm-12 form-label align-self-center mb-1 d-flex justify-content-between">
              <div class="d-flex">
                <div class="special-radio-btn-group flex-grow-1">
                  <div class="btn-group">
                    <button type="button" class="btn btn-outline-primary tippy-btn mb-xs-1 me-1"
                      (click)="onEditTemplateClicked(definedEmailTemplate.qcuPreApprovedEmail, 'qcuPreApprovedEmail', definedEmailTemplate.qcuPreApprovedUsingStripoEditor)">
                      <div class="d-flex">
                        <span class="fw-bolder button-item-text flex-grow-1">
                          <i class="fa fa-pencil-alt"></i>
                        </span>
                        <span class="fw-bolder button-item-text filter-label flex-grow-1 ms-1">
                          Edit
                        </span>
                      </div>
                    </button>
                    <button type="button" class="btn btn-outline-primary tippy-btn mb-xs-1"
                      (click)="populateDefaultEmailTemplate(definedEmailTemplate.qcuPreApprovedOverride, 'QuickClientUpdatePreApproved', 'qcuPreApprovedSubject', 'qcuPreApprovedEmail', true)">
                      <div class="d-flex">
                        <span class="fw-bolder button-item-text flex-grow-1">
                          <i class="fas fa-redo-alt fa-rotate-180"></i>
                        </span>
                        <span class="fw-bolder button-item-text filter-label flex-grow-1 ms-1">
                          Reload Default
                        </span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <iframe style="width:100%; height: 700px; border:1px solid #e3ebf6; border-radius: 0.25rem;"
                id="qcuPreApprovedEmailIframe"></iframe>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h5 class="accordion-header m-0">
      <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse"
        [ngClass]="{ collapsed: !collapsibleSectionVisibilityStatuses.qcuInProcessEmail }"
        (click)="onEmailTemplateEditorVisibilityChanged('qcuInProcessEmail')"
        [attr.aria-expanded]="collapsibleSectionVisibilityStatuses.qcuInProcessEmail"
        data-bs-target="#qcu-in-process-override" aria-expanded="true" aria-controls="collapse1">
        Quick Client Update InProcess <span *ngIf="definedEmailTemplate.qcuInProcessOverride === true"
          class="text-success ms-1">- (Overridden)</span>
      </button>
    </h5>
    <div id="qcu-in-process-override" class="accordion-collapse collapse">
      <div class="accordion-body">
        <div class="form-group mb-3 row">
          <div class="col-sm-2"></div>
          <div class="col-sm-10">
            <div class="form-check form-check-inline">
              <input class="form-check-input" id="qcuInProcessOverride" type="checkbox"
                [(ngModel)]="definedEmailTemplate.qcuInProcessOverride"
                (change)="populateDefaultEmailTemplate(definedEmailTemplate.qcuInProcessOverride, 'QuickClientUpdateInProcess', 'qcuInProcessSubject', 'qcuInProcessEmail')">
              <label class="form-check-label" for="qcuInProcessOverride">Override QCU InProcess</label>
            </div>
          </div>
        </div>
        <ng-container *ngIf="definedEmailTemplate.qcuInProcessOverride">
          <div class="form-group mb-3 row">
            <div class="col-sm-2"></div>
            <div class="col-sm-10">
              <div class="form-check form-check-inline">
                <input id="qcuInProcessIncludeEmailSignature" class="form-check-input" type="checkbox"
                  [(ngModel)]="definedEmailTemplate.qcuInProcessIncludeEmailSignature">
                <label class="form-check-label" for="qcuInProcessIncludeEmailSignature">Include Email Signature
                </label>
              </div>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">CC</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="CC" type="text"
                [(ngModel)]="definedEmailTemplate.qcuInProcessCC">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">BCC</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="BCC" type="text"
                [(ngModel)]="definedEmailTemplate.qcuInProcessBCC">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">CC</label>
            <div class="col-sm-10">
              <ng-select width="100%" name="qcu-in-process-roles-cc" bindLabel="name" bindValue="value"
              placeholder="-- Select --" [closeOnSelect]="false" [multiple]="true" groupBy="groupName"
              [(ngModel)]="qcuInProcessRolesCC" [items]="roles" appendTo="body"
              (ngModelChange)="onRoleMultiselectChanged('qcuInProcessRolesCC')">
            </ng-select>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">BCC</label>
            <div class="col-sm-10">
              <ng-select width="100%" name="qcu-in-process-roles-bcc" bindLabel="name" bindValue="value"
              placeholder="-- Select --" [closeOnSelect]="false" [multiple]="true" groupBy="groupName"
              [(ngModel)]="qcuInProcessRolesBCC" [items]="roles" appendTo="body"
              (ngModelChange)="onRoleMultiselectChanged('qcuInProcessRolesBCC')">
            </ng-select>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Subject</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="Subject" type="text"
                [(ngModel)]="definedEmailTemplate.qcuInProcessSubject"
                (contextmenu)="onContextMenu($event, definedEmailTemplate, 'qcuInProcessSubject')">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <div class="col-sm-12 form-label align-self-center mb-1 d-flex justify-content-between">
              <div class="d-flex">
                <div class="special-radio-btn-group flex-grow-1">
                  <div class="btn-group">
                    <button type="button" class="btn btn-outline-primary tippy-btn mb-xs-1 me-1"
                      (click)="onEditTemplateClicked(definedEmailTemplate.qcuInProcessEmail, 'qcuInProcessEmail', definedEmailTemplate.qcuInProcessUsingStripoEditor)">
                      <div class="d-flex">
                        <span class="fw-bolder button-item-text flex-grow-1">
                          <i class="fa fa-pencil-alt"></i>
                        </span>
                        <span class="fw-bolder button-item-text filter-label flex-grow-1 ms-1">
                          Edit
                        </span>
                      </div>
                    </button>
                    <button type="button" class="btn btn-outline-primary tippy-btn mb-xs-1"
                      (click)="populateDefaultEmailTemplate(definedEmailTemplate.qcuInProcessOverride, 'QuickClientUpdateInProcess', 'qcuInProcessSubject', 'qcuInProcessEmail', true)">
                      <div class="d-flex">
                        <span class="fw-bolder button-item-text flex-grow-1">
                          <i class="fas fa-redo-alt fa-rotate-180"></i>
                        </span>
                        <span class="fw-bolder button-item-text filter-label flex-grow-1 ms-1">
                          Reload Default
                        </span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <iframe style="width:100%; height: 700px; border:1px solid #e3ebf6; border-radius: 0.25rem;"
                id="qcuInProcessEmailIframe"></iframe>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <!--Video Template-->
  <div class="accordion-item">
    <h5 class="accordion-header m-0">
      <button class="accordion-button fw-semibold" type="button" data-bs-toggle="collapse"
        [ngClass]="{ collapsed: !collapsibleSectionVisibilityStatuses.videoMessage }"
        (click)="onEmailTemplateEditorVisibilityChanged('videoMessageEmail')" data-bs-target="#videomessage-override"
        [attr.aria-expanded]="collapsibleSectionVisibilityStatuses.videoMessage" aria-controls="collapse1">
        Video Message Template<span *ngIf="definedEmailTemplate.videoMessageOverride === true" class="text-success ms-1">-
          (Overridden)</span>
      </button>
    </h5>
    <div id="videomessage-override" class="accordion-collapse collapse"
    [ngClass]="{ show: collapsibleSectionVisibilityStatuses.videoMessageEmail }">
      <div class="accordion-body">
        <div class="form-group mb-3 row">
          <div class="col-sm-2"></div>
          <div class="col-sm-10">
            <div class="form-check form-check-inline">
              <input id="videoMessageOverride" class="form-check-input" type="checkbox"
                (change)="populateDefaultEmailTemplate(definedEmailTemplate.videoMessageOverride, 'VideoMessage', 'videoMessageSubject', 'videoMessageEmail')"
                [(ngModel)]="definedEmailTemplate.videoMessageOverride">
              <label class="form-check-label" for="videoMessageOverride">Override Video Message Template</label>
            </div>
          </div>
        </div>
        <ng-container *ngIf="definedEmailTemplate.videoMessageOverride">
          <div class="form-group mb-3 row">
            <div class="col-sm-2"></div>
            <div class="col-sm-10">
              <div class="form-check form-check-inline">
                <input id="videoMessageIncludeEmailSignature" class="form-check-input" type="checkbox"
                  [(ngModel)]="definedEmailTemplate.videoMessageIncludeEmailSignature">
                <label class="form-check-label" for="videoMessageIncludeEmailSignature">Include Email Signature </label>
              </div>
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">CC</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="CC" type="text" [(ngModel)]="definedEmailTemplate.videoMessageCC">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">BCC</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="BCC" type="text" [(ngModel)]="definedEmailTemplate.videoMessageBCC">
            </div>
          </div>
          <div class="form-group mb-3 row">
            <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Subject</label>
            <div class="col-sm-10">
              <input class="form-control" placeholder="Subject" type="text"
                [(ngModel)]="definedEmailTemplate.videoMessageSubject"
                (contextmenu)="onContextMenu($event, definedEmailTemplate, 'videoMessageSubject')">
            </div>
          </div>
          <hr>
          <div class="form-group mb-3 row">
            <div class="col-sm-12 form-label align-self-center mb-1 d-flex justify-content-between">
              <div class="d-flex">
                <div class="special-radio-btn-group flex-grow-1">
                  <div class="btn-group">
                    <button type="button" class="btn btn-outline-primary tippy-btn mb-xs-1 me-1"
                      (click)="onEditTemplateClicked(definedEmailTemplate.videoMessageEmail, 'videoMessageEmail', definedEmailTemplate.videoMessageUsingStripoEditor)">
                      <div class="d-flex">
                        <span class="fw-bolder button-item-text flex-grow-1">
                          <i class="fa fa-pencil-alt"></i>
                        </span>
                        <span class="fw-bolder button-item-text filter-label flex-grow-1 ms-1">
                          Edit
                        </span>
                      </div>
                    </button>
                    <button type="button" class="btn btn-outline-primary tippy-btn mb-xs-1"
                      (click)="populateDefaultEmailTemplate(definedEmailTemplate.videoMessageOverride, 'videoMessage', 'videoMessageSubject', 'videoMessageEmail', true)">
                      <div class="d-flex">
                        <span class="fw-bolder button-item-text flex-grow-1">
                          <i class="fas fa-redo-alt fa-rotate-180"></i>
                        </span>
                        <span class="fw-bolder button-item-text filter-label flex-grow-1 ms-1">
                          Reload Default
                        </span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <iframe style="width:100%; height: 700px; border:1px solid #e3ebf6; border-radius: 0.25rem;"
                id="videoMessageEmailIframe">
              </iframe>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <!--Video Template-->

  <div class="text-end" *ngIf="type === 'system-level'">
    <div class="col-md-12 my-1">
      <hr />
    </div>
    <button type="submit" class="btn btn-primary right" [disabled]="isSaving" (click)="save()">
      <span *ngIf="!isSaving"><i class="fa fa-save me-2"></i> Save</span>
      <span *ngIf="isSaving">
        <span class="spinner-border spinner-border-sm"></span> Saving
      </span>
    </button>
  </div>
</div>

<merge-field-context-menu #appMergeFieldsMenu [menuItems]="availableMergeFields">
</merge-field-context-menu>

<merge-field-context-menu #leadMergeFieldsMenu [menuItems]="leadMergeFieldsWithCurrentNote">
</merge-field-context-menu>

<drawer #editEmailTemplateDrawer [name]="'editEmailTemplateDrawer'" [options]="editEmailTemplateDrawerOptions">
</drawer>
