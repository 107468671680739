import { UrlaMortgage } from "src/app/modules/urla/models/urla-mortgage.model";
import { CustomData, DataExchangeSyncDir, LoanApplication } from "../loan";
import { BorrowerDto } from "src/app/modules/contacts/models/borrower-dto.model";
import { LosEventDirection, LosEventOperation, LosEventSource, LosEventStatus } from "./los-events.model";

export class LosAppOperationResult {
  readonly refNumber: string;
  readonly losIdentifier: string;
  readonly losVendor: string;
  readonly losSyncDir: DataExchangeSyncDir;
  readonly losInstanceId: string;
  readonly application: LoanApplication;
  readonly loanBorrowers: BorrowerDto[];
  readonly mortgage: UrlaMortgage;
  readonly customData: CustomData[];
}

export interface LosEvent {
  readonly applicationId: number
  readonly companyId: number
  readonly dateInserted: string
  readonly dateUpdated: string
  readonly description: string
  readonly entityId: number
  readonly entityKey: string
  readonly eventDirection: LosEventDirection
  readonly eventOperation: LosEventOperation
  readonly eventSource: LosEventSource
  readonly eventType: string
  readonly insertedBy: string
  readonly losEntityId: string
  readonly losEventId: number
  readonly losIdentifier: string
  readonly losVendor: string
  readonly mortgageId: number
  readonly status: LosEventStatus
  readonly subStatus: string
  readonly updatedBy: string
  readonly errors: string
  readonly reqTraceId: string
}

export class LosTransactionHistory {
  readonly applicationTime: string = ""
  readonly losSyncDir: string = ""
  readonly losLoanTime: string = ""
  readonly events: Array<LosEvent> = []
  readonly selectiveUpdateEnabled: boolean;
}