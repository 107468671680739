import { formatDate } from '@angular/common';
import { AfterViewInit, Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription, catchError, defaultIfEmpty, firstValueFrom, from, lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApplicationContext, CustomData, LoanApplication, MortgageBorrower } from 'src/app/models';
import { LoanPurpose } from 'src/app/models/config/loan-purpose.model';
import { LoanProduct } from 'src/app/models/config/product.model';
import { LoanDetailsInfo } from 'src/app/models/loan/loan-details-info.model';
import { MortgageCalculationDetails } from 'src/app/models/mortgage-calculation-details.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { BorrowerDto } from 'src/app/modules/contacts/models/borrower-dto.model';
import { Lead } from 'src/app/modules/leads/models/lead.model';
import { ManageBorrowersComponent } from 'src/app/modules/urla/borrower-information/manage-borrowers/manage-borrowers.component';
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import { MortgageCalculationService } from 'src/app/modules/urla/services/mortgage-calculation.service';
import {
  AddMortgageBorrowerDialogComponent,
} from 'src/app/modules/urla/urla-main/add-mortgage-borrower-dialog/add-mortgage-borrower-dialog.component';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { DrawerOptions, DrawerService, DrawerSize } from 'src/app/shared/services/drawer.service';
import Swal from 'sweetalert2';
import { DiffChecker } from '../../../../../utils/diff-checker';
import { ComponentCanDeactivate } from '../../../../core/route-guards/pending-changes.guard';
import { MortgageService } from '../../../../services/mortgage.service';
import { AppDetailsService } from '../../services/app-details.service';
import { AssetAndCashFlowComponent } from './asset-and-cash-flow/asset-and-cash-flow.component';
import { CashFlowAnalysisComponent } from './cash-flow-analysis/cash-flow-analysis.component';
import { IncomeAnalysisComponent } from './income-analysis/income-analysis.component';
import { LiabilitiesInDetailComponent } from './liabilities-in-detail/liabilities-in-detail.component';
import { LoanBorrowerInfoComponent } from './loan-borrower-info/loan-borrower-info.component';
import { LoanLiabilitiesComponent } from './loan-liabilities/loan-liabilities.component';
import { LoanReferralInfoComponent } from './loan-referral-info/loan-referral-info.component';
import { PropertyInfoComponent } from './property-info/property-info.component';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { Utils } from 'src/app/core/services/utils';

@Component({
  selector: 'deal-structure',
  templateUrl: 'deal-structure.component.html',
  styleUrls: ['./deal-structure.component.scss']
})
export class DealStructureComponent extends ApplicationContextBoundComponent implements OnInit, OnDestroy, AfterViewInit, ComponentCanDeactivate {

  @ViewChild("loanFormV2") loanFormV2: NgForm;

  @ViewChild("loanLiabilities") loanLiabilities: LoanLiabilitiesComponent;

  @ViewChild("liabitiesInDetails") liabitiesInDetails: LiabilitiesInDetailComponent;

  @ViewChild("loanAsset") loanAsset: AssetAndCashFlowComponent;

  @ViewChild("cashFlowAnalysis") cashFlowAnalysis: CashFlowAnalysisComponent;

  @ViewChild("incomeAnalysis") incomeAnalysis: IncomeAnalysisComponent;

  application: LoanApplication;

  isPrequalificationCheckReadonly: any = {};

  lead = new Lead();

  mortgage: UrlaMortgage;

  mortgageCalculations: MortgageCalculationDetails;

  borrowerGroups: MortgageBorrower[][] = [];

  tab: number;

  context: ApplicationContext;

  products: LoanProduct[] = [];

  loanPurposes: LoanPurpose[];

  customData: CustomData[];

  showIncomeAnalysisCardBody: boolean = true;
  showCashflowAnalysisCardBody: boolean = false;
  showReferralCardBody: boolean = true;
  showBorrowerCardBody: boolean = true;
  showCreditReportingCardBody: boolean = true;
  showLiabilitiesAtGlanceCardBody: boolean = false;
  showLiabilitiesInDetailCardBody: boolean = false;
  showAssetsInDetailCardBody: boolean = false;
  showPricingCardBody: boolean = false;
  showAUSCardBody: boolean = false;
  isPurchase: boolean = false;
  pricingEnabled: boolean = false;
  desktopUnderwriterEnabled: boolean = false;
  lpaEnabled: boolean = false;
  desktopOriginatorEnabled: boolean = false;

  liabilityTypes: EnumerationItem[] = [];

  creditReportingHtml: string = "";

  isSubjectPropertyAddressTbd: boolean = false;

  isExceedFtc: boolean;

  isHomesiteHack = false;

  isMortgageDataInitializing: boolean = false;

  benefitCalculatorsDrawerOptions: DrawerOptions = {
    size: DrawerSize.Large,
    containerWrapperId: null
  }

  loanBorrowers: BorrowerDto[] = [];

  protected getBorrowerDisplayName: (borrower: MortgageBorrower) => string = () => '';

  protected borrowersThatWeCanRunCreditFor: MortgageBorrower[] = [];

  protected hasApplicationReceivedKeyDate: boolean = false;

  protected isLoanReadOnly: boolean = false;

  private _loanPurposeValueChanged: boolean;
  private _oldLoanPurposeValue: number;

  private _loanLosLdeLinkChangesSubscription: Subscription;
  private _loanInfoChangesSubscription: Subscription;
  private _purposeOfLoanChangedSubscription: Subscription;

  private _originalApplication: LoanApplication;
  private _originalMortgage: UrlaMortgage;
  private _originalCustomData: CustomData[];

  private _ngAfterViewInitSubject = new Subject<void>();
  private _initializeOriginalValuesSubject = new Subject<void>();
  private _initializeOriginalValuesSubscription: Subscription;

  private _initializingForTheFirstTime: boolean = true;

  // TODO system level:
  // creditReportingScript
  // valueStr="html", key="creditReportingScript"

  constructor(
    injector: Injector,
    private readonly _appDetailsService: AppDetailsService,
    private readonly _modalService: NgbModal,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notificationService: NotificationService,
    private readonly _enumsService: EnumerationService,
    private readonly _configurationService: ConfigurationService,
    private readonly _drawerService: DrawerService,
    private readonly _mortgageCalculationsService: MortgageCalculationService,
    private readonly _mortgageService: MortgageService
  ) {
    super(injector);

    this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe(context => {
      if (context.application && !this.isMortgageDataInitializing) {
        this.initializeLoanInfoV2(context);
      }
    });

    this._purposeOfLoanChangedSubscription = this._mortgageService.loanPurpose$
      .subscribe((updatedPurposeOfLoan: string) => {
        if (this.mortgage) {
          this.mortgage.subjectProperty.purposeOfLoan = updatedPurposeOfLoan;
          this.isPurchase = this.mortgage.subjectProperty.purposeOfLoan === "Purchase";
        }
      });
  }

  ngOnInit() {
    this.getBorrowerDisplayName = Utils.getBorrowerDisplayName;

    this.isHomesiteHack = this.applicationContext.userPermissions.companyId == 158;
    this.pricingEnabled = this.applicationContext.userPermissions.pricingEnabled;
    this.desktopUnderwriterEnabled = this.applicationContext.userPermissions.desktopUnderwriterEnabled;
    this.lpaEnabled = this.applicationContext.userPermissions.lpaEnabled;
    this.desktopOriginatorEnabled = this.applicationContext.userPermissions.desktopOriginatorEnabled;
    this.showLiabilitiesAtGlanceCardBody = !this.isHomesiteHack;
    this.showLiabilitiesInDetailCardBody = this.isHomesiteHack;
    this.showAssetsInDetailCardBody = this.isHomesiteHack;

    this._enumsService.getMortgageEnumerations().subscribe(enums => {
      this.liabilityTypes = enums[Constants.enumerations.liabilityTypes];
    });
    if (this.applicationContext.application && !this.application) {
      this.initializeLoanInfoV2(this.applicationContext);
    }

    this._configurationService.getCompanyConfiguration('CreditReportingScript').subscribe(creditReportingScript => {
      this.creditReportingHtml = creditReportingScript.valueStr ?? "";
    })
  }

  ngAfterViewInit(): void {
    const subject = this._ngAfterViewInitSubject;
    subject.next();
    subject.complete();
  }

  ngOnDestroy(): void {
    if (this._loanLosLdeLinkChangesSubscription) {
      this._loanLosLdeLinkChangesSubscription.unsubscribe();
    }
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
    if (this._purposeOfLoanChangedSubscription) {
      this._purposeOfLoanChangedSubscription.unsubscribe();
    }
    this._initializeOriginalValuesSubscription?.unsubscribe();
  }

  private _isDirty = (): boolean => {
    const diffCheckers = [
      new DiffChecker(
        this._originalApplication,
        this.application,
        'application',
      ),
      new DiffChecker(
        this._originalMortgage,
        this.mortgage,
        'mortgage',
      ),
      new DiffChecker(
        this._originalCustomData,
        this.customData,
        'customData',
      ),
    ];

    return diffCheckers
      .map((e) => e.calculateDiff(true))
      .some(Boolean);
  }

  canDeactivate = (): boolean | Observable<boolean> => {
    // dont check when session expires
    if (!this.applicationContext) return true;

    // Any of the "original" fields can be used here.
    return this._originalApplication == null || !this._isDirty();
  }


  private showNotSavedDialog = async (): Promise<boolean> => {
    const result = await Swal.fire({
      showDenyButton: true,
      title: 'Are you sure?',
      text: `You have changes that are not saved. Would you like to save your changes? If you choose Discard below, your changes will be lost.`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Save & Continue!',
      cancelButtonText: 'Cancel',
      cancelButtonColor: "#DD6B55",
      denyButtonText: `Discard & Continue!`,
      reverseButtons: true
    });

    if (result.isDenied) return true;
    if (!result.isConfirmed) return false;

    await this.save();

    return true;
  }

  confirm(): boolean | Observable<boolean> {
    return from(this.showNotSavedDialog());
  }

  protected loanBorrowersMap: Map<number, BorrowerDto> = new Map<number, BorrowerDto>();

  private adjustBorrowers = (borrowers: MortgageBorrower[]) => {
    this.borrowerGroups = [];

    this.borrowerGroups = borrowers.length > 0 ? Object.values(_.groupBy(borrowers, 'printApplicationIndex')) : [];

    if (this.borrowerGroups.length > 0) {
      this.tab = this.borrowerGroups[0][0].borrowerId;
    }

    borrowers.forEach(borrower => {
      const loanBorrower = this.loanBorrowers.find(b => b.borrowerId === borrower.contactId);
      this.loanBorrowersMap.set(borrower.borrowerId, loanBorrower);
      if (borrower.authorizationMethod === "Internet") {
        this.isPrequalificationCheckReadonly[borrower.borrowerId.toString()] = true;
        borrower.authorizedCreditCheck = true;
      }

    });
  }

  private _resetOriginalValuesSubscription = () => {
    const initializeOriginalValuesSubject = this._initializeOriginalValuesSubject;
    if (initializeOriginalValuesSubject != null) {
      this._initializeOriginalValuesSubscription?.unsubscribe();
      initializeOriginalValuesSubject.complete();
    }

    this._initializeOriginalValuesSubscription = this._ngAfterViewInitSubject
      .pipe(
        defaultIfEmpty(undefined),
        map(() => undefined),
      )
      .subscribe(initializeOriginalValuesSubject);
  }

  private _resetOriginalValues = async (): Promise<void> => {
    this._resetOriginalValuesSubscription();

    this._originalApplication = null;
    this._originalMortgage = null;
    this._originalCustomData = null;

    // Wait for default values on child components to be set.
    await lastValueFrom(
      this._initializeOriginalValuesSubject,
      { defaultValue: undefined },
    );

    this._originalApplication = _.cloneDeep(this.application);
    this._originalMortgage = _.cloneDeep(this.mortgage);
    this._originalCustomData = _.cloneDeep(this.customData);
  }

  private initializeLoanInfoV2 = (context: ApplicationContext): Promise<void> => {
    this.application = _.cloneDeep(context.application);
    this.isLoanReadOnly = this.applicationContext.applicationIsReadOnly;
    this.context = context;
    this.products = context.globalConfig.product;
    this.customData = _.cloneDeep(context.customData);
    this.loanPurposes = context.globalConfig.loanPurpose;
    this._oldLoanPurposeValue = this.application.loanPurposeId;
    this.mortgage = _.cloneDeep(context.currentMortgage);
    this.mortgageCalculations = context.currentMortgageCalculationDetails;
    if (this._initializingForTheFirstTime) {
      this.showReferralCardBody = !this.application.loanPurposeId || !this.application.loanTypeId;
    }

    this.borrowersThatWeCanRunCreditFor = this.mortgage.borrowers.filter(b => b.signingRole === "Borrower" || b.signingRole === "CoSigner");

    this.loanBorrowers = context.borrowers;

    this.initializeMortgageRelatedData();

    this._initializingForTheFirstTime = false;

    if (context.applicationKeyDatesByType) {
      this.hasApplicationReceivedKeyDate = !!context.applicationKeyDatesByType['applicationReceived']?.eventDate;
    }

    return this._initializeOriginalValues();
  }

  private _initializeOriginalValues = (): Promise<void> => {
    const { mortgage } = this;
    try {
      const { borrowers } = mortgage;

      borrowers.forEach(this.onYesNoOptionChanged);
      this.setMortgageFieldsProperlyForEachChildToEnsureProperDirtyCheck();
    } catch (e) {
      console.error(e);
      throw e;
    }

    return this._resetOriginalValues();
  }

  calculateIfTotalAssetsExceedsFtc = (cashMarketSubTotal?: number) => {
    if (!cashMarketSubTotal) {
      cashMarketSubTotal = 0;
      this.mortgage?.assets?.forEach(asset => {
        cashMarketSubTotal += Number(asset.cashMarketValue || 0);
      });
    }

    const ftc = this.applicationContext.currentMortgageCalculationDetails?.fundsToClose || {};
    this.isExceedFtc = cashMarketSubTotal >= ftc.cashFromToBorrower;
  }

  selectTab = (id) => {
    this.tab = id;
  }

  addAsset = () => {
    this.showAssetsInDetailCardBody = true;
    setTimeout(() => this.loanAsset.addAsset(), 100);
  }

  addLiability = () => {
    this.showLiabilitiesInDetailCardBody = true;
    setTimeout(() => this.liabitiesInDetails.addLiability(), 100);
  }

  onLeadUpdated = () => {
  }

  onAddNewBorrowerClicked = () => {
    const modalRef = this._modalService.open(AddMortgageBorrowerDialogComponent, Constants.modalOptions.xlarge);
    modalRef.componentInstance.mortgage = _.cloneDeep(this.mortgage);

    modalRef.result.then((selectedBorrower: MortgageBorrower) => {
      this._appDetailsService.getBorrowers(this.application.applicationId).subscribe((borrowers) => {
        selectedBorrower.mortgageId = this.mortgage.mortgageId;
        this.mortgage.borrowers.push(selectedBorrower);
        this.adjustBorrowers(this.mortgage.borrowers);
        this.applicationContextService.updateMortgageAndApplication(this.mortgage, this.application, undefined, borrowers);
      })
    }, error => { })
  }

  onManageBorrowersClicked = () => {
    const modalRef = this._modalService.open(ManageBorrowersComponent, { centered: true });
    modalRef.componentInstance.mortgageBorrowers = _.cloneDeep(this.mortgage.borrowers);

    modalRef.result.then(savedBorrowers => {
      let removedBorrower = this.mortgage.borrowers.length != savedBorrowers.length;

      if (removedBorrower) {
        this.applicationContextService.reloadApplicationAndMortgagePostAction(this.application.applicationId).subscribe(appContext => { });
      }
      else {
        this.mortgage.borrowers = savedBorrowers;
        this.adjustBorrowers(this.mortgage.borrowers);
        this.applicationContextService.updateMortgageAndApplication(this.mortgage, this.application);
      }
    }, error => { })
  }

  save = async (): Promise<void> => {
    this.loanFormV2.form.markAllAsTouched();
    if (!this.loanFormV2.form.valid) {
      return;
    }

    if (this.application.productPricing && this.application.productPricing.productId) {
      let product = this.products.find(el => el.productId.toString() == this.application.productPricing.productId);
      if (product) {
        this.application.productPricing.productName = product.productName;
      }
    }

    let payLoad = new LoanDetailsInfo();
    payLoad.application = this.application;
    payLoad.customData = null;
    this.mortgage.extension.isPreApprovalLoan = this.isSubjectPropertyAddressTbd;
    payLoad.application.mortgageLoan = this.mortgage;

    await this._spinner.show();

    try {
      const loanDetailsInfo = await firstValueFrom(
        this._appDetailsService.saveLoanInfo(this.application.applicationId, payLoad),
      );

      if (!loanDetailsInfo) {
        return;
      }
      const borrowers = await firstValueFrom(
        this._appDetailsService.getBorrowers(this.application.applicationId),
      );
      this._oldLoanPurposeValue = this.application.loanPurposeId;
      this.applicationContextService.updateMortgageAndApplication(loanDetailsInfo.application.mortgageLoan, loanDetailsInfo.application, undefined, borrowers);
      this._notificationService.showSuccess(
        'Your loan has been saved successfully.',
        'Success!',
      );

      this._initializingForTheFirstTime = true;

      if (this._loanPurposeValueChanged) {
        this._loanPurposeValueChanged = false;
        await this.saveLoanStatus();
      } else {
        await this._spinner.hide();
      }
    } catch (error) {
      this._notificationService.showError(
        error.message ?? 'Unable to save Loan Info',
        'Error!',
      );

      await this._spinner.hide();
    }
  };
  onYesNoOptionChanged = (borrower) => {
    // FIXME: If the today date is changed after component init, the dirty check
    //  will be broken.
    const today = new Date();
    borrower.dateAuthorizedCreditCheck = borrower.authorizedCreditCheck ? formatDate(today, 'MM/dd/yyyy', 'en-US') : undefined;
  }

  onLoanPurposeChanged() {
    this._loanPurposeValueChanged = this._oldLoanPurposeValue != this.application.loanPurposeId;
  }

  onLiabilitiesChanged = (source: string) => {
    if (source !== 'loanLiabilities')
      this.loanLiabilities.refresh();

    this.cashFlowAnalysis?.refresh();

  }

  onIncomesChanged = () => {
    // this.incomeAnalysis.refresh();
    // this.cashFlowAnalysis.refresh();
  }

  onCalculatorsClicked = () => {
    this._drawerService.show("benefitCalculatorsDrawer", 10);
  }

  private updateApplication = () => {
    this.applicationContextService.updateApplication(this.application);
  }

  private saveLoanStatus = async (): Promise<void> => {
    const rethrow = (error: { message?: string }, fallbackMsg: string) => {
      error.message ??= fallbackMsg;
      throw error;
    };

    try {
      const result = await firstValueFrom(
        this._appDetailsService.setLoanStatus(this.application.applicationId),
      );
      if (result == null) {
        return;
      }

      const loanStatusId = this.application.loanStatusId ? this.application.loanStatusId : -1;

      await firstValueFrom(
        this._appDetailsService.setNextLoanStatus(this.application.loanPurposeId, loanStatusId, this.application.applicationId)
          .pipe(catchError((error) => rethrow(error, 'Unable to set next Loan Status'))),
      );

      this.updateApplication();
    } catch (error) {
      this._notificationService.showError(
        error.message ?? 'Unable to set Loan Status',
        'Error!',
      );
    } finally {
      await this._spinner.hide();
    }
  }

  private initializeMortgageRelatedData = () => {
    this.calculateIfTotalAssetsExceedsFtc();
    this.isPurchase = this._mortgageCalculationsService.isPurposeOfLoanPurchase(this.mortgage);
    this.isSubjectPropertyAddressTbd = this.mortgage.subjectProperty.address1?.toLocaleLowerCase() === 'tbd';
    this.adjustBorrowers(this.mortgage.borrowers);
  }

  // Every child component does its own thing and sets default values etc when they do their
  // binding. Since that changes the original models we give to these child components, it
  // messes up our dirty check and creates false positives. We set the defaults here in the parent
  // component, so that every initial value in these child components are exactly like we want.
  // The same thing can be achieved potentially at "setters" of these individual components, but for
  // that to work, *ngIfs on these components need to change to [hidden]. We did not do that to avoid
  // drastic changes to the general structure for now.
  private setMortgageFieldsProperlyForEachChildToEnsureProperDirtyCheck = () => {
    const { application, mortgage } = this;
    const { borrowers } = mortgage;

    borrowers.forEach(LoanBorrowerInfoComponent.setBorrowerDefaults);
    borrowers.forEach(IncomeAnalysisComponent.setBorrowerDefaults);
    AssetAndCashFlowComponent.setMortgageDefaults(mortgage);
    LoanReferralInfoComponent.setApplicationDefaults(application);
    LiabilitiesInDetailComponent.setMortgageDefaults(mortgage);
    PropertyInfoComponent.setMortgageDefaults(mortgage);
  }
}
