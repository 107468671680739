import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalConfig } from 'src/app/models/config/global-config.model';
import { DocumentTemplatePage } from 'src/app/models/document-template-page.model';
import { AdminService } from 'src/app/services/admin.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { LoanService } from 'src/app/services/loan';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { Subscription } from "rxjs";
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'document-generate',
  templateUrl: './document-generate.component.html',
  styleUrls: ['./document-generate.component.scss']
})
export class DocumentGenerateComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input()
  loanDocTaskId: number;

  @Input()
  appId: number;

  @Input()
  selectedTemplateId: number | '';

  @Input()
  shouldDcoTemplateBeDisabled = false;

  @Output()
  onDocumentGenerated: EventEmitter<never> = new EventEmitter<never>();

  @Output()
  onCancel: EventEmitter<boolean> = new EventEmitter<boolean>();

  pages: DocumentTemplatePage[] = [];

  currentPage: DocumentTemplatePage;

  globalConfig: GlobalConfig;

  currentPageIndex: number = -1;

  steps: MenuItem[] = [];

  noInput: boolean = false;
  isLoading: boolean = false;

  private _loanInfoChangesSubscription: Subscription;
  private _hasPreSelectedDocTemplate: boolean;

  constructor(
    private readonly _configurationService: ConfigurationService,
    private readonly _loanService: LoanService,
    private readonly _adminService: AdminService,
    private readonly _notifyService: NotificationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this._hasPreSelectedDocTemplate = !!this.selectedTemplateId;
    if (!this.selectedTemplateId) {
      this.selectedTemplateId = '';
    }
    // KAAN: Again If branch below is weird - why, why and why?? Scared to change it...
    if (!this.applicationContext?.globalConfig) {
      this.applicationContextService.loanInfoChanges.subscribe((context) => {
        this.globalConfig = context.globalConfig;
        if (this.selectedTemplateId) {
          this.start();
        }
      }, (err) => {
        console.log(err);
      });
    } else {
      this.globalConfig = this.applicationContext.globalConfig;
      if (this.selectedTemplateId) {
        this.start();
      }
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
  }

  selectedTemplateChanged = () => {
    this.currentPage = null;
    this.pages = [];
    this.initSteps();
  }

  private _loadPages(pages: DocumentTemplatePage[]) {
    if (pages.length === 0) {
      this.currentPageIndex = -1;
      return
    }

    this.pages = pages;
    this.currentPageIndex = 0;
    this.currentPage = this.pages[this.currentPageIndex];

    this.initSteps(pages);
  }

  start = () => {
    if (!this.selectedTemplateId) {
      return
    }

    this.isLoading = true;
    this._configurationService.getPages(Number(this.selectedTemplateId))
      .subscribe({
        next: (pages) => {
          this._loanService.getMergeFieldValues(this.appId, Number(this.selectedTemplateId)).subscribe({
            next: (mergeFields) => {
              pages = pages.filter(p => !!p.fields?.length);

              if (pages.length === 0) {
                this.noInput = true;
                this.currentPageIndex++;
              } else {
                pages.forEach(page => {
                  page.fields.forEach(field => {
                    if (!field.globalMergeFieldKey) {
                      return;
                    }

                    const fields = field.globalMergeFieldKey.split(',');
                    const fieldValue = mergeFields?.filter(e => fields.includes(e.fieldKey))?.map(x => x.value);
                    if (fieldValue) {
                      field.defaultValue = fieldValue.join(" ");
                    }
                  });
                });
                this._loadPages(pages);
              }
            },
            error: (error) => {
              this._notifyService.showError(error?.message || "Couldn't load merge fields.", 'Error!');
            }
          }).add(() => this.isLoading = false)
        },
        error: (error) => {
          this._notifyService.showError(error?.message || "Couldn't load pages.", 'Error!');
          this.isLoading = false;
        }
      });
  }

  onBackClicked = () => {
    this.currentPageIndex = this.currentPageIndex - 1;

    if (this.currentPageIndex === -1) {
      this.currentPage = null;
      this.noInput = false;
    } else {
      this.currentPage = this.pages[this.currentPageIndex];
    }
  }

  onNextClicked = () => {
    this.currentPageIndex++;
    this.currentPage = this.pages[this.currentPageIndex];
  }

  save = (useOldGenerator: boolean) => {
    const fields = this.pages.map(function (page) {
      return page.fields
    });

    const flattenFields = Array.prototype.concat(...fields);

    const replacementValues = {};
    flattenFields.forEach((field) => {
      if (!field.defaultValue) {
        return
      }

      if (field.fieldType === 'Currency') {
        replacementValues[field.fieldKey] = this.formatCurrency(field.defaultValue);
      } else if (field.fieldType === 'Percent') {
        replacementValues[field.fieldKey] = this.formatPercentage(field.defaultValue);
      } else {
        replacementValues[field.fieldKey] = field.defaultValue;
      }
    });

    const request = {
      documentTemplateId: this.selectedTemplateId,
      applicationId: this.appId,
      replacementValues: replacementValues,
      useOldGenerator: useOldGenerator
    }
    if (this.loanDocTaskId) {
      request["loanDocTaskId"] = this.loanDocTaskId;
    }

    this._spinner.show();
    this._adminService.postGeneratedDocuments(request).subscribe({
      next: () => {
        this._notifyService.showSuccess("Document template generated!", 'Success!');
        this.onDocumentGenerated.emit();
      },
      error: (error) => {
        this._notifyService.showError(error?.message || "Couldn't generate document.", 'Error!');
      }
    }).add(() => this._spinner.hide())
  }

  private formatCurrency = (currency) => {
    return '$' + currency.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }

  private formatPercentage = (percentage) => {
    return (percentage * 100).toFixed(3) + '%';
  }

  private initSteps = (pages?: any) => {
    this.steps = [
      { label: 'Select Document Template' }
    ];

    if (!!pages?.length) {
      pages.forEach(p => this.steps.push({ label: p.title }));
      this.steps = [...this.steps];
    }
  }
}
