import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'view-notes-advisories-dialog',
  templateUrl: './view-notes-advisories-dialog.component.html',
  styleUrls: ['./view-notes-advisories-dialog.component.scss']
})
export class ViewNotesAdvisoriesDialogComponent implements OnInit {
  @Input() notesAndAdvisories: string[];
  @Input() pricingRequestPayload: string;

  parameters: {name: string, value: string, type: string}[] = [];

  globalSearchString: string;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    const parameters = this.pricingRequestPayload ? JSON.parse(this.pricingRequestPayload)?.CreditApplicationFields || [] : [];
    this.parameters = parameters.map(p => {
      return { name: p.fieldName, value: p.value?.value, type: p.value?.Style };
    })
  }

}
