import { Component, Injector, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import _ from "lodash";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { NgxSpinnerService } from "ngx-spinner";
import { firstValueFrom, Observable, of } from "rxjs";
import { Utils } from "src/app/core/services/utils";
import { CityCountyState } from "src/app/models/city-county-state.model";
import { EnumerationItem } from "src/app/models/simple-enum-item.model";
import { ZipCodeLookupResult } from "src/app/models/zipcode-lookup-result.model";
import { PricingEngineVendor } from "src/app/modules/admin/tpo-config/models/configuration-product.model";
import { Channel } from "src/app/modules/leads/models/lead.model";
import { CityCountyPickerDialogComponent } from "src/app/modules/urla/components/city-county-picker-dialog/city-county-picker-dialog.component";
import { Constants } from "src/app/services/constants";
import { EnumerationService } from "src/app/services/enumeration-service";
import { NotificationService } from "src/app/services/notification.service";
import { ZipCodeService } from "src/app/services/zipcode.service";
import { ApplicationContextBoundComponent } from "src/app/shared/components/application-context-bound.component";
import { EnabledBusinessChannel } from "../../../models/business-channel.model";
import { BaseRequest } from "../../../models/pricing/base-request.model";
import { CustomFieldSection } from "../../../models/pricing/custom-field-section.model";
import { CustomField } from "../../../models/pricing/custom-fields.model";
import { PricingFieldAccess, PricingFieldSpec } from "../../../models/pricing/pricing-field-spec.model";
import { CustomFieldValue, ExpandedGuidelines, LoanInformation, ProductSearchRequest, ProductSearchRequestInfo } from "../../../models/pricing/product-search-request-info.model";
import { VendorFeatureRequest } from "../../../models/pricing/product-search-result.model";
import { PricingConfigurationService } from "../../../services/pricing-configuration.service";
import { PricingService } from "../../../services/pricing.service";
import { PricingTransactionType } from "../../pricing-details/pricing-details.component";
import { SecondLientAmountCalculatorDialogComponent } from "../../pricing-parameters/second-lien-amount-calculator-dialog/second-lien-amount-calculator-dialog.component";
import { LoanApplication } from "src/app/models";
import { UrlaMortgage } from "src/app/modules/urla/models/urla-mortgage.model";
import { BranchService } from "src/app/modules/admin/company/services/branch.service";

@Component({
  template: ''
})
export abstract class PricingParametersBase extends ApplicationContextBoundComponent implements OnInit {

  @Input()
  selectedVendor: string;

  @Output()
  requestReady: EventEmitter<ProductSearchRequest> = new EventEmitter<ProductSearchRequest>();

  @Input() // This is used when pricing is during the new application wizard
  externalLoanAndMortgageInfo: ExternalLoanAndMortgageInfo;

  @Input()
  set enabledChannels(enabledChannels: EnabledBusinessChannel[]) {
    this._enabledChannels = enabledChannels;
    this.groupSelectedVendorPricingProfiles();
    if (this.selectedVendor == 'OptimalBlue') {
      if (this.enabledChannels.length > 0)
        this.businessChannelId = this.enabledChannels[0].pricingChannelId;
    } else {
      if (this.enabledChannels.length > 0) {
        this.selectedProfileChannel = this.enabledChannels[0];

        if (this.request) {
          this.request.credentialId = this.selectedProfileChannel.credentialId;
          this.request.pricingChannelId = this.selectedProfileChannel.pricingChannelId;
        }
      }
    }
  }

  get enabledChannels(): EnabledBusinessChannel[] {
    return this._enabledChannels;
  }

  @Input()
  applicationChannel: Channel | null;

  @Input()
  channelSelectionEnabled: boolean = true;

  @Input()
  transactionType: PricingTransactionType = null;

  @Input()
  showMultipleColumns: boolean;

  get visibleFeatureFields(): string[] {
    return this._visibleFeatureFields;
  }

  @Input()
  set visibleFeatureFields(value: string[]) {
    this._visibleFeatureFields = value;
    if (!this.request || this.request.applicationId) {
      return;
    }
    if (this.multipleLoanTypesVisible && !this.request.loanTypes?.length) {
      const defaultLoanTypesForQuickPricer = ['Conventional', 'FHA', 'VA', 'USDA'];
      this.selectedLoanTypes = this.filteredLoanTypeOptions.filter(f => defaultLoanTypesForQuickPricer.includes(f.value))
      this.onMultipleLoanTypesChanged();
    } else {
      this.request.loanTypes = [];
    }
  }

  private _visibleFeatureFields: string[] = [];

  protected request: ProductSearchRequest;
  protected disableDti: boolean;

  private _businessChannelId: number | string = 0;

  get businessChannelId(): number | string {
    return this._businessChannelId;
  }
  protected set businessChannelId(value: number | string) {
    this._businessChannelId = value;
  }

  protected notifsService: NotificationService;
  protected pricingService: PricingService;
  protected spinnerService: NgxSpinnerService;
  protected enumsService: EnumerationService;
  protected zipCodeService: ZipCodeService;
  protected modalService: NgbModal;
  protected pricingConfigService: PricingConfigurationService;
  protected branchService: BranchService;

  protected desiredLockPeriodsList = [
    { value: 15, name: '15' },
    { value: 30, name: '30' },
    { value: 45, name: '45' },
    { value: 60, name: '60' },
    { value: 75, name: '75' },
  ];

  protected disableCreditScore: boolean = false;

  protected citizenshipOptions: EnumerationItem[] = [];
  protected yesNoOptions: EnumerationItem[] = [];
  protected loanPurposeOptions: EnumerationItem[] = [];
  protected refinancePurposeOptions: EnumerationItem[] = [];
  protected stateOptions: EnumerationItem[] = [];
  protected occupancyOptions: EnumerationItem[] = [];
  protected typeOfVeteranOptions: EnumerationItem[] = [];
  protected propertyTypeOptions: EnumerationItem[] = [];
  protected numberOfUnitsOptions: EnumerationItem[] = [];
  protected loanTermOptions: EnumerationItem[] = [];
  protected loanTypeOptions: EnumerationItem[] = [];
  protected filteredLoanTypeOptions: EnumerationItem[] = [];
  protected amortizationTypeOptions: EnumerationItem[] = [];
  protected armFixedTermOptions: EnumerationItem[] = [];
  protected productTypeOptions: EnumerationItem[] = [];
  protected filteredProductTypeOptions: EnumerationItem[] = [];
  protected projectTypeOptions: EnumerationItem[] = [];
  protected buyDownOptions: EnumerationItem[] = [];
  protected filteredBuyDownOptions: EnumerationItem[] = [];
  protected filteredDocumentationTypes: EnumerationItem[] = [];
  protected automatedUnderwritingSystemOptions: EnumerationItem[] = [];
  protected includeLoCompensationInPricingOptions: EnumerationItem[] = [];
  protected compensationPercentBasedOnOptions: EnumerationItem[] = [];
  protected documentationTypeOptions: EnumerationItem[] = [];
  protected constructionMethodOptions: EnumerationItem[] = [];
  protected attachmentTypeOptions: EnumerationItem[] = [];
  protected prepaymentPenaltyOptions: EnumerationItem[] = [];
  protected manufacturedHomeWidthTypeOptions: EnumerationItem[] = [];
  protected projectDesignTypeOptions: EnumerationItem[] = [];
  protected currentPropertyWillBeTypes: EnumerationItem[] = [];
  protected lienPositionTypeOptions: EnumerationItem[] = [];
  protected incomeVerificationType: EnumerationItem[] = [];
  protected bankStatementExpenseMethod: EnumerationItem[] = [];
  protected incomeVerificationMethod: EnumerationItem[] = [];
  protected investorExperience: EnumerationItem[] = [];
  protected bankruptcySeasoning: EnumerationItem[] = [];
  protected bankruptcyType: EnumerationItem[] = [];
  protected tradelineType: EnumerationItem[] = [];
  protected deedInLieuType: EnumerationItem[] = [];
  protected chareOffType: EnumerationItem[] = [];
  protected foreclosureType: EnumerationItem[] = [];
  protected shortSaleType: EnumerationItem[] = [];
  protected forbearanceType: EnumerationItem[] = [];
  protected bankruptcyOutcome: EnumerationItem[] = [];
  protected housingEventType: EnumerationItem[] = [];
  protected housingEventSeasoning: EnumerationItem[] = [];
  protected suffixes: EnumerationItem[] = [];
  protected loanTerms: any = {};
  protected financeEntireAmount: boolean = true;
  protected creditProviders: EnumerationItem[] = [];
  protected months1224: EnumerationItem[] = [
    { name: '-- Select One --', value: null },
    { name: '12 Months', value: 12 },
    { name: '24 Months', value: 24 }
  ];

  protected manuallyEnterPmiMipFfGFeeDetails: boolean = false;

  protected hasMortgageLates: boolean = false;

  protected helocOrMortgageLoanType: string = "Mortgage";

  protected selectedAmortizationTypes: EnumerationItem[] = [
    { name: 'Fixed', value: 'FixedRate' },
  ];

  protected selectedLoanTerms: EnumerationItem[] = [
    { name: '30 Year', value: 360 },
  ];

  protected selectedArmFixedTerms: EnumerationItem[] = [];

  protected selectedProductTypes: EnumerationItem[] = [
    { name: 'Standard Products', value: 'StandardProducts' },
  ];

  protected selectedLoanTypes: EnumerationItem[] = [];

  public selectedProfileChannel: EnabledBusinessChannel = null;

  protected groupedVendorProfiles: any[] = [];
  protected countyList: EnumerationItem[] = [];
  protected showMonthlyIncome: boolean = true;

  protected multiSelectSettings: IDropdownSettings = {};
  protected loanTypesMultiSelectSettings: IDropdownSettings = {};

  protected lastZipCodeSearch: string;

  private _enabledChannels: EnabledBusinessChannel[] = [];
  private _originalProductSearchRequest: ProductSearchRequest;

  get originalProductSearchRequest(): ProductSearchRequest {
    return this._originalProductSearchRequest;
  }

  protected set originalProductSearchRequest(value: ProductSearchRequest) {
    this._originalProductSearchRequest = value;
  }

  get isPurchase(): boolean {
    const loanPurposePurchaseEnumValue = this.enumsService.getEnumValue(
      Constants.enumerationValueNames.LoanPurposeType.Purchase
    );
    return (
      this.request.loanInformation.loanPurpose ===
      loanPurposePurchaseEnumValue
    );
  }

  get isHomeEquityLoanType(): boolean {
    if (this.multipleLoanTypesVisible) {
      return this.request.loanTypes.includes('HomeEquity');
    }
    return this.request.loanInformation.loanType === 'HomeEquity';
  }

  get isVALoan(): boolean {
    if (this.multipleLoanTypesVisible) {
      return this.request.loanTypes.includes('VA');
    } else {
      return this.request.loanInformation.loanType === 'VA'
    }
  }

  get isFHA(): boolean {
    if (this.multipleLoanTypesVisible) {
      return _.isArray(this.request.loanTypes) ? this.request.loanTypes.includes('FHA') : false;
    }
    return this.request.loanInformation?.loanType === 'FHA';
  }

  get multipleLoanTypesVisible(): boolean {
    return this.visibleFeatureFields.includes('Settings_MultipleLoanTypes');
  }

  get isQuickPricer() {
    return !!!this.applicationContext?.application;
  }

  get totalLoanAmount(): number {
    const totalLoanAmount = this.request.loanInformation.baseLoanAmount +
      (this.request.loanInformation.upfrontPmiMipFfGfFinancedAmount
        ? this.request.loanInformation.upfrontPmiMipFfGfFinancedAmount
        : 0);
    return totalLoanAmount;
  }

  protected fieldSpecs: PricingFieldSpec[] = [];

  protected readonlyFields: string[] = [];
  protected hiddenFields: string[] = [];
  protected hiddenEnumOptionsByField: Map<string, string[]> = new Map();

  protected customFieldSections: CustomFieldSection[] = [];
  protected customFieldValues: any = {};

  constructor(private readonly _injector2: Injector
  ) {
    super(_injector2);
    this.notifsService = this._injector2.get(NotificationService);
    this.pricingService = this._injector2.get(PricingService);
    this.spinnerService = this._injector2.get(NgxSpinnerService);
    this.enumsService = this._injector2.get(EnumerationService);
    this.zipCodeService = this._injector2.get(ZipCodeService);
    this.modalService = this._injector2.get(NgbModal);
    this.pricingConfigService = this._injector2.get(PricingConfigurationService);
    this.branchService = this._injector2.get(BranchService);

    this.multiSelectSettings = {
      idField: 'value',
      textField: 'name',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };

    this.loanTypesMultiSelectSettings = {
      idField: 'value',
      textField: 'name',
      itemsShowLimit: 5,
      allowSearchFilter: true,
    };

    this.enumsService.getPricingEnumerations().subscribe((enums) => {
      this.citizenshipOptions = enums[Constants.pricingEnumerations.citizenship];
      this.yesNoOptions = enums[Constants.pricingEnumerations.yesNo];
      this.occupancyOptions = enums[Constants.pricingEnumerations.occupancy];
      this.typeOfVeteranOptions = enums[Constants.pricingEnumerations.typeOfVeteran];
      this.numberOfUnitsOptions = enums[Constants.pricingEnumerations.numberOfUnits];

      this.buyDownOptions = enums[Constants.pricingEnumerations.buyDown];
      this.automatedUnderwritingSystemOptions = enums[Constants.pricingEnumerations.automatedUnderwritingSystem];
      this.armFixedTermOptions = this.enumsService.armFixedTerms;
      this.documentationTypeOptions = enums[Constants.pricingEnumerations.documentationType];

      this.prepaymentPenaltyOptions = enums[Constants.pricingEnumerations.prepaymentPenalty];

      // We do these for the fields on the bottom, since these 2 fields come in from the server with text enum values as opposed to integer Ids.
      const productTypeOptions = enums[Constants.pricingEnumerations.productType];
      this.productTypeOptions = productTypeOptions.map(
        (o) => (o.value = new EnumerationItem(o.name, o.alternateValue, undefined, undefined, o.supportedPricingVendors))
      );
      this.loanTermOptions = this.enumsService.loanTerms;

      this.includeLoCompensationInPricingOptions = enums[Constants.pricingEnumerations.includeLoCompensationPricing];
      this.compensationPercentBasedOnOptions = enums[Constants.pricingEnumerations.compensationPercentBasedOn]

      this.stateOptions = this.enumsService.states;

      this.incomeVerificationType = enums[Constants.pricingEnumerations.incomeVerificationType]
        .map(x => ({ ...x, name: this.getSplittedEnumValue(x.name) }));
      this.incomeVerificationMethod = enums[Constants.pricingEnumerations.incomeVerificationMethod]
        .map(x => ({ ...x, name: this.getSplittedEnumValue(x.name) }));
      this.bankStatementExpenseMethod = enums[Constants.pricingEnumerations.bankStatementExpenseMethod]
        .map(x => ({ ...x, name: this.getSplittedEnumValue(x.name) }));
      this.bankruptcySeasoning = enums[Constants.pricingEnumerations.seasoning]
        .map(x => ({ ...x, name: this.getSplittedEnumValue(x.name) }));
      this.bankruptcyType = enums[Constants.pricingEnumerations.bankruptcyType]
        .map(x => ({ ...x, name: this.getSplittedEnumValue(x.name) }));
      this.tradelineType = enums[Constants.pricingEnumerations.tradelineType]
        .map(x => ({ ...x, name: this.getSplittedEnumValue(x.name) }));
      this.deedInLieuType = enums[Constants.pricingEnumerations.deedInLieuType]
        .map(x => ({ ...x, name: this.getSplittedEnumValue(x.name) }));
      this.chareOffType = enums[Constants.pricingEnumerations.chareOffType]
        .map(x => ({ ...x, name: this.getSplittedEnumValue(x.name) }));
      this.foreclosureType = enums[Constants.pricingEnumerations.foreclosureType]
        .map(x => ({ ...x, name: this.getSplittedEnumValue(x.name) }));
      this.shortSaleType = enums[Constants.pricingEnumerations.shortSaleType]
        .map(x => ({ ...x, name: this.getSplittedEnumValue(x.name) }));
      this.forbearanceType = enums[Constants.pricingEnumerations.forbearanceType]
        .map(x => ({ ...x, name: this.getSplittedEnumValue(x.name) }));
      this.bankruptcyOutcome = enums[Constants.pricingEnumerations.bankruptcyOutcome]
        .map(x => ({ ...x, name: this.getSplittedEnumValue(x.name) }));
      this.housingEventType = enums[Constants.pricingEnumerations.housingEventType]
        .map(x => ({ ...x, name: this.getSplittedEnumValue(x.name) }));
      this.housingEventSeasoning = enums[Constants.pricingEnumerations.seasoning]
        .map(x => ({ ...x, name: this.getSplittedEnumValue(x.name) }));
      this.investorExperience = enums[Constants.pricingEnumerations.investorExperience]
        .map(x => ({ ...x, name: this.getSplittedEnumValue(x.name) }));

      this.creditProviders = _.orderBy(this.enumsService.creditProviders, ["name"], ["asc"]);

      this.enumsService.getMortgageEnumerations().subscribe((enums) => {
        this.loanPurposeOptions = enums[Constants.mortgageEnumerations.loanPurpose];
        this.refinancePurposeOptions = enums[Constants.mortgageEnumerations.refinancePurpose];
        this.propertyTypeOptions = enums[Constants.mortgageEnumerations.propertyType];
        this.amortizationTypeOptions = enums[Constants.mortgageEnumerations.amortizationType];
        this.loanTypeOptions = enums[Constants.mortgageEnumerations.mortgageAppliedForType];
        this.constructionMethodOptions = enums[Constants.mortgageEnumerations.constructionMethodType];
        this.attachmentTypeOptions = enums[Constants.mortgageEnumerations.attachmentType];
        this.projectTypeOptions = enums[Constants.mortgageEnumerations.projectType];
        this.manufacturedHomeWidthTypeOptions = enums[Constants.mortgageEnumerations.manufacturedHomeWidthType];
        this.projectDesignTypeOptions = enums[Constants.mortgageEnumerations.projectDesignType];
        //this.lienPositionTypeOptions = enums[Constants.mortgageEnumerations.lienPositionType];
        this.currentPropertyWillBeTypes = enums[Constants.mortgageEnumerations.currentPropertyWillBeType];
        this.suffixes = enums[Constants.enumerations.suffix];

        this.lienPositionTypeOptions = [
          new EnumerationItem('First Lien', 'FirstLien'),
          new EnumerationItem('Second Lien', 'SecondLien'),
        ];
      });
    });
  }

  public abstract validate(): boolean;

  public getRequest(): Promise<ProductSearchRequest> {
    if (this.request) {
      return Promise.resolve(this.request);
    }
    return this.initializeRequest();
  }

  // Default initialization
  // Get from defaults for Quick Pricer
  // If application, call API to get the request for mortgageId
  // Get the features for the vendor, custom fields for the vendor etc.
  protected initializeRequest = async (zip?: string): Promise<ProductSearchRequest> => {
    if (this.isQuickPricer && !!!this.externalLoanAndMortgageInfo) {
      const requestInfo = await this.pricingService.getDefaultPricingRequest(zip);
      this.disableDti = requestInfo.disableDTI;
      // this.disableCreditScore = requestInfo.disableCreditScore;

      if (!zip) {
        const branchId = this.applicationContext.userPermissions.branchIds?.length > 0 ? this.applicationContext.userPermissions.branchIds[0] : null
        if (branchId) {
          var branch = await firstValueFrom(this.branchService.getBranch(this.applicationContext.currentlyLoggedInUserProfile.userProfile.branchId));
          if (branch && branch.zip) {
            var result = await firstValueFrom(this.zipCodeService.lookupZipCode(branch.zip, true));
            if (result?.length > 0){
              requestInfo.request.propertyInformation.city = result[0].city;
              requestInfo.request.propertyInformation.zipCode = branch.zip;
              requestInfo.request.propertyInformation.state = result[0].state?.toLowerCase();
              requestInfo.request.propertyInformation.county = result[0].county;
              requestInfo.request.propertyInformation.countyFips = result[0].countyFips;
            }
          }
        }
      }

      return Promise.resolve(requestInfo.request);
    }

    let apiCall: Observable<ProductSearchRequestInfo>;

    if (!!this.externalLoanAndMortgageInfo) {
      if (this.externalLoanAndMortgageInfo.application) {
        apiCall = this.pricingService.getPricingRequestForApplication(this.externalLoanAndMortgageInfo.application)
      } else if (this.externalLoanAndMortgageInfo.mortgage) {
        let reqInfo = new ProductSearchRequestInfo();
        let req = new ProductSearchRequest();
        let loanInfo = new LoanInformation();

        const loanPurposes = this.applicationContext.globalConfig.loanPurpose;
        const loanTypes = this.applicationContext.globalConfig.loanType;

        loanInfo.loanPurpose = this.externalLoanAndMortgageInfo.loanPurposeId ? loanPurposes.find(lp => lp.loanPurposeId == this.externalLoanAndMortgageInfo.loanPurposeId)?.loanPurposeName : null;
        loanInfo.loanType = this.externalLoanAndMortgageInfo.loanTypeId ? loanTypes.find(lt => lt.loanTypeId == this.externalLoanAndMortgageInfo.loanTypeId)?.loanTypeName : null;

        req.loanInformation = loanInfo;
        reqInfo.request = req;

        apiCall = of(reqInfo);

      } else {
        apiCall = of(null);
      }
    } else { // is not quick pricer
      if (this.applicationContext.currentMortgage) {
        apiCall = this.pricingService.getMortgagePricingRequest(this.applicationContext.currentMortgage.mortgageId)
      } else {
        apiCall = of(null);
      }
    }

    this.spinnerService.show();

    try {
      const response = await firstValueFrom(apiCall)
      const defaultProductSearchRequestInfo = await this.pricingService.getDefaultPricingRequest(zip);
      this.copyProductSearchCriteriFromDefaults(response, defaultProductSearchRequestInfo);

      const newProductSearchRequestInfo = _.merge(defaultProductSearchRequestInfo, response);
      // this.disableCreditScore = newProductSearchRequestInfo.disableCreditScore;
      this.disableDti = newProductSearchRequestInfo.disableDTI;

      let newRequest = newProductSearchRequestInfo.request;
      return newRequest;
    } catch (error) {
      this.notifsService.showError(
        error.message || 'Unable to get Mortgage Pricings',
        'Error!'
      );
    } finally {
      this.spinnerService.hide();
    }
  }

  async ngOnInit(): Promise<void> {
    // You need to know, if you will create the request using the loan within applicationContext,
    // or using an external loan (i.e. used from new application wizard).
    this.request = await this.initializeRequest();
    this.requestReady.emit(this.request);
    // This is virtual, can be overriden by sub-classes
    this.onAfterRequestCreated(this.request);
  }

  // virtual
  protected onAfterRequestCreated(request: ProductSearchRequest) {
    this.correctRequestFields(request);
    this._originalProductSearchRequest = _.cloneDeep(this.request);
    // Any changes to the request that subclasses do will be picked up as a change after this point...
    this.filterDropDownsByVendor();

    if (request.loanInformation.expandedGuidelines) {
      this.hasMortgageLates = request.loanInformation.expandedGuidelines.mortgageLatesx12012Mos > 0 ||
        request.loanInformation.expandedGuidelines.mortgageLatesx12013To24Mos > 0 ||
        request.loanInformation.expandedGuidelines.mortgageLatesx3012Mos > 0 ||
        request.loanInformation.expandedGuidelines.mortgageLatesx3013To24Mos > 0 ||
        request.loanInformation.expandedGuidelines.mortgageLatesx6012Mos > 0 ||
        request.loanInformation.expandedGuidelines.mortgageLatesx6013To24Mos > 0 ||
        request.loanInformation.expandedGuidelines.mortgageLatesx9012Mos > 0 ||
        request.loanInformation.expandedGuidelines.mortgageLatesx9013To24Mos > 0;
    }

    this.lastZipCodeSearch = request.propertyInformation.zipCode;
    if (request.loanInformation.loanPurpose !== 'Refinance') {
      request.loanInformation.refinancePurpose = null;
    }

    if (request.loanInformation && request.loanInformation.baseLoanAmount) {
      request.loanInformation.baseLoanAmount = Math.floor(request.loanInformation.baseLoanAmount);
    }
    if (request.propertyInformation.zipCode && !(request.propertyInformation.state && request.propertyInformation.county)
    ) {
      this.lookupZipCodeRelatedInfo(request.propertyInformation.zipCode, request);
    }

    this.initializeAmortizationTypesForDefaultSearchCriteria(request);
    this.initializeLoanTermsForDefaultSearchCriteria(request);
    this.initializeProductTypesForDefaultSearchCriteria(request);

    this.fixDefaultValues(request);
    this.calculateLtvAndCltv(request);

    this.getCountyListBySelectedState(request);

    this.loadFeatures(request);
    this.loadCustomFields(request);

    this.showMonthlyIncome = false;
    setTimeout(() => {
      this.showMonthlyIncome = true;
    }, 200);

    this.selectedLoanTypes = this.filteredLoanTypeOptions.filter(f => f.value === request.loanInformation.loanType);
    this.selectedProductTypes = this.filteredProductTypeOptions.filter(f => request.productTypes.includes(f.value));

    if (this.applicationContext.isCompanyPRMG) {
      this.customizeDropdownOptionsForPrmg();
    }
  }

  private customizeDropdownOptionsForPrmg() {
    const indexOfDaca = this.citizenshipOptions.findIndex(
      (option) => option.value === 'DACA'
    );
    if (indexOfDaca > -1) {
      this.citizenshipOptions.splice(indexOfDaca, 1);
    }
    const indexOfUnknownResidencyStatus = this.citizenshipOptions.findIndex(
      (option) => option.value === 'UnknownResidencyStatus'
    );
    if (indexOfUnknownResidencyStatus > -1) {
      this.citizenshipOptions.splice(indexOfUnknownResidencyStatus, 1);
    }
    const foreignNationalResidencyStatus = this.citizenshipOptions.findIndex(
      (option) => option.value === 'ForeignNational'
    );
    if (foreignNationalResidencyStatus > -1) {
      this.citizenshipOptions.splice(foreignNationalResidencyStatus, 1);
    }
    const itinResidencyStatus = this.citizenshipOptions.findIndex(
      (option) => option.value === 'ITIN'
    );
    if (itinResidencyStatus > -1) {
      this.citizenshipOptions.splice(itinResidencyStatus, 1);
    }
  }

  private _ltvHasChanged: boolean = false;
  protected onLtvKeyDown = (e: any) => {
    if (e.key === 'Tab') {
      return;
    }
    this._ltvHasChanged = true;
  }

  protected onLtvValueChanged = () => {
    //do not re calculate if the value is not changed
    if (!this._ltvHasChanged) {
      return;
    }
    this.calculateBaseLoanAmount();
    if (this.isVALoan) {
      this.calculateAndSetFundingFee();
    }
    this._ltvHasChanged = false;
  };

  private _cltvHasChanged: boolean = false;
  protected onCltvKeyDown = (e: any) => {
    if (e.key === 'Tab') {
      return;
    }
    this._cltvHasChanged = true;
  }

  protected onCltvValueChanged = () => {
    //do not re calculate if the value is not changed
    if (!this._cltvHasChanged) {
      return;
    }
    if (this.request.loanInformation.lienType === 'FirstLien') {
      this.calculateSecondLienAmount();
    } else if (this.request.loanInformation.lienType === 'SecondLien') {
      this.calculateBaseLoanAmount();
    }
    this._cltvHasChanged = false;
  };

  protected onHasMortgageLatesChanged = () => {
    if (!this.hasMortgageLates) {
      this.request.loanInformation.expandedGuidelines.mortgageLatesx12012Mos = 0;
      this.request.loanInformation.expandedGuidelines.mortgageLatesx12013To24Mos = 0;
      this.request.loanInformation.expandedGuidelines.mortgageLatesx3012Mos = 0;
      this.request.loanInformation.expandedGuidelines.mortgageLatesx3013To24Mos = 0;
      this.request.loanInformation.expandedGuidelines.mortgageLatesx6012Mos = 0;
      this.request.loanInformation.expandedGuidelines.mortgageLatesx6013To24Mos = 0;
      this.request.loanInformation.expandedGuidelines.mortgageLatesx9012Mos = 0;
      this.request.loanInformation.expandedGuidelines.mortgageLatesx9013To24Mos = 0;
    }
  }

  protected onBaseLoanAmountChanged = () => {
    this.calculateLtvAndCltv();
    this.calculateTotalLoanAmount();
    this.calculatePmiFeeAmount();
  };

  protected onAppraisedValueChanged = () => {
    this.calculateLtvAndCltv();
  };

  protected onPurchasePriceChanged = () => {
    this.calculateLtvAndCltv();
  };

  protected onFirstLienAmountChanged = () => {
    this.calculateLtvAndCltv();
    this.onCltvValueChanged()
  }

  protected onSecondLienAmountChanged = () => {
    this.calculateLtvAndCltv();
  };

  private removeRefinancePurpose = () => {
    this.request.loanInformation.refinancePurpose = null;
  }

  protected onLoanPurposeChanged = () => {
    if (this.request.loanInformation.loanPurpose !== 'Refinance') {
      this.removeRefinancePurpose();
    }
    if (this.isVALoan) {
      this.calculateAndSetFundingFee();
    }
    this.calculateLtvAndCltv();
  }

  protected onRefiPurposeChanged = () => {
    if (this.isVALoan) {
      this.calculateAndSetFundingFee();
    }
  }

  protected onVaFirstTimeUseChanged = () => {
    this.calculateAndSetFundingFee();
  }

  private calculateTotalLoanAmount() {
    this.request.loanInformation.totalLoanAmount = this.request.loanInformation.baseLoanAmount +
      this.request.loanInformation.upfrontPmiMipFfGfFinancedAmount;
  }

  private calculatePmiFeeAmount() {
    this.request.loanInformation.upfrontPmiMipFfGfAmount =
      this.request.loanInformation.baseLoanAmount * this.request.loanInformation.upfrontPmiMipFfGfPercent;
  }

  private calculateFinanceAmount() {
    this.request.loanInformation.upfrontPmiMipFfGfFinancedAmount =
      this.request.loanInformation.upfrontPmiMipFfGfAmount -
      this.request.loanInformation.upfrontPmiMipFfGfPaidinCash;
  }

  protected onUpfrontPmiMipFfGfFinancedAmountChanged = () => {
    this.calculateTotalLoanAmount();
  }

  protected onUpfrontPmiMipFfGfAmountChanged = () => {
    this.calculateFinanceAmount();
  }

  protected onUpfrontPmiMipFfGfPaidinCashChanged = () => {
    this.calculateFinanceAmount();
  }

  protected onUpfrontPmiMipFfGfPercentChanged = () => {
    this.calculatePmiFeeAmount();
  }

  protected onLoanTypeChanged = () => {
    if (!this.isVALoan) {
      this.request.borrowerInformation.typeOfVeteran = null;
      this.request.loanInformation.exemptFromVaFundingFee = null;
      this.request.borrowerInformation.vaFirstTimeUse = null;
      this.request.loanInformation.upfrontPmiMipFfGfPercent = 0;
      if (this.isHomeEquityLoanType) {
        this.request.loanInformation.calculateTotalLoanAmount = true;
        this.request.loanInformation.helocDrawnAmount = 0;
        this.request.loanInformation.helocLineAmount = 0;
        if (this.request.loanInformation.lienType === 'FirstLien') {
          this.request.loanInformation.secondLienAmount = 0;
        }
      }
    } else {
      this.calculateAndSetFundingFee();
    }
  }

  private calculateBaseLoanAmount = () => {
    let minValue = this.minOfSalesPriceAndAppraisedValue();
    if (!minValue) {
      minValue = 0;
    }
    if (this.request.loanInformation.firstLienAmount > 0) {
      this.request.loanInformation.firstLienAmount =
        this.request.loanInformation.ltv * minValue;
    } else {
      this.request.loanInformation.baseLoanAmount =
        this.request.loanInformation.ltv * minValue;
    }
    if (
      (this.request.loanInformation.secondLienAmount == 0 ||
        !this.request.loanInformation.secondLienAmount)
      && (this.request.loanInformation.firstLienAmount == 0 ||
        !this.request.loanInformation.firstLienAmount)
      && (this.request.loanInformation.helocLineAmount == 0 ||
        !this.request.loanInformation.helocLineAmount)
    ) {
      this.request.loanInformation.cltv =
        this.request.loanInformation.ltv;
    }
  };

  private calculateSecondLienAmount = () => {
    const minValue = this.minOfSalesPriceAndAppraisedValue();
    this.request.loanInformation.secondLienAmount =
      this.request.loanInformation.cltv * minValue -
      this.request.loanInformation.baseLoanAmount;
    if (
      (this.request.loanInformation.secondLienAmount == 0 ||
        !this.request.loanInformation.secondLienAmount)
      && (this.request.loanInformation.firstLienAmount == 0 ||
        !this.request.loanInformation.firstLienAmount)
      && (this.request.loanInformation.helocLineAmount == 0 ||
        !this.request.loanInformation.helocLineAmount)
    ) {
      this.request.loanInformation.cltv =
        this.request.loanInformation.ltv;
    }
  };

  protected onSecondLienAmountCalculatorClicked = () => {
    const modalRef = this.modalService.open(
      SecondLientAmountCalculatorDialogComponent,
      Constants.modalOptions.medium
    );
    modalRef.componentInstance.loanInformation =
      this.request.loanInformation;
    modalRef.componentInstance.loanType = this.helocOrMortgageLoanType;
    modalRef.result.then(
      (settings) => {
        this.request.loanInformation = settings.loanInformation;
        this.helocOrMortgageLoanType = settings.loanType;
        this.calculateLtvAndCltv();
      },
      (error) => { }
    );
  };

  protected calculateLtvAndCltv = (request?: ProductSearchRequest): void => {
    // ltv (loan-to-value) = (Loan Amount / Home Value) * 100
    // cltv (combined loan-to-value) = ((Loan amount + Existing Lien) / Home Value) * 100

    const req = request ? request : this.request;

    const minValue = this.minOfSalesPriceAndAppraisedValue(req);
    if (minValue == 0) {
      req.loanInformation.ltv = 0;
      req.loanInformation.cltv = 0;
    } else {
      req.loanInformation.ltv = req.loanInformation.baseLoanAmount / minValue;
      req.loanInformation.cltv =
        req.loanInformation.firstLienAmount > 0
          ? (req.loanInformation.firstLienAmount + req.loanInformation.baseLoanAmount) / minValue
          : req.loanInformation.ltv;
      if (
        (req.loanInformation.secondLienAmount == 0 ||
          !req.loanInformation.secondLienAmount)
        && (req.loanInformation.firstLienAmount == 0 ||
          !req.loanInformation.firstLienAmount)
        && (req.loanInformation.helocLineAmount == 0 ||
          !req.loanInformation.helocLineAmount)
      ) {
        req.loanInformation.cltv = req.loanInformation.ltv;
      } else if (req.loanInformation.firstLienAmount > 0) {
        req.loanInformation.cltv =
          (req.loanInformation.baseLoanAmount +
            req.loanInformation.firstLienAmount) /
          minValue;
      } else if (req.loanInformation.helocLineAmount > 0) {
        req.loanInformation.cltv =
          (req.loanInformation.baseLoanAmount +
            req.loanInformation.helocLineAmount) /
          minValue;
      } else {
        req.loanInformation.cltv =
          (req.loanInformation.baseLoanAmount +
            req.loanInformation.secondLienAmount) /
          minValue;
      }
    }
    if (this.isVALoan) {
      this.calculateAndSetFundingFee(req);
    }
  };

  protected calculateAndSetFundingFee = (request?: ProductSearchRequest) => {
    const req = request ? request : this.request;

    if (req.loanInformation.loanPurpose === 'Purchase') {
      const downPaymentPercent = 1 - req.loanInformation.ltv;
      if (req.borrowerInformation.vaFirstTimeUse) {
        if (downPaymentPercent < 0.05) {
          req.loanInformation.upfrontPmiMipFfGfPercent = 0.023;
        } else if (downPaymentPercent >= 0.05 && downPaymentPercent < 0.1) {
          req.loanInformation.upfrontPmiMipFfGfPercent = 0.0165;
        } else {
          req.loanInformation.upfrontPmiMipFfGfPercent = 0.014;
        }
      } else {
        if (downPaymentPercent < 0.05) {
          req.loanInformation.upfrontPmiMipFfGfPercent = 0.036;
        } else if (downPaymentPercent >= 0.05 && downPaymentPercent < 0.1) {
          req.loanInformation.upfrontPmiMipFfGfPercent = 0.0165;
        } else {
          req.loanInformation.upfrontPmiMipFfGfPercent = 0.014;
        }
      }
    } else if (
      req.loanInformation.loanPurpose === 'Refinance') {
      if (
        req.loanInformation.refinancePurpose === 'CashOutOther' ||
        req.loanInformation.refinancePurpose === 'CashOutLimited'
      ) {
        if (req.borrowerInformation.vaFirstTimeUse) {
          req.loanInformation.upfrontPmiMipFfGfPercent = 0.023;
        } else {
          req.loanInformation.upfrontPmiMipFfGfPercent = 0.036;
        }
      } else if (
        req.loanInformation.refinancePurpose === 'NoCashOutStreamlinedRefinance'
      ) {
        req.loanInformation.upfrontPmiMipFfGfPercent = 0.005;
      }
    }
  };

  protected lookupZipCodeRelatedInfo = (zipCode: string, request?: ProductSearchRequest) => {
    const req = request ? request : this.request;

    this.zipCodeService.lookupZipCode(zipCode, false).subscribe((result) => {
      if (result.length > 1) {
        const modalRef = this.modalService.open(
          CityCountyPickerDialogComponent,
          Constants.modalOptions.medium
        );
        modalRef.componentInstance.optionsToPickFrom = result;
        modalRef.result.then((selectedStateAndCounty: CityCountyState) => {
          if (selectedStateAndCounty) {
            const selectedState = this.stateOptions.find(
              (state) => state.name === selectedStateAndCounty.state
            );
            if (selectedState) {
              req.propertyInformation.state =
                selectedState.value;
              req.propertyInformation.county =
                selectedStateAndCounty.county;
              req.propertyInformation.countyFips =
                selectedStateAndCounty.countyFips;
            }
          }
        });
      } else if (result.length === 1) {
        const selectedState = this.stateOptions.find(
          (state) => state.name === result[0].state
        );
        if (selectedState) {
          req.propertyInformation.state =
            selectedState.value;
          req.propertyInformation.county =
            result[0].county;
          req.propertyInformation.countyFips = result[0].countyFips;
        }
      }
    });
  };

  protected loanTermsChanged = () => {
    this.request.loanTerms = this.selectedLoanTerms.map(
      (lt) => lt.value
    );
  };

  protected amortizationTypeChanged = () => {
    this.request.amortizationTypes =
      this.selectedAmortizationTypes.map((aT) => aT.value);
  };

  protected armFixedTermChanged = () => {
    this.request.armFixedTerms = this.selectedArmFixedTerms.map(
      (aT) => aT.value
    );
  };

  protected productTypeChanged = () => {
    this.request.productTypes = this.selectedProductTypes.map(
      (sT) => sT.value
    );
  };

  protected onZipCodeRelatedInfoChanged = (zipCode: ZipCodeLookupResult) => {
    this.lastZipCodeSearch = zipCode.zipcode;
    if (zipCode) {
      this.request.propertyInformation.state =
        zipCode.state.toLowerCase();
      this.request.propertyInformation.county = _.toUpper(
        zipCode.county
      ); // titleCase string
      this.request.propertyInformation.countyFips = zipCode.countyFips;
      this.request.propertyInformation.zipCode = zipCode.zipcode;
    } else {
      this.request.propertyInformation.state = '';
      this.request.propertyInformation.county = '';
      this.request.propertyInformation.countyFips = '';
    }
    this.getCountyListBySelectedState();
  };

  protected onZipCodeBlur = (e) => {
    if (this.lastZipCodeSearch !== this.request.propertyInformation.zipCode) {
      this.request.propertyInformation.state = '';
      this.request.propertyInformation.county = '';
      this.request.propertyInformation.countyFips = '';
      this.countyList = [];
    }
  }

  protected onCountySelect = ({ item }): void => {
    this.request.propertyInformation.countyFips = item.value;
  }

  protected initializeAmortizationTypesForDefaultSearchCriteria = (request?: ProductSearchRequest) => {
    this.selectedAmortizationTypes = [];
    const req = request ? request : this.request;
    if (req.amortizationTypes) {
      req.amortizationTypes.forEach((at) => {
        const selectedOption = this.amortizationTypeOptions.find(
          (o) => o.value === at
        );
        this.selectedAmortizationTypes.push(selectedOption);
      });
    } else {
      this.selectedAmortizationTypes = [];
      req.amortizationTypes = [];
    }
  };

  protected initializeLoanTermsForDefaultSearchCriteria = (request?: ProductSearchRequest) => {
    this.selectedLoanTerms = [];
    const req = request ? request : this.request;
    if (this.request.loanTerms) {
      req.loanTerms.forEach((lT) => {
        const selectedOption = this.loanTermOptions.find((o) => o.value === lT);
        this.selectedLoanTerms.push(selectedOption);
      });
    } else {
      this.selectedLoanTerms = [];
      req.loanTerms = [];
    }
  };

  protected initializeProductTypesForDefaultSearchCriteria = (request?: ProductSearchRequest) => {
    this.selectedProductTypes = [];
    const req = request ? request : this.request;

    if (req.productTypes) {
      req.productTypes.forEach((pT) => {
        const selectedOption = this.productTypeOptions.find(
          (o) => o.value === pT
        );
        this.selectedProductTypes.push(selectedOption);
      });
    } else {
      this.selectedProductTypes = [];
      req.productTypes = [];
    }
  };

  protected isConstructionTypeManufactured = (): boolean => {
    const isManufactured =
      this.request.propertyInformation.constructionMethod ===
      'Manufactured';
    if (!isManufactured) {
      this.request.propertyInformation.manufacturedHomeWidthType =
        null;
    }
    return isManufactured;
  };

  protected isProjectTypeCondominium = (): boolean => {
    const isCondominium =
      this.request.propertyInformation.projectType ===
      'Condominium';
    if (!isCondominium) {
      this.request.propertyInformation.projectDesignType = null;
    }
    return isCondominium;
  };

  private getCountyListBySelectedState = (request?: ProductSearchRequest) => {
    const req = request ? request : this.request;

    if (!req.propertyInformation.state) {
      this.countyList = [];
      return;
    }
    this.zipCodeService.getCountiesByState(req.propertyInformation.state)
      .subscribe({
        next: (list) => {
          this.countyList = list;
        },
        error: (err) => {
          this.notifsService.showError(err ? err.message : 'Unable to get counties for selected state.', 'Error!');
        }
      });
  }

  private copyProductSearchCriteriFromDefaults = (requestInfo: ProductSearchRequestInfo, defaultInfo: ProductSearchRequestInfo) => {
    requestInfo.request.amortizationTypes = defaultInfo.request.amortizationTypes;
    requestInfo.request.loanTerms = defaultInfo.request.loanTerms;
    requestInfo.request.productTypes = defaultInfo.request.productTypes;
    requestInfo.request.armFixedTerms = defaultInfo.request.armFixedTerms;
  }

  protected minOfSalesPriceAndAppraisedValue = (request?: ProductSearchRequest): number => {
    const req = request ? request : this.request;

    if (req.loanInformation.loanPurpose !== 'Purchase') {
      return req.propertyInformation.appraisedValue || 0;
    }

    var salesPrice = req.propertyInformation.salesPrice
      ? req.propertyInformation.salesPrice
      : Number.MAX_VALUE;
    var appraisedValue = req.propertyInformation.appraisedValue
      ? req.propertyInformation.appraisedValue
      : Number.MAX_VALUE;
    var min = Math.min(salesPrice, appraisedValue);
    return min != Number.MAX_VALUE ? min : 0;
  }

  private correctRequestFields = (request: ProductSearchRequest): void => {
    request.credentialId = this._enabledChannels[0]?.credentialId;
    request.channel = this._enabledChannels[0]?.channel;
    request.pricingChannelId = this.selectedVendor != 'OptimalBlue' ? this._enabledChannels[0]?.pricingChannelId : undefined;
    request.optimalBlueOriginatorId = this.selectedVendor == 'OptimalBlue' ? this._enabledChannels[0]?.originatorId : undefined;

    if (!request.desiredRate && this.applicationContext.currentMortgage?.mortgageTerm?.interestRate) {
      request.desiredRate = this.applicationContext.currentMortgage.mortgageTerm.interestRate / 100;
      request.desiredPrice = null;
    } else {
      request.desiredPrice = 100;
    }
    if (request.loanLevelDebtToIncomeRatio) {
      if (request.loanLevelDebtToIncomeRatio >= 999) {
        request.loanLevelDebtToIncomeRatio = 0.01;
      } else {
        request.loanLevelDebtToIncomeRatio = request.loanLevelDebtToIncomeRatio / 100;
      }
    }
    if (request.loanInformation && request.loanInformation.upfrontPmiMipFfGfPercent) {
      request.loanInformation.upfrontPmiMipFfGfPercent = request.loanInformation.upfrontPmiMipFfGfPercent / 100;
    }

    if (!request.loanInformation.taxesAndInsuranceMonthly) {
      //newRequest.taxesAndInsurance = ??? TODO: ayilmaz, what to set this to from mortgage model?
    }

    if (request.loanInformation.waiveEscrows == null) {
      request.loanInformation.waiveEscrows = false;
    }

    this.fixDefaultValues(request);
  }

  private getSplittedEnumValue = (enumValue: string): string => {
    return Utils.splitCamelCaseStringCorrectly(enumValue);
  }

  private groupSelectedVendorPricingProfiles() {
    this.groupedVendorProfiles = [];
    if (!this.enabledChannels?.length) {
      return;
    }
    this.groupedVendorProfiles = _.chain(this.enabledChannels)
      .groupBy("externalCompanyId")
      .map((compGrp, compId) => {
        const externalCompanyName = compId === "undefined"
          ? ""
          : this.getExternalCompanyNameById(Number(compId));
        const branchGrping = !externalCompanyName ? compGrp : _.chain(compGrp)
          .groupBy("branchId")
          .map((branchGrp, branchId) => {
            const branchName = branchId === "undefined"
              ? ""
              : this.getBranchNameById(Number(branchId));
            return {
              branchName,
              branchCreds: branchGrp
            };
          }).value();
        return {
          externalCompanyName,
          externalCompanyCreds: branchGrping
        };
      })
      .orderBy("externalCompanyName")
      .value();
  }

  private getExternalCompanyNameById(externalCompanyId: number) {
    const matchingCompany = this.applicationContext?.globalConfig?.externalCompanies.find(ec => ec.externalCompanyId === externalCompanyId);
    return matchingCompany?.name || '';
  }

  private getBranchNameById(branchId: number) {
    const matchingBranch = this.applicationContext?.globalConfig?.branches.find(b => b.branchId === branchId);
    return matchingBranch?.branchName || '';
  }

  private filterDropDownsByVendor = () => {
    this.filteredLoanTypeOptions = this.loanTypeOptions.filter(this.filterBySupportedVendor);
    this.filteredProductTypeOptions = this.productTypeOptions.filter(this.filterBySupportedVendor);
    this.filteredBuyDownOptions = this.buyDownOptions.filter(this.filterBySupportedVendor);
    this.filteredDocumentationTypes = this.documentationTypeOptions.filter(this.filterBySupportedVendor);
  }

  private filterBySupportedVendor = (item: EnumerationItem) => {
    if (!item.supportedPricingVendors) {
      return false;
    }
    const supportedVendors = item.supportedPricingVendors.split(',').map(v => v.trim());
    return supportedVendors.includes('All') || supportedVendors.includes(this.selectedVendor);
  }

  private fixDefaultValues = (request: ProductSearchRequest) => {
    if (!request.loanInformation.secondLienAmount) {
      request.loanInformation.secondLienAmount = 0;
    }
    if (!request.loanInformation.firstLienAmount) {
      request.loanInformation.firstLienAmount = 0;
    }
    if (!request.loanInformation.cashOutAmount) {
      request.loanInformation.cashOutAmount = 0;
    }
    if (!request.propertyInformation.appraisedValue) {
      request.propertyInformation.appraisedValue = 0;
    }
    if (!request.borrowerInformation.fico) {
      request.borrowerInformation.fico = null;
    }
    if (!request.representativeCreditScore) {
      request.representativeCreditScore = null;
    }
    if (
      !request.loanLevelDebtToIncomeRatio ||
      request.loanLevelDebtToIncomeRatio === 999.99
    ) {
      request.loanLevelDebtToIncomeRatio = null;
    } else if (request.loanLevelDebtToIncomeRatio >= 1) {
      request.loanLevelDebtToIncomeRatio = 1;
    }
    if (
      request.loanInformation.nonOccupyingCoBorrower == null
    ) {
      request.loanInformation.nonOccupyingCoBorrower = false;
    }
    if (!request.loanInformation.lienType) {
      request.loanInformation.lienType = 'FirstLien';
    }
    if (!request.loanInformation.expandedGuidelines) {
      request.loanInformation.expandedGuidelines = new ExpandedGuidelines();
    }

    if (request.propertyInformation.county && this.request.propertyInformation.county.includes("County")) {
      request.propertyInformation.county = this.request.propertyInformation.county.replace("County", "").trim().toUpperCase();
    }
  };

  protected showNonWarrantableProjectCheckBox(): boolean {
    return this.request.propertyInformation.projectType &&
      this.visibleFeatureFields.includes('Property_NonWarrantableProject');
  }

  protected onOptimalBlueChannelChanged = () => {
    this.request.loanInformation.customFieldValues = [];
    const obOriginatorId = this.enabledChannels.find(c => c.pricingChannelId == this.businessChannelId)?.originatorId;
    this.request.optimalBlueOriginatorId = obOriginatorId;
    this.onBusinessChannelChanged({ type: this.selectedVendor, id: this.businessChannelId as number });
  };

  protected onPollyLenderPriceChannelChanged = (selectedChannel: EnabledBusinessChannel) => {
    this.request.credentialId = selectedChannel?.credentialId
    this.request.pricingChannelId = selectedChannel?.pricingChannelId;
    this.request.channel = selectedChannel?.channel;
    this.onBusinessChannelChanged({ type: this.selectedVendor, id: this.request.credentialId });
  }

  private onBusinessChannelChanged = (selectedChannel: { type: string, id: number }) => {
    this.businessChannelId = selectedChannel.type === 'OptimalBlue' ? selectedChannel?.id : null;
    if (this.businessChannelId) {
      this.loadCustomFields();
    }
    if (selectedChannel.id) {
      this.loadFeatures();
    }
  }

  protected onLienTypeChanged = () => {
    if (this.request.loanInformation.lienType === 'FirstLien') {
      this.request.loanInformation.firstLienAmount = 0;
      if (this.isHomeEquityLoanType) {
        this.request.loanInformation.calculateTotalLoanAmount = true;
        this.request.loanInformation.secondLienAmount = 0;
        this.request.loanInformation.helocDrawnAmount = 0;
        this.request.loanInformation.helocLineAmount = 0;
      }
    } else if (this.request.loanInformation.lienType === 'SecondLien') {
      this.request.loanInformation.secondLienAmount = 0;
      this.request.loanInformation.helocDrawnAmount = 0;
      this.request.loanInformation.helocLineAmount = 0;
      this.request.loanInformation.calculateTotalLoanAmount = true;
    }
    this.calculateLtvAndCltv(this.request);
  }

  protected onChangeFinanceEntireAmount = () => {
    if (this.financeEntireAmount) {
      this.request.loanInformation.upfrontPmiMipFfGfPaidinCash = 0;
    }
  };

  protected onManuallyEnterPmiMipFfGFeeDetailsChanged = () => {
    this.request.loanInformation.calculateTotalLoanAmount = !this.manuallyEnterPmiMipFfGFeeDetails;
  }

  protected onExemptFromFundingFeeChanged = () => {
    if (this.request.loanInformation.exemptFromVaFundingFee) {
      this.request.loanInformation.upfrontPmiMipFfGfPercent = 0;
      this.request.loanInformation.upfrontPmiMipFfGfAmount = 0;
      this.request.loanInformation.upfrontPmiMipFfGfPaidinCash = 0;
      this.request.loanInformation.upfrontPmiMipFfGfFinancedAmount = 0;
    } else {
      this.calculateAndSetFundingFee();
    }
  };

  protected onCustomFieldValueChanged = (fieldId: string, fieldName: string) => {
    const valueToSet = this.customFieldValues[fieldId];
    if (!this.request.loanInformation.customFieldValues) {
      this.request.loanInformation.customFieldValues = [];
    }
    const loanInfoCustomField =
      this.request.loanInformation.customFieldValues.find(
        (cfv) => cfv.customFieldId === fieldId
      );
    if (loanInfoCustomField) {
      loanInfoCustomField.value = valueToSet;
    } else {
      let newCustomField = new CustomFieldValue(fieldId, fieldName, valueToSet);
      this.request.loanInformation.customFieldValues.push(
        newCustomField
      );
    }
    if (this.selectedVendor === 'LoanPass') {
      this.setWhetherCustomFieldsAreHidden();
    }
  };

  protected showField = (customField: CustomField) => {
    let customFields: CustomField[] = [];
    this.customFieldSections.forEach(section => {
      customFields = customFields.concat(section.customFields);
    });
    if (customField.displayMode == 'DependsOnAnyValue') {
      let field = customFields.find(cf => cf.name == customField.dependsOnCustomFieldName);
      if (field) {
        return !!this.customFieldValues[field.thirdPartyCustomFieldId];
      } else {
        return false;
      }
    } else if (customField.displayMode == 'DependsOnSpecificValue') {
      let field = customFields.find(cf => cf.name == customField.dependsOnCustomFieldName);
      if (field) {
        if (field.type === 'List') {
          return customField.dependsOnValues?.includes(this.customFieldValues[field.thirdPartyCustomFieldId])
        } else {
          return this.customFieldValues[field.thirdPartyCustomFieldId]?.toString() === customField.dependsOnValues[0];
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  protected setWhetherCustomFieldsAreHidden = () => {
    this.customFieldSections.forEach(section => {
      section.customFields.forEach(field => {
        field.isHidden = !this.showField(field);
        if (field.isHidden) {
          this.customFieldValues[field.thirdPartyCustomFieldId] = null;
        }
      });
    });
  }

  protected isHiddenSection = (section: CustomFieldSection) => {
    return section.customFields.every(field => field.isHidden);
  }

  protected onMultipleLoanTypesChanged = () => {
    this.request.loanTypes = this.selectedLoanTypes.map(lt => lt.value);
    this.onLoanTypeChanged();
  }

  protected isHiddenExpandedGuidelinesSection = () => {
    return (!this.visibleFeatureFields.includes('Expanded_TradelineType') || this.hiddenFields.includes('ExpandedGuidelines.TradelineType')) &&
    (!this.visibleFeatureFields.includes('Expanded_BankruptcyType') || this.hiddenFields.includes('BorrowerInformation.Bankruptcy')) &&
    (!this.visibleFeatureFields.includes('Expanded_HousingEventType') || this.hiddenFields.includes('ExpandedGuidelines.HousingEventType')) &&
    (!this.visibleFeatureFields.includes('Expanded_IncomeVerificationMethod') || this.hiddenFields.includes('ExpandedGuidelines.IncomeVerificationMethod')) &&
    (!this.visibleFeatureFields.includes('Expanded_IncomeVerificationType') || this.hiddenFields.includes('ExpandedGuidelines.IncomeVerificationType')) &&
    (!this.visibleFeatureFields.includes('Expanded_MonthsChargeOff') || this.hiddenFields.includes('ExpandedGuidelines.ChargeOffMonths')) &&
    (!this.visibleFeatureFields.includes('Expanded_MonthsDeedInLieu') || this.hiddenFields.includes('ExpandedGuidelines.DeedinLieuMonths')) &&
    (!this.visibleFeatureFields.includes('Expanded_MonthsNoticeOfDefault') || this.hiddenFields.includes('ExpandedGuidelines.DefaultNoticeMonths')) &&
    (!this.visibleFeatureFields.includes('Expanded_MonthsForeclosure') || this.hiddenFields.includes('ExpandedGuidelines.ForeclosureMonths')) &&
    (!this.visibleFeatureFields.includes('Expanded_MonthsLoanModification') || this.hiddenFields.includes('ExpandedGuidelines.LoanModificationMonths')) &&
    (!this.visibleFeatureFields.includes('Expanded_MonthsShortSale') || this.hiddenFields.includes('ExpandedGuidelines.ShortSaleMonths')) &&
    (!this.visibleFeatureFields.includes('Expanded_AssetDepletionAmount') || this.hiddenFields.includes('ExpandedGuidelines.AssetDepletionAmount')) &&
    (!this.visibleFeatureFields.includes('Expanded_PropertiesOwned') || this.hiddenFields.includes('ExpandedGuidelines.PropertiesOwned')) &&
    (!this.visibleFeatureFields.includes('Expanded_DebtConsolidation') || this.hiddenFields.includes('ExpandedGuidelines.DebtConsolidation')) &&
    (!this.visibleFeatureFields.includes('Expanded_UniqueProperty') || this.hiddenFields.includes('ExpandedGuidelines.UniqueProperty')) &&
    (!this.visibleFeatureFields.includes('Expanded_DeedInLieuType') || this.hiddenFields.includes('ExpandedGuidelines.DeedInLieuType')) &&
    (!this.visibleFeatureFields.includes('Expanded_ChareOffType') || this.hiddenFields.includes('ExpandedGuidelines.ChareOffType')) &&
    (!this.visibleFeatureFields.includes('Expanded_ForeclosureType') || this.hiddenFields.includes('ExpandedGuidelines.ForeclosureType')) &&
    (!this.visibleFeatureFields.includes('Expanded_ShortSaleType') || this.hiddenFields.includes('ExpandedGuidelines.ShortSaleType')) &&
    (!this.visibleFeatureFields.includes('Expanded_ForbearanceType') || this.hiddenFields.includes('ExpandedGuidelines.ForbearanceType')) &&
    this.isHasMortgageLatesHidden();
  }

  protected isHasMortgageLatesHidden = () => {
    return !this.visibleFeatureFields.includes('Expanded_MortgageLates') || (this.hiddenFields.includes('ExpandedGuidelines.MortgageLatesx3012Mos') &&
    this.hiddenFields.includes('ExpandedGuidelines.MortgageLatesx6012Mos') && this.hiddenFields.includes('ExpandedGuidelines.MortgageLatesx9012Mos') &&
    this.hiddenFields.includes('ExpandedGuidelines.MortgageLatesx12012Mos') && this.hiddenFields.includes('ExpandedGuidelines.MortgageLatesx3013To24Mos') &&
    this.hiddenFields.includes('ExpandedGuidelines.MortgageLatesx6013To24Mos') && this.hiddenFields.includes('ExpandedGuidelines.MortgageLatesx9013To24Mos') &&
    this.hiddenFields.includes('ExpandedGuidelines.MortgageLatesx12013To24Mos'));
  }

  protected isHiddenManuallyEnterPmiMipFfGFeeDetails = () => {
    return this.hiddenFields.includes('LoanInformation.UpfrontPmiMipFfGfPercent') && this.hiddenFields.includes('LoanInformation.UpfrontPmiMipFfGfAmount') &&
    this.hiddenFields.includes('LoanInformation.UpfrontPmiMipFfGfPaidinCash') && this.hiddenFields.includes('LoanInformation.UpfrontPmiMipFfGfFinancedAmount') &&
    (!this.isFHA || (this.hiddenFields.includes('LoanInformation.FhaCaseAssigned') && this.hiddenFields.includes('LoanInformation.FhaCaseEndorsement'))) &&
    this.hiddenFields.includes('ExpandedGuidelines.MortgageLatesx12013To24Mos');
  }

  private loadFeatures = (request?: ProductSearchRequest) => {
    const req = request ? request : this.request;

    const payload: VendorFeatureRequest = {
      optimalBlueOriginatorId: this.selectedVendor === 'OptimalBlue' ? req.optimalBlueOriginatorId : undefined,
      optimalBlueChannelId: this.selectedVendor === 'OptimalBlue' ? this.businessChannelId as number : undefined,
      applicationId: undefined,
      credentialId: req.credentialId,
      pricingChannelId: this.selectedVendor !== 'OptimalBlue' ? req.pricingChannelId : undefined,
      encompassTpoOrgId: undefined,
      encompassBrokerPriceGroup: undefined,
      encompassDelegatedPriceGroup: undefined,
      encompassNonDelegatedPriceGroup: undefined,
    }
    this.spinnerService.show();

    if (payload.credentialId) {
      this.loadFieldConfigsForVendor(payload.credentialId);
    }

    this.pricingService
      .getFeaturesByVendor(this.selectedVendor, payload).subscribe((res) => {
        this.visibleFeatureFields = res;
        if (this.selectedVendor === 'Polly' && this.visibleFeatureFields.includes('Loan_CountyLoanLimitYear') && !this.request.loanInformation.countyLoanLimitYear) {
          this.request.loanInformation.countyLoanLimitYear = 2025;
        }
        this.spinnerService.hide();
      },
        (error) => {
          this.spinnerService.hide();
          this.notifsService.showError(
            error ? error.message : 'Unable to retrieve custom fields.',
            'Error!'
          );
        }).add(() => this.spinnerService.hide());
  }

  private loadFieldConfigsForVendor = (credId: number) => {
    this.spinnerService.show();
    let obs;

    if (this.selectedVendor === 'OptimalBlue') {
      obs = this.pricingConfigService.getConfigByVendor(PricingEngineVendor.OptimalBlue);
    }
    else {
      obs = this.pricingConfigService.getConfig(credId);
    }

    obs.subscribe({
      next: (config) => {
        this.fieldSpecs = config?.fieldSpecs;
        this.adjustFieldConfigsForVendor();
      }, error: (err) => {
        this.notifsService.showError(err.message || err, "Fields Config Error!");
      }
    }).add(() => {
      this.spinnerService.hide();
    })
  }

  private loadCustomFields = (request?: ProductSearchRequest) => {
    const req = request ? request : this.request;

    let baseRequest = new BaseRequest(this.businessChannelId as number);
    baseRequest.credentialId = req.credentialId;
    this.spinnerService.show();
    this.pricingService.getCustomFieldsV2(this.selectedVendor, baseRequest).subscribe({
      next: ((result: CustomFieldSection[]) => {
        this.customFieldSections = result
        let index = this.customFieldSections.findIndex(cfs => !cfs.name);
        if (index > -1) {
          this.customFieldSections[index].displayOrder = -1;
        }

        this.customFieldSections.forEach((cfs) => {
          if (cfs.customFields && cfs.customFields.length > 0) {
            cfs.customFields.forEach(cf => {
              cf['htmlElementName'] = cf.name.toLowerCase().replace('@', '-');
              if (!cf.thirdPartyCustomFieldId) {
                cf.thirdPartyCustomFieldId = 'field@' + cf.name.toLowerCase().replace(' ', '-');
              }
              const alreadyAdded = this.customFieldValues.hasOwnProperty(cf.thirdPartyCustomFieldId);
              if (!alreadyAdded) {
                if (cf.type === 'Checkbox') {
                  this.customFieldValues[cf.thirdPartyCustomFieldId] = cf.defaultValue?.toLowerCase() === 'true' || cf.defaultValue === '1';
                } else {
                  this.customFieldValues[cf.thirdPartyCustomFieldId] = cf.defaultValue || null;
                }
              }
              if (this.selectedVendor === 'LoanPass') {
                cf.isHidden = !this.showField(cf);
              }
            })
          }
        });

        if (req && req.loanInformation) {
          req.loanInformation.customFieldValues = [];
          this.customFieldSections.forEach((cfs) => {
            if (cfs.customFields && cfs.customFields.length > 0) {
              cfs.customFields.forEach(field => {
                req.loanInformation.customFieldValues.push(
                  new CustomFieldValue(field.thirdPartyCustomFieldId, field.name, field.defaultValue)
                );
              });
            }
          });
        }
      }),
      error: ((error: any) => {
        this.notifsService.showError(error?.message || `Unable to get custom fields for ${this.selectedVendor}`, 'Failure!');
      })
    }).add(() => this.spinnerService.hide());
  };

  private adjustFieldConfigsForVendor = () => {
    this.readonlyFields = [];
    this.hiddenFields = [];
    this.hiddenEnumOptionsByField.clear();

    if (this.fieldSpecs?.length > 0) {
      this.fieldSpecs.forEach(spec => {
        if (this.transactionType) {
          if (spec.repriceFieldAccess == PricingFieldAccess.ReadOnly) {
            this.readonlyFields.push(spec.fieldName);
          }

          if (spec.repriceFieldAccess == PricingFieldAccess.Hidden) {
            this.hiddenFields.push(spec.fieldName);
          }
        } else {
          if (spec.fieldAccess == PricingFieldAccess.ReadOnly) {
            this.readonlyFields.push(spec.fieldName);
          }

          if (spec.fieldAccess == PricingFieldAccess.Hidden) {
            this.hiddenFields.push(spec.fieldName);
          }
        }

        if (spec.hiddenEnumOptions?.length) {
          this.hiddenEnumOptionsByField.set(spec.fieldName, spec.hiddenEnumOptions);
        }

        if (spec.defaultValue) {
          let modelFieldName = spec.fieldName.split(".").map(p => Utils.lowerCaseFirstLetter(p)).join(".");
          let modelFieldNameWords = modelFieldName.split(".");

          let requestModelRef = modelFieldName;

          if (modelFieldNameWords.length == 2 && modelFieldNameWords[0] == "expandedGuidelines") {
            requestModelRef = "loanInformation." + modelFieldName;
          }

          const isNumeric = (string) => /^[+-]?\d+(\.\d+)?$/.test(string);
          const setFieldValue = (spec) => {
            // field type is List ?
            if (["amortizationType", "productType"].includes(requestModelRef)) {
              requestModelRef += "s";

              let defaultValueAsArray = spec.defaultValue.split(",") as string[];
              _.set(this.request, requestModelRef, defaultValueAsArray);

              if (requestModelRef == "productTypes") {
                this.initializeProductTypesForDefaultSearchCriteria();
              }
              else if (requestModelRef == "amortizationTypes") {
                this.initializeAmortizationTypesForDefaultSearchCriteria();
              }

            }
            else {
              if (["true", "false"].includes(spec.defaultValue)) {
                _.set(this.request, requestModelRef, Boolean(spec.defaultValue));
              }
              else if (isNumeric(spec.defaultValue)) {
                _.set(this.request, requestModelRef, Number(spec.defaultValue));
              }
              else {
                _.set(this.request, requestModelRef, spec.defaultValue);
              }
            }
          }

          if (this.isQuickPricer) {
            setFieldValue(spec);
          }
          else { // isLoan
            if (_.isNil(_.get(this.request, requestModelRef))) {
              setFieldValue(spec);
            }
          }
        }
      });

      this.amortizationTypeOptions = this.amortizationTypeOptions
        .filter(t => !this.hiddenEnumOptionsByField.get('AmortizationType')?.includes(t.alternateValue));
      this.filteredLoanTypeOptions = this.filteredLoanTypeOptions
        .filter(t => !this.hiddenEnumOptionsByField.get('LoanInformation.LoanType')?.includes(t.alternateValue));
      this.productTypeOptions = this.productTypeOptions
        .filter(t => !this.hiddenEnumOptionsByField.get('ProductType')?.includes(t.alternateValue));
    }
  }
}

export class ExternalLoanAndMortgageInfo {
  application: LoanApplication;
  mortgage: UrlaMortgage;
  loanTypeId?: number;
  loanPurposeId?: number;
}
