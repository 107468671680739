import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit, Output,
} from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Configuration, LoanApplication, Mortgage } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components/application-context-bound.component';
import { LoanProductPricing, LockStatus } from '../../models/pricing/loan-product-pricing.model';
import { LockResponse } from '../../models/pricing/lock-response.model';
import { Adjustment } from '../../models/pricing/pricing-quote.model';
import { ProductPriceValidation } from '../../models/pricing/product-price-validation.model';
import { PricingService } from '../../services/pricing.service';
import { LoanPricingSearchDialogComponent } from '../loan-pricing-search-dialog/loan-pricing-search-dialog.component';
import { PricingValidationDialogComponent } from '../pricing-validation-dialog/pricing-validation-dialog.component';
import { ViewAdjustmentsDialogComponent } from '../view-adjustments-dialog/view-adjustments-dialog.component';
import { ViewNotesAdvisoriesDialogComponent } from '../view-notes-advisories-dialog/view-notes-advisories-dialog.component';
import Swal from 'sweetalert2';
import { Subscription, firstValueFrom } from 'rxjs';
import { LosService } from 'src/app/services/los.service';
import { TpoConfigService } from 'src/app/modules/admin/tpo-config/services/tpo-config.service';
import { TpoConfiguration } from 'src/app/modules/admin/tpo-config/models/tpo-configuration.model';
import { LockExtensionModalComponent } from '../lock-extension-modal/lock-extension-modal.component';
import { SafeResourceUrl } from '@angular/platform-browser';
import { LenderPricePricerDialogComponent } from '../lenderprice-pricer/lenderprice-pricer-dialog/lenderprice-pricer-dialog.component';
import { PricingVendor } from 'src/app/models/pricing/pricing-vendor';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { LoanService } from 'src/app/services/loan/loan.service';
import { Utils } from 'src/app/core/services/utils';

@Component({
  selector: 'pricing-details',
  templateUrl: 'pricing-details.component.html',
  styleUrls: ['./pricing-details.component.scss'],
})
export class PricingDetailsComponent
  extends ApplicationContextBoundComponent
  implements OnInit {

  productPricing: LoanProductPricing;

  lockResponse: LockResponse;

  amortizationTypeForMortgage: string;

  adjustments: Adjustment[] = [];

  isProductPricingValid: boolean = true;

  isLoading: boolean = false;
  isLoadingPricingValidity: boolean = false;

  isTpoUser: boolean;
  isPRMG: boolean;

  @Input()
  enableChannelSelection: boolean = false;

  @Output()
  scenariosChanged: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  set currentMortgage(currentMortgage: Mortgage) {
    if (this._currentMortgage && currentMortgage && this._currentMortgage.mortgageId === currentMortgage.mortgageId) {
      return;
    }
    this._currentMortgage = currentMortgage;
    if (currentMortgage) {
      this.initialize(currentMortgage);
    }
  }

  get currentMortgage(): Mortgage {
    return this._currentMortgage;
  }

  @Input()
  application: LoanApplication;

  protected testLenderpriceUrl: SafeResourceUrl;

  protected hideInvestor: boolean = false;

  private readonly _modalOptions: NgbModalOptions;

  private readonly _modalOptionsLarge: NgbModalOptions;

  private _amortizationTypeOptions: EnumerationItem[] = [];

  private _pricingValidation: ProductPriceValidation;

  private _defaultVendor: string;

  private _loanLosLdeLinkChangesSubscription: Subscription;
  private _contextChangeSubscription: Subscription;
  private _mortgageEnumsSubscription: Subscription;
  private _pricingEnumsSubscription: Subscription;
  private _loanInfoChangesSubscription: Subscription;
  private _keyDatesSubscription: Subscription;

  private _pricingVendorsEnum: EnumerationItem[] = [];

  private _currentMortgage: Mortgage;

  externalLockEnabled: boolean = false;
  requestedRateLock: boolean = false;
  losStatusType: string;
  tpoConfigs: TpoConfiguration;
  allowPricingActions: boolean = false;
  showManualLockForm: boolean = false;
  hasRateLockExpirationKeyDate: boolean = false;
  loanId: number;

  @Output() setAmortizationTabVisibility = new EventEmitter<boolean>();
  @Output() amortizationTypeAssigned = new EventEmitter<string>();

  constructor(
    private readonly injector: Injector,
    private readonly _modalService: NgbModal,
    private readonly _enumsService: EnumerationService,
    private readonly _pricingService: PricingService,
    private readonly _losService: LosService,
    private readonly _loanService: LoanService,
    private readonly _notifsService: NotificationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _tpoConfigService: TpoConfigService,
    private readonly _configurationService: ConfigurationService,
  ) {
    super(injector);

    this._modalOptions = {
      size: 'xl',
      backdrop: 'static',
      centered: true,
      scrollable: true,
      windowClass: 'modal-fullscreen',
      modalDialogClass: 'modal-fullscreen'
    };
    this._modalOptionsLarge = {
      size: 'xl',
      backdrop: 'static',
      centered: true,
      scrollable: true,
    };
    this._mortgageEnumsSubscription = this._enumsService.getMortgageEnumerations().subscribe((enums) => {
      this._amortizationTypeOptions =
        enums[Constants.mortgageEnumerations.amortizationType];
    });
    this._pricingEnumsSubscription = this._enumsService.getPricingEnumerations().subscribe((enums) => {
      this._pricingVendorsEnum = enums[Constants.pricingEnumerations.pricingEngineVendor];
    });

    this.allowPricingActions = this.applicationContext.userPermissions.allowPricingActions;
    this.loanId = this.applicationContext.application.applicationId;
    this.isPRMG = this.applicationContext.isCompanyPRMG;
    this.showManualLockForm = this.isPRMG;
  }

  ngOnInit(): void {
    this._contextChangeSubscription = this.applicationContextService.context.subscribe(context => {
      this.isTpoUser = context.isTpo;
      if (this.isTpoUser) {
        this.getTPOConfig();
      }
      this.checkLosSync(context.application);
      this.hideInvestor = context.isCompanyPRMG && !context.userPermissions.admin;
    });

    this._loanLosLdeLinkChangesSubscription = this.applicationContextService.loanLosLdeChanges.subscribe(context => {
      this.checkLosSync(context.application);
      this.productPricing = null;
      this.initialize(this._currentMortgage);
    });

    this.setDefaultPricingVendor();

    this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe(context => {
      if (context.application) {
        this.productPricing = null;
        this._currentMortgage = context.currentMortgage;
        if (context.application) {
          this.initialize(this._currentMortgage);
        }
      }
    });

    this._configurationService.getCompanyConfiguration('ExternalPricingLockEnabled')
      .subscribe({
        next: (externalPricingLockEnabled: Configuration) => {
          this.externalLockEnabled = externalPricingLockEnabled && externalPricingLockEnabled.value > 0;
        },
        error: () => {
        }
      });

    this.hasRateLockExpirationKeyDate = this.applicationContext.applicationKeyDatesByType
        ? !!this.applicationContext.applicationKeyDatesByType['rateLockExpiration']?.eventDate : false;

    this._keyDatesSubscription = this.applicationContextService.keyDatesChanges.subscribe((changes) => {
      this.hasRateLockExpirationKeyDate = changes.keyDatesByType
        ? !!changes.keyDatesByType['rateLockExpiration']?.eventDate : false;
    })
  }

  private setDefaultPricingVendor = () => {
    this._spinner.show();
    this._pricingService.getPricingCredentials(this._currentMortgage.applicationId).subscribe(
      (pricingCreds) => {
        var enabledPricingVendors = Object.keys(pricingCreds).map(x => x.toLowerCase());
        const filteredPricingVendors = this._pricingVendorsEnum.filter(vendor => enabledPricingVendors.indexOf(vendor.value.toLowerCase()) > -1);
        if (filteredPricingVendors.length > 0) {
          this._defaultVendor = filteredPricingVendors[0].value;
        }
      },
      (error) => {
        this._notifsService.showError(
          error ? error.message : 'Unable to get channels',
          'Error!'
        );
      }).add(() => {
        this._spinner.hide()
      });
  }

  private getTPOConfig() {
    this._tpoConfigService.getTpoConfigs()
      .subscribe({
        next: (tpoConfigs) => {
          this.tpoConfigs = tpoConfigs;
        },
        error: (err) => {
          this._notifsService.showError(err ? err.message : 'Unable to retrieve top config', 'Error!');
        }
      });
  }

  get tpoPricingLockEnabled() {
    if (!this.tpoConfigs?.pricingAction_LockEnabled) {
      return false;
    }

    return this.tpoConfigs.pricingAction_LockEnabled.includes(this.applicationContext.application?.channel);
  }

  get tpoPricingCancelLockEnabled() {
    if (!this.tpoConfigs?.pricingAction_CancelLockEnabled) {
      return false;
    }

    return this.tpoConfigs.pricingAction_CancelLockEnabled.includes(this.applicationContext.application?.channel);
  }

  get tpoPricingRepriceLockEnabled() {
    if (!this.tpoConfigs?.pricingAction_RepriceEnabled) {
      return false;
    }

    return this.tpoConfigs.pricingAction_RepriceEnabled.includes(this.applicationContext.application?.channel);
  }

  get tpoPricingRequestLockExtensionEnabled() {
    if (!this.tpoConfigs?.pricingAction_LockExtensionEnabled) {
      return false;
    }

    return this.tpoConfigs.pricingAction_LockExtensionEnabled.includes(this.applicationContext.application?.channel);
  }

  get tpoPricingProgramChangeEnabled() {
    if (!this.tpoConfigs?.pricingAction_ProgramChangeEnabled) {
      return false;
    }

    return this.tpoConfigs.pricingAction_ProgramChangeEnabled.includes(this.applicationContext.application?.channel);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._mortgageEnumsSubscription) {
      this._mortgageEnumsSubscription.unsubscribe();
    }
    if (this._pricingEnumsSubscription) {
      this._pricingEnumsSubscription.unsubscribe();
    }
    if (this._contextChangeSubscription) {
      this._contextChangeSubscription.unsubscribe();
    }
    if (this._loanLosLdeLinkChangesSubscription) {
      this._loanLosLdeLinkChangesSubscription.unsubscribe();
    }
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
    if (this._keyDatesSubscription) {
      this._keyDatesSubscription.unsubscribe();
    }
  }

  loadAmortizationType = (mortgage: Mortgage) => {
    const amortizationType = mortgage.mortgageTerm.amortizationType;
    if (!amortizationType) {
      this.amortizationTypeForMortgage = '--';
      return;
    }
    const lookupItem = this._amortizationTypeOptions.find(
      (item) => item.value == amortizationType
    );
    this.amortizationTypeForMortgage = lookupItem ? lookupItem.name : '--';
    this.amortizationTypeAssigned.emit(this.amortizationTypeForMortgage);
  };

  viewNotesAndAdvisories = () => {
    const modalRef = this._modalService.open(
      ViewNotesAdvisoriesDialogComponent,
      this._modalOptionsLarge
    );
    modalRef.componentInstance.notesAndAdvisories = this.productPricing.productNotesAndAdvisories;
    modalRef.componentInstance.pricingRequestPayload = this.productPricing.pricingVendorRequestPayload;
  };

  onRequestRateLockClicked = () => {

    if (this.externalLockEnabled) {
      Swal.fire({
        title: 'Are you sure?',
        text: `This will lock this loan. As part of this process, the loan will be synced to the LOS. This process can take a minute or two, please be patient.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, continue!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true
      }).then((result: any) => {
        if (result.isConfirmed) {
          if (this.applicationContext.application?.losIdentifier || this.applicationContext.userPermissions.companyId != 222) {
            this._requestRateLock();
          } else {
            this.autoCreateLosLoan();
          }
        }
      });
    }
    else {
      this._requestRateLock();
    }
  };

  onRequestLockExtension = () => {
    const modalRef = this._modalService.open(
      LockExtensionModalComponent,
      Constants.modalOptions.large
    );
    modalRef.componentInstance.applicationId = this.applicationContext.application?.applicationId;
    modalRef.componentInstance.currentApplication = this.applicationContext.application;
    modalRef.result.then(
      (response: any) => {
        this.lockResponse = response;
        this.requestedRateLock = true;
        if (this.lockResponse.success) {
          this._notifsService.showSuccess('Lock extension request has been submitted!', "Lock extension");
          this.initialize(this.currentMortgage);
        } else {
          this._notifsService.showError(this.lockResponse.errorMessage, 'Lock extension request failed!');
        }
      },
      (res) => { }
    );
  }

  private autoCreateLosLoan = async () => {
    try {
      this._spinner.show();
      const losAppOpResult = await firstValueFrom(
        this._losService.autoCreateLosLoan(this.applicationContext.application?.applicationId),
      );
      this.applicationContextService.updateMortgageAndApplication(losAppOpResult.mortgage, losAppOpResult.application, losAppOpResult.customData);
      this._requestRateLock();
    } catch (e) {
      this._spinner.hide();
      console.error(e);
      // this.error = "There was an error attempting to prepare your loan for fees. Please contact your AE.";
      this._notifsService.showError(
        e ? e.message : 'Unable to request lock',
        'Lock request failed!'
      );
    } finally {
    }
  }

  private _requestRateLock = () => {
    this._spinner.show();
    this._pricingService
      .requestRateLock(this._currentMortgage.applicationId)
      .subscribe({
        next: (response) => {
          this.lockResponse = response; //In case of a requirement to press response on an alert card
          this.requestedRateLock = true;
          this.applicationContext.application.productPricing.lockStatus = response.success ? LockStatus.Requested : this.applicationContext.application?.productPricing.lockStatus;
          this._spinner.hide();
          if (this.lockResponse.success) {
            this.applicationContextService.updateLoanInfo(this._currentMortgage.applicationId).subscribe();
          } else {
            this._notifsService.showError(
              this.lockResponse.errorMessage,
              'Lock request failed!'
            );
          }
        },
        error: (error) => {
          this._spinner.hide();
          this._notifsService.showError(
            error ? error.message : 'Unable to request lock',
            'Lock request failed!'
          );
        }
      }
      );
  }

  onApproveRateLock = () => {
    this._pricingService.setApproveLock(this._currentMortgage.applicationId).subscribe(result => {
      if (result.success) {
        this._notifsService.showSuccess("Rate Lock Approved Successfully!", "Success");
        this.applicationContextService.updateLoanInfo(this._currentMortgage.applicationId).subscribe();
      } else {
        this._notifsService.showError("Rate Lock Not Approved!", "Error");
      }
    }, (err) => {
      this._notifsService.showError(err.error.message || err.error, "Error");
    })
  }

  onRejectRateLock = () => {
    this._pricingService.setRejectLock(this._currentMortgage.applicationId).subscribe(result => {
      if (result.success) {
        this.lockResponse = result;
        this._notifsService.showSuccess("Rate Lock Rejected Successfully!", "Success");
        this.applicationContextService.updateLoanInfo(this._currentMortgage.applicationId).subscribe();
      } else {
        this._notifsService.showError("Rate Lock Not Approved!", "Error");
      }
    }, (err) => {
      this._notifsService.showError(err.error.message || err.error, "Error");
    })
  }

  onCancelRateLock = () => {
    this._pricingService.setCancelLock(this._currentMortgage.applicationId).subscribe(result => {
      if (result.success) {
        this._notifsService.showSuccess("Rate Lock Cancelled Successfully!", "Success");
        this.applicationContextService.updateLoanInfo(this._currentMortgage.applicationId).subscribe();
      } else {
        this._notifsService.showError("Rate Lock Not Cancelled!", "Error");
      }
    }, (err) => {
      this._notifsService.showError(err.error.message || err.error, "Error");
    })
  }

  onRequestLockCancellation = () => {
    this._pricingService.setRequestCancellationLock(this._currentMortgage.applicationId).subscribe(result => {
      if (result.success) {
        this._notifsService.showSuccess("Request Lock Cancellation Successfully!", "Success");
        this.applicationContextService.updateLoanInfo(this._currentMortgage.applicationId).subscribe();
      } else {
        this._notifsService.showError("Request Lock Cancellation Fail!", "Error");
      }
    }, (err) => {
      this._notifsService.showError(err.error.message || err.error, "Error");
    })
  }

  onSearchProductsClicked = (type?: PricingTransactionType) => {
    if (this._defaultVendor && this._defaultVendor === PricingVendor.LenderPriceIframe) {
      this.openLenderPricePricerDialog();
      return;
    }
    this.openLodaPricerDialog(type);
  };

  onAdjustmentsClicked = () => {
    this._pricingService
      .getAdjustments(this.currentMortgage.applicationId)
      .subscribe(
        (response) => {
          this.adjustments = response;
          const modalRef = this._modalService.open(
            ViewAdjustmentsDialogComponent,
            Constants.modalOptions.large
          );
          modalRef.componentInstance.adjustments = this.adjustments;
        },
        (error) => {
          this._notifsService.showError(
            error ? error.message : 'Unable to retrieve adjustments',
            'Error!'
          );
        }
      );
  };

  showPricingValidationModal = () => {
    const modalRef = this._modalService.open(
      PricingValidationDialogComponent,
      Constants.modalOptions.large
    );
    modalRef.componentInstance.pricingValidation = this._pricingValidation;
  }

  private initialize = (mortgage: Mortgage) => {
    this.isLoading = true;
    this._pricingService
      .getLoanProductPricing(this.currentMortgage.applicationId)
      .subscribe(
        (response) => {
          this.loadAmortizationType(mortgage);
          this.productPricing = response;
          if (!this.productPricing) {
            this.productPricing = new LoanProductPricing();
          } else {
            this.setAmortizationTabVisibility.emit(this.productPricing.assignDate !== null);
            if (this.productPricing.lockStatus === 'Accepted' && this.productPricing.lockExpirationDate) {
              const lockExpirationDate = this.productPricing.lockExpirationDate.replace('T00:00:00.000Z', 'T23:59:59.999Z')
              const expirationDate = Date.parse(lockExpirationDate);
              const now = new Date().getTime();
              if (now >= expirationDate) {
                this.productPricing.lockStatus = 'Expired';
              }
              this.isLoading = false;
            }
            else {
              this.isLoading = false;
              this.isLoadingPricingValidity = true;
              this._pricingService
                .checkIfProductPricingIsValid(this.currentMortgage.applicationId)
                .subscribe((validationResult: ProductPriceValidation) => {
                  this.isLoadingPricingValidity = false;
                  this.isProductPricingValid = validationResult.validity === 'Valid';
                  this._pricingValidation = validationResult;
                },
                  (error) => {
                    this.isLoadingPricingValidity = false;
                    this._notifsService.showError(
                      error ? error.message : 'Unable to check pricing validity',
                      'Error!'
                    );
                  }
                );
            }
          }
          //soooo hacky
          if (this.productPricing.rate) {
            this.productPricing.rate = this.productPricing.rate / 100;
          }
        },
        (error) => {
          this.isLoading = false;
          this._notifsService.showError(
            error ? error.message : 'Unable to retrieve product pricing',
            'Error!'
          );
        }
      );
  };

  private openLodaPricerDialog = (type?: PricingTransactionType) => {
    const modalRef = this._modalService.open(
      LoanPricingSearchDialogComponent,
      this._modalOptions
    );
    modalRef.componentInstance.scenariosChanged.subscribe((e: any) => this.scenariosChanged.emit(e));
    modalRef.componentInstance.enableChannelSelection = this.enableChannelSelection;
    modalRef.componentInstance.lockStatus = this.productPricing.lockStatus;
    modalRef.componentInstance.transactionType = type;
    modalRef.componentInstance.isLoanReadOnly = this.applicationContext.applicationIsReadOnly;
    modalRef.result.then(
      (result: any) => {
        this.productPricing = null;
        this._currentMortgage = this.applicationContext.currentMortgage;
        this.initialize(this._currentMortgage);
      },
      (res) => { }
    );
  };

  private openLenderPricePricerDialog = () => {
    const modalRef = this._modalService.open(
      LenderPricePricerDialogComponent,
      this._modalOptions
    );
    modalRef.result.then(
      (result: any) => {
        this.productPricing = null;

        this._spinner.show();
        setTimeout(() => {
          this._losService.pullFromLos(this._currentMortgage.applicationId).subscribe((losResult) => {
            this.applicationContextService.updateLoanInfo(this._currentMortgage.applicationId).subscribe();
            this._spinner.hide();
          }, err => {
            this.applicationContextService.updateLoanInfo(this._currentMortgage.applicationId).subscribe();
            this._spinner.hide();
          });
        }, 20000);
      },
      (res) => { }
    );
  };

  private checkLosSync = (application: LoanApplication) => {
    const { refNumber, losVendor, losIdentifier } = application;
    if (refNumber && !losVendor && !losIdentifier) {
      this.losStatusType = 'referenced';
    } else if (losVendor && losIdentifier) {
      this.losStatusType = 'linked';
    } else {
      this.losStatusType = 'notAssociated';
    }
  };
}

export enum PricingTransactionType {
  reprice = "reprice",
  programChange = "programChange"
}
