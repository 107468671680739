<div class="row mt-1">
    <div class="col-md-12">
        <div class="form-check form-check-inline">
            <input id="pricingEnabled" name="pricingEnabled" class="form-check-input" type="checkbox"
                [(ngModel)]="pricingEnabled.value" ToggleValue [true]="1" [false]="0">
            <label class="form-check-label" for="pricingEnabled">Enable Pricing Integration</label>
        </div>
    </div>
    <div class="col-md-12 mt-2">
        <div class="form-check form-check-inline">
            <input id="lockWithVendor" name="lockWithVendor" class="form-check-input" type="checkbox"
                [(ngModel)]="externalLocksEnabled.value" ToggleValue [true]="1" [false]="0">
            <label class="form-check-label" for="lockWithVendor">Lock loans with Pricing Vendor</label>
        </div>
    </div>
    <div class="col-md-12 mt-2">
      <div class="form-check form-check-inline">
          <input id="pricingEnabledInNewApp" name="pricingEnabledInNewApp" class="form-check-input" type="checkbox"
              [(ngModel)]="pricingEnabledInNewApp.value" ToggleValue [true]="1" [false]="0">
          <label class="form-check-label" for="pricingEnabledInNewApp">Enable pricing on New Application wizard</label>
      </div>
  </div>
    <div class="col-md-12">
        <hr>
    </div>
    <div class="col-md-2 text-end">
        <label class="custom-control-label"> Default Pricing Vendor </label>
    </div>
    <div class="col-md-4">
        <select class="form-select" name="defaultPricing" [(ngModel)]="defaultPricingVendor.valueStr">
            <option value="">-- Select One --</option>
            <ng-container *ngFor="let vendor of vendorsWithCreds;trackBy: trackByValue">
                <option [value]="vendor.value"> {{vendor.name}} </option>
            </ng-container>
        </select>
    </div>
    <div class="col-md-6"></div>
    <div class="col-md-2 text-end mt-3">
        <label class="custom-control-label"> Lock Desk Email Address</label>
    </div>
    <div class="col-md-4 mt-3">
        <input type="text" name="lockDesk" class="form-control" [(ngModel)]="lockDeskEmailAddress.valueStr" />
    </div>
    <div class="col-md-6"></div>
    <div class="d-flex align-content-end flex-wrap">
        <div class="mt-4 me-2" *ngFor="let vendor of vendors">
            <credential-item [credential]="vendor" [credentials]="vendors"
                (onCredentialSucessfullyDeleted)="onCredsDelete($event)"
                (openCredentialModal)="openVendorEditor($event)">
            </credential-item>
        </div>
        <ng-container *ngIf="missingPricingVendors.length > 0">
            <div class="mt-4 me-2">
                <create-credential [credentialType]="'NewVendor'"
                    (openCredentialModal)="openVendorPicker()"></create-credential>
            </div>
        </ng-container>
    </div>

    <!-- pricing scenario -->

    <div class="col-md-12 mt-4">
        <div class="fieldset-title">
            Pricing Scenario
            <span class="badge badge-soft-primary font-14">New!</span>
        </div>
    </div>

    <div class="col-md-2 text-end mt-3">
        <label class="custom-control-label">Time</label>
    </div>
    <div class="col-md-4 mt-3">
        <!-- interval 15 minutes -->
        <timepicker [(ngModel)]="refreshTime" name="refreshTime" [showMeridian]="false" [minuteStep]="15"
            (ngModelChange)="onRefreshTimeChanged()"></timepicker>
    </div>
    <div class="col-md-6"></div>

    <div class="col-md-2 text-end mt-3">
        <label class="custom-control-label">Days</label>
    </div>
    <div class="col-md-4 mt-3">
        <ng-select name="refreshDays" class="custom-ng-select" bindLabel="name" bindValue="value"
            placeholder="Refresh Days" [(ngModel)]="refreshDays" [multiple]="true" [closeOnSelect]="false"
            [items]="daysOfWeekArray" (change)="onRefreshDaysChanged()"></ng-select>
    </div>
    <div class="col-md-6"></div>

    <div class="col-md-2 text-end mt-3">
        <label class="custom-control-label">Statuses</label>
    </div>
    <div class="col-md-4 mt-3">
        <ng-select name="refreshLoanStatuses" class="custom-ng-select" bindLabel="loanStatusName"
            bindValue="loanStatusId" placeholder="Refresh Loan Statuses" [(ngModel)]="refreshLoanStatuses"
            [multiple]="true" [closeOnSelect]="false" [items]="loanStatus | sort: 'asc':'loanStatusName'"
            (change)="onRefreshLoanStatusesChanged()"></ng-select>
    </div>
    <div class="col-md-6"></div>

    <!--  -->
</div>
<div class="row">
    <div class="col-md-12">
        <div class="text-end">
            <div class="col-md-12 my-1">
                <hr />
            </div>
            <button type="submit" class="btn btn-primary right" [disabled]="isSaving" (click)="save()">
                <span *ngIf="!isSaving"><i class="fa fa-save me-2"></i> Save</span>
                <span *ngIf="isSaving">
                    <span class="spinner-border spinner-border-sm"></span> Saving
                </span>
            </button>
        </div>
    </div>
</div>
