<ng-template #pageActionBarMiddle>
  <ng-container *ngIf="!applicationLoadFailed">
    <admin-loan-summary-top-bar [borrowerId]="primaryBorrower?.borrowerId"
      [isNiceInContactVisible]="isNiceInContactVisible"
      [borrowers]="applicationContext ? applicationContext.borrowers : []"
      (loadApplicationTaskCounts)="onLoadApplicationTaskCounts()"
      (loanActivityToggled)="onLoanActivityToggled()"
      (setAppointmentClicked)="onSetAppointmentClicked()"
      (sendEmailSmsClicked)="onSendEmailSmsClicked()"
      (sendEmailClicked)="onSendEmailClicked()"
      (sendVideoEmailClicked)="onSendVideoEmailClicked()"
      (sendEmailWithAttachmentClicked)="onSendEmailWithAttachmentClicked($event)"
      (loanSummaryClicked)="onLoanSummaryClicked()"
      (disclosureTrackingClicked)="onDisclosureTrackingClicked()"
      (identifyingDocumentsClicked)="onIdentifyingDocumentsClicked()"
      (emailSmsHistoryClicked)="onEmailSmsHistoryClicked()"
      (viewEsignatureHistoryClicked)="onViewEsignatureHistoryClicked()"
      (exportMismoClicked)="onExportMismoClicked()"
      (exportDUClicked)="onExportDUClicked()"
      (importMismoClicked)="onImportMismoClicked()"
      (generateDocClicked)="onGenerateDocClicked($event)"
      (mcrAuditDataClicked)="onMcrAuditDataClicked()"
      (viewTitleHistoryClicked)="onViewTitleHistoryClicked()"
      (keyDatesClicked)="onKeyDatesClicked()"
      (benefitCalculatorsclicked)="onBenefitCalculatorsclicked()"
      (monsterInsightsClicked)="onMonsterInsightsClicked()"
      (checkListsClicked)="onCheckListsClicked()"
      (denialClicked)="onDenialClicked()"
      (creditInquiryClicked)="onCreditInquiryClicked()"
      (reloadTasks)="reloadTasks()"
      (createWireRequestClicked)="onCreateWireRequestClicked()"
      (purchaseAdviceClicked)="onPurchaseAdviceClicked()">
    </admin-loan-summary-top-bar>
  </ng-container>
</ng-template>

<ng-template #mainContent>
  <ng-container *ngIf="!isLoadingApplicationDetails; else loading">
    <div class="offset-xxl-0 col-xxl-12 col-xxxl-10" [ngClass]="{'offset-xxxl-1': !isNiceInContactVisible}">
      <div class="row">
        <app-tasks #taskCounters [taskCounts]="taskCounts" (statSelected)="onStatSelected($event)"></app-tasks>
        <task-table *ngIf="tasks" [filterId]="filterId" [scrollable]="true" [expandToVisibleScreen]="false"
          [appId]="application.applicationId" [tasks]="tasks" class="mb-2" (reloadTasks)="reloadTasks()">
        </task-table>
        <div *ngIf="tasks">
          <div class="card text-center">
            <a (click)="onCloseTaskTableClicked()" class="p-1"><i class="fa fa-times pe-1"></i> Close Task View</a>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-1half col-sm-2" [ngClass]="{'offset-xxxl-1': !isNiceInContactVisible}">
        <div class="card card-stickified" [ngClass]="{ 'card-stickified-open-dialer': secondPageActionBarVisible }">
          <div class="card-body p-0">
            <app-details-menu *ngIf="application" [application]="application" [borrowerId]="borrowerId"
              [borrowers]="applicationContext ? applicationContext.borrowers : []" [permissions]="permissions"
              (generateDocClicked)="onGenerateDocClicked()" (setAppointmentClicked)="onSetAppointmentClicked()"
              (sendEmailSmsClicked)="onSendEmailSmsClicked()" (emailSmsHistoryClicked)="onEmailSmsHistoryClicked()"
              (viewEsignatureHistoryClicked)="onViewEsignatureHistoryClicked()" [isTpoUser]="isTpoUser">
            </app-details-menu>
            <!-- <tpo-menu *ngIf="application" [loanId]="application.applicationId" [borrowerId]="borrowerId"></tpo-menu> -->
          </div>
        </div>
      </div>
      <div [ngClass]="{'col offset-e-xxxl-1': isWideContent, 'col-xxxl-8 col-md-10half': !isWideContent}">
        <router-outlet></router-outlet>
      </div>
    </div>
  </ng-container>

  <ng-template #loading>
    <div class="offset-lg-0 offset-xxl-1 offset-xxxl-2 col-lg-12 col-xxl-10 col-xxxl-8">
      <loading-indicator [loadingMessage]="'Loading application details...'"></loading-indicator>
    </div>
  </ng-template>
</ng-template>

<admin-page-template [useActionBarSections]="true" [pageMainContentTemplate]="mainContent"
  [pageActionBarMiddleContentTemplate]="pageActionBarMiddle" [isNiceInContactVisible]="isNiceInContactVisible">
</admin-page-template>

<drawer [title]="'Loan Activity'" [options]="loanActivityDrawerOptions" [name]="'loanActivityDrawer'"
  [templateRef]="loanActivityRef" (closed)="onLoanActivityDrawerClosed()">
</drawer>
<ng-template #loanActivityRef>
  <div style="height: 90%; padding: 12px">
    <loan-activity [showBorrowerLink]="false"></loan-activity>
  </div>
</ng-template>

<drawer #createEventDrawer [title]="'Set Appointment'" [name]="'createEventDrawer'" [options]="drawerOptions">
</drawer>

<drawer [title]="'Send E-mail'" [name]="'sendEmailSmsDrawer'" [templateRef]="sendEmailSmsContentRef"
  [options]="drawerOptionsXl" (closed)="onSendEmailSmsDrawerclosed()">
</drawer>
<ng-template #sendEmailSmsContentRef>
  <div style="padding: 20px">
    <send-email-sms-dialog
      [uploadedVideoBlob] = "uploadedVideoBlob"
      [isVideo] = "isSendingVideoEmail"
      [isModal]="false"
      [attachments]="attachments"
      [isShowOnlyEmail]="true"
      (sentSuccessfully)="onEmailSmsSentSuccessfully()"
      (cancelled)="onEmailSmsSendCancelled()">
    </send-email-sms-dialog>
  </div>
</ng-template>

<drawer [title]="'E-mail/SMS History'" [name]="'emailSmsHistoryDrawer'" [templateRef]="emailSmsHistoryContentRef"
  [options]="emailSmsHistoryDrawerOptions">
</drawer>
<ng-template #emailSmsHistoryContentRef>
  <div style="padding: 20px">
    <correspondence-history [scrollOffsetInPixels]="270"></correspondence-history>
  </div>
</ng-template>

<drawer [title]="'E-Signature History'" [name]="'esignHistoryDrawer'" [templateRef]="esignHistoryContentRef"
  [options]="esignHistoryDrawerOptions">
</drawer>
<ng-template #esignHistoryContentRef>
  <div style="padding: 20px">
    <esignature-history></esignature-history>
  </div>
</ng-template>

<drawer #loanSummaryDrawer [title]="getLoanSummaryDrawerTitle()" [name]="'loanSummaryDrawer'"
  [templateRef]="loanSummaryDrawerContentRef" [options]="loanSummaryDrawerOptions">
</drawer>

<ng-template #loanSummaryDrawerContentRef>
  <admin-loan-summary-v3 [inDrawer]="true"></admin-loan-summary-v3>
</ng-template>

<drawer #disclosureTrackingDrawer [title]="'Disclosure Tracking Tool'" [name]="'disclosureTrackingDrawer'"
  [templateRef]="disclosureTrackingDrawerContentRef" [options]="disclosureTrackingDrawerOptions">
</drawer>

<ng-template #disclosureTrackingDrawerContentRef>
  <disclosure-tracking 
    [appId]="application.applicationId" 
    [mortgageId]="this.mortgage.mortgageId"
    [urlaFieldsConfig]="urlaFieldsConfig"
    [borrowers]="this.mortgage.borrowers" 
    [usersAll]="applicationContext.globalConfig.usersAll"
    [timeZone]="applicationContext.currentlyLoggedInUserProfile.userProfile.timeZone">
  </disclosure-tracking>
</ng-template>

<drawer [title]="'Identifying Documents'" [name]="'identifyingDocumentsDrawer2'" [templateRef]="identifyingDocumentsContentRef2"
  [options]="identifyingDocumentsDrawerOptions">
</drawer>
<ng-template #identifyingDocumentsContentRef2>
  <identifying-documents [showTab]="true"></identifying-documents>
</ng-template>

<drawer #keyDatesDrawer [title]="'Key Dates'" [name]="'keyDatesDrawer'" [templateRef]="keyDateContentRef"
  [options]="keyDatesDrawerOptions">
</drawer>
<ng-template #keyDateContentRef>
  <diV class="m-2 p-2">
    <key-dates></key-dates>
  </diV>
</ng-template>

<drawer #mcrAuditDataSummaryEditor [title]="'Editing MCR Audit Data'" [name]="'mcrAuditDataSummaryEditorDrawer'"
  [templateRef]="mcrAuditDataEditorContentRef" [options]="mcrDataAuditDrawerOptions">
</drawer>
<ng-template #mcrAuditDataEditorContentRef>
  <div class="m-2 p-2">
    <summary-mcr-audit-data [mcrAuditSummaries]="mcrAuditLookup" [appId]="application.applicationId" (refreshRequired)="onMcrAuditDataClicked(false)">
    </summary-mcr-audit-data>
  </div>
</ng-template>

<drawer [title]="'Title History'" [name]="'viewTitleHistoryDrawer'" [templateRef]="viewTitleHistoryContentRef"
  [options]="viewTitleHistoryDrawerOptions">
</drawer>
<ng-template #viewTitleHistoryContentRef>
  <div style="padding: 20px">
    <title-history [applicationId]="application.applicationId"></title-history>
  </div>
</ng-template>

<drawer [title]="'Benefit Summaries'" [name]="'benefitCalculatorsDrawer'" [templateRef]="benefitCalculatorsContentRef"
  [options]="benefitCalculatorsDrawerOptions">
</drawer>
<ng-template #benefitCalculatorsContentRef>
  <div class="m-2 p-2">
    <benefit-calculators [mortgage]="mortgage" [currentLoanInterestRate]="application.currentLoanInterestRate"
      [currentLoanTerm]="application.currentLoanTerm" [isRefinance]="isRefinance">
    </benefit-calculators>
  </div>
</ng-template>

<drawer [title]="'Monster Insights'" [name]="'monsterInsightsDrawer'" [templateRef]="monsterInsightsContentRef"
  [options]="monsterInsightsDrawerOptions">
</drawer>
<ng-template #monsterInsightsContentRef>
  <div class="m-2 p-2">
    <div>
      <button class="btn btn-sm btn-outline-primary pull-right" (click)="generateMonsterInsightsUrl()">{{application.monsterInsightsUrl ? 'Regenerate' : 'Generate'}} Insights Url</button>
    </div>
    <div style="height: 100vh;" *ngIf="application.monsterInsightsUrl">
      <iframe height="100%" width="100%" loading="lazy" allowfullscreen
         referrerpolicy="no-referrer-when-downgrade" [src]="application.monsterInsightsUrl | safeUrl">
      </iframe>
     </div>
  </div>
</ng-template>

<drawer [title]="'Checklists'" [name]="'checkListsDrawer'" [templateRef]="checkListsContentRef"
  [options]="checkListsDrawerOptions">
</drawer>
<ng-template #checkListsContentRef>
  <div class="m-2 p-2">
    <loan-app-checklists [applicationId]="application.applicationId"
      [borrowerNameById]="borrowerNameById"></loan-app-checklists>
  </div>
</ng-template>

<drawer [title]="'Denial / Termination / Adverse Action'" [name]="'denialDrawer'" [templateRef]="denialContentRef"
  [options]="denialDrawerOptions">
</drawer>
<ng-template #denialContentRef>
  <div class="m-2 p-2">
    <app-hmda [deniedByUserName]="getDeniedByUserName()" [documentTypes]="documentTypes" [mortgage]="mortgage"
      [application]="application" (onSave)="onHmdaAfterSave($event)" (onClose)="onHmdaDrawerClosed()"></app-hmda>
  </div>
</ng-template>

<drawer [title]="'Generate Document'" [name]="'docGenDrawer'" [templateRef]="docGenDrawerRef"
  [options]="docGenDrawerOptions" (closed)="onGenerateDocClosed()">
</drawer>
<ng-template #docGenDrawerRef>
  <generate-document-drawer [applicationId]="application.applicationId" [documentTemplates]="allowedDocumentTemplates"
    [showOnlyPrequalLetterDocs]="isPrequalLetterDocGenerationActive" (onClose)="onGenerateDocClosed()">
  </generate-document-drawer>
</ng-template>

<drawer [title]="'Credit Inquiry'" [name]="'creditInquiryDrawer'" [templateRef]="creditInquiryDrawerRef"
  [options]="creditInquiryDrawerOptions" (closed)="onCreditInquiryClosed()">
</drawer>
<ng-template #creditInquiryDrawerRef>
  <credit-inquiry [mortgage]="mortgage" (closewindow)="onCreditInquiryClosed()"></credit-inquiry>
</ng-template>

<drawer [title]="'Create Wire Request'" [name]="'createWireRequestDrawer'" [templateRef]="createWireRequestDrawerRef"
  [options]="createWireRequestDrawerOptions" (closed)="onCreateWireRequestClosed()">
</drawer>
<ng-template #createWireRequestDrawerRef>
  <create-wire-request-drawer [applicationId]="application.applicationId"
    (onClose)="onCreateWireRequestClosed()"></create-wire-request-drawer>
</ng-template>

<drawer [title]="'Purchase Advice'" [name]="'purchaseAdviceDrawer'" [templateRef]="purchaseAdviceDrawerRef"
  [options]="purchaseAdviceDrawerOptions" (closed)="onPurchaseAdviceClosed()">
</drawer>
<ng-template #purchaseAdviceDrawerRef>
  <purchase-advice
    [app]="application"
   (onClose)="onPurchaseAdviceClosed()"
   (onSave)="onPurchaseAdviceSaved($event)">
  </purchase-advice>
</ng-template>
