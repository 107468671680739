import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Borrower, LoanApplication, UserPermissions } from 'src/app/models';
import { CopyApplicationDialogComponent } from 'src/app/modules/applications/copy-application-dialog/copy-application-dialog.component';
import { Constants } from 'src/app/services/constants';
import { ApplicationMode, NavigationService } from 'src/app/services/navigation.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { Subscription } from 'rxjs';
import { LinkToLeadDialogComponent } from 'src/app/modules/applications/link-to-lead-dialog/link-to-lead-dialog.component';
import { ConversationService } from 'src/app/modules/conversations/services/conversation.service';
import { AppDetailsService } from '../../services/app-details.service';
import { LoanDetailsInfo } from 'src/app/models/loan/loan-details-info.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/services/notification.service';
import { TaskTypeEnum } from 'src/app/models/task/task.model';
import { LastAttemptedContactDialogComponent } from '../last-attempted-contact-dialog/last-attempted-contact-dialog.component';
import { Router } from '@angular/router';
import { MarkManualTransferDialogComponent } from 'src/app/modules/dialer/components/mark-manual-transfer-dialog/mark-manual-transfer-dialog.component';

@Component({
  selector: 'loan-summary-more-menu',
  templateUrl: 'loan-summary-more-menu.component.html',
  styleUrls: ['./loan-summary-more-menu.component.scss'],
})
export class LoanSummaryMoreMenuComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input()
  borrowerId: number;

  @Input()
  borrowers: Borrower[];

  @Input() isUrlaFormOpened: boolean = false;

  @Input() isNiceInContactVisible: boolean;

  @Input() onlineAppEnabled: boolean;

  @Input() onlineAppTaskExists: boolean;

  @Input() loanBeamEnabled: boolean;

  @Input() loanBeamDocSyncTaskExists: boolean;

  @Input() showESignTaskOption: boolean;

  @Output()
  closeRequested: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  exportMismoClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  exportDUClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  importMismoClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  loanSummaryClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  disclosureTrackingClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  keyDatesClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  generateDocClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  processingWorksheetClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  emailSmsHistoryClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  addNewTaskClicked: EventEmitter<any> = new EventEmitter<{ prop1: TaskTypeEnum, prop2: boolean, prop3: boolean, prop4: any }>();

  @Output()
  addOnlineAppTaskClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  addLoanBeamDocSyncTaskClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  startCobrowseClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  loanActivityToggled: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  setAppointmentClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  sendEmailSmsClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  sendEmailClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  sendVideoEmailClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  viewEsignatureHistoryClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  viewTitleHistoryClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  mortgageCalculatorsClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  monsterInsightsClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  mcrAuditDataClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  checkListsClicked: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  denialClicked: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  exportsUrlaDocsClicked: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  creditInquiryClicked: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  createWireRequestClicked: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  purchaseAdviceClicked: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  identifyingDocumentsClicked: EventEmitter<any> = new EventEmitter<any>();

  application: LoanApplication;
  borrowerName: string;
  permissions: UserPermissions;
  applicationMode: string;

  systemGenerateDocumentMenuActive: boolean = false;
  addNewTaskMenuActive: boolean = false;
  internalMenuActive: boolean = false;
  borrowerMenuActive: boolean = false;
  quickClientUpdateMenuActive: boolean = false;

  protected canBeOpenedInTpoPortal: boolean = false;

  protected canRemoveFromAllReports: boolean = false;

  protected companyId: number;

  private _loanInfoChangesSubscription: Subscription;
  private _contextSubscription: Subscription;
  private _refreshedContextSubscription: Subscription;

  constructor(
    private readonly injector: Injector,
    private readonly _conversationService: ConversationService,
    private readonly _modalService: NgbModal,
    private readonly _navigationService: NavigationService,
    private readonly _appDetailsService: AppDetailsService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notificationService: NotificationService,
    private readonly _router: Router
  ) {
    super(injector);
  }

  ngOnInit() {
    this.applicationMode = this._navigationService.applicationMode == ApplicationMode.Classic ? 'admin' :
      this._navigationService.applicationMode == ApplicationMode.NextGen ? 'loda-nextgen' : 'admin';
    this._contextSubscription = this.applicationContextService.context.subscribe((context) => {
      this.permissions = this.applicationContext.userPermissions;
      this.companyId = this.applicationContext.userPermissions.companyId;
      this.canRemoveFromAllReports = this.permissions.allowRemoveFromAllReports;
      if (context.application) {
        this.application = context.application;
        this.canBeOpenedInTpoPortal = this.application.externalCompanyId && this.application.externalCompanyId > 0;
      }
    });
    this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe((context) => {
      if (context.application) {
        this.application = context.application;
        this.canBeOpenedInTpoPortal = this.application.externalCompanyId && this.application.externalCompanyId > 0;
      }
    });
    const borrower = this.borrowers ? this.borrowers.find(b => b.borrowerId === this.borrowerId) : null;
    this.borrowerName = borrower ? `${borrower.firstName || ""} ${borrower.lastName || ""}` : "";
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._loanInfoChangesSubscription?.unsubscribe();
    this._contextSubscription?.unsubscribe();
    this._refreshedContextSubscription?.unsubscribe();
  }

  onExportMismoClicked = () => {
    this.exportMismoClicked.emit();
  }

  onExportDUClicked = () => {
    this.exportDUClicked.emit();
  }

  onImportMismoClicked = () => {
    this.importMismoClicked.emit();
  }

  onLoanSummaryClicked = () => {
    this.loanSummaryClicked.emit();
  }

  onDisclosureTrackingClicked = () => {
    this.disclosureTrackingClicked.emit();
  }

  addNewTask = (taskType: TaskTypeEnum, condition: boolean, requestBorrower: boolean, loanDoc: any) => {
    this.addNewTaskClicked.emit({ taskType, condition, requestBorrower, loanDoc });
  }

  addOnlineAppTask = () => {
    this.addOnlineAppTaskClicked.emit();
  }

  addLoanBeamDocSyncTask = () => {
    this.addLoanBeamDocSyncTaskClicked.emit();
  }

  onIdentifyingDocumentsClicked = () => {
    this.identifyingDocumentsClicked.emit()
  }

  onProcessingWorksheetClicked = () => {
    this.processingWorksheetClicked.emit();
  }

  onGenerateDocClicked = (isPrequalFilterActive: boolean = false) => {
    this.generateDocClicked.emit(isPrequalFilterActive);
  }

  exportUrlaDocs = () => {
    this.exportsUrlaDocsClicked.emit();
  }

  onCreditInquiryClicked = () => {
    this.creditInquiryClicked.emit();
  }

  onKeyDatesClicked = () => {
    this.keyDatesClicked.emit();
  }

  onLoanActivityToggled = () => {
    this.loanActivityToggled.emit();
  }

  onViewEsignatureHistoryClicked = () => {
    this.viewEsignatureHistoryClicked.emit();
  }

  onMortgageCalculatorsClicked = () => {
    this.mortgageCalculatorsClicked.emit();
  }

  onMonsterInsightsClicked = () => {
    this.monsterInsightsClicked.emit();
  }

  onBorrowerMessagingClicked = () => {
    this._conversationService.addBorrowerChat(this.application.applicationId, this.borrowerId, { senderDisplayName: this.borrowerName }, true);
  }

  onSetAppointmentClicked = () => {
    this.setAppointmentClicked.emit();
  }

  onSendEmailSmsClicked = () => {
    this.sendEmailSmsClicked.emit();
  }

  onSendEmailClicked = () => {
    this.sendEmailClicked.emit();
  }

  onSendVideoEmailClicked = () => {
    this.sendVideoEmailClicked.emit();
  }

  onEmailSmsHistoryClicked = () => {
    this.emailSmsHistoryClicked.emit();
  }

  onViewTitleHistoryClicked = () => {
    this.viewTitleHistoryClicked.emit();
  }

  onStartCobrowseClicked = () => {
    this.startCobrowseClicked.emit();
  }

  onCopyApplicationClicked = () => {
    const modalRef = this._modalService.open(CopyApplicationDialogComponent, Constants.modalOptions.xlarge)
    modalRef.componentInstance.title = 'Copy Application';
    modalRef.result.then((result) => {

    }, (res) => {

    });
  }

  onLinkToLeadClicked = () => {
    const modalRef = this._modalService.open(LinkToLeadDialogComponent, { ...Constants.modalOptions.ninetyPercentOfScreenWidth, scrollable: false })
    modalRef.componentInstance.title = 'Link To Lead';
    modalRef.componentInstance.appId = this.application.applicationId;

    modalRef.result.then((result) => {
      this.applicationContextService.updateLoanInfo(this.application.applicationId).subscribe();
    }, (res) => {

    });
  }

  onMcrAuditDataClicked = () => {
    this.mcrAuditDataClicked.emit();
  }

  onCheckListsClicked = () => {
    this.checkListsClicked.emit();
  }

  onDenialClicked = () => {
    this.denialClicked.emit();
  }

  onCreateWireRequestClicked = () => {
    this.createWireRequestClicked.emit();
  }

  onPurchaseAdviceClicked = () => {
    this.purchaseAdviceClicked.emit();
  }

  onAttemptedContactClicked = () => {
    const modalRef = this._modalService.open(LastAttemptedContactDialogComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.appId = this.application.applicationId;
  }

  onMarkManualTransferClicked = () => {
    const modalRef = this._modalService.open(MarkManualTransferDialogComponent, {...Constants.modalOptions.large, scrollable: false});
    modalRef.componentInstance.application = this.application;
    modalRef.componentInstance.allRoles = this.applicationContext.globalConfig.roles;
  }

  onRemoveFromAllReportsToggled = () => {
    this.application.removeFromReporting = !this.application.removeFromReporting;

    const payload = new LoanDetailsInfo();
    payload.application = this.application;

    // just remember to include mortgageId
    payload.application.mortgageLoan = null;
    payload.customData = null

    this._spinner.show();
    this._appDetailsService.saveLoanInfo(this.application.applicationId, payload).subscribe({
      next: (res) => {
        this.applicationContextService.updateApplication(this.application);
      },
      error: (err) => {

      }
    }).add(() => this._spinner.hide());
  }

  protected onOpenLoanInTpoPortalClicked = () => {
    this._router.navigate(['open-loan-in-tpo'], {
      queryParams: { applicationId: this.application.applicationId },
    });
  }

  protected quickClientUpdateClicked = (typeOfQuickClientTask: string) => {
    this._spinner.show();
    this._appDetailsService.updateQuickClientTask(this.application.applicationId, typeOfQuickClientTask)
      .subscribe({
        next: () => {
          this._notificationService.showSuccess('Quick client task updated!', 'Success');
        },
        error: (err) => {
          this._notificationService.showError(err ? err.error.message || err.message : 'Error updating quick client task!', 'Error');
        }
      }).add(() => this._spinner.hide());
  }
}
