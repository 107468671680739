import { ChannelFlags, LosVendor } from "src/app/models"

export class ConfigurationProduct {
  productId: number;
  pricingVendorProductId: string = null;
  productName: string;
  productCategory: string = null;
  term: number;
  sortOrder: number;
  active: boolean
  pricingVendor: PricingEngineVendor = null;
  enabledChannels: string; // ChannelFlags[]
  isTpoSubmissionAllowed: boolean;
  duAllowedRecommendations: string; // MortgageDuAusRecommendation[]
  lpaAllowedRecommendations: string; // MortgageLpaAusRecommendation[]
  duTpoSubmissionAllowed: string; // MortgageDuAusRecommendation[]
  lpaTpoSubmissionAllowed: string; // MortgageLpaAusRecommendation[]
  tpoCreditRequired: boolean;
  losVendor: LosVendor = null;
  losVendorProductId: number = null;

  companyId: number;
  insertedBy: string;
  dateInserted: Date;
  updatedBy: string;
  dateUpdated: Date;
}

export enum MortgageLpaAusRecommendation {
  AcceptEligible = "AcceptEligible",
  AcceptIneligible = "AcceptIneligible",
  CautionEligible = "CautionEligible",
  CautionIneligible = "CautionIneligible",
  ReferEligible = "ReferEligible",
  ReferIneligible = "ReferIneligible",
  None = "None"
}

export enum MortgageDuAusRecommendation {
  ApproveEligible = "ApproveEligible",
  ApproveIneligible = "ApproveIneligible",
  OutOfScope = "OutOfScope",
  ReferEligible = "ReferEligible",
  ReferIneligible = "ReferIneligible",
  ReferWithCaution = "ReferWithCaution",
  None = "None"
}

export enum PricingEngineVendor {
  Unknown = "Unknown",
  Lodasoft = "Lodasoft",
  OptimalBlue = "OptimalBlue",
  LenderPrice = "LenderPrice",
  Polly = "Polly",
  MeridianLink = "MeridianLink",
  LoanPass = "LoanPass",
  LoanPassIframe = "LoanPassIframe",
  LenderPriceIframe = "LenderPriceIframe"
}
