import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { NotificationService } from '../../../services/notification.service';
import { LdeService } from 'src/app/services/lde.service';
import { LdeTransactionHistory, LdeEvent, LosEventDirection, LosEventOperation, LosEventSource, LosEventStatus } from 'src/app/models';
import { Clipboard } from '@angular/cdk/clipboard';
import { LosLdeTransactionHistoryFiltersComponent } from 'src/app/shared/components/los-lde-transaction-history-filters/los-lde-transaction-history-filters.component';

@Component({
  selector: 'lde-transaction-history',
  templateUrl: 'lde-transaction-history.component.html',
  styleUrls: ['./lde-transaction-history.component.scss']
})
export class LdeTransactionHistoryComponent implements OnInit {
  @Input()
  applicationId: number;

  @ViewChild('losLdeTransactionHistoryFilter')
  losLdeTransactionHistoryFilterComponent: LosLdeTransactionHistoryFiltersComponent;

  transactionHistory: LdeTransactionHistory;

  loadingHistory: boolean = false;
  areIgnoredEventsHidden: boolean = true;

  applicationTimeStr: string = '';
  ldeLoanTimeStr: string = '';
  stateKey = 'los-lde-transaction-history-table-state';

  filteredTransactionHistoryEvents: LdeEvent[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    public _datePipe: DatePipe,
    private readonly _notificationService: NotificationService,
    private readonly _ldeService: LdeService,
    private readonly _clipboard: Clipboard
  ) {

  }

  ngOnInit(): void {
    this.loadingHistory = true;
    this._ldeService.getTransactionHistory(this.applicationId)
      .subscribe({
        next: (response) => {
          this.transactionHistory = response || new LdeTransactionHistory();

          if(this.transactionHistory.applicationTime) {
            this.applicationTimeStr = this._datePipe.transform(this.transactionHistory.applicationTime, 'MMM d, y h:mm:ss a');
          }

          if(this.transactionHistory.ldeLoanTime) {
            this.ldeLoanTimeStr = this._datePipe.transform(this.transactionHistory.ldeLoanTime, 'MMM d, y h:mm:ss a');
          }

          // by default show only acknowledged events
          if (this.areIgnoredEventsHidden) {
            this._filterIgnoredEventsOut(this.transactionHistory.events);
          }

          
          this.filteredTransactionHistoryEvents = [...this.transactionHistory.events];
        },
        error: (error) => {
          this._notificationService.showError(
            error?.message,
            'Error!'
          );
        }
      }).add(() => this.loadingHistory = false);
  }

  toggleIgnoredEventsHidden() {
    // this will emit an event and run this.onFilterChanged()
    this.losLdeTransactionHistoryFilterComponent.onFilterChanged();
    
    if (this.areIgnoredEventsHidden) {
      this._filterIgnoredEventsOut(this.filteredTransactionHistoryEvents);
    }
  }

  onFilterChanged({
    eventSourceFilter,
    directionFilter,
    operationFilter,
    statusFilter,
  }: {
    eventSourceFilter: LosEventSource
    directionFilter: LosEventDirection
    operationFilter: LosEventOperation
    statusFilter: LosEventStatus
  }) {
    this.filteredTransactionHistoryEvents = this.transactionHistory.events.filter(event => 
      (!eventSourceFilter || eventSourceFilter === event.eventSource) &&
      (!directionFilter || directionFilter === event.eventDirection) &&
      (!operationFilter || operationFilter === event.eventOperation) &&
      (!statusFilter || statusFilter === event.status)
    )

    if (!statusFilter && this.areIgnoredEventsHidden) {
      this._filterIgnoredEventsOut(this.filteredTransactionHistoryEvents);
    }
  }

  onCopyRequestTraceId(reqTraceId: string) {
    this._clipboard.copy(reqTraceId);
    this._notificationService.showInfo('Request trace ID copied to clipboard.', 'Copied!');
  }

  private _filterIgnoredEventsOut(events: LdeEvent[]) {
    this.filteredTransactionHistoryEvents = events.filter(event => event.status != LosEventStatus.Ignored);
  }
}
