<loan-summary-bar [hasRateLockExpirationKeyDate]="hasRateLockExpirationKeyDate"
  [borrowers]="applicationContext ? applicationContext.borrowers : []">
</loan-summary-bar>

<div class="button-items loan-controls">
  <div class="btn-group" [ngClass]="{'d-none': isNiceInContactVisible}">
    <button class="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown">
      Add Task ... <i class="fas fa-caret-down"></i>
    </button>
    <div class="dropdown-menu">
      <a class="dropdown-item" (click)="$event.stopPropagation();internalMenuActive = !internalMenuActive"> Internal
        <i class="ms-2 fas fa-caret-{{ internalMenuActive ? 'down' : 'right'}}"></i>
      </a>
      <div style="padding-left: 10px !important" *ngIf="internalMenuActive">
        <a class="dropdown-item" (click)="addNewTask('RequestDocument', false, null, null)"> Task </a>
        <a class="dropdown-item" (click)="addNewTask('RequestDocument', true, null, null)"> Condition </a>
      </div>
      <a class="dropdown-item" (click)="$event.stopPropagation();borrowerMenuActive = !borrowerMenuActive"> Borrower
        <i class="ms-2 fas fa-caret-{{ borrowerMenuActive ? 'down' : 'right'}}"></i>
      </a>
      <div style="padding-left: 10px !important" *ngIf="borrowerMenuActive">
        <a class="dropdown-item" (click)="addNewTask('RequestDocument', false, true, null)"> Task </a>
        <a class="dropdown-item" (click)="addNewTask('RequestDocument', true, true, null)"> Condition </a>
        <a class="dropdown-item" (click)="addNewTask('RequestInformation', false, true, null)"> Ask Question </a>
      </div>
      <a class="dropdown-item"
        (click)="$event.stopPropagation();quickClientUpdateMenuActive = !quickClientUpdateMenuActive"> Quick Client
        Update
        <i class="ms-2 fas fa-caret-{{ quickClientUpdateMenuActive ? 'down' : 'right'}}"></i>
      </a>
      <div style="padding-left: 10px !important" *ngIf="quickClientUpdateMenuActive">
        <a class="dropdown-item" (click)="quickClientUpdateClicked('QuickClientUpdateReferralLead')"> Referral Lead </a>
        <a class="dropdown-item" (click)="quickClientUpdateClicked('QuickClientUpdatePreApproved')"> Pre Approved </a>
        <a class="dropdown-item" (click)="quickClientUpdateClicked('QuickClientUpdateInProcess')"> In Process </a>
      </div>
      <a *ngIf="showESignTaskOption" class="dropdown-item" (click)="addNewTask('EsignDocument', null, null, null)">
        E-sign Task
      </a>
      <a *ngIf="onlineAppEnabled && !onlineAppTaskExists" class="dropdown-item" (click)="addOnlineAppTask()">
        Borrower Online App
      </a>
      <a *ngIf="loanBeamEnabled && !loanBeamDocSyncTaskExists" class="dropdown-item"
        (click)="addLoanBeamDocSyncTask()">LoanBeam Doc Sync</a>
    </div>
  </div>
  <!-- <button type="button" class="btn btn-outline-primary"><i class="fas fa-redo-alt fa-rotate-180"></i></button> -->
  <button type="button" class="btn btn-outline-primary control-btn" [ngClass]="{'d-none': isNiceInContactVisible}"
    (click)="onKeyDatesClicked()" title="Key Dates">
    <i class="ti-calendar"></i>
  </button>
  <!-- <button type="button" class="btn btn-outline-primary"> Pre-Approval Letter </button> -->
  <div class="btn-group control-btn" [ngClass]="{'d-none': isNiceInContactVisible}">
    <button class="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown"
      (click)="systemGenerateDocumentMenuActive = false">
      Generate Document ... <i class="fas fa-caret-down"></i>
    </button>
    <div class="dropdown-menu">
      <a class="dropdown-item" (click)="onGenerateDocClicked()">Custom</a>
      <a class="dropdown-item" (click)="
          $event.stopPropagation();
          systemGenerateDocumentMenuActive = !systemGenerateDocumentMenuActive
        ">
        System
        <i class="ms-2 fas fa-caret-{{
            systemGenerateDocumentMenuActive ? 'down' : 'right'
          }}"></i></a>
      <div style="padding-left: 10px !important" *ngIf="systemGenerateDocumentMenuActive">
        <a class="dropdown-item" (click)="onCreditInquiryClicked()">Credit Inquiry LOE</a>
        <a class="dropdown-item" (click)="onGenerateDocClicked(true)">Prequalification Letter</a>
        <a class="dropdown-item" (click)="exportUrlaDocs()">URLA</a>
      </div>
    </div>
  </div>
  <button type="button" class="btn btn-outline-primary control-btn" [ngClass]="{'d-none': isNiceInContactVisible}"
    (click)="onLoanActivityToggled()" title="Loan Activity">
    <i class="ti-comment-alt"></i>
  </button>
  <button type="button" id="buttonMenu" class="btn btn-outline-primary" data-bs-container="body"
    data-bs-toggle="popover" placement="bottom" [popover]="templateMenu" data-trigger="click" [outsideClick]="true"
    [containerClass]="'templateMenu '">
    <i class="ti-more-alt"></i>
  </button>
  <ng-template #templateMenu>
    <loan-summary-more-menu [borrowerId]="borrowerId" [borrowers]="borrowers" [isUrlaFormOpened]="urlaFormOpened"
      [onlineAppEnabled]="onlineAppEnabled" [onlineAppTaskExists]="onlineAppTaskExists"
      [loanBeamEnabled]="loanBeamEnabled" [loanBeamDocSyncTaskExists]="loanBeamDocSyncTaskExists"
      [showESignTaskOption]="showESignTaskOption" [isNiceInContactVisible]="isNiceInContactVisible"
      (closeRequested)="onCloseLoanSummaryMoreMenu($event)" (generateDocClicked)="onGenerateDocClicked($event)"
      (setAppointmentClicked)="onSetAppointmentClicked()" (sendEmailSmsClicked)="onSendEmailSmsClicked()"
      (sendEmailClicked)="onSendEmailClicked()" (emailSmsHistoryClicked)="onEmailSmsHistoryClicked()"
      (sendVideoEmailClicked)="onSendVideoEmailClicked()"
      (loanSummaryClicked)="onLoanSummaryClicked()" (disclosureTrackingClicked)="onDisclosureTrackingClicked()"
      (loanActivityToggled)="onLoanActivityToggled()" (exportMismoClicked)="onExportMismoClicked()"
      (exportDUClicked)="onExportDUClicked()" (importMismoClicked)="onImportMismoClicked()"
      (mcrAuditDataClicked)="onMcrAuditDataClicked()" (viewEsignatureHistoryClicked)="onViewEsignatureHistoryClicked()"
      (keyDatesClicked)="onKeyDatesClicked()" (viewTitleHistoryClicked)="onViewTitleHistoryClicked()"
      (mortgageCalculatorsClicked)="onCalculatorsClicked()" (monsterInsightsClicked)="onMonsterInsightsClicked()"
      (checkListsClicked)="onCheckListsClicked()" (denialClicked)="onDenialClicked()"
      (exportsUrlaDocsClicked)="exportUrlaDocs()" (creditInquiryClicked)="onCreditInquiryClicked()"
      (createWireRequestClicked)="onCreateWireRequestClicked()"
      (addNewTaskClicked)="onAddNewTaskClicked($event)"
      (addOnlineAppTaskClicked) = "onAddOnlineAppTaskClicked()" (addLoanBeamDocSyncTaskClicked)="onAddLoanBeamDocSyncTaskClicked()"
      (identifyingDocumentsClicked)="onIdentifyingDocumentsClicked()" (purchaseAdviceClicked)="onPurchaseAdviceClicked()">
    </loan-summary-more-menu>
  </ng-template>
</div>