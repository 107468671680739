<div id="navigation" class="navigation-menu-mobile active">
  <!-- Navigation Menu-->
  <ul class="navigation-menu">
    <li *ngFor="let item of menu; let i = index;" [class.has-submenu]="item.children && item.children.length"
      [hidden]="(!item.requireSuperAdminPermission && companyId == 1) || (item.requireAdminPermission && !userHasAdminPermission) || (item.requireSuperAdminPermission && !userHasSuperAdminPermission) || (item.restrictedWhileImpersonating && userHasSuperAdminPermission && userIsImpersonating)">
      <a [ngClass]="{'active': item.selected }" [routerLink]="item.link ? ['/admin' + item.link] : undefined"
        (click)="onMenuToggled(i, item, 1);">
        <span> <i [ngClass]="item.icon"></i> {{ item.text }}</span>
      </a>
      <ul *ngIf="item.children" class="submenu" [ngClass]="{'open' : menuItemStatuses.get(i)}">
        <li *ngFor="let ch of item.children; let ii = index;" class="header__item--parent"
          [hidden]="ch.hide ||
            (ch.requireAdminPermission && !userHasAdminPermission) ||
            (ch.requireCallReportPermission && !allowMortgageCallReports) ||
            (ch.requireEnabledPricingPermission && !pricingEnabled) ||
            (ch.requireExternalCompanyEnabled && !externalCompanyEnabled) ||
            (ch.requireSuperAdminPermission && !userHasSuperAdminPermission) ||
            (ch.requiredEnabledLeadManagementPermission && !leadManagementEnabled) ||
            (ch.requireBroadcastManagerAccessPermission && !broadcastManagerEnabled)">
          <a *ngIf="!ch.externalLink" [ngClass]="{'active': ch.selected }" [routerLink]="getRouterLink(ch)" [class.has-submenu]="ch.children"
            (click)="onMenuToggled(i + '.' + ii, ch, 2)">
            <i [ngClass]="ch.icon ? ch.icon : item.icon"></i> {{ ch.text }}
            <i class="fas fa-chevron-right float-right" *ngIf="ch.children"></i>
          </a>
          <a *ngIf="ch.externalLink" href="{{ch.link || ''}}" target="_blank">
            <i [ngClass]="ch.icon ? ch.icon : ch.icon"></i> {{ ch.text }}
          </a>
          <ul *ngIf="ch.children" class="submenu" [ngClass]="{'open' : menuItemStatuses.get(i + '.' + ii)}">
            <li *ngFor="let sub of ch.children; let iii = index;" class="header__item--parent"
              [hidden]="(sub.requireAdminPermission && !userHasAdminPermission) || (sub.requireEnabledBranchesPermission && !branchesEnabled) || (sub.requireEnabledPricingPermission && !pricingEnabled) || (sub.requireExternalCompanyEnabled && !externalCompanyEnabled)">
              <a *ngIf="!sub.externalLink" [ngClass]="{'active': sub.selected }" [routerLink]="getRouterLink(sub)"
                (click)="onMenuToggled(i + '.' + ii + '.' + iii, sub, 3)">
                <i [ngClass]="sub.icon ? sub.icon : item.icon"></i> {{ sub.text }}
              </a>
              <a *ngIf="sub.externalLink" href="{{sub.link || ''}}" target="_blank">
                <i [ngClass]="sub.icon ? sub.icon : item.icon"></i> {{ sub.text }}
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
</div>
